export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE";

export const GET_ALL_EVENTS_REQUEST = "GET_ALL_EVENTS_REQUEST";
export const GET_ALL_EVENTS_SUCCESS = "GET_ALL_EVENTS_SUCCESS";
export const GET_ALL_EVENTS_FAILURE = "GET_ALL_EVENTS_FAILURE";

export const GET_ALL_FACILTY_REQUEST = "GET_ALL_FACILTY_REQUEST";
export const GET_ALL_FACILTY_SUCCESS = "GET_ALL_FACILTY_SUCCESS";
export const GET_ALL_FACILTY_FAILURE = "GET_ALL_FACILTY_FAILURE";

export const GET_ALL_EVENTS_BY_USERID_REQUEST =
  "GET_ALL_EVENTS_BY_USERID_REQUEST";
export const GET_ALL_EVENTS_BY_USERID_SUCCESS =
  "GET_ALL_EVENTS_BY_USERID_SUCCESS";
export const GET_ALL_EVENTS_BY_USERID_FAILURE =
  "GET_ALL_EVENTS_BY_USERID_FAILURE";

export const EDIT_EVENT_REQUEST = "EDIT_EVENT_REQUEST";
export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_FAILURE = "EDIT_EVENT_FAILURE";

export const REMOVE_TIME_UPDATE_EVENT_REQUEST =
  "REMOVE_TIME_UPDATE_EVENT_REQUEST";
export const REMOVE_TIME_UPDATE_EVENT_SUCCESS =
  "REMOVE_TIME_UPDATE_EVENT_SUCCESS";
export const REMOVE_TIME_UPDATE_EVENT_FAILURE =
  "REMOVE_TIME_UPDATE_EVENT_ERROR";

export const REMOVE_ENTIRE_EVENT_SUCCESS = "REMOVE_ENTIRE_EVENT_SUCCESS";
export const REMOVE_ENTIRE_EVENT_REQUEST = "REMOVE_ENTIRE_EVENT_REQUEST";
export const REMOVE_ENTIRE_EVENT_FAILURE = "REMOVE_ENTIRE_EVENT_FAILURE";

export const TEAM_CALENDAR_SIDE_TEAM_SELECTION_SUCCESS =
  "TEAM_CALENDAR_SIDE_TEAM_SELECTION_SUCCESS";
export const TEAM_CALENDAR_SIDE_TEAM_SELECTION_REQUEST =
  "TEAM_CALENDAR_SIDE_TEAM_SELECTION_REQUEST";
export const TEAM_CALENDAR_SIDE_TEAM_SELECTION_FAILURE =
  "TEAM_CALENDAR_SIDE_TEAM_SELECTION_FAILURE";

export const RESET_STATE = "RESET_STATE";

export const EXPORT_EVENTS_REQUEST = "EXPORT_EVENTS_REQUEST";
export const EXPORT_EVENTS_SUCCESS = "EXPORT_EVENTS_SUCCESS";
export const EXPORT_EVENTS_FAILURE = "EXPORT_EVENTS_FAILURE";

export const GET_USER_INTEREST_LIST_REQEUST = "GET_USER_INTEREST_LIST_REQEUST";
export const GET_USER_INTEREST_LIST_SCCESS = "GET_USER_INTEREST_LIST_SCCESS";
export const GET_USER_INTEREST_LIST_FAILURE = "GET_USER_INTEREST_LIST_FAILURE";

export const EDIT_EVENTS_DETAILS = "EDIT_EVENTS_DETAILS";
