// import firebase from "firebase/compat/app";

// const firebaseConfig = {
//   apiKey: "AIzaSyASqRRO-horF5USuMf0PO1_vP0nn3W0xf4",
//   authDomain: "yo-playbook.firebaseapp.com",
//   projectId: "yo-playbook",
//   storageBucket: "yo-playbook.appspot.com",
//   messagingSenderId: "1004134748660",
//   appId: "1:1004134748660:web:d9a9eac16b1c738b5a29a1",
//   measurementId: "G-QXN0EPK7KY",
// };

// firebase.initializeApp(firebaseConfig);

// export default firebase;

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// vijay demo
// const firebaseConfig = {
//   apiKey: "AIzaSyCN-e7A2R0bOKwivgN58Qb5nhZ9fXZxg9o",
//   authDomain: "fir-analytics-demo-42f47.firebaseapp.com",
//   projectId: "fir-analytics-demo-42f47",
//   storageBucket: "fir-analytics-demo-42f47.appspot.com",
//   messagingSenderId: "682119058553",
//   appId: "1:682119058553:web:1148da3b708f88d142b0a8",
//   measurementId: "G-M1XEK849M7"
// };

//client's old account
// const firebaseConfig = {
//   apiKey: "AIzaSyCAii7uMQexBOjl16VPxXFIEtE1QvvcgHs",
//   authDomain: "yo-playbook-e5254.firebaseapp.com",
//   projectId: "yo-playbook-e5254",
//   storageBucket: "yo-playbook-e5254.appspot.com",
//   messagingSenderId: "691851012311",
//   appId: "1:691851012311:web:d5b07d5c09ca358bf56892",
//   measurementId: "G-C91PL6TYEC",
// };

const firebaseConfig = {
  apiKey: "AIzaSyBqbgP-Q-cRuVEkEY6u4OebMQjTOeMFq44",
  authDomain: "yo-playbook-6de87.firebaseapp.com",
  projectId: "yo-playbook-6de87",
  storageBucket: "yo-playbook-6de87.appspot.com",
  messagingSenderId: "829056938321",
  appId: "1:829056938321:web:80060998799cd98710a370",
  measurementId: "G-0R83CWNH8T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Export analytics and logEvent for use in other files
export { analytics, logEvent, app };
