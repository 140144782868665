import {
  GET_GIF_DATA,
  GET_PROFILE_LIST_DATA_FAILURE,
  GET_PROFILE_LIST_DATA_REQUEST,
  GET_PROFILE_LIST_DATA_SUCCESS,
} from "../constants/profileList";

export function profileListReducer(state = {}, action) {
  switch (action.type) {
    case GET_PROFILE_LIST_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE_LIST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        getProfileListData: action.payload,
      };
    case GET_GIF_DATA:
      return {
        ...state,
        loading: false,
        gifData: action.payload,
      };
    case GET_PROFILE_LIST_DATA_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
