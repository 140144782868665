export const CREATE_HOBBIES_REQUEST = "CREATE_HOBBIES_REQUEST";
export const CREATE_HOBBIES_SUCCESS = "CREATE_HOBBIES_SUCCESS";
export const CREATE_HOBBIES_FAILURE = "CREATE_HOBBIES_FAILURE";

export const UPDATE_HOBBIES_REQUEST = "UPDATE_HOBBIES_REQUEST";
export const UPDATE_HOBBIES_SUCCESS = "UPDATE_HOBBIES_SUCCESS";
export const UPDATE_HOBBIES_FAILURE = "UPDATE_HOBBIES_FAILURE";

export const DELETE_HOBBIES_REQUEST = "DELETE_HOBBIES_REQUEST";
export const DELETE_HOBBIES_SUCCESS = "DELETE_HOBBIES_SUCCESS";
export const DELETE_HOBBIES_FAILURE = "DELETE_HOBBIES_FAILURE";

export const GET_ALL_HOBBIES_REQUEST = "GET_ALL_HOBBIES_REQUEST";
export const GET_ALL_HOBBIES_SUCCESS = "GET_ALL_HOBBIES_SUCCESS";
export const GET_ALL_HOBBIES_FAILURE = "GET_ALL_HOBBIES_FAILURE";

export const GET_HOBBIES_BY_ID_REQUEST = "GET_HOBBIES_BY_ID_REQUEST";
export const GET_HOBBIES_BY_ID_SUCCESS = "GET_HOBBIES_BY_ID_SUCCESS";
export const GET_HOBBIES_BY_ID_FAILURE = "GET_HOBBIES_BY_ID_FAILURE";

export const GET_HOBBIES_LIST_REQUEST = "GET_HOBBIES_LIST_REQUEST";
export const GET_HOBBIES_LIST_SUCCESS = "GET_HOBBIES_LIST_SUCCESS";
export const GET_HOBBIES_LIST_FAILURE = "GET_HOBBIES_LIST_FAILURE";

export const RESET = "RESET";
