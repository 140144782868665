import {
  TESTIMONIAL_REQUEST,
  TESTIMONIAL_SUCCESS,
  TESTIMONIAL_FAILURE,
} from "../constants/dashboard";

export function dashboardReducer(state = {}, action) {
  switch (action.type) {
    case TESTIMONIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TESTIMONIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        testimonialData: action.payload,
      };
    case TESTIMONIAL_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
