import {
  CREATE_SOCIAL_LINKS_FAILURE,
  CREATE_SOCIAL_LINKS_REQUEST,
  CREATE_SOCIAL_LINKS_SUCCESS,
  DELETE_SOCIAL_LINKS_FAILURE,
  DELETE_SOCIAL_LINKS_REQUEST,
  DELETE_SOCIAL_LINKS_SUCCESS,
  GET_ALL_SOCIAL_LINKS_FAILURE,
  GET_ALL_SOCIAL_LINKS_REQUEST,
  GET_ALL_SOCIAL_LINKS_SUCCESS,
  GET_SOCIAL_LINKS_BY_ID_FAILURE,
  GET_SOCIAL_LINKS_BY_ID_REQUEST,
  GET_SOCIAL_LINKS_BY_ID_SUCCESS,
  UPDATE_SOCIAL_LINKS_FAILURE,
  UPDATE_SOCIAL_LINKS_REQUEST,
  UPDATE_SOCIAL_LINKS_SUCCESS,
  GET_SOCIAL_LINKS_LIST_REQUEST,
  GET_SOCIAL_LINKS_LIST_SUCCESS,
  GET_SOCIAL_LINKS_LIST_FAILURE,
  RESET,
} from "../constants/socialLinks";

const initialState = {};

export function socialLinksReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_SOCIAL_LINKS_REQUEST:
    case UPDATE_SOCIAL_LINKS_REQUEST:
    case DELETE_SOCIAL_LINKS_REQUEST:
    case GET_ALL_SOCIAL_LINKS_REQUEST:
    case GET_SOCIAL_LINKS_BY_ID_REQUEST:
    case GET_SOCIAL_LINKS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        createSocialLinksData: action.payload,
      };
    case UPDATE_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSocialLinksData: action.payload,
      };
    case DELETE_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSocialLinksData: action.payload,
      };
    case GET_ALL_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllSocialLinksData: action.payload,
      };
    case GET_SOCIAL_LINKS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getSocialLinksByIdData: action.payload,
      };
    case GET_SOCIAL_LINKS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getSocialLinkListData: action.payload,
      };
    case CREATE_SOCIAL_LINKS_FAILURE:
    case UPDATE_SOCIAL_LINKS_FAILURE:
    case DELETE_SOCIAL_LINKS_FAILURE:
    case GET_ALL_SOCIAL_LINKS_FAILURE:
    case GET_SOCIAL_LINKS_BY_ID_FAILURE:
    case GET_SOCIAL_LINKS_LIST_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
