import React from "react";
import axios from "axios";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import RightChatBody from "./RightChatBody";
import {
  GetAllRoomByUser,
  GetAllChatByRoom,
  GetAllChatGroupMember,
  getAllUnseenMessagesCount,
} from "../../../store/action/chatAction";
import { Toast } from "primereact/toast";
// import FileSaver from "file-saver";
import { useNavigate } from "react-router-dom";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { IndividualChatUsersResult } from "../../../store/action/newChatAction";
import {
  getAllDataByRoomId,
  updateSeenMessages,
} from "../../../store/action/chatAction";
import { GET_ALL_CHAT_BY_ROOMID_RESET } from "../../../store/constants/chat";
import { Avatar } from "primereact/avatar";
import Follow from "../../Profile/UserProfileBody/ProfileTabSection/SinglePost/Follow";
import LeftChatBody from "./LeftChatBody";
import useFetchAllGif from "../../../hooks/useFetchAllGif";
import { useGetUnder13User } from "../../../hooks/useGetUnder13User";
import { EVENT_SCREENS } from "../../../configue/firebase/EventConstants";
import FirebaseLogEvents from "../../../configue/firebase/LogEvents";

const socket = io.connect(process.env.REACT_APP_API_URL);

function TeamChatList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUnder13 = useGetUnder13User();
  const { getAllRoomByUserData } = useSelector((state) => state.chat);
  const [groupUserLists, setGroupUserLists] = React.useState([]);
  const [newGroupMessage, setNewGroupMessage] = React.useState();
  const [isActive, setIsActive] = React.useState(false);
  const [isSelectFileType, setIsSelectFileType] = React.useState(false);
  const [tagName, setTagName] = React.useState("");
  const [teamName, setTeamName] = React.useState("");
  const [isShowSettings, setIsShowSettings] = React.useState(false);
  const [isShowMedia, setIsShowMedia] = React.useState(false);
  const [isShowMember, setIsShowMember] = React.useState(false);
  const [isShowGifPicker, setIsShowGifPicker] = React.useState(false);
  const [room, setRoom] = React.useState("");
  const [roomData, setRoomData] = React.useState(null);
  const [roomLogo, setRoomLogo] = React.useState("");
  const [userList, setUserList] = React.useState([]);
  const [newMessage, setNewMessage] = React.useState();
  const [showEmoji, setShowEmoji] = React.useState(false);
  const {
    getAllChatByRoomData,
    getAllChatByRoomIdData,
    getAllUnseenCountData,
    getAllChatGroupMemberData,
  } = useSelector((state) => state.chat);
  const toast = React.useRef(null);
  const [currentMessage, setCurrentMessage] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [blobFiles, setBlobFiles] = React.useState([]);
  const [isDoc, setIsDoc] = React.useState(false);
  const [isSelectFile, setIsSelectFile] = React.useState(false);
  // const [messageList, setMessageList] = React.useState([]);
  const [indivisualMessageList, setIndivisualMessageList] = React.useState([]);
  const messagesEndRef = React.useRef(null);
  const [chatRoomData, setChatRoomData] = React.useState([]);
  const [activeSection, setActiveSection] = React.useState(
    isUnder13 ? "teamone" : "individualone"
  );
  const [receviedUserId, setReceivedUserId] = React.useState("");
  const [chatManagementId, setChatManagementId] = React.useState("");
  const [individualValue, setIndividualValue] = React.useState("");
  const [onlineUsersList, setOnlineUsersList] = React.useState([]);
  const [serachedGifText, setSearchedGifText] = React.useState("");
  const gifBoxRef = React.useRef(null);

  const { individualChatUserData } = useSelector(
    (state) => state.newChatReducer
  );
  const scrollToBottom = React.useCallback(() => {
    messagesEndRef.current?.scrollIntoView(false);
  }, []);

  const gifLists = useFetchAllGif(serachedGifText);

  React.useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  React.useEffect(() => {
    if (isActive) {
      FirebaseLogEvents(
        EVENT_SCREENS.chat_listing.event_name,
        EVENT_SCREENS.chat_listing.event_description
      );
    }
  }, [isActive]);

  React.useEffect(() => {
    if (room) {
      FirebaseLogEvents(
        EVENT_SCREENS.chat.event_name,
        EVENT_SCREENS.chat.event_description
      );
    }
  }, [room]);

  // // close socket after browser close
  // React.useEffect(() => {
  //   const handleBeforeUnload = (ev) => {
  //     ev.preventDefault();
  //     socket.emit("Disconnect", sessionStorage.getItem("id"));
  //     socket.emit("user-disconnect", sessionStorage.getItem("id"));
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     // socket.close();
  //   };
  // }, []);

  // join team group

  React.useEffect(() => {
    if (
      getAllRoomByUserData &&
      getAllRoomByUserData?.data &&
      isActive &&
      activeSection === "teamone"
    ) {
      setGroupUserLists(getAllRoomByUserData.data);
      for (let data of getAllRoomByUserData.data) {
        if (data && data?.chat_user_management?.vRoomId) {
          socket.emit("join_room", data?.chat_user_management?.vRoomId);
        }
      }
    }
  }, [getAllRoomByUserData, activeSection, isActive]);

  // received team group messge
  React.useEffect(() => {
    socket.on("receive_message", (data) => {
      setChatRoomData((list) => [...list, data]);
      setNewGroupMessage(data);
    });
  }, [setChatRoomData]);

  // Real time manage count for receiving new message (Team Group)
  let filterGroupUserList = React.useCallback(
    (msg) => {
      if (getAllRoomByUserData && getAllRoomByUserData.code === "200") {
        // for buffer storage
        let found = getAllRoomByUserData?.data.find(
          (element) => element?.chat_user_management?.vRoomId === msg.vRoomId
        );
        let unFound = getAllRoomByUserData?.data.filter(
          (element) => element?.chat_user_management?.vRoomId !== msg.vRoomId
        );
        found = {
          ...found,
          chat_user_management: {
            ...found.chat_user_management,
            eSeenCount: ++found.chat_user_management.eSeenCount,
            LastMessage: msg.vMessage,
            AddedTime: msg.dAddedTime,
            image_Count: msg.chat_file_transactions?.length,
            eType: msg?.eType,
          },
        };
        setGroupUserLists([found, ...unFound]);
      }
    },
    [getAllRoomByUserData]
  );

  // this useEffect render when new message would be got for count filter
  React.useEffect(() => {
    if (newGroupMessage !== undefined) {
      filterGroupUserList(newGroupMessage);
    }
  }, [filterGroupUserList, newGroupMessage]);

  //  =================== Start this is method is used to grouped chat date wise ===============================
  const groupedDays = React.useCallback((messages) => {
    return messages?.reduce((acc, el, i) => {
      const messageDay = moment(el.dAddedDate).format(
        `${process.env.REACT_APP_DATE_FORMAT}`
      );
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
  }, []);

  const generateItems = React.useCallback(
    (messages) => {
      const days = groupedDays(messages);
      const sortedDays = Object.keys(days).sort(
        (x, y) =>
          moment(x, `${process.env.REACT_APP_DATE_FORMAT}`).unix() -
          moment(y, `${process.env.REACT_APP_DATE_FORMAT}`).unix()
      );
      const items = sortedDays?.reduce((acc, date) => {
        const sortedMessages = days[date].sort(
          (x, y) => new Date(x.dAddedDate) - new Date(y.dAddedDate)
        );
        return acc.concat([{ type: "day", date, id: date }, ...sortedMessages]);
      }, []);
      if (activeSection === "teamone") {
        setChatRoomData(items);
      } else {
        setIndivisualMessageList(items);
      }
    },
    [setChatRoomData, activeSection, setIndivisualMessageList, groupedDays]
  );
  //  ==================== END this is method is used to grouped chat date wise ====================

  // if chat room data is not null then store on this state setChatRoomData
  React.useEffect(() => {
    if (getAllChatByRoomData && getAllChatByRoomData?.code === "200") {
      // let isImg = true;
      // let items = [];
      // for (let data of getAllChatByRoomData.data) {
      //   if (+data.iAddedById === +sessionStorage.getItem("id")) {
      //     isImg = true;
      //     items.push({ ...data, isReceivedImg: isImg });
      //   } else {
      //     items.push({ ...data, isReceivedImg: isImg });
      //     isImg = false;
      //   }
      // }
      generateItems(getAllChatByRoomData.data);
    } else {
      setChatRoomData([]);
    }
  }, [setChatRoomData, generateItems, getAllChatByRoomData]);

  // when the user remove the message then used... (Team Chat)
  React.useEffect(() => {
    if (socket) {
      let items = [];
      socket.on("delete_receive_message", (data) => {
        if (
          sessionStorage.getItem("roomId") &&
          data.vRoomId === sessionStorage.getItem("roomId")
        ) {
          setChatRoomData((pre) => (items = [...pre]));
          let tempIndx = items.findIndex(
            (val) => +val.iChatId === +data.iChatId
          );
          items.splice(tempIndx, 1, data);
          setChatRoomData([...items]);
          return;
        } else {
          let list = [];
          setGroupUserLists((pre) => (list = [...pre]));
          let tempIndex = list.findIndex(
            (val) => val?.chat_user_management?.vRoomId === data.vRoomId
          );
          let matchList = list.find(
            (val) => val?.chat_user_management?.vRoomId === data.vRoomId
          );
          list.splice(tempIndex, 1, {
            ...matchList,
            chat_user_management: {
              ...matchList.chat_user_management,
              eDelete: "Yes",
            },
          });
          setGroupUserLists([...list]);
        }
      });
    }
  }, []);

  // send message for team group
  const send = React.useCallback(
    async (gifData = "") => {
      setCurrentMessage("");
      setFiles([]);
      setBlobFiles([]);
      const currentTime = moment();
      let t = moment(
        moment(currentTime.format("YYYY-MM-DD")).format(
          `${process.env.REACT_APP_DATE_FORMAT}`
        ) +
          " " +
          currentTime.format("hh:mm:ss")
      )
        .utc()
        .format("hh:mm A");
      const timeMoment = moment(t, "HH:mm:ss");
      const currentHour = timeMoment.format("hh"); // HH for 24-hour format
      const currentMinute = timeMoment.format("mm");
      const currentSec = timeMoment.format("ss");
      let dateTime = moment();
      dateTime.set("hour", currentHour);
      dateTime.set("minute", currentMinute);
      dateTime.set("second", currentSec);
      let addedDateTime = dateTime.format("YYYY-MM-DD, HH:mm:ss a");
      if (currentMessage.trim() !== "" || files.length > 0 || gifData !== "") {
        const formData = new FormData();
        formData.append(
          "iChatUserManagementId",
          roomData?.ChatUserManagementId
        );
        formData.append("vRoomId", room);
        formData.append("vUserName", roomData?.team?.tTeamTagline);
        formData.append("iAddedById", sessionStorage.getItem("id"));
        formData.append("vMessage", currentMessage);
        formData.append("dAddedTime", addedDateTime);
        formData.append("iTeamId", roomData?.iTeamId);
        if (gifData !== "") {
          formData.append("vLink", gifData);
        }
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            formData.append("vFile", files[i].vFile);
          }
        }
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        const teamRes = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/Chat/createChat`,
          formData,
          config
        );
        if (teamRes && teamRes?.data?.code === "200") {
          socket.emit("send_message", JSON.stringify(teamRes?.data?.data));
          if (
            chatRoomData.length > 0 &&
            moment(chatRoomData[chatRoomData.length - 1]?.dAddedDate).format(
              `${process.env.REACT_APP_DATE_FORMAT}`
            ) === moment().format(`${process.env.REACT_APP_DATE_FORMAT}`)
          ) {
            setChatRoomData((list) => [...list, teamRes?.data?.data]);
          } else {
            let date = {
              type: "day",
              date: moment().format(`${process.env.REACT_APP_DATE_FORMAT}`),
              id: moment().format(`${process.env.REACT_APP_DATE_FORMAT}`),
            };
            setChatRoomData((pre) => [
              ...pre,
              date,
              {
                ...teamRes?.data?.data,
              },
            ]);
          }
          setIsDoc(false);
          if (files.length > 0) setIsSelectFile(false);
        }
      }
    },
    [
      currentMessage,
      chatRoomData,
      setIsDoc,
      setBlobFiles,
      setIsSelectFile,
      files,
      setFiles,
      setCurrentMessage,
      room,
      roomData,
      setChatRoomData,
    ]
  );

  const handleClick = React.useCallback(
    (event) => {
      event.preventDefault();
      setIsActive((current) => !current);
      socket.emit("new-user-add", sessionStorage.getItem("id"));
      setRoomData();
      setRoom("");
      setRoomLogo("");
      setIndividualValue("");
      setIsShowSettings(false);
      setIsShowMedia(false);
      setIsShowMember(false);
      if (isUnder13) {
        setActiveSection("teamone");
      } else {
        setActiveSection("individualone");
      }
      sessionStorage.removeItem("roomId");
      dispatch(
        getAllUnseenMessagesCount({ iUserId: sessionStorage.getItem("id") })
      );
      dispatch(GetAllRoomByUser());
      dispatch(
        IndividualChatUsersResult({ iUserId: sessionStorage.getItem("id") })
      );
    },
    [dispatch, setIndividualValue, isUnder13, setActiveSection, setIsActive]
  );

  const handleCloseClick = React.useCallback(
    (event) => {
      event.preventDefault();
      socket.emit("Disconnect", sessionStorage.getItem("id"));
      socket.emit("user-disconnect", sessionStorage.getItem("id"));
      setIsActive((current) => !current);
      setRoomData();
      setShowEmoji(false);
      setRoom("");
      setRoomLogo("");
      setIsShowSettings(false);
      setIsShowMedia(false);
      setIsShowMember(false);
      setActiveSection("teamone");
      setIndividualValue("");
      sessionStorage.removeItem("roomId");
      setCurrentMessage("");
      dispatch(
        getAllUnseenMessagesCount({ iUserId: sessionStorage.getItem("id") })
      );
    },
    [dispatch, setActiveSection, setIsActive]
  );

  const handleFileChange = React.useCallback(
    (event) => {
      const targetFiles = event.target.files;
      let videoType = ["video/mp4", "video/webm", "video/mpeg"];
      const targetFilesObject = [...targetFiles];
      if (
        event.target.files.length > 30 ||
        files?.length + targetFilesObject?.length > 30
      ) {
        toast.current.show({
          severity: "warn",
          summary: "You can not add more than 30 files.",
          life: 3000,
        });
      } else {
        targetFilesObject.map((file) => {
          if (isDoc) {
            if (Math.round(file?.size / 1024 / 1024) > 5) {
              toast.current.show({
                severity: "warn",
                summary: "You Can't Upload More Than 5MB Document",
                life: 3000,
              });
            } else {
              setFiles((prevDoc) => [
                ...prevDoc,
                {
                  eType: "Application",
                  vFile: file,
                  vSize: file.size,
                },
              ]);
              const reader = new FileReader();
              reader.onload = function (e) {
                const fileContent = e.target.result;
                setBlobFiles((prevDoc) => [
                  ...prevDoc,
                  {
                    eType: "Application",
                    vFile: fileContent,
                    vSize: file.size,
                  },
                ]);
              };
              reader.readAsDataURL(file);
              // setBlobFiles((prevDoc) => [
              //   ...prevDoc,
              //   {
              //     eType: "Application",
              //     vFile: URL.createObjectURL(file),
              //     vSize: file.size,
              //   },
              // ]);
            }
          } else if (videoType.includes(file.type)) {
            if (Math.round(file?.size / 1024 / 1024) > 10) {
              toast.current.show({
                severity: "warn",
                summary: "You Can't Upload More Than 10MB Video",
                life: 3000,
              });
            } else {
              setFiles((prevImages) => [
                ...prevImages,
                {
                  eType: "video",
                  vFile: file,
                  vSize: file.size,
                },
              ]);
              setBlobFiles((prevImages) => [
                ...prevImages,
                {
                  eType: "video",
                  vFile: URL.createObjectURL(file),
                  vSize: file.size,
                },
              ]);
            }
          } else {
            if (Math.round(file?.size / 1024 / 1024) > 5) {
              toast.current.show({
                severity: "warn",
                summary: "You Can't Upload More Than 5MB Images",
                life: 3000,
              });
            } else {
              setFiles((prevImages) => [
                ...prevImages,
                {
                  eType: "image",
                  vFile: file,
                  vSize: file.size,
                },
              ]);
              setBlobFiles((prevImages) => [
                ...prevImages,
                {
                  eType: "image",
                  vFile: URL.createObjectURL(file),
                  vSize: file.size,
                },
              ]);
            }
          }
          return false;
        });
      }
    },
    [toast, files, isDoc, setBlobFiles, setFiles]
  );

  const handleJoinRoom = React.useCallback(
    (e, team, chat_user_management, data) => {
      e.preventDefault();
      setTagName(team.tTeamTagline);
      setTeamName(team.vTeamName);
      setRoomLogo(team.vTeamLogo);
      setIndividualValue("");
      setRoomData(data);
      if (chat_user_management && chat_user_management?.vRoomId) {
        setRoom(chat_user_management?.vRoomId);
        sessionStorage.setItem("roomId", chat_user_management?.vRoomId);
        // socket.emit("join_room", chat_user_management?.vRoomId);
        dispatch(
          updateSeenMessages({
            iAddedById: sessionStorage.getItem("id"),
            vRoomId: data?.chat_user_management?.vRoomId,
          })
        );
        dispatch(
          GetAllChatByRoom({
            iUserId: sessionStorage.getItem("id"),
            vRoomId: chat_user_management.vRoomId,
          })
        );
      }
      if (data && data?.iTeamId) {
        dispatch(
          GetAllChatGroupMember({
            iTeamId: data.iTeamId,
            iUserId: sessionStorage.getItem("id"),
          })
        );
      }
    },
    [
      setTagName,
      setRoom,
      setIndividualValue,
      setRoomData,
      setRoomLogo,
      setTeamName,
      dispatch,
    ]
  );
  const handleTeamMemberList = React.useCallback(() => {
    setIsShowSettings(true);
  }, [setIsShowSettings]);

  // ==================== start handle indivisual join room ===========================
  // add online user
  React.useEffect(() => {
    socket.emit("new-user-add", sessionStorage.getItem("id"));
  }, []);

  // get all online users list
  React.useEffect(() => {
    if (isActive) {
      socket.on("get-users", (onlineUserList) => {
        let onlineUsers = [];
        for (let users of onlineUserList) {
          onlineUsers.push(parseInt(users.userId));
        }
        setOnlineUsersList(onlineUsers);
      });
    }
  }, [setOnlineUsersList, isActive]);

  // join into room whoever is online user
  React.useEffect(() => {
    if (individualChatUserData && individualChatUserData?.data && isActive) {
      let followersId = [];
      setUserList(individualChatUserData.data);
      for (let data of individualChatUserData.data) {
        followersId.push(data.user?.iRequesterId);
        if (
          data &&
          data?.vRoomId &&
          onlineUsersList.includes(parseInt(data.iUserId))
        ) {
          socket.emit("join_room", data.vRoomId);
        }
      }
    }
  }, [individualChatUserData, onlineUsersList, isActive]);

  React.useEffect(() => {
    dispatch(
      getAllUnseenMessagesCount({ iUserId: sessionStorage.getItem("id") })
    );
  }, [dispatch]);

  // if chat room data is not null then store on this state setIndivisualMessageList
  React.useEffect(() => {
    if (getAllChatByRoomIdData && getAllChatByRoomIdData.code === 0) {
      generateItems(getAllChatByRoomIdData.data);
    } else {
      setIndivisualMessageList([]);
    }
  }, [generateItems, getAllChatByRoomIdData, setIndivisualMessageList]);

  // receive message
  React.useEffect(() => {
    socket.on("single_receive_message", (msg) => {
      let data;
      setIndivisualMessageList((pre) => (data = [...pre]));

      if (
        +msg.iReceivedById === +sessionStorage.getItem("id") &&
        msg.vRoomId === sessionStorage.getItem("roomId")
      ) {
        if (
          data?.length === 0 ||
          (data &&
            moment(data[data?.length - 1]?.dAddedDate).format(
              `${process.env.REACT_APP_DATE_FORMAT}`
            ) !== moment().format(`${process.env.REACT_APP_DATE_FORMAT}`))
        ) {
          let date = {
            type: "day",
            date: moment().format(`${process.env.REACT_APP_DATE_FORMAT}`),
            id: moment().format(`${process.env.REACT_APP_DATE_FORMAT}`),
          };
          setIndivisualMessageList((pre) => [date, ...pre, msg]);
          return;
        }
        setIndivisualMessageList((pre) => (data = [...pre, msg]));
        dispatch(
          updateSeenMessages({
            iAddedById: sessionStorage.getItem("id"),
            vRoomId: msg.vRoomId,
          })
        );
        return;
      }
      if (+msg.iReceivedById === +sessionStorage.getItem("id")) {
        setNewMessage(msg);
      }
    });
  }, [dispatch]);

  // when the user remove the message then used... (Individual)
  React.useEffect(() => {
    if (socket) {
      let items = [];
      socket.on("individual_delete_single_receive_message", (data) => {
        if (
          sessionStorage.getItem("roomId") &&
          data.vRoomId === sessionStorage.getItem("roomId")
        ) {
          setIndivisualMessageList((pre) => (items = [...pre]));
          let tempIndx = items.findIndex(
            (val) => +val.iChatId === +data.iChatId
          );
          items.splice(tempIndx, 1, data);
          setIndivisualMessageList([...items]);
        } else {
          let list = [];
          setUserList((pre) => (list = [...pre]));
          let tempIndex = list.findIndex((val) => val.vRoomId === data.vRoomId);
          let matchList = list.find((val) => val.vRoomId === data.vRoomId);
          list.splice(tempIndex, 1, { ...matchList, eDelete: "Yes" });
          setUserList([...list]);
        }
      });
    }
  }, []);

  // Real time manage count for receiving new message
  let filterUserList = React.useCallback(
    (msg) => {
      if (individualChatUserData && individualChatUserData.code === "200") {
        // for buffer storage
        let found = individualChatUserData?.data.find(
          (element) =>
            +element?.user?.iRequesterId === +msg.iAddedById &&
            element.vRoomId === msg.vRoomId
        );
        let unFound = individualChatUserData?.data.filter(
          (element) =>
            +element.user?.iRequesterId !== +msg.iAddedById &&
            element.vRoomId !== msg.vRoomId
        );
        found = {
          ...found,
          eSeenCount: ++found.eSeenCount,
          LastMessage: msg.vMessage,
          eType: msg?.eType,
          image_Count: msg.chat_file_transactions?.length,
          AddedTime: msg.dAddedTime,
        };
        setUserList([found, ...unFound]);
      }
    },
    [individualChatUserData]
  );
  //  this useEffect render when new message would be got for count filter
  React.useEffect(() => {
    if (newMessage !== undefined) {
      filterUserList(newMessage);
    }
  }, [filterUserList, newMessage]);

  // close opened emoji picker
  React.useEffect(() => {
    const handleClickOutsideBox = (event) => {
      const box = document.getElementById("emoji-section-className");
      if (!box?.contains(event.target) && showEmoji) {
        setShowEmoji(false);
      }
    };
    document.addEventListener("click", handleClickOutsideBox);
    return () => {
      document.removeEventListener("click", handleClickOutsideBox);
    };
  }, [setShowEmoji, showEmoji]);

  // close opend gif section
  React.useEffect(() => {
    const handleClickOutsideBox = (event) => {
      const box = document.getElementById("gif-section-id");
      if (!box?.contains(event.target) && isShowGifPicker) {
        setIsShowGifPicker(false);
      }
    };
    document.addEventListener("click", handleClickOutsideBox);
    return () => {
      document.removeEventListener("click", handleClickOutsideBox);
    };
  }, [setIsShowGifPicker, isShowGifPicker]);

  const handleIndivisualJoinRoom = (e, room, data, logo, reqId) => {
    e.preventDefault();
    setReceivedUserId(reqId);
    setChatManagementId(data.iChatUserManagementId);
    setTagName(room);
    setRoomData(data);
    setRoomLogo(logo);
    setIndividualValue("");
    if (data && data?.vRoomId) {
      sessionStorage.setItem("roomId", data.vRoomId);
      dispatch(
        updateSeenMessages({
          iAddedById: data.iUserId,
          vRoomId: data.vRoomId,
        })
      );
      dispatch(getAllDataByRoomId({ vRoomId: data.vRoomId }));
      // socket.emit("join_room", data.vRoomId);
      setRoom(data.vRoomId);
    }
  };

  // send message to the user
  const sendIndivisual = React.useCallback(
    async (gifData = "") => {
      const currentTime = moment();
      let t = moment(
        moment(currentTime.format("YYYY-MM-DD")).format(
          `${process.env.REACT_APP_DATE_FORMAT}`
        ) +
          " " +
          currentTime.format("hh:mm:ss")
      )
        .utc()
        .format("hh:mm A");
      const timeMoment = moment(t, "hh:mm:ss a");
      const currentHour = timeMoment.format("hh"); // HH for 24-hour format
      const currentMinute = timeMoment.format("mm");
      const currentSec = timeMoment.format("ss");
      let dateTime = moment();
      dateTime.set("hour", currentHour);
      dateTime.set("minute", currentMinute);
      dateTime.set("second", currentSec);
      let addedDateTime = dateTime.format("YYYY-MM-DD, hh:mm:ss a");
      setCurrentMessage("");
      setFiles([]);
      setBlobFiles([]);
      if (
        currentMessage.trim().length > 0 ||
        files.length > 0 ||
        gifData !== ""
      ) {
        const formData = new FormData();
        formData.append("vRoomId", room);
        formData.append("vUserName", sessionStorage.getItem("userName"));
        formData.append("iAddedById", sessionStorage.getItem("id"));
        formData.append("dAddedTime", addedDateTime);
        formData.append("iReceivedById", receviedUserId);
        formData.append("vMessage", currentMessage);
        formData.append("iChatUserManagementId", chatManagementId);
        formData.append("eSeen", "No");
        if (gifData !== "") {
          formData.append("vLink", gifData);
        }
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            formData.append("vFile", files[i].vFile);
          }
        }
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        let res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/Chat/individualChat`,
          formData,
          config
        );
        if (res.data.code === 0) {
          let resChatMesg = {
            ...res.data.data,
            iReceivedById: receviedUserId,
          };
          if (
            indivisualMessageList?.length > 0 &&
            moment(
              indivisualMessageList[indivisualMessageList.length - 1]
                ?.dAddedDate
            ).format(`${process.env.REACT_APP_DATE_FORMAT}`) ===
              moment().format(`${process.env.REACT_APP_DATE_FORMAT}`)
          ) {
            setIndivisualMessageList((pre) => [
              ...pre,
              {
                ...resChatMesg,
                chat_transaction: {
                  eSeen: onlineUsersList.includes(receviedUserId)
                    ? "Yes"
                    : "No",
                },
              },
            ]);
          } else {
            let date = {
              type: "day",
              date: moment().format(`${process.env.REACT_APP_DATE_FORMAT}`),
              id: moment().format(`${process.env.REACT_APP_DATE_FORMAT}`),
            };
            setIndivisualMessageList((pre) => [
              ...pre,
              date,
              {
                ...resChatMesg,
                chat_transaction: {
                  eSeen: onlineUsersList.includes(receviedUserId)
                    ? "Yes"
                    : "No",
                },
              },
            ]);
          }
          socket.emit("single_message", JSON.stringify(resChatMesg));
          setIsDoc(false);
          if (files.length > 0) setIsSelectFile(false);
        }
      }
    },
    [
      currentMessage,
      chatManagementId,
      indivisualMessageList,
      setIndivisualMessageList,
      files,
      onlineUsersList,
      setBlobFiles,
      setFiles,
      receviedUserId,
      room,
    ]
  );

  // ========================= end handle indivisual chat ====================

  const removeFileHandler = React.useCallback(
    (id) => {
      const newImages = files.filter((_, index) => index !== id);
      const blogImages = blobFiles.filter((_, index) => index !== id);
      setFiles(newImages);
      setBlobFiles(blogImages);
    },

    [blobFiles, files]
  );

  const handleChatBackClick = () => {
    setRoomData();
    setRoom("");
    setRoomLogo("");
    setIsSelectFileType(false);
    setIsSelectFile(false);
    setBlobFiles([]);
    setCurrentMessage("");
    setIsDoc(false);
    setActiveSection((pre) =>
      pre === "individualone" ? "individualone" : "teamone"
    );
    sessionStorage.setItem("roomId", "");
    dispatch({ type: GET_ALL_CHAT_BY_ROOMID_RESET });
    setIndivisualMessageList([]);
    setChatRoomData([]);
    dispatch(GetAllRoomByUser());
    dispatch(
      IndividualChatUsersResult({ iUserId: sessionStorage.getItem("id") })
    );
  };

  const handleFileSelectDisplay = React.useCallback(
    (e) => {
      e.preventDefault();
      if (isSelectFile) {
        setIsSelectFile(!isSelectFile);
        setFiles([]);
        setIsSelectFileType(false);
      } else {
        setIsSelectFileType(!isSelectFileType);
      }
    },
    [isSelectFileType, isSelectFile]
  );

  // get date into weeks wise format individual listing time
  let getDateWeekFormat = (date) => {
    let new_date = moment
      .utc(
        moment(date).format("YYYY-MM-DD") + " " + moment(date).format("hh:mm")
      )
      .local()
      .format("YYYY-MM-DD, h:mm:ss a");
    var seconds = Math.floor(
      (new Date(moment().format("YYYY-MM-DDTHH:mm:ssZ")) -
        new Date(
          moment(new_date, "YYYY-MM-DD, h:mm:ss a").format(
            "YYYY-MM-DDTHH:mm:ssZ"
          )
        )) /
        1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  };

  // =============== setup for emoji ====================
  const showEmojiSection = React.useCallback(
    (e) => {
      e.stopPropagation();
      setShowEmoji((showEmoji) => !showEmoji);
      setIsShowGifPicker(false);
    },
    [setShowEmoji, setIsShowGifPicker]
  );

  const showGifSection = React.useCallback(
    (e) => {
      e.stopPropagation();
      setIsShowGifPicker((showEmoji) => !showEmoji);
      setShowEmoji(false);
    },
    [setShowEmoji, setIsShowGifPicker]
  );

  const onEmojiSelect = React.useCallback((event, emojiObject) => {
    let textAreaElement = document.getElementById("chat-message");
    textAreaElement.value = textAreaElement.value + event.native;
    setCurrentMessage(textAreaElement.value);
  }, []);

  // setup gif for quick chat
  const handleSelectGif = React.useCallback(
    (e, gifData) => {
      setShowEmoji(false);
      e.preventDefault();
      if (activeSection === "teamone") {
        // this is for quick group chat
        send(gifData);
      } else {
        sendIndivisual(gifData);
      }
      setIsShowGifPicker(false);
    },
    [activeSection, send, sendIndivisual]
  );

  return (
    <>
      <Toast ref={toast} />
      <button type="button" onClick={handleClick} className="chat-open">
        {getAllUnseenCountData &&
        getAllUnseenCountData.code === 0 &&
        getAllUnseenCountData?.data?.UserSeenCount > 0 ? (
          <p className="notify-number">
            {getAllUnseenCountData.data.UserSeenCount}
          </p>
        ) : (
          ""
        )}
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.001"
            height="24.024"
            viewBox="0 0 24.001 24.024"
          >
            <g id="fi-rr-comment" transform="translate(0.001 0.024)">
              <path
                id="Path_461"
                data-name="Path 461"
                d="M24,11.247A12.012,12.012,0,1,0,12.017,24H19a5.005,5.005,0,0,0,5-5ZM22,19a3,3,0,0,1-3,3H12.017a10.041,10.041,0,0,1-7.476-3.343,9.917,9.917,0,0,1-2.476-7.814,10.043,10.043,0,0,1,8.656-8.761A10.564,10.564,0,0,1,12.021,2,9.921,9.921,0,0,1,18.4,4.3,10.041,10.041,0,0,1,22,11.342Z"
              ></path>
              <path
                id="Path_462"
                data-name="Path 462"
                d="M8,9h4a1,1,0,0,0,0-2H8A1,1,0,0,0,8,9Z"
              ></path>
              <path
                id="Path_463"
                data-name="Path 463"
                d="M16,11H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"
              ></path>
              <path
                id="Path_464"
                data-name="Path 464"
                d="M16,15H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"
              ></path>
            </g>
          </svg>
        </span>
      </button>
      {isActive ? (
        <div className={isActive ? "open-chat all-chat-box" : "all-chat-box"}>
          <div className="offcanvas-header">
            <div onClick={() => navigate("/chat")}>
              <i className="fas fa-expand-alt"></i>
            </div>
            <h5>Chats</h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={handleCloseClick}
            ></button>
          </div>
          <div className="offcanvas-body">
            {room ? (
              <div
                style={{
                  backgroundImage: "url('../assets/img/onchat-banner.png')",
                  position:
                    isShowMedia || isShowSettings || isShowMember
                      ? "relative"
                      : "unset",
                }}
                className={
                  isActive ? "show-user-chat user-chat-view" : "user-chat-view"
                }
              >
                <div className="selected-chat">
                  <div className="inner-header">
                    <div className="inner-left">
                      <div className="back-btn" onClick={handleChatBackClick}>
                        <i className="fas fa-chevron-left"></i>
                      </div>
                      <div className="image-box">
                        <img
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                          }}
                          src={roomLogo}
                          alt=""
                        />
                      </div>

                      <div>
                        <p>{tagName !== "" ? tagName : ""}</p>
                        <p className="online-user">
                          {activeSection === "teamone" ? (
                            <p style={{ fontSize: "10px" }}>
                              {teamName !== "" ? teamName : ""}
                            </p>
                          ) : (
                            <>
                              <p style={{ fontSize: "10px" }}>
                                {onlineUsersList.includes(receviedUserId)
                                  ? "Online"
                                  : "Offline"}
                              </p>
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    {activeSection === "teamone" ? (
                      <div className="inner-right">
                        <button
                          className="sended-media"
                          onClick={handleTeamMemberList}
                        >
                          <div className="image-box">
                            <i className="far fa-users"></i>
                          </div>
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="chatting-box">
                    <div className="chat-text">
                      {/* =======================   chat set for individual ========================= */}
                      {indivisualMessageList &&
                        indivisualMessageList.length > 0 &&
                        activeSection === "individualone" &&
                        indivisualMessageList?.map((chatData, key) => (
                          <>
                            {chatData?.hasOwnProperty("type") ? (
                              <React.Fragment key={key}>
                                {moment().format(
                                  `${process.env.REACT_APP_DATE_FORMAT}`
                                ) ===
                                moment(chatData?.date).format(
                                  `${process.env.REACT_APP_DATE_FORMAT}`
                                ) ? (
                                  <div className="date-line">
                                    <span>Today</span>
                                  </div>
                                ) : moment()
                                    .subtract(1, "days")
                                    .format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                  moment(chatData?.date).format(
                                    `${process.env.REACT_APP_DATE_FORMAT}`
                                  ) ? (
                                  <div className="date-line">
                                    <span>Yesterday</span>
                                  </div>
                                ) : moment()
                                    .subtract(2, "days")
                                    .format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                  moment(chatData?.date).format(
                                    `${process.env.REACT_APP_DATE_FORMAT}`
                                  ) ? (
                                  <div className="date-line">
                                    <span>
                                      {moment(
                                        moment()
                                          .subtract(2, "days")
                                          .format(
                                            `${process.env.REACT_APP_DATE_FORMAT}`
                                          )
                                      ).format("dddd")}
                                    </span>
                                  </div>
                                ) : moment()
                                    .subtract(3, "days")
                                    .format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                  moment(chatData?.date).format(
                                    `${process.env.REACT_APP_DATE_FORMAT}`
                                  ) ? (
                                  <div className="date-line">
                                    <span>
                                      {moment(
                                        moment()
                                          .subtract(3, "days")
                                          .format(
                                            `${process.env.REACT_APP_DATE_FORMAT}`
                                          )
                                      ).format("dddd")}
                                    </span>
                                  </div>
                                ) : moment()
                                    .subtract(4, "days")
                                    .format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                  moment(chatData?.date).format(
                                    `${process.env.REACT_APP_DATE_FORMAT}`
                                  ) ? (
                                  <div className="date-line">
                                    <span>
                                      {moment(
                                        moment()
                                          .subtract(4, "days")
                                          .format(
                                            `${process.env.REACT_APP_DATE_FORMAT}`
                                          )
                                      ).format("dddd")}
                                    </span>
                                  </div>
                                ) : moment()
                                    .subtract(5, "days")
                                    .format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                  moment(chatData?.date).format(
                                    `${process.env.REACT_APP_DATE_FORMAT}`
                                  ) ? (
                                  <div className="date-line">
                                    <span>
                                      {moment(
                                        moment()
                                          .subtract(5, "days")
                                          .format(
                                            `${process.env.REACT_APP_DATE_FORMAT}`
                                          )
                                      ).format("dddd")}
                                    </span>
                                  </div>
                                ) : moment()
                                    .subtract(6, "days")
                                    .format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                  moment(chatData?.date).format(
                                    `${process.env.REACT_APP_DATE_FORMAT}`
                                  ) ? (
                                  <div className="date-line">
                                    <span>
                                      {moment(
                                        moment()
                                          .subtract(6, "days")
                                          .format(
                                            `${process.env.REACT_APP_DATE_FORMAT}`
                                          )
                                      ).format("dddd")}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="date-line">
                                    <span>
                                      {moment(chatData?.date).format("MMM")}
                                      {""}
                                      {moment(chatData?.date).format("DD")}
                                      {", "}
                                      {moment(chatData?.date).format("YYYY")}
                                    </span>
                                  </div>
                                )}
                              </React.Fragment>
                            ) : (
                              <>
                                {chatData?.iAddedById?.toString() ===
                                sessionStorage.getItem("id").toString() ? (
                                  <RightChatBody chatData={chatData} />
                                ) : (
                                  <LeftChatBody chatData={chatData} />
                                )}
                              </>
                            )}
                          </>
                        ))}

                      {/* =================== end chat set for indivisual ===================*/}

                      {chatRoomData.length > 0 &&
                        activeSection === "teamone" &&
                        chatRoomData &&
                        chatRoomData !== undefined &&
                        chatRoomData?.map((chatData, key) => (
                          <>
                            {chatData?.hasOwnProperty("type") ? (
                              <React.Fragment>
                                {moment().format(
                                  `${process.env.REACT_APP_DATE_FORMAT}`
                                ) ===
                                moment(chatData?.date).format(
                                  `${process.env.REACT_APP_DATE_FORMAT}`
                                ) ? (
                                  <div className="date-line">
                                    <span>Today</span>
                                  </div>
                                ) : moment()
                                    .subtract(1, "days")
                                    .format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                  moment(chatData?.date).format(
                                    `${process.env.REACT_APP_DATE_FORMAT}`
                                  ) ? (
                                  <div className="date-line">
                                    <span>Yesterday</span>
                                  </div>
                                ) : moment()
                                    .subtract(2, "days")
                                    .format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                  moment(chatData?.date).format(
                                    `${process.env.REACT_APP_DATE_FORMAT}`
                                  ) ? (
                                  <div className="date-line">
                                    <span>
                                      {moment(
                                        moment()
                                          .subtract(2, "days")
                                          .format(
                                            `${process.env.REACT_APP_DATE_FORMAT}`
                                          )
                                      ).format("dddd")}
                                    </span>
                                  </div>
                                ) : moment()
                                    .subtract(3, "days")
                                    .format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                  moment(chatData?.date).format(
                                    `${process.env.REACT_APP_DATE_FORMAT}`
                                  ) ? (
                                  <div className="date-line">
                                    <span>
                                      {moment(
                                        moment()
                                          .subtract(3, "days")
                                          .format(
                                            `${process.env.REACT_APP_DATE_FORMAT}`
                                          )
                                      ).format("dddd")}
                                    </span>
                                  </div>
                                ) : moment()
                                    .subtract(4, "days")
                                    .format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                  moment(chatData?.date).format(
                                    `${process.env.REACT_APP_DATE_FORMAT}`
                                  ) ? (
                                  <div className="date-line">
                                    <span>
                                      {moment(
                                        moment()
                                          .subtract(4, "days")
                                          .format(
                                            `${process.env.REACT_APP_DATE_FORMAT}`
                                          )
                                      ).format("dddd")}
                                    </span>
                                  </div>
                                ) : moment()
                                    .subtract(5, "days")
                                    .format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                  moment(chatData?.date).format(
                                    `${process.env.REACT_APP_DATE_FORMAT}`
                                  ) ? (
                                  <div className="date-line">
                                    <span>
                                      {moment(
                                        moment()
                                          .subtract(5, "days")
                                          .format(
                                            `${process.env.REACT_APP_DATE_FORMAT}`
                                          )
                                      ).format("dddd")}
                                    </span>
                                  </div>
                                ) : moment()
                                    .subtract(6, "days")
                                    .format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                  moment(chatData?.date).format(
                                    `${process.env.REACT_APP_DATE_FORMAT}`
                                  ) ? (
                                  <div className="date-line">
                                    <span>
                                      {moment(
                                        moment()
                                          .subtract(6, "days")
                                          .format(
                                            `${process.env.REACT_APP_DATE_FORMAT}`
                                          )
                                      ).format("dddd")}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="date-line">
                                    <span>
                                      {moment(chatData?.date).format("MMM")}
                                      {""}
                                      {moment(chatData?.date).format("DD")}
                                      {", "}
                                      {moment(chatData?.date).format("YYYY")}
                                    </span>
                                  </div>
                                )}
                              </React.Fragment>
                            ) : (
                              <>
                                {chatData?.iAddedById?.toString() ===
                                sessionStorage.getItem("id").toString() ? (
                                  <RightChatBody chatData={chatData} />
                                ) : (
                                  <LeftChatBody chatData={chatData} />
                                )}
                              </>
                            )}
                          </>
                        ))}
                      <div ref={messagesEndRef} />
                    </div>
                  </div>
                  <div className="send-msg">
                    {isSelectFile ? (
                      <div className="post-upload-images">
                        {blobFiles.length > 0 ? (
                          <div className="inner-post-upload-images">
                            {blobFiles?.map((item, key) =>
                              item.eType === "image" ? (
                                <div
                                  className="five-upload"
                                  // key={Math.random() * 10000 * key + 1}
                                >
                                  <a
                                    className="image-box"
                                    data-fancybox={`gallery${key}`}
                                    id={key}
                                    href={item?.vFile}
                                    data-options='{"loop":true}'
                                  >
                                    <div className="images-box">
                                      <img src={item?.vFile} alt="" />
                                    </div>
                                  </a>
                                  <button className="btn btn-primary">
                                    <i
                                      className="fas fa-times"
                                      onClick={() => removeFileHandler(key)}
                                    ></i>
                                  </button>
                                </div>
                              ) : item.eType === "video" ? (
                                <div className="five-upload">
                                  <div className="images-box">
                                    <a
                                      className="image-box"
                                      data-fancybox={`gallery${key}`}
                                      id={key}
                                      href={item?.vFile}
                                      data-options='{"loop":true}'
                                    >
                                      <video
                                        allowFullScreen="false"
                                        id="video"
                                        src={item?.vFile}
                                        alt=""
                                        muted
                                      />
                                      {/* <video
                                        src={item?.vFile}
                                        alt=""
                                        controls
                                        // autoPlay
                                      /> */}
                                    </a>
                                  </div>
                                  <button className="btn btn-primary">
                                    <i
                                      className="fas fa-times"
                                      onClick={() => removeFileHandler(key)}
                                    ></i>
                                  </button>
                                </div>
                              ) : (
                                <div className="five-upload">
                                  <div id="pdfPreview"></div>
                                  <div className="images-box" id="test-id">
                                    <object
                                      data={item.vFile}
                                      aria-labelledby="vFile"
                                    ></object>
                                  </div>
                                  <button className="btn btn-primary">
                                    <i
                                      className="fas fa-times"
                                      onClick={() => removeFileHandler(key)}
                                    ></i>
                                  </button>
                                </div>
                              )
                            )}
                            <div className="five-upload add-image-box">
                              <i className="fal fa-plus"></i>
                              {isDoc ? (
                                <input
                                  type="file"
                                  className="form-control"
                                  accept="text/plain,
                                  .xlsx, .xls,
                            application/pdf,
                            application/xlsx,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  onChange={(e) => handleFileChange(e)}
                                />
                              ) : (
                                <input
                                  type="file"
                                  multiple
                                  className="form-control"
                                  accept="video/*,image/*"
                                  onChange={(e) => handleFileChange(e)}
                                />
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="add-image-box big-added">
                            <i className="fal fa-plus"></i>
                            {isDoc ? (
                              <input
                                type="file"
                                className="form-control"
                                accept="text/plain,
                                .xlsx, .xls,
                            application/pdf,
                            application/xlsx,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={(e) => handleFileChange(e)}
                              />
                            ) : (
                              <input
                                type="file"
                                className="form-control"
                                multiple
                                accept="video/*,image/*"
                                onChange={(e) => handleFileChange(e)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="form-group">
                      {activeSection === "teamone" ? (
                        <input
                          type="text"
                          className="form-control type-box"
                          placeholder="Write a Message Here..."
                          value={currentMessage}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === 13) {
                              e.preventDefault();
                              send();
                            }
                          }}
                          onChange={(e) => {
                            setCurrentMessage(e.target.value);
                          }}
                        />
                      ) : (
                        <input
                          type="text"
                          id="chat-message"
                          className="form-control type-box"
                          placeholder="Write a Message Here..."
                          disabled={
                            blobFiles.length > 0 &&
                            blobFiles[0]?.eType === "Application"
                              ? true
                              : false
                          }
                          value={currentMessage}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === 13) {
                              e.preventDefault();
                              sendIndivisual();
                            }
                          }}
                          onChange={(e) => {
                            setCurrentMessage(e.target.value);
                          }}
                        />
                      )}
                      <div className="emoji-box" id="emoji-section-className">
                        {showEmoji ? (
                          <Picker
                            data={data}
                            onEmojiSelect={onEmojiSelect}
                            emojiSize="20"
                            emojiButtonSize="30"
                            perLine="10"
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      {isShowGifPicker ? (
                        <>
                          <div
                            className="gif-box"
                            id="gif-section-id"
                            ref={gifBoxRef}
                          >
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Search Gif"
                                onChange={(e) =>
                                  setSearchedGifText(e.target.value)
                                }
                              />
                            </div>
                            <div className="gif-images">
                              {gifLists?.length > 0 ? (
                                gifLists?.map((el) => (
                                  <div
                                    className="image-box"
                                    onClick={(e) =>
                                      handleSelectGif(
                                        e,
                                        el.media[0].nanogif?.url
                                      )
                                    }
                                  >
                                    <img
                                      src={el.media[0].nanogif?.url}
                                      alt=""
                                    />
                                  </div>
                                ))
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="btn-in">
                        {/* =================  add gif ================= */}
                        <div
                          className="box"
                          onClick={showGifSection}
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-gif"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M8 8h-3a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h3v-4h-1"></path>
                            <path d="M12 8v8"></path>
                            <path d="M16 16v-8h5"></path>
                            <path d="M20 12h-4"></path>
                          </svg>
                        </div>

                        {/* ========== end gif ============= */}

                        <div
                          className="image-box"
                          role="button"
                          // onClick={() => handleFileSelectDisplay()}
                        >
                          <i
                            className="fal fa-smile"
                            onClick={showEmojiSection}
                          ></i>
                        </div>
                        <div
                          className={
                            isSelectFileType
                              ? "import-in anim-icon"
                              : "import-in"
                          }
                        >
                          <div
                            className="image-box"
                            role="button"
                            onClick={(e) => handleFileSelectDisplay(e)}
                          >
                            <img src="../assets/img/import-icon.png" alt="" />
                          </div>
                          <div
                            id="select-icon"
                            className="select-icon d-flex flex-column"
                          >
                            <div
                              className="image-video box"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsSelectFile(!isSelectFile);
                                setIsSelectFileType(!isSelectFileType);
                                setIsDoc(false);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24.5"
                                height="24.5"
                                viewBox="0 0 24.5 24.5"
                              >
                                <g
                                  id="fi-rr-picture"
                                  transform="translate(0.25 0.25)"
                                >
                                  <path
                                    id="Path_140"
                                    data-name="Path 140"
                                    d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0ZM5,2H19a3,3,0,0,1,3,3V19a2.951,2.951,0,0,1-.3,1.285l-9.163-9.163a5,5,0,0,0-7.072,0L2,14.586V5A3,3,0,0,1,5,2ZM5,22a3,3,0,0,1-3-3V17.414l4.878-4.878a3,3,0,0,1,4.244,0L20.285,21.7A2.951,2.951,0,0,1,19,22Z"
                                    fill="#fff"
                                    stroke="#fff"
                                    strokeWidth="0.5"
                                  ></path>
                                  <path
                                    id="Path_141"
                                    data-name="Path 141"
                                    d="M16,10.5A3.5,3.5,0,1,0,12.5,7,3.5,3.5,0,0,0,16,10.5Zm0-5A1.5,1.5,0,1,1,14.5,7,1.5,1.5,0,0,1,16,5.5Z"
                                    fill="#fff"
                                    stroke="#fff"
                                    strokeWidth="0.5"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            {currentMessage === "" ? (
                              <div
                                className="doc box"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsSelectFile(!isSelectFile);
                                  setIsSelectFileType(!isSelectFileType);
                                  setIsDoc(true);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20.5"
                                  height="24.5"
                                  viewBox="0 0 20.5 24.5"
                                >
                                  <path
                                    id="fi-rr-document"
                                    d="M17,14a1,1,0,0,1-1,1H8a1,1,0,0,1,0-2h8A1,1,0,0,1,17,14Zm-4,3H8a1,1,0,0,0,0,2h5a1,1,0,0,0,0-2Zm9-6.515V19a5.006,5.006,0,0,1-5,5H7a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,7,0h4.515a6.958,6.958,0,0,1,4.95,2.05l3.484,3.486A6.951,6.951,0,0,1,22,10.485ZM15.051,3.464A5.01,5.01,0,0,0,14,2.684V7a1,1,0,0,0,1,1h4.316a4.983,4.983,0,0,0-.781-1.05ZM20,10.485c0-.165-.032-.323-.047-.485H15a3,3,0,0,1-3-3V2.047C11.838,2.032,11.679,2,11.515,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3Z"
                                    transform="translate(-1.75 0.25)"
                                    fill="#fff"
                                    stroke="#fff"
                                    strokeWidth="0.5"
                                  ></path>
                                  sendhandleTyping
                                </svg>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        {activeSection === "teamone" ? (
                          <button
                            type="button"
                            className="send-btn"
                            id="chat-message"
                            onClick={(e) => {
                              e.preventDefault();
                              send();
                            }}
                          >
                            <div className="image-box">
                              <img src="../assets/img/send-icon.png" alt="" />
                            </div>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="send-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              sendIndivisual();
                            }}
                          >
                            <div className="image-box">
                              <img src="../assets/img/send-icon.png" alt="" />
                            </div>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    isShowSettings ? "media-canvas show" : "media-canvas"
                  }
                >
                  <div className="setting-box">
                    <div
                      className="box"
                      onClick={() => setIsShowMedia(true)}
                      type="button"
                    >
                      <i className="far fa-images"></i>
                      <p>Media, Videos And Doc</p>
                    </div>
                    <div className="box" onClick={() => setIsShowMember(true)}>
                      <i className="far fa-user-friends"></i>
                      <p>Member Of Group</p>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    isShowSettings ? "media-canvas show" : "media-canvas"
                  }
                >
                  <div className="media-images">
                    <div className="title-header">
                      <div
                        className="back-btn"
                        onClick={() => setIsShowSettings(false)}
                      >
                        <i className="fas fa-chevron-left"></i>
                      </div>
                      <p>
                        Members of Group
                        {getAllChatGroupMemberData?.data != null
                          ? `(${getAllChatGroupMemberData?.data?.length})`
                          : ""}
                      </p>
                    </div>
                    <div className="member-list-box">
                      {getAllChatGroupMemberData &&
                        getAllChatGroupMemberData?.data !== null &&
                        getAllChatGroupMemberData?.data?.map((memData, key) => (
                          <div
                            className="box"
                            // key={Math.random() * 10000 * key + 1}
                          >
                            <div className="user-data">
                              <div className="images-box">
                                {memData?.user?.vImage ===
                                `${process.env.REACT_APP_GET_PUBLIC_PATH}/uploads/user/null` ? (
                                  <>
                                    <Avatar
                                      label={
                                        memData?.user?.vFirstName?.slice(0, 1) +
                                        memData?.user?.vLastName?.slice(0, 1)
                                      }
                                      shape="circle"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Avatar image={memData?.user?.vImage} />
                                  </>
                                )}
                              </div>
                              <div className="text">
                                <h2>
                                  {`${memData?.user?.vFirstName || ""} ${
                                    memData?.user?.vLastName || ""
                                  }`}
                                </h2>
                                <p>{memData?.user?.vRole || ""}</p>
                              </div>
                            </div>
                            {isUnder13 ? (
                              <></>
                            ) : memData.eRequesterStatus === "Following" ? (
                              <div
                                className="chat-icon"
                                onClick={() => navigate("/chat")}
                              >
                                {/* <i className="fal fa-comment-lines"></i> */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24.001"
                                  height="24.024"
                                  viewBox="0 0 24.001 24.024"
                                >
                                  <g
                                    id="fi-rr-comment"
                                    transform="translate(0.001 0.024)"
                                  >
                                    <path
                                      id="Path_461"
                                      data-name="Path 461"
                                      d="M24,11.247A12.012,12.012,0,1,0,12.017,24H19a5.005,5.005,0,0,0,5-5ZM22,19a3,3,0,0,1-3,3H12.017a10.041,10.041,0,0,1-7.476-3.343,9.917,9.917,0,0,1-2.476-7.814,10.043,10.043,0,0,1,8.656-8.761A10.564,10.564,0,0,1,12.021,2,9.921,9.921,0,0,1,18.4,4.3,10.041,10.041,0,0,1,22,11.342Z"
                                    ></path>
                                    <path
                                      id="Path_462"
                                      data-name="Path 462"
                                      d="M8,9h4a1,1,0,0,0,0-2H8A1,1,0,0,0,8,9Z"
                                    ></path>
                                    <path
                                      id="Path_463"
                                      data-name="Path 463"
                                      d="M16,11H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"
                                    ></path>
                                    <path
                                      id="Path_464"
                                      data-name="Path 464"
                                      d="M16,15H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            ) : memData.iUserId ===
                              +sessionStorage.getItem("id") ? (
                              "( you )"
                            ) : (
                              <>
                                <Follow data={memData} key={key} />
                              </>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="user-chatlist-view">
                <div className="searchbar">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => setIndividualValue(e.target.value)}
                    />
                    <div className="image-box">
                      <img
                        src="../assets/img/fi-rr-search.png"
                        alt="search-icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="select-chat">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {isUnder13 ? (
                      <></>
                    ) : (
                      <li className="nav-item" role="presentation">
                        <button
                          className={
                            activeSection === "individualone"
                              ? `nav-link active text-dark`
                              : `nav-link text-dark`
                          }
                          id="individual-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#individual"
                          type="button"
                          role="tab"
                          aria-controls="individual"
                          aria-selected="true"
                          onClick={() => {
                            // setRoomData(null);
                            setActiveSection("individualone");
                          }}
                        >
                          <i className="fal fa-user text-dark"> </i>
                          <span>Individual</span>
                        </button>
                      </li>
                    )}
                    <li
                      className={isUnder13 ? "nav-item w-100" : "nav-item"}
                      role="presentation"
                    >
                      <button
                        className={
                          isUnder13
                            ? `nav-link active text-dark w-100`
                            : activeSection === "teamone"
                            ? `nav-link active text-dark`
                            : `nav-link text-dark`
                        }
                        id="team-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#team"
                        type="button"
                        role="tab"
                        aria-controls="team"
                        aria-selected="false"
                        onClick={() => {
                          dispatch(GetAllRoomByUser());
                          setRoomData(null);
                          setActiveSection("teamone");
                        }}
                      >
                        <i className="fal fa-users"></i>
                        <span>Teams</span>
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    {isUnder13 ? (
                      <></>
                    ) : (
                      <div
                        className={
                          activeSection === "individualone"
                            ? `tab-pane fade show active`
                            : `tab-pane fade`
                        }
                        id="individual"
                        role="tabpanel"
                        aria-labelledby="individual-tab"
                      >
                        {activeSection === "individualone" ? (
                          <>
                            <div className="select-chat-box">
                              {userList && userList.length > 0 ? (
                                userList
                                  ?.filter((val) =>
                                    val.user?.FullName?.toLowerCase().includes(
                                      individualValue.toLowerCase()
                                    )
                                  )
                                  ?.map((item, key) => (
                                    <div
                                      className="boxes"
                                      key={key}
                                      role="button"
                                      onClick={(e) => {
                                        handleIndivisualJoinRoom(
                                          e,
                                          item.user.FullName,
                                          item,
                                          item.user.vImage,
                                          item?.user?.iRequesterId
                                        );
                                      }}
                                    >
                                      <div className="image-box">
                                        {item?.user?.vImage ===
                                        `${process.env.REACT_APP_GET_PUBLIC_PATH}/uploads/user/null` ? (
                                          <>
                                            <Avatar
                                              label={
                                                item?.user?.vFirstName?.slice(
                                                  0,
                                                  1
                                                ) +
                                                item?.user?.vLastName?.slice(
                                                  0,
                                                  1
                                                )
                                              }
                                              shape="circle"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <Avatar
                                              image={item?.user?.vImage}
                                            />
                                          </>
                                        )}
                                      </div>
                                      <div className="text">
                                        <div>
                                          <p className="msg-text">
                                            {item?.user?.FullName}
                                          </p>
                                          <p className="msg-last">
                                            {item.eDelete === "Yes" ? (
                                              <>
                                                <i>{`This Message Is Deleted`}</i>
                                              </>
                                            ) : (
                                              <>
                                                {item.image_Count > 0 ? (
                                                  <>
                                                    {item?.image_Count > 1 ? (
                                                      <>
                                                        <i className="far fa-photo-video"></i>
                                                        media
                                                      </>
                                                    ) : (
                                                      <>
                                                        {item?.eType ===
                                                        "image" ? (
                                                          <>
                                                            <i className="fas fa-image"></i>
                                                            Photo
                                                          </>
                                                        ) : item?.eType ===
                                                          "video" ? (
                                                          <>
                                                            <i className="fas fa-video"></i>
                                                            video
                                                          </>
                                                        ) : item?.eType ===
                                                          "gif" ? (
                                                          <>
                                                            <i className="fad fa-gift-card"></i>
                                                            GIF
                                                          </>
                                                        ) : (
                                                          <>
                                                            <i className="fas fa-file"></i>
                                                            file
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>{item.LastMessage}</>
                                                )}
                                              </>
                                            )}
                                          </p>
                                          {/* <p className="msg-last">
                                          {item.LastMessage}
                                        </p> */}
                                        </div>
                                        <div>
                                          <p className="time">
                                            {(item.LastMessage !== null ||
                                              item?.eType === "gif") &&
                                              getDateWeekFormat(
                                                item?.AddedTime
                                              )}
                                          </p>
                                          {item.eSeenCount > 0 && (
                                            <p className="count">
                                              {item.eSeenCount}
                                              {/* {handleNewMsgCount(item?.vRoomId)} */}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ))
                              ) : individualChatUserData?.data === null ||
                                individualChatUserData?.data?.length === 0 ? (
                                <>
                                  <p className="no-chat-style">
                                    You Do Not Have Any Follower.
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="no-chat-style">Loading...</p>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}

                    <div
                      className={
                        isUnder13
                          ? `tab-pane fade show active`
                          : activeSection === "teamone"
                          ? `tab-pane fade show active`
                          : `tab-pane fade`
                      }
                      id="team"
                      role="tabpanel"
                      aria-labelledby="team-tab"
                    >
                      {activeSection === "teamone" ? (
                        <div className="select-chat-box">
                          {groupUserLists && groupUserLists.length > 0 ? (
                            groupUserLists
                              ?.filter((val) =>
                                val.team?.tTeamTagline
                                  ?.toLowerCase()
                                  .includes(individualValue.toLowerCase())
                              )
                              ?.map((item, key) => (
                                <div
                                  className="boxes"
                                  key={key + 1}
                                  role="button"
                                  onClick={(e) =>
                                    handleJoinRoom(
                                      e,
                                      item.team,
                                      item.chat_user_management,
                                      item
                                    )
                                  }
                                >
                                  <div className="image-box">
                                    <img
                                      src={item?.team?.vTeamLogo}
                                      alt="chat"
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                      }}
                                    />
                                  </div>
                                  <div className="text">
                                    <div>
                                      <p className="msg-text">
                                        {item?.team?.tTeamTagline}
                                      </p>
                                      <p className="msg-last">
                                        {item?.chat_user_management?.eDelete ===
                                        "Yes" ? (
                                          <>
                                            <i>{`This Message Is Deleted`}</i>
                                          </>
                                        ) : (
                                          <>
                                            {item?.chat_user_management
                                              ?.image_Count > 0 ? (
                                              <>
                                                {item?.chat_user_management
                                                  ?.image_Count > 1 ? (
                                                  <>
                                                    <i className="far fa-photo-video"></i>
                                                    media
                                                  </>
                                                ) : (
                                                  <>
                                                    {item?.chat_user_management
                                                      ?.eType === "image" ? (
                                                      <>
                                                        <i className="fas fa-image"></i>
                                                        Photo
                                                      </>
                                                    ) : item
                                                        ?.chat_user_management
                                                        ?.eType === "video" ? (
                                                      <>
                                                        <i className="fas fa-video"></i>
                                                        video
                                                      </>
                                                    ) : item
                                                        ?.chat_user_management
                                                        ?.eType === "gif" ? (
                                                      <>
                                                        <i className="fad fa-gift-card"></i>
                                                        GIF
                                                      </>
                                                    ) : (
                                                      <>
                                                        <i className="fas fa-file"></i>
                                                        file
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {
                                                  item?.chat_user_management
                                                    ?.LastMessage
                                                }
                                              </>
                                            )}
                                          </>
                                        )}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="time">
                                        {item.LastMessage !== null &&
                                          item?.chat_user_management
                                            ?.AddedTime &&
                                          getDateWeekFormat(
                                            item?.chat_user_management
                                              ?.AddedTime
                                          )}
                                      </p>
                                      {item?.chat_user_management?.eSeenCount >
                                        0 && (
                                        <p className="count">
                                          {
                                            item?.chat_user_management
                                              ?.eSeenCount
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))
                          ) : individualChatUserData?.data === null ||
                            getAllRoomByUserData?.data?.length === 0 ? (
                            <>
                              <p className="no-chat-style">
                                You Do Not Have Any Team Follower.
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="no-chat-style">Loading...</p>
                            </>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <> </>
      )}
    </>
  );
}
export default React.memo(TeamChatList);
