import React from "react";

const SettingsSVG = ()=>{
    return(
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26.564"
        height="26.564"
        viewBox="0 0 26.564 26.564"
        className="icon-feather-settings"
      >
        <g
          id="Icon_feather-settings"
          data-name="Icon feather-settings"
          transform="translate(-0.6 -0.6)"
        >
          <path
            id="Path_8"
            data-name="Path 8"
            d="M20.254,16.877A3.377,3.377,0,1,1,16.877,13.5,3.377,3.377,0,0,1,20.254,16.877Z"
            transform="translate(-2.995 -2.995)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.8"
          />
          <path
            id="Path_9"
            data-name="Path 9"
            d="M22.212,17.259a1.857,1.857,0,0,0,.371,2.049l.068.068a2.253,2.253,0,1,1-3.186,3.186l-.068-.068a1.872,1.872,0,0,0-3.174,1.328v.191a2.251,2.251,0,0,1-4.5,0v-.1a1.857,1.857,0,0,0-1.216-1.7,1.857,1.857,0,0,0-2.049.371l-.068.068A2.253,2.253,0,1,1,5.2,19.465l.068-.068a1.872,1.872,0,0,0-1.328-3.174H3.751a2.251,2.251,0,0,1,0-4.5h.1a1.857,1.857,0,0,0,1.7-1.216,1.857,1.857,0,0,0-.371-2.049l-.068-.068A2.253,2.253,0,1,1,8.3,5.2l.068.068a1.857,1.857,0,0,0,2.049.371h.09a1.857,1.857,0,0,0,1.126-1.7V3.751a2.251,2.251,0,0,1,4.5,0v.1a1.872,1.872,0,0,0,3.174,1.328l.068-.068A2.253,2.253,0,1,1,22.561,8.3l-.068.068a1.857,1.857,0,0,0-.371,2.049v.09a1.857,1.857,0,0,0,1.7,1.126h.191a2.251,2.251,0,0,1,0,4.5h-.1a1.857,1.857,0,0,0-1.7,1.126Z"
            transform="translate(0 0)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.8"
          />
        </g>
      </svg>

    );
};
export default SettingsSVG;