import React from "react";

const MyWishlistSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18.451"
      viewBox="0 0 21 18.451"
    >
      <path
        id="fi-rr-heart"
        d="M15.306,1.917A5.6,5.6,0,0,0,10.5,4.8,5.6,5.6,0,0,0,5.685,1.917,5.948,5.948,0,0,0,0,8.083C0,12.06,4.185,16.4,7.7,19.349a4.35,4.35,0,0,0,5.6,0c3.511-2.945,7.7-7.288,7.7-11.265a5.948,5.948,0,0,0-5.685-6.166ZM12.17,18.01a2.6,2.6,0,0,1-3.35,0C4.326,14.24,1.749,10.622,1.749,8.083A4.2,4.2,0,0,1,5.685,3.666,4.2,4.2,0,0,1,9.62,8.083a.875.875,0,1,0,1.749,0,4.2,4.2,0,0,1,3.936-4.417,4.2,4.2,0,0,1,3.936,4.417c0,2.539-2.578,6.157-7.071,9.924Z"
        transform="translate(0.005 -1.917)"
      />
    </svg>
  );
};
export default MyWishlistSVG;
