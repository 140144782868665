import {
  ADD_CANVAS_FOLDER_REQUEST,
  ADD_CANVAS_FOLDER_SUCCESS,
  ADD_CANVAS_FOLDER_FAILURE,
  GET_CANVAS_FOLDER_LIST_REQUEST,
  GET_CANVAS_FOLDER_LIST_SUCCESS,
  GET_CANVAS_FOLDER_LIST_FAILURE,
  RESET_STATE,
} from "../constants/canvasFolder";

const initialState = {
  addCanvasData: {},
};

export function canvasFolderReducer(state = {}, action) {
  switch (action.type) {
    case ADD_CANVAS_FOLDER_REQUEST:
    case GET_CANVAS_FOLDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_CANVAS_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
        addCategoryData: action.payload,
      };
    case GET_CANVAS_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getPlaybookDetailsData: action.payload,
      };
    // case GET_CANVAS_FOLDER_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     getCanvasFolderListDataByTeamIdOld: action.payload,
    //   };
    case GET_CANVAS_FOLDER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        getPlaybookDetailsData: {},
      };
    case ADD_CANVAS_FOLDER_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
