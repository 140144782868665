import React from "react";
import { useDispatch } from "react-redux";
import { GetNotificationCount } from "../../../../store/action/notificationsAction";
import { GetAllNotificationList } from "../../../../store/action/notificationsAction";
// import { useDispatch } from "react-redux";
import axios from "axios";

function NotificationUserInviteButtons({ item }) {
  const dispatch = useDispatch();

  //  when the user accept the user request then used..
  const handleAcceptUserRequest = React.useCallback(
    async (data) => {
      if (data && Object.keys(data).length > 0) {
        const resData = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/teamMember/acceptAndDeclineRequest`,
          {
            iUserId: data.iVisibleId,
            vAuthCode: data.vAuthCode,
            eInvite: "Accept",
          }
        );
        if (resData.status === 200 && resData.data && resData.data.code === 0) {
          dispatch(
            GetAllNotificationList({ iVisibleId: sessionStorage.getItem("id") })
          );
          dispatch(
            GetNotificationCount({ iVisibleId: sessionStorage.getItem("id") })
          );
        }
      }
    },
    [dispatch]
  );

  //  when the user decline the user request then used...
  const handleDeclineUserRequest = React.useCallback(
    async (data) => {
      if (data && Object.keys(data).length > 0) {
        const resData = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/teamMember/acceptAndDeclineRequest`,
          {
            iUserId: data.iVisibleId,
            vAuthCode: data.vAuthCode,
            eInvite: "Decline",
          }
        );
        if (resData.status === 200 && resData.data && resData.data.code === 0) {
          dispatch(
            GetAllNotificationList({ iVisibleId: sessionStorage.getItem("id") })
          );
          dispatch(
            GetNotificationCount({ iVisibleId: sessionStorage.getItem("id") })
          );
        }
      }
    },
    [dispatch]
  );


  return (
    <div className="action-btn">
      <button
        className="accept-btn btn"
        onClick={() => handleAcceptUserRequest(item)}
      >
        Accept
      </button>
      <button
        className="cancel-btn btn"
        onClick={() => handleDeclineUserRequest(item)}
      >
        Decline
      </button>
    </div>
  );
}

export default NotificationUserInviteButtons;
