import {
  GET_ALL_CATEGORYWISE_SUBCATEGORY_REQUEST,
  GET_ALL_CATEGORYWISE_SUBCATEGORY_SUCCESS,
  GET_ALL_CATEGORYWISE_SUBCATEGORY_FAILURE,
} from "../constants/home";
import axios from "axios";

export const GetCategorywiseSubCat = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_CATEGORYWISE_SUBCATEGORY_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/subCatgeory/getAllSubcategoryData`
    );
    dispatch({
      type: GET_ALL_CATEGORYWISE_SUBCATEGORY_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_CATEGORYWISE_SUBCATEGORY_FAILURE });
  }
};
