import {
  CREATE_SECONDARY_SCHOOL_FAILURE,
  CREATE_SECONDARY_SCHOOL_REQUEST,
  CREATE_SECONDARY_SCHOOL_SUCCESS,
  DELETE_SECONDARY_SCHOOL_FAILURE,
  DELETE_SECONDARY_SCHOOL_REQUEST,
  DELETE_SECONDARY_SCHOOL_SUCCESS,
  GET_ALL_SECONDARY_SCHOOL_FAILURE,
  GET_ALL_SECONDARY_SCHOOL_REQUEST,
  GET_ALL_SECONDARY_SCHOOL_SUCCESS,
  GET_SECONDARY_SCHOOL_BY_ID_FAILURE,
  GET_SECONDARY_SCHOOL_BY_ID_REQUEST,
  GET_SECONDARY_SCHOOL_BY_ID_RESET,
  GET_SECONDARY_SCHOOL_BY_ID_SUCCESS,
  RESET_SECONDARY_SCHOOL_CREATE_DATA,
  RESET_UPDATE_SECONDARY_SCHOOL,
  UPDATE_SECONDARY_SCHOOL_FAILURE,
  UPDATE_SECONDARY_SCHOOL_REQUEST,
  UPDATE_SECONDARY_SCHOOL_SUCCESS,
} from "../constants/secondarySchool";

export function secondarySchoolReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_SECONDARY_SCHOOL_REQUEST:
    case UPDATE_SECONDARY_SCHOOL_REQUEST:
    case DELETE_SECONDARY_SCHOOL_REQUEST:
    case GET_ALL_SECONDARY_SCHOOL_REQUEST:
    case GET_SECONDARY_SCHOOL_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_SECONDARY_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        createSecondarySchoolData: action.payload,
      };
    case UPDATE_SECONDARY_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSecondarySchoolData: action.payload,
      };
    case DELETE_SECONDARY_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSecondarySchoolData: action.payload,
      };
    case GET_ALL_SECONDARY_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllSecondarySchoolData: action.payload,
      };
    case GET_SECONDARY_SCHOOL_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getSecondarySchoolByIdData: action.payload,
      };
    case CREATE_SECONDARY_SCHOOL_FAILURE:
    case UPDATE_SECONDARY_SCHOOL_FAILURE:
    case DELETE_SECONDARY_SCHOOL_FAILURE:
    case GET_ALL_SECONDARY_SCHOOL_FAILURE:
    case GET_SECONDARY_SCHOOL_BY_ID_FAILURE:
      return {
        ...state,
        loading: true,
      };
      case RESET_UPDATE_SECONDARY_SCHOOL:
        return {
          ...state,
          loading: false,
          updateSecondarySchoolData: {},
        };
        case RESET_SECONDARY_SCHOOL_CREATE_DATA:
          return {
            ...state,
            loading: false,
            createSecondarySchoolData: {},
          };
          case GET_SECONDARY_SCHOOL_BY_ID_RESET:
            return {
              ...state,
              loading: false,
              getSecondarySchoolByIdData: {},
            };
    default:
      return state;
  }
}
