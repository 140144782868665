import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_GIF_DATA } from "../store/constants/profileList";

const useFetchAllGif = (search_term, index) => {
  const [allGifData, setAllGifData] = React.useState([]);
  const dispatch = useDispatch();
  const { gifData } = useSelector((state) => state.profileList);

  const httpGetAsyncCategoryWise = (theUrl, callback) => {
    // create the request object
    var xmlHttp = new XMLHttpRequest();

    // set the state change callback to capture when the response comes in
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        callback(xmlHttp.responseText);
      }
    };

    // open as a GET call, pass in the url and set async = True
    xmlHttp.open("GET", theUrl, true);

    // call send with no params as they were passed in on the url string
    xmlHttp.send(null);

    return;
  };

  const tenorCallback_trending_category_wise = React.useCallback(
    (responsetext) => {
      // parse the json response
      var response_objects = JSON.parse(responsetext);

      let top_10_gifs = response_objects["results"];

      setAllGifData(top_10_gifs);

      // load the GIFs -- for our example we will load the first GIFs preview size (nanogif) and share size (tinygif)

      // document.getElementById("preview_gif").src = top_10_gifs[0]["media"][0]["nanogif"]["url"];

      // document.getElementById("share_gif").src = top_10_gifs[0]["media"][0]["tinygif"]["url"];
      dispatch({ type: GET_GIF_DATA, payload: top_10_gifs });
      return top_10_gifs;
    },
    [dispatch]
  );

  const fetchData = React.useCallback(
    (search_term) => {
      const apikey = process.env.REACT_APP_TENOR_API_KEY;
      const lmt = 10;
      var search_url =
        "https://g.tenor.com/v1/random?q=" +
        search_term +
        "&key=" +
        apikey +
        "&limit=" +
        lmt;
      httpGetAsyncCategoryWise(
        search_url,
        tenorCallback_trending_category_wise
      );
    },
    [tenorCallback_trending_category_wise]
  );

  React.useEffect(() => {
    if (index === 0 || search_term !== "") fetchData(search_term);
  }, [fetchData, index, search_term]);

  // console.log("allGifData inside search gif", allGifData)
  if (gifData && search_term === "") {
    if (gifData && search_term === null) {
      fetchData(search_term);
    }
    return gifData;
  }
  return allGifData;
};

export default useFetchAllGif;
