import axios from 'axios'
import {
    GET_ALL_RECENT_USER_POST_REQUEST,
    GET_ALL_RECENT_USER_POST_SUCCESS,
    GET_ALL_RECENT_USER_POST_FAILURE,
} from '../constants/recentUserPost'

export const RecentUserPost = (values) => async (dispatch) => {
    dispatch({ type: GET_ALL_RECENT_USER_POST_REQUEST })
    try {
        const resData = await axios.post(`${process.env.REACT_APP_API_URL}/api/post/getAllRecentPostData`, values)
        dispatch({ type: GET_ALL_RECENT_USER_POST_SUCCESS, payload: resData?.data })
    } catch (error) {
        console.log(error)
        dispatch({ type: GET_ALL_RECENT_USER_POST_FAILURE })
    }
}