import {
  GET_ALL_SEARCH_POST_DATA_FAILURE,
  GET_ALL_SEARCH_POST_DATA_REQUEST,
  GET_ALL_SEARCH_POST_DATA_SUCCESS,
  GET_ALL_SEARCH_TEAMS_DATA_FAILURE,
  GET_ALL_SEARCH_TEAMS_DATA_REQUEST,
  GET_ALL_SEARCH_TEAMS_DATA_SUCCESS,
  GET_ALL_SEARCH_RESULT_REQUEST,
  GET_ALL_SEARCH_RESULT_SUCCESS,
  GET_ALL_SEARCH_RESULT_FAILURE,
} from "../constants/search";
import axios from "axios";

export const GetSearchPost = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SEARCH_POST_DATA_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/searchData`,
      values
    );
    dispatch({ type: GET_ALL_SEARCH_POST_DATA_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_SEARCH_POST_DATA_FAILURE });
  }
};

export const GetSearchTeams = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SEARCH_TEAMS_DATA_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Team/searchTeamData`,
      values
    );
    dispatch({ type: GET_ALL_SEARCH_TEAMS_DATA_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_SEARCH_TEAMS_DATA_FAILURE });
  }
};

// here through whatever we have to search then globally find and set the values...
export const GetAllSearchResults = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SEARCH_RESULT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/searchAllData`,
      values
    );
    dispatch({ type: GET_ALL_SEARCH_RESULT_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_SEARCH_RESULT_FAILURE });
  }
}

