import {
  GET_FILETR_LIST_FAILURE,
  GET_FILETR_LIST_REQUEST,
  GET_FILETR_LIST_SUCCESS,
  GET_MSG_BOARD_FILTER_FAILURE,
  GET_MSG_BOARD_FILTER_REQEUST,
  GET_MSG_BOARD_FILTER_SUCCESS,
} from "../constants/myPlaybook";

export function myPlaybook(state = {}, action) {
  switch (action.type) {
    case GET_FILETR_LIST_REQUEST:
    case GET_MSG_BOARD_FILTER_REQEUST:
      return {
        ...state,
        loading: true,
      };
    case GET_FILETR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        playbookFilterData: action.payload,
      };
    case GET_MSG_BOARD_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        msgBoardFilterData: action.payload,
      };
    case GET_FILETR_LIST_FAILURE:
    case GET_MSG_BOARD_FILTER_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
