import {
  ADD_POST_COMMENT_TEAM_FAILURE,
  ADD_POST_COMMENT_TEAM_REQUEST,
  ADD_POST_COMMENT_TEAM_SUCCESS,
  CREATE_COMMENT_REPLY_TEAM_FAILURE,
  CREATE_COMMENT_REPLY_TEAM_REQUEST,
  CREATE_COMMENT_REPLY_TEAM_SUCCESS,
  VIEW_MORE_CLICKED_TEAM_POST_ID,
  VIEW_MORE_COMMENT_TEAM_POST_DATA_FAILURE,
  VIEW_MORE_COMMENT_TEAM_POST_DATA_REQUEST,
  VIEW_MORE_COMMENT_TEAM_POST_DATA_RESET,
  VIEW_MORE_COMMENT_TEAM_POST_DATA_SUCCESS,
} from "../constants/teamComment";

export function teamCommentReduer(state = {}, action) {
  switch (action.type) {
    case ADD_POST_COMMENT_TEAM_REQUEST:
    case VIEW_MORE_COMMENT_TEAM_POST_DATA_REQUEST:
    case CREATE_COMMENT_REPLY_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_POST_COMMENT_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        addPostCommentDataTeam: action.payload,
      };

    case VIEW_MORE_COMMENT_TEAM_POST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        viewMoreTimePostDataTeam: action.payload,
      };
    case VIEW_MORE_CLICKED_TEAM_POST_ID:
      return {
        ...state,
        viewMoreTimePostIdTeam: action.payload,
      };

    case CREATE_COMMENT_REPLY_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        addPostCommentReplyTeam: action.payload,
      };

    case VIEW_MORE_COMMENT_TEAM_POST_DATA_RESET:
      return {
        ...state,
        loading: false,
        viewMoreTimePostDataTeam: {},
      };

    case ADD_POST_COMMENT_TEAM_FAILURE:
    case VIEW_MORE_COMMENT_TEAM_POST_DATA_FAILURE:
    case CREATE_COMMENT_REPLY_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
