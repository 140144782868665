import {
  CREATE_WEBSITE_FAILURE,
  CREATE_WEBSITE_REQUEST,
  CREATE_WEBSITE_SUCCESS,
  DELETE_WEBSITE_FAILURE,
  DELETE_WEBSITE_REQUEST,
  DELETE_WEBSITE_SUCCESS,
  GET_ALL_WEBSITE_FAILURE,
  GET_ALL_WEBSITE_REQUEST,
  GET_ALL_WEBSITE_SUCCESS,
  GET_WEBSITE_BY_ID_FAILURE,
  GET_WEBSITE_BY_ID_REQUEST,
  GET_WEBSITE_BY_ID_SUCCESS,
  UPDATE_WEBSITE_FAILURE,
  UPDATE_WEBSITE_REQUEST,
  UPDATE_WEBSITE_SUCCESS,
  RESET,
} from "../constants/website";
const initialState = {};
export function websiteReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_WEBSITE_REQUEST:
    case UPDATE_WEBSITE_REQUEST:
    case DELETE_WEBSITE_REQUEST:
    case GET_ALL_WEBSITE_REQUEST:
    case GET_WEBSITE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_WEBSITE_SUCCESS:
      return {
        ...state,
        loading: false,
        createWebsiteData: action.payload,
      };
    case UPDATE_WEBSITE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateWebsiteData: action.payload,
      };
    case DELETE_WEBSITE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteWebsiteData: action.payload,
      };
    case GET_ALL_WEBSITE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllWebsiteData: action.payload,
      };
    case GET_WEBSITE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getWebsiteByIdData: action.payload,
      };
    case CREATE_WEBSITE_FAILURE:
    case UPDATE_WEBSITE_FAILURE:
    case DELETE_WEBSITE_FAILURE:
    case GET_ALL_WEBSITE_FAILURE:
    case GET_WEBSITE_BY_ID_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
