import React from "react";
import DashboardPageContainer from "../CustomComponents/DashboardPageContainer/DashboardPageContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllTeamChatList,
  GetMessageInfoTimeDetails,
  IndividualChatUserLists,
} from "../../store/action/newChatAction";
import { Avatar } from "primereact/avatar";
import moment from "moment";
import { io } from "socket.io-client";
import Data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import axios from "axios";
import IndividualLatestChatRightBody from "./IndividualLatestChatRightBody";
import IndividualLatestChatLeftBody from "./IndividualLatestChatLeftBody";
import useFetchAllGif from "../../hooks/useFetchAllGif";
import ChatFollowUser from "./ChatFollowUser";
import { useGetUnder13User } from "../../hooks/useGetUnder13User";
import { Toast } from "primereact/toast";
import { EVENT_SCREENS } from "../../configue/firebase/EventConstants";
import FirebaseLogEvents from "../../configue/firebase/LogEvents";

const socket = io.connect(process.env.REACT_APP_API_URL);

function LatestChat() {
  const dispatch = useDispatch();
  const isUnder13 = useGetUnder13User();
  const toast = React.useRef(null);
  const { individualChatUserData, teamsChatListData, getMessageInfoTimeData } =
    useSelector((state) => state.newChatReducer);

  // by default chat is opened or not
  const [isChatOpen, setIsChatOpen] = React.useState(false);

  // for last message basis and use in scroll down
  // const lastMessageRef = React.useRef(null);
  // const [isLastMessageCalled, setIsLastMessageCalled] = React.useState(false);
  // set up active tab basis...
  const [activeTabName, setActiveTabName] = React.useState(
    isUnder13 ? "teamone" : "individualone"
  );
  const [individualChatUserList, setIndividualChatUserList] = React.useState(
    []
  );
  const [teamChatList, setTeamChatList] = React.useState([]);

  // emoji basis set up
  const [isEmojiMartShow, setIsEmojiMartShow] = React.useState(false);
  const [isShowGifPicker, setIsShowGifPicker] = React.useState(false);
  const [serachedGifText, setSearchedGifText] = React.useState("");
  const gifBoxRef = React.useRef(null);

  // when the user change the room then used...
  const [isChatLoading, setIsChatLoading] = React.useState(false);

  // chat header basis stuffs
  const initialChatHeaderValues = {
    roomId: "",
    roomName: "",
    //when user joins team room
    teamName: "",
    roomLogo: "",
    individualOrTeam: "",
    //when user joins individual room
    userShortName: "",
    ChatUserManagementId: "",
    receviedUserId: "",
    groupTimeTeamId: "",
    userId: "",
  };
  const [chatHeaderInformation, setChatHeaderInformation] = React.useState(
    initialChatHeaderValues
  );

  // individual room message listing when user joins individual list
  const [userIndividualChatData, setUserIndividualChatData] = React.useState(
    []
  );
  const [
    deleteIndividualMessageTimeDetails,
    setDeleteIndividualMessageTimeDetails,
  ] = React.useState("");

  // Team chat basis => teams chat listing basis...
  const [teamsUserChatData, setTeamsUserChatData] = React.useState([]);

  // here is used for chat time send message basis
  const initialChatValues = React.useMemo(
    () => ({
      chatTextMessage: "",
      chatImagesFiles: [],
      chatImagesPreviews: [],
      chatApplicationFiles: "",
      chatApplicationPreview: "",
      chatGifLink: "",
    }),
    []
  );
  const [chatFormValues, setChatFormValues] = React.useState(initialChatValues);

  // Group Related States
  const initialMessageInfoValues = {
    readByUsers: [],
    deliveredByUsers: [],
  };
  const [messageInfoValues, setMessageInfoValues] = React.useState(
    initialMessageInfoValues
  );
  const [allTeamsChatMemberUsersList, setAllTeamsChatMembersUserList] =
    React.useState([]);
  const [deleteGroupMessageTimeDetails, setDeleteGroupMessageTimeDetails] =
    React.useState("");

  // when the user search the listing then used...
  const [searchUser, setSearchUser] = React.useState("");

  // online user's listing basis
  const [onlineUserList, setOnlineUserList] = React.useState([]);

  // when the use want to send the image and files and document then used...
  const [isOpenFileSharingOptions, setIsOpenFileSharingOptions] =
    React.useState(false);
  const [isOpenImageVideoSelection, setIsOpenImageVideoSelection] =
    React.useState(false);
  const [isOpenDocumentSelection, setIsOpenDocumentSelection] =
    React.useState(false);

  // user chatMedia related stuffs
  const initialChatMedias = React.useMemo(
    () => ({
      chatImages: [],
      chatVideos: [],
      chatDocuments: [],
    }),
    []
  );
  const [chatMediaList, setChatMediaList] = React.useState(initialChatMedias);

  const messagesEndRef = React.useRef(null);

  const scrollToBottom = React.useCallback(() => {
    messagesEndRef.current?.scrollIntoView(false);
  }, [messagesEndRef]);

  React.useEffect(() => {
    FirebaseLogEvents(
      EVENT_SCREENS.chat_listing.event_name,
      EVENT_SCREENS.chat_listing.event_description
    );
  }, []);

  React.useEffect(() => {
    if (isChatOpen) {
      FirebaseLogEvents(
        EVENT_SCREENS.chat.event_name,
        EVENT_SCREENS.chat.event_description
      );
    }
  }, [isChatOpen]);

  React.useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  React.useEffect(() => {
    if (isUnder13 && activeTabName === "individualone") {
      setActiveTabName("teamone");
    }
  }, [isUnder13, activeTabName]);

  // when the get the gif then used..
  const gifLists = useFetchAllGif(serachedGifText);

  // get date into weeks wise format individual listing time
  let getDateWeekFormat = (date) => {
    let new_date = moment
      .utc(
        moment(date).format("YYYY-MM-DD") + " " + moment(date).format("hh:mm")
      )
      .local()
      .format("YYYY-MM-DD, h:mm:ss a");
    var seconds = Math.floor(
      (new Date(moment().format("YYYY-MM-DDTHH:mm:ssZ")) -
        new Date(
          moment(new_date, "YYYY-MM-DD, h:mm:ss a").format(
            "YYYY-MM-DDTHH:mm:ssZ"
          )
        )) /
        1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  };

  const groupedDays = React.useCallback((messages) => {
    return messages?.reduce((acc, el, i) => {
      const messageDay = moment(el.dAddedDate).format(
        `${process.env.REACT_APP_DATE_FORMAT}`
      );
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
  }, []);

  // On Room join message array set-up
  const generateItems = React.useCallback(
    (messages) => {
      const days = groupedDays(messages);
      const sortedDays = Object.keys(days).sort(
        (x, y) =>
          moment(x, `${process.env.REACT_APP_DATE_FORMAT}`).unix() -
          moment(y, `${process.env.REACT_APP_DATE_FORMAT}`).unix()
      );
      const items = sortedDays?.reduce((acc, date) => {
        const sortedMessages = days[date].sort(
          (x, y) => new Date(x.dAddedDate) - new Date(y.dAddedDate)
        );
        return acc.concat([{ type: "day", date, id: date }, ...sortedMessages]);
      }, []);
      if (activeTabName === "individualone") {
        setUserIndividualChatData(items);
      } else {
        setTeamsUserChatData(items);
      }
    },
    [
      setTeamsUserChatData,
      activeTabName,
      setUserIndividualChatData,
      groupedDays,
    ]
  );

  // used for online user's list
  React.useEffect(() => {
    socket.emit("new-user-add", sessionStorage.getItem("id"));
    sessionStorage.removeItem("roomId");
  }, []);

  React.useEffect(() => {
    socket.on("get-users", (onlineUserList) => {
      let onlineUsers = [];
      for (let users of onlineUserList) {
        onlineUsers.push(parseInt(users.userId));
      }
      setOnlineUserList(onlineUsers);
    });
    return () => {
      sessionStorage.removeItem("roomId");
    };
  }, [setOnlineUserList]);

  // const scrollToBottom = React.useCallback(() => {
  //   lastMessageRef.current?.scrollIntoView(false);
  // }, []);

  // React.useEffect(() => {
  //   // if (isLastMessageCalled) {
  //     // console.log(isLastMessageCalled);
  //     scrollToBottom();
  //   // }
  // }, [scrollToBottom]);

  // when the by default check latest message and scroll basis used...
  // React.useEffect(() => {
  //   // lastMessageRef.current?.scrollIntoView({ behavior: "smooth", block: "end" })
  //   if (!isChatLoading && isLastMessageCalled) {
  //     lastMessageRef.current?.scrollIntoView();
  //     // setIsLastMessageCalled(false)
  //   }

  //   return () => {
  //     setIsLastMessageCalled(false);
  //   };
  // }, [isLastMessageCalled, isChatLoading, setIsLastMessageCalled]);

  React.useEffect(() => {
    if (activeTabName === "individualone") {
      dispatch(
        IndividualChatUserLists({ iUserId: sessionStorage.getItem("id") })
      );
    } else if (activeTabName === "teamone") {
      dispatch(GetAllTeamChatList({ iUserId: sessionStorage.getItem("id") }));
    }
  }, [dispatch, activeTabName]);

  // when the individual chat user list dispatch then used...
  React.useEffect(() => {
    if (individualChatUserData && individualChatUserData?.code === "200") {
      setIndividualChatUserList(individualChatUserData?.data);
    } else {
      setIndividualChatUserList([]);
    }
  }, [individualChatUserData]);

  // when the team chat dispatch then used...
  React.useEffect(() => {
    if (teamsChatListData && teamsChatListData?.code === "200") {
      setTeamChatList(teamsChatListData?.data);
    } else {
      setTeamChatList([]);
    }
  }, [teamsChatListData]);

  // when the search user then used...
  const handleSearchUsers = (e) => {
    const { value } = e.target;
    setSearchUser(value);
  };

  // when the get individual user chat then used...
  const getIndividualChatData = React.useCallback(
    async (individualChatInformation) => {
      if (
        individualChatInformation !== null &&
        individualChatInformation !== undefined &&
        individualChatInformation !== ""
      ) {
        const values = {
          vRoomId: individualChatInformation?.vRoomId,
        };
        // here' through get the individual user chat data...
        const getIndividualUserChat = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/Chat/getAllOneToOneChatList`,
          values
        );
        if (getIndividualUserChat && getIndividualUserChat?.data?.code === 0) {
          // use for last message scrolling basis...
          // setUserIndividualChatData(getIndividualUserChat?.data?.data)
          generateItems(getIndividualUserChat?.data?.data);
          // when the get all the chat then used..
          setIsChatLoading(false);
          // message scroll basis...
          // setIsLastMessageCalled(true);
        } else {
          setUserIndividualChatData([]);
        }
      } else {
        setUserIndividualChatData([]);
      }
    },
    [generateItems, setIsChatLoading]
  );

  // when the get Teams chat then used...
  const getTeamsChataData = React.useCallback(
    async (teamsChatInformation) => {
      // GetAllChatByRoom({ iChatUserManagementId: iChatUserManagementId })
      if (
        teamsChatInformation !== null &&
        teamsChatInformation !== undefined &&
        teamsChatInformation !== ""
      ) {
        const values = {
          vRoomId: teamsChatInformation?.chat_user_management?.vRoomId,
          iUserId: sessionStorage.getItem("id"),
        };
        const getTeamsChatRecords = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/Chat/getAllChatData`,
          values
        );
        if (getTeamsChatRecords && getTeamsChatRecords?.data?.code === "200") {
          // use for last message scrolling basis...
          // setTeamsUserChatData(getTeamsChatRecords?.data?.data)
          generateItems(getTeamsChatRecords?.data?.data);
          // message scroll basis...
          // setIsLastMessageCalled(true);
          // when the get all the chat then used..
          setIsChatLoading(false);
        } else {
          setTeamsUserChatData([]);
        }
      } else {
        setTeamsUserChatData([]);
      }
    },
    [generateItems]
  );

  // when Individual user side chating then join room then used...
  const handleJoinIndividualChatRoom = React.useCallback(
    async (e, individualUserInformation) => {
      // when the individual user join the chat then used...
      e.preventDefault();
      // here we will set if user has open file sharing option and much more then used...
      setIsOpenFileSharingOptions(false);
      setIsOpenImageVideoSelection(false);
      setIsOpenDocumentSelection(false);
      setChatFormValues(initialChatValues);
      // when the user change the room then used...
      setIsChatLoading(true);
      // media modal hide basis
      window.$("#mediauplaodmodal").offcanvas("hide");
      setChatMediaList(initialChatMedias);

      socket.emit(
        "join_room",
        individualUserInformation?.vRoomId,
        individualUserInformation?.iTeamId
      );
      // when the user join then chat is opened true
      setIsChatOpen(true);
      sessionStorage.setItem("roomId", individualUserInformation?.vRoomId);
      // when the user seen the messages then used...
      const values = {
        iAddedById: individualUserInformation?.iUserId,
        vRoomId: individualUserInformation?.vRoomId,
      };
      const updateUserSeenMessages = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Chat/UnseenUpdateToSeen`,
        values
      );
      if (updateUserSeenMessages && updateUserSeenMessages?.data?.code === 0) {
        dispatch(
          IndividualChatUserLists({ iUserId: sessionStorage.getItem("id") })
        );
      }

      setChatHeaderInformation({
        roomId: individualUserInformation?.vRoomId,
        roomLogo: individualUserInformation?.user?.vImage,
        roomName: individualUserInformation?.user?.FullName,
        teamName: "",
        individualOrTeam: "individualone",
        userShortName:
          individualUserInformation?.user?.vFirstName?.slice(0, 1) +
          individualUserInformation?.user?.vLastName?.slice(0, 1),
        ChatUserManagementId: individualUserInformation?.iChatUserManagementId,
        receviedUserId: individualUserInformation?.user?.iRequesterId,
        groupTimeTeamId: "",
        userId: individualUserInformation?.user?.iUserId,
      });
      // here through call the individual chat data
      getIndividualChatData(individualUserInformation);
    },
    [
      dispatch,
      setChatHeaderInformation,
      initialChatMedias,
      initialChatValues,
      getIndividualChatData,
      setIsChatOpen,
    ]
  );

  // when Team's side chating then join room then used...
  const handleJoinTeamChatRoom = React.useCallback(
    async (e, teamInformation) => {
      // when the user join the group chat then used...
      e.preventDefault();
      setIsOpenFileSharingOptions(false);
      setIsOpenImageVideoSelection(false);
      setIsOpenDocumentSelection(false);
      setChatFormValues(initialChatValues);
      // when the user change the room then used...
      setIsChatLoading(true);
      // memeber modal hide basis
      window.$("#offcanvasRight").offcanvas("hide");
      setAllTeamsChatMembersUserList([]);
      // media modal hide basis
      window.$("#mediauplaodmodal").offcanvas("hide");
      setChatMediaList(initialChatMedias);
      socket.emit("join_room", teamInformation?.chat_user_management?.vRoomId);
      // when the user join then chat is opened true
      setIsChatOpen(true);
      sessionStorage.setItem(
        "roomId",
        teamInformation?.chat_user_management?.vRoomId
      );
      // when the user join the room then user seen then used...
      const values = {
        iAddedById: teamInformation?.iUserId,
        vRoomId: teamInformation?.chat_user_management?.vRoomId,
      };
      const updateUserSeenMessages = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Chat/UnseenUpdateToSeen`,
        values
      );
      if (updateUserSeenMessages && updateUserSeenMessages?.data?.code === 0) {
        dispatch(GetAllTeamChatList({ iUserId: sessionStorage.getItem("id") }));
      }
      setChatHeaderInformation({
        roomId: teamInformation?.chat_user_management?.vRoomId,
        roomLogo: teamInformation?.team?.vTeamLogo,
        roomName: teamInformation?.team?.tTeamTagline,
        teamName: teamInformation?.team?.vTeamName,
        individualOrTeam: "teamone",
        userShortName: "",
        ChatUserManagementId: teamInformation?.ChatUserManagementId,
        receviedUserId: "",
        groupTimeTeamId: teamInformation?.iTeamId,
        userId: "",
      });
      // here through call the teams chat data
      getTeamsChataData(teamInformation);
    },
    [
      dispatch,
      initialChatMedias,
      initialChatValues,
      setChatHeaderInformation,
      getTeamsChataData,
      setIsChatOpen,
    ]
  );

  // when the user type the message for send puropose then used..
  const handleChatTextChanges = React.useCallback(
    (e) => {
      e.preventDefault();
      setChatFormValues((prev) => ({
        ...prev,
        chatTextMessage: e.target.value,
      }));
    },
    [setChatFormValues]
  );

  // when the user send the chat message then used....
  const handleSendMessage = React.useCallback(
    async (e, gifData) => {
      e.preventDefault();
      const currentTime = moment();
      let t = moment(
        moment(currentTime.format("YYYY-MM-DD")).format(
          `${process.env.REACT_APP_DATE_FORMAT}`
        ) +
          " " +
          currentTime.format("hh:mm:ss")
      )
        .utc()
        .format("hh:mm A");
      const timeMoment = moment(t, "HH:mm:ss");
      const currentHour = timeMoment.format("hh"); // HH for 24-hour format
      const currentMinute = timeMoment.format("mm");
      const currentSec = timeMoment.format("ss");
      let dateTime = moment();
      dateTime.set("hour", currentHour);
      dateTime.set("minute", currentMinute);
      dateTime.set("second", currentSec);
      let addedDateTime = dateTime.format("YYYY-MM-DD, HH:mm:ss a");
      if (
        (chatFormValues.chatTextMessage?.trim()?.length > 0 ||
          chatFormValues.chatImagesFiles?.length > 0 ||
          chatFormValues.chatApplicationFiles !== "" ||
          (gifData !== null && gifData !== undefined && gifData !== "")) &&
        chatHeaderInformation.individualOrTeam === "individualone"
      ) {
        // console.log("if called");
        // here is new way to create the chat => using api calling...
        const individualFormData = new FormData();
        individualFormData.append("vRoomId", chatHeaderInformation?.roomId);
        individualFormData.append("dAddedTime", addedDateTime);
        individualFormData.append(
          "iChatUserManagementId",
          chatHeaderInformation?.ChatUserManagementId
        );
        individualFormData.append(
          "vUserName",
          sessionStorage.getItem("userName")
        );
        individualFormData.append("iAddedById", sessionStorage.getItem("id"));
        if (chatFormValues?.chatTextMessage?.length > 0) {
          individualFormData.append(
            "vMessage",
            chatFormValues?.chatTextMessage?.length > 0
              ? chatFormValues?.chatTextMessage
              : ""
          );
        }
        individualFormData.append(
          "iReceivedById",
          chatHeaderInformation?.receviedUserId
        );
        if (chatFormValues?.chatImagesFiles?.length > 0) {
          for (let i = 0; i < chatFormValues?.chatImagesFiles?.length; i++) {
            individualFormData.append(
              "vFile",
              chatFormValues?.chatImagesFiles[i]
            );
          }
        }
        if (chatFormValues?.chatApplicationFiles !== "") {
          individualFormData.append(
            "vFile",
            chatFormValues?.chatApplicationFiles
          );
        }
        if (gifData !== null && gifData !== undefined && gifData !== "") {
          individualFormData.append("vLink", gifData);
        }

        const sendIndividualMessageResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/Chat/individualChat`,
          individualFormData
        );
        if (sendIndividualMessageResponse?.data?.code === 0) {
          const lastMessage = moment(
            userIndividualChatData[userIndividualChatData?.length - 1]
              ?.dAddedDate
          ).format(`${process.env.REACT_APP_DATE_FORMAT}`);
          const isFormateReceiveTime =
            lastMessage ===
            moment().format(`${process.env.REACT_APP_DATE_FORMAT}`)
              ? false
              : true;
          // here flage through set up today basis view...
          if (isFormateReceiveTime) {
            const dateObj = {
              type: "day",
              date: sendIndividualMessageResponse?.data?.data?.dAddedDate,
              id: sendIndividualMessageResponse?.data?.data?.dAddedDate,
            };
            setUserIndividualChatData((prev) => [
              ...prev,
              dateObj,
              {
                ...sendIndividualMessageResponse?.data?.data,
                iReceivedById: chatHeaderInformation?.receviedUserId,
              },
            ]);
          } else {
            setUserIndividualChatData((prev) => [
              ...prev,
              {
                ...sendIndividualMessageResponse?.data?.data,
                iReceivedById: chatHeaderInformation?.receviedUserId,
              },
            ]);
          }

          const latestIndividualUserList = [];
          individualChatUserList?.map((el) => {
            if (
              el.vRoomId === sendIndividualMessageResponse?.data?.data?.vRoomId
            ) {
              return latestIndividualUserList.push({
                ...el,
                eDelete: "No",
                LastMessage:
                  sendIndividualMessageResponse?.data?.data?.vMessage,
                AddedTime:
                  sendIndividualMessageResponse?.data?.data?.dAddedTime,
                eType: sendIndividualMessageResponse?.data?.data?.eType,
              });
            }
            return latestIndividualUserList.push(el);
          });
          setIndividualChatUserList(latestIndividualUserList);
          socket.emit(
            "single_message",
            JSON.stringify({
              ...sendIndividualMessageResponse?.data?.data,
              iReceivedById: chatHeaderInformation?.receviedUserId,
              isFormateReceiveTime: isFormateReceiveTime,
            })
          );
        }
        setChatFormValues(initialChatValues);
        // message scroll basis...
        // setIsLastMessageCalled(true);
      } else if (
        (chatFormValues?.chatTextMessage ||
          chatFormValues?.chatTextMessage?.trim()?.length > 0 ||
          chatFormValues?.chatImagesFiles?.length > 0 ||
          chatFormValues?.chatApplicationFiles !== "" ||
          (gifData !== null && gifData !== undefined && gifData !== "")) &&
        chatHeaderInformation?.individualOrTeam === "teamone"
      ) {
        const formData = new FormData();
        formData.append(
          "iChatUserManagementId",
          chatHeaderInformation?.ChatUserManagementId
        );
        formData.append("vRoomId", chatHeaderInformation?.roomId);
        formData.append("vUserName", chatHeaderInformation?.teamName);
        formData.append("iAddedById", sessionStorage.getItem("id"));
        formData.append("dAddedTime", addedDateTime);
        if (chatFormValues?.chatTextMessage?.length > 0) {
          formData.append(
            "vMessage",
            chatFormValues?.chatTextMessage?.length > 0
              ? chatFormValues?.chatTextMessage
              : ""
          );
        }
        formData.append("iTeamId", chatHeaderInformation?.groupTimeTeamId);
        if (chatFormValues?.chatImagesFiles?.length > 0) {
          for (let i = 0; i < chatFormValues?.chatImagesFiles?.length; i++) {
            formData.append("vFile", chatFormValues?.chatImagesFiles[i]);
          }
        }
        if (chatFormValues?.chatApplicationFiles !== "") {
          formData.append("vFile", chatFormValues?.chatApplicationFiles);
        }
        if (gifData !== null && gifData !== undefined && gifData !== "") {
          formData.append("vLink", gifData);
        }
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        const sendingTeamsMessage = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/Chat/createChat`,
          formData,
          config
        );
        if (sendingTeamsMessage && sendingTeamsMessage?.data?.code === "200") {
          const lastMessage = moment(
            teamChatList[teamChatList?.length - 1]?.dAddedDate
          ).format(`${process.env.REACT_APP_DATE_FORMAT}`);
          const isFormateReceiveTime =
            lastMessage ===
            moment().format(`${process.env.REACT_APP_DATE_FORMAT}`)
              ? false
              : true;
          if (isFormateReceiveTime) {
            const dateObj = {
              type: "day",
              date: sendingTeamsMessage?.data?.data?.dAddedDate,
              id: sendingTeamsMessage?.data?.data?.dAddedDate,
            };
            setTeamsUserChatData((list) => [
              ...list,
              dateObj,
              sendingTeamsMessage?.data?.data,
            ]);
          } else {
            setTeamsUserChatData((list) => [
              ...list,
              sendingTeamsMessage?.data?.data,
            ]);
          }

          // last message related set up...
          const latestTeamUserList = [];
          teamChatList?.map((el) => {
            if (
              el?.chat_user_management?.vRoomId ===
              sendingTeamsMessage?.data?.data?.vRoomId
            ) {
              return latestTeamUserList.push({
                ...el,
                chat_user_management: {
                  ...el.chat_user_management,
                  LastMessage: sendingTeamsMessage?.data?.data?.vMessage,
                  AddedTime: sendingTeamsMessage?.data?.data?.dAddedTime,
                  AddedDate: sendingTeamsMessage?.data?.data?.AddedDate,
                  eType: sendingTeamsMessage?.data?.data?.eType,
                  eDelete: "No",
                },
              });
            }
            return latestTeamUserList.push(el);
          });

          setTeamChatList(latestTeamUserList);

          socket.emit(
            "send_message",
            JSON.stringify({
              ...sendingTeamsMessage?.data?.data,
              isFormateReceiveTime: isFormateReceiveTime,
            })
          );
          // message scroll basis...
          // setIsLastMessageCalled(true);
        }
      }
      setIsOpenFileSharingOptions(false);
      setIsOpenImageVideoSelection(false);
      setIsOpenDocumentSelection(false);
      setIsEmojiMartShow(false);
      setIsShowGifPicker(false);
      setChatFormValues(initialChatValues);
    },
    [
      individualChatUserList,
      teamChatList,
      initialChatValues,
      userIndividualChatData,
      chatFormValues,
      chatHeaderInformation,
      setTeamChatList,
      setIndividualChatUserList,
      setChatFormValues,
    ]
  );

  // upcomming_message individual message basis...
  React.useEffect(() => {
    socket.on("message", (data) => {
      if (
        parseInt(sessionStorage.getItem("id")) === parseInt(data?.iReceivedById)
      ) {
        // when the user not join the room then used...
        if (
          sessionStorage.getItem("roomId") === null ||
          sessionStorage.getItem("roomId") !== data?.vRoomId
        ) {
          let individualChatData = [];
          setIndividualChatUserList((pre) => (individualChatData = pre));
          if (individualChatData && individualChatData.length > 0) {
            // for buffer storage
            let found = individualChatData.find(
              (element) =>
                +element?.user?.iRequesterId === +data.iAddedById &&
                element.vRoomId === data.vRoomId
            );
            let unFound = individualChatData.filter(
              (element) =>
                +element.user?.iRequesterId !== +data.iAddedById &&
                element.vRoomId !== data.vRoomId
            );
            found = {
              ...found,
              eSeenCount: ++found.eSeenCount,
              LastMessage: data.vMessage,
              eType: data?.eType,
              image_Count: data.chat_file_transactions?.length,
              AddedTime: data.dAddedTime,
            };
            setIndividualChatUserList([found, ...unFound]);
          }
          // setIndividualChatUserList((prev) => {
          //   let demoArray = [];
          //   prev?.map((el) => {
          //     if (el.vRoomId === data?.vRoomId) {
          //       return demoArray.push({
          //         ...el,
          //         eSeenCount: el?.eSeenCount + 1,
          //         AddedDate: data?.dAddedDate,
          //         AddedTime: data?.dAddedTime,
          //         LastMessage: data?.vMessage,
          //         eType: data?.eType,
          //       });
          //     }
          //     return demoArray.push(el);
          //   });
          //   return [...demoArray];
          // });
        }
      }
    });
  }, []);

  // upcomming_message group message basis...
  React.useEffect(() => {
    socket.on("group_message", (data) => {
      // when the user not join the room then used...
      if (
        sessionStorage.getItem("roomId") === null ||
        sessionStorage.getItem("roomId") !== data?.vRoomId
      ) {
        // let individualChatData = [];
        // setTeamChatList((pre) => (individualChatData = pre));
        // if (individualChatData && individualChatData.length > 0) {
        //   // for buffer storage
        //   let found = individualChatData.find(
        //     (element) =>
        //       element?.chat_user_management?.vRoomId === data?.vRoomId
        //   );
        //   let unFound = individualChatData.filter(
        //     (element) =>
        //       element?.chat_user_management?.vRoomId !== data?.vRoomId
        //   );
        //   console.log(found, "found")
        //   if (found) {
        //     found = {
        //       ...found,
        //       chat_user_management: {
        //         ...found.chat_user_management,
        //         eSeenCount: ++found.chat_user_management.eSeenCount,
        //         LastMessage: data.vMessage,
        //         eType: data?.eType,
        //         image_Count: data.chat_file_transactions?.length,
        //         AddedTime: data.dAddedTime,
        //         AddedDate: data?.dAddedDate,
        //       },
        //     };
        //   }
        //   setTeamChatList([found, ...unFound]);
        // }
        setTeamChatList((prev) => {
          let demoArray = [];
          prev?.map((el) => {
            if (el?.chat_user_management?.vRoomId === data?.vRoomId) {
              return demoArray.push({
                ...el,
                chat_user_management: {
                  ...el?.chat_user_management,
                  eSeenCount: el?.chat_user_management?.eSeenCount + 1,
                  AddedDate: data?.dAddedDate,
                  AddedTime: data?.dAddedTime,
                  LastMessage: data?.vMessage,
                  eType: data?.eType,
                },
              });
            }
            return demoArray.push(el);
          });
          return [...demoArray];
        });
      }
    });
  }, []);

  // when the opposite user receive messages then used...
  React.useEffect(() => {
    socket.on("single_receive_message", async (msg) => {
      if (
        +msg.iReceivedById === +sessionStorage.getItem("id") &&
        msg.vRoomId === sessionStorage.getItem("roomId")
      ) {
        // here flage through set up today basis view...
        if (msg?.isFormateReceiveTime) {
          const dateObj = {
            type: "day",
            date: msg?.dAddedDate,
            id: msg?.dAddedDate,
          };
          setUserIndividualChatData((pre) => [...pre, dateObj, msg]);
        } else {
          setUserIndividualChatData((pre) => [...pre, msg]);
        }
        // message scroll basis...
        // setIsLastMessageCalled(true);

        // when the user seen the messages then used...
        const values = {
          iAddedById: sessionStorage.getItem("id"),
          vRoomId: msg.vRoomId,
        };

        const updateUserSeenMessages = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/Chat/UnseenUpdateToSeen`,
          values
        );
        if (
          updateUserSeenMessages &&
          updateUserSeenMessages?.data?.code === 0
        ) {
          setIndividualChatUserList((prev) => {
            let demoArray = [];
            prev?.map((el) => {
              if (el.vRoomId === msg?.vRoomId) {
                return demoArray.push({
                  ...el,
                  AddedDate: msg?.dAddedDate,
                  AddedTime: msg?.dAddedTime,
                  LastMessage: msg?.vMessage,
                  eType: msg?.eType,
                });
              }
              return demoArray.push(el);
            });
            return [...demoArray];
          });
        }
      }
    });
  }, []);

  // when the teams side receive message for all the users then used...
  React.useEffect(() => {
    if (socket) {
      socket.on("receive_message", (data) => {
        if (data?.isFormateReceiveTime) {
          const dateObj = {
            type: "day",
            date: data?.dAddedDate,
            id: data?.dAddedDate,
          };
          if (
            sessionStorage.getItem("roomId") !== null ||
            sessionStorage.getItem("roomId") === data?.vRoomId
          ) {
            setTeamsUserChatData((prev) => [...prev, dateObj, data]);
          }
        } else {
          if (
            sessionStorage.getItem("roomId") !== null ||
            sessionStorage.getItem("roomId") === data?.vRoomId
          ) {
            setTeamsUserChatData((prev) => [...prev, data]);
          }
        }
        if (
          sessionStorage.getItem("roomId") !== null ||
          sessionStorage.getItem("roomId") === data?.vRoomId
        ) {
          setTeamChatList((prev) => {
            let demoArray = [];
            prev?.map((el) => {
              if (el?.chat_user_management?.vRoomId === data?.vRoomId) {
                return demoArray.push({
                  ...el,
                  chat_user_management: {
                    ...el?.chat_user_management,
                    AddedDate: data?.AddedDate,
                    AddedTime: data?.AddedTime,
                    LastMessage: data?.vMessage,
                    eType: data?.eType,
                  },
                });
              }
              return demoArray.push(el);
            });
            return [...demoArray];
          });
        }

        // message scroll basis...
        // setIsLastMessageCalled(true);
      });
    }
  }, []);

  // when the user remove the message then used...
  React.useEffect(() => {
    if (socket) {
      socket.on("individual_delete_single_receive_message", (data) => {
        let IndividualDeleteTimeArray = [];
        userIndividualChatData?.map((el) => {
          if (el.iChatId === data?.iChatId) {
            return IndividualDeleteTimeArray.push({
              ...data,
            });
          }
          return IndividualDeleteTimeArray.push(el);
        });
        setUserIndividualChatData(IndividualDeleteTimeArray);
        // last message update basis
        const latestIndividualUserList = [];
        individualChatUserList?.map((el) => {
          if (el.vRoomId === data?.vRoomId) {
            return latestIndividualUserList.push({
              ...el,
              eDelete: "Yes",
              // LastMessage: sendIndividualMessageResponse?.data?.data?.vMessage,
              // AddedTime: sendIndividualMessageResponse?.data?.data?.dAddedTime
            });
          }
          return latestIndividualUserList.push(el);
        });
        setIndividualChatUserList(latestIndividualUserList);
        // message scroll basis...
        // setIsLastMessageCalled(true);
      });
    }
  }, [
    userIndividualChatData,
    individualChatUserList,
    setUserIndividualChatData,
    setIndividualChatUserList,
  ]);

  // when the user remove the group message then used...
  React.useEffect(() => {
    if (socket) {
      socket.on("delete_receive_message", (data) => {
        let TeamsDeleteTimeArray = [];
        teamsUserChatData?.map((el) => {
          if (el.iChatId === data?.iChatId) {
            return TeamsDeleteTimeArray.push({
              ...data,
            });
          }
          return TeamsDeleteTimeArray.push(el);
        });
        setTeamsUserChatData(TeamsDeleteTimeArray);
        // message scroll basis...
        // setIsLastMessageCalled(true);
      });
    }
  }, [teamsUserChatData, setTeamsUserChatData]);

  // when the group chat and user want to open the MESSAGE INFO then used...
  const handleOpenMessageInfoBox = (e, messageDetails) => {
    const values = {
      iChatId: messageDetails?.iChatId,
      vRoomId: messageDetails?.vRoomId,
    };
    dispatch(GetMessageInfoTimeDetails(values));
  };

  // when the GetMEssageInforTimeDetails dispatched then used...
  React.useEffect(() => {
    if (getMessageInfoTimeData && getMessageInfoTimeData?.code === 0) {
      if (getMessageInfoTimeData?.data?.length > 0) {
        setMessageInfoValues({
          readByUsers: getMessageInfoTimeData?.data?.filter(
            (res) =>
              parseInt(res.iReceivedById) !==
                parseInt(sessionStorage.getItem("id")) && res.eSeen === "Yes"
          ),
          deliveredByUsers: getMessageInfoTimeData?.data?.filter(
            (res) =>
              parseInt(res.iReceivedById) !==
                parseInt(sessionStorage.getItem("id")) && res.eSeen === "No"
          ),
        });
      }
    } else {
      setMessageInfoValues({
        readByUsers: [],
        deliveredByUsers: [],
      });
    }
  }, [getMessageInfoTimeData, setMessageInfoValues]);

  // when the user get the Teams or Group members then used...
  const handleGetGroupMember = React.useCallback(
    async (e, teamsDetails) => {
      e.preventDefault();
      const values = {
        iUserId: sessionStorage.getItem("id"),
        iTeamId: teamsDetails?.groupTimeTeamId,
      };
      const resGroupMemberData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/teamMember/getAllChatGroupMemberData`,
        values
      );
      if (resGroupMemberData && resGroupMemberData?.data?.code === "200") {
        window.$("#offcanvasRight").offcanvas("show");
        setAllTeamsChatMembersUserList(resGroupMemberData?.data?.data);
      } else {
        setAllTeamsChatMembersUserList([]);
      }
    },
    [setAllTeamsChatMembersUserList]
  );

  // when the user remove the group message then used...
  const handleDeleteGroupMessageConformation = React.useCallback(
    (e, messageDetails) => {
      e.preventDefault();
      // console.log("handleDeleteGroupMessageConformation", messageDetails);
      setDeleteGroupMessageTimeDetails(messageDetails);
    },
    [setDeleteGroupMessageTimeDetails]
  );

  // when the final group message delete for every-one then used...
  const handleDeleteGroupMessageForEveryone = React.useCallback(async () => {
    const values = {
      iChatId: deleteGroupMessageTimeDetails?.iChatId,
      vRoomId: deleteGroupMessageTimeDetails?.vRoomId,
    };
    const delteGroupMessateTimeResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Chat/groupChatDelete`,
      values
    );
    if (delteGroupMessateTimeResponse?.data?.code === 0) {
      // here through we have update message when we delete the message then used...
      let updatedTeamsMessageArray = [];
      let removedTeamMessageObj = "";
      teamsUserChatData?.map((el) => {
        if (el.iChatId === deleteGroupMessageTimeDetails?.iChatId) {
          removedTeamMessageObj = {
            ...el,
            chat_transaction: {
              ...el.chat_transaction,
              eDelete: "Yes",
            },
          };
          return updatedTeamsMessageArray.push({
            ...el,
            chat_transaction: {
              ...el.chat_transaction,
              eDelete: "Yes",
            },
          });
        }
        return updatedTeamsMessageArray.push(el);
      });
      // last message related set up...
      setTeamChatList((prev) => {
        let demoArray = [];
        prev?.map((el) => {
          if (
            el?.chat_user_management?.vRoomId ===
            deleteGroupMessageTimeDetails?.vRoomId
          ) {
            return demoArray.push({
              ...el,
              chat_user_management: {
                ...el?.chat_user_management,
                eDelete: "Yes",
              },
            });
          }
          return demoArray.push(el);
        });
        return [...demoArray];
      });
      setTeamsUserChatData(updatedTeamsMessageArray);
      socket.emit("delete_message", JSON.stringify(removedTeamMessageObj));
      window.$("#deleteGroupChatRightSide").modal("hide");
      setDeleteGroupMessageTimeDetails("");
    }
  }, [
    setTeamsUserChatData,
    teamsUserChatData,
    deleteGroupMessageTimeDetails,
    setDeleteGroupMessageTimeDetails,
  ]);

  // when the final group message delete for me then used...
  const handleDeleteGroupMessageForMe = React.useCallback(async () => {
    const values = {
      iChatId: deleteGroupMessageTimeDetails?.iChatId,
      iUserId: sessionStorage.getItem("id"),
      vRoomId: deleteGroupMessageTimeDetails?.vRoomId,
    };
    const delteGroupMessateTimeResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Chat/groupChatDelete`,
      values
    );
    if (delteGroupMessateTimeResponse?.data?.code === 0) {
      // here through we have update message when we delete the message then used...
      let updatedTeamsMessageArray = [];
      teamsUserChatData?.map((el) => {
        if (el.iChatId === deleteGroupMessageTimeDetails?.iChatId) {
          return updatedTeamsMessageArray.push({
            ...el,
            eDelete: "Yes",
          });
        }
        return updatedTeamsMessageArray.push(el);
      });
      setTeamsUserChatData(updatedTeamsMessageArray);
      window.$("#deleteGroupChatRightSide").modal("hide");
      setDeleteGroupMessageTimeDetails("");
    }
  }, [
    teamsUserChatData,
    setTeamsUserChatData,
    deleteGroupMessageTimeDetails,
    setDeleteGroupMessageTimeDetails,
  ]);

  // when the user remove group message for right side then used...
  const handleRightSideDeleteGroupMessageForMe = React.useCallback(async () => {
    // console.log(deleteGroupMessageTimeDetails);
    const values = {
      iChatId: deleteGroupMessageTimeDetails?.iChatId,
      iUserId: sessionStorage.getItem("id"),
      vRoomId: deleteGroupMessageTimeDetails?.vRoomId,
    };
    const delteGroupMessateTimeResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Chat/groupChatDelete`,
      values
    );
    if (delteGroupMessateTimeResponse?.data?.code === 0) {
      // here through we have update message when we delete the message then used...
      let updatedTeamsMessageArray = [];
      let removedTeamMessageObj = "";
      teamsUserChatData?.map((el) => {
        if (el.iChatId === deleteGroupMessageTimeDetails?.iChatId) {
          removedTeamMessageObj = {
            ...el,
            chat_transaction: {
              ...el.chat_transaction,
              eDelete: "Yes",
            },
          };
          return updatedTeamsMessageArray.push({
            ...el,
            chat_transaction: {
              ...el.chat_transaction,
              eDelete: "Yes",
            },
          });
        }
        return updatedTeamsMessageArray.push(el);
      });
      // last message related set up...
      setTeamChatList((prev) => {
        let demoArray = [];
        prev?.map((el) => {
          if (
            el?.chat_user_management?.vRoomId ===
            deleteGroupMessageTimeDetails?.vRoomId
          ) {
            return demoArray.push({
              ...el,
              chat_user_management: {
                ...el?.chat_user_management,
                eDelete: "Yes",
              },
            });
          }
          return demoArray.push(el);
        });
        return [...demoArray];
      });
      setTeamsUserChatData(updatedTeamsMessageArray);
      socket.emit("delete_message", JSON.stringify(removedTeamMessageObj));
      window.$("#deleteGroupMessageLeftSide").modal("hide");
      setDeleteGroupMessageTimeDetails("");
    }
  }, [
    teamsUserChatData,
    setTeamsUserChatData,
    setTeamChatList,
    deleteGroupMessageTimeDetails,
    setDeleteGroupMessageTimeDetails,
  ]);

  // when the user want to share images,files and documents then used...
  const handleSelectImageVideoFiles = React.useCallback(
    (e) => {
      e.preventDefault();
      const { files } = e.target;
      const targetFiles = e.target.files;
      let videoType = ["video/mp4", "video/webm", "video/mpeg"];
      const targetFilesObject = [...targetFiles];
      const blobImagesArray = [];
      const filesImagesArray = [];
      if (
        e.target.files.length > 30 ||
        files?.length + targetFilesObject?.length > 30
      ) {
        toast.current.show({
          severity: "warn",
          summary: "You can not add more than 30 files.",
          life: 3000,
        });
      } else {
        for (let i = 0; i < files?.length; i++) {
          if (videoType.includes(files[i].type)) {
            if (Math.round(files[i]?.size / 1024 / 1024) > 10) {
              toast.current.show({
                severity: "warn",
                summary: "You Can't Upload More Than 10MB Video",
                life: 3000,
              });
              return;
            } else {
              blobImagesArray?.push({
                vFile: URL.createObjectURL(files[i]),
                eType: "video",
                vSize: files[i].size,
              });
              filesImagesArray.push(files[i]);
            }
          } else {
            if (Math.round(files[i]?.size / 1024 / 1024) > 5) {
              toast.current.show({
                severity: "warn",
                summary: "You Can't Upload More Than 5MB Images",
                life: 3000,
              });
            } else {
              blobImagesArray?.push({
                vFile: URL.createObjectURL(files[i]),
                eType: "image",
                vSize: files[i].size,
              });
              filesImagesArray.push(files[i]);
            }
          }
        }

        setChatFormValues((prev) => ({
          chatImagesFiles: [...prev.chatImagesFiles, ...filesImagesArray],
          chatImagesPreviews: [...prev.chatImagesPreviews, ...blobImagesArray],
        }));
      }
    },
    [setChatFormValues]
  );

  // when the user remove the images then used...
  const handleRemoveImageVideoItems = React.useCallback(
    (itemIndex) => {
      setChatFormValues((prev) => ({
        ...prev,
        chatImagesFiles: prev.chatImagesFiles.filter(
          (res, index) => index !== itemIndex
        ),
        chatImagesPreviews: prev.chatImagesPreviews.filter(
          (res, index) => index !== itemIndex
        ),
      }));
    },
    [setChatFormValues]
  );

  // when the user want to share documents then used...
  const handleSelectDocumentFiles = React.useCallback(
    (e) => {
      e.preventDefault();
      const { files } = e.target;
      const reader = new FileReader();
      reader.onload = function (e) {
        const fileContent = e.target.result;
        setChatFormValues((prev) => ({
          chatApplicationFiles: files[0],
          chatApplicationPreview: {
            name: files[0].name,
            type: files[0].type,
            vFile: fileContent,
          },
        }));
        // setChatFormValues((prevDoc) => [
        //   {
        //     chatApplicationFiles: files[0],
        //     chatApplicationPreview: {
        //       name: files[0].name,
        //       type: files[0].type,
        //       vFile: fileContent,
        //     },
        //   },
        // ]);
      };
      reader.readAsDataURL(files[0]);
      // setChatFormValues((prev) => ({
      //   chatApplicationFiles: files[0],
      //   chatApplicationPreview: { name: files[0].name, type: files[0].type },
      // }));
    },
    [setChatFormValues]
  );

  // when the user want to select the emoji then used...
  const handleSelectEmoji = React.useCallback(
    (emojiObj) => {
      setChatFormValues((prev) => ({
        ...prev,
        chatTextMessage: prev?.chatTextMessage + emojiObj?.native,
      }));
    },
    [setChatFormValues]
  );

  // when the user select the gifpicker through gif then used..
  // const handleSelectGif = React.useCallback(
  //     (e, gifData) => {

  //         e.preventDefault();
  //         // here through we have to direct send the gif...
  //         handleSendMessage(e, gifData)
  //         setIsShowGifPicker(false);
  //     },
  //     [handleSendMessage, setIsShowGifPicker]
  // );

  // Gif Basis set up
  const handleSelectGif = React.useCallback(
    async (e, gifData) => {
      e.preventDefault();
      const currentTime = moment();
      let t = moment(
        moment(currentTime.format("YYYY-MM-DD")).format(
          `${process.env.REACT_APP_DATE_FORMAT}`
        ) +
          " " +
          currentTime.format("hh:mm:ss")
      )
        .utc()
        .format("hh:mm A");
      const timeMoment = moment(t, "hh:mm:ss a");
      const currentHour = timeMoment.format("hh"); // HH for 24-hour format
      const currentMinute = timeMoment.format("mm");
      const currentSec = timeMoment.format("ss");
      let dateTime = moment();
      dateTime.set("hour", currentHour);
      dateTime.set("minute", currentMinute);
      dateTime.set("second", currentSec);
      let addedDateTime = dateTime.format("YYYY-MM-DD, hh:mm:ss a");
      if (
        gifData !== null &&
        gifData !== undefined &&
        gifData !== "" &&
        chatHeaderInformation?.individualOrTeam === "individualone"
      ) {
        // here is new way to create the chat => using api calling...
        const individualFormData = new FormData();
        individualFormData.append("vRoomId", chatHeaderInformation?.roomId);
        individualFormData.append(
          "iChatUserManagementId",
          chatHeaderInformation?.ChatUserManagementId
        );
        individualFormData.append(
          "vUserName",
          sessionStorage.getItem("userName")
        );
        individualFormData.append("iAddedById", sessionStorage.getItem("id"));
        individualFormData.append("dAddedTime", addedDateTime);
        individualFormData.append(
          "iReceivedById",
          chatHeaderInformation?.receviedUserId
        );
        if (gifData !== null && gifData !== undefined && gifData !== "") {
          individualFormData.append("vLink", gifData);
        }

        const sendIndividualMessageResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/Chat/individualChat`,
          individualFormData
        );
        if (sendIndividualMessageResponse?.data?.code === 0) {
          const lastMessage = moment(
            userIndividualChatData[userIndividualChatData?.length - 1]
              ?.dAddedDate
          ).format(`${process.env.REACT_APP_DATE_FORMAT}`);
          const isFormateReceiveTime =
            lastMessage ===
            moment().format(`${process.env.REACT_APP_DATE_FORMAT}`)
              ? false
              : true;
          // here flage through set up today basis view...
          if (isFormateReceiveTime) {
            const dateObj = {
              type: "day",
              date: sendIndividualMessageResponse?.data?.data?.dAddedDate,
              id: sendIndividualMessageResponse?.data?.data?.dAddedDate,
            };
            setUserIndividualChatData((prev) => [
              ...prev,
              dateObj,
              {
                ...sendIndividualMessageResponse?.data?.data,
                iReceivedById: chatHeaderInformation?.receviedUserId,
              },
            ]);
          } else {
            setUserIndividualChatData((prev) => [
              ...prev,
              {
                ...sendIndividualMessageResponse?.data?.data,
                iReceivedById: chatHeaderInformation?.receviedUserId,
              },
            ]);
          }

          const latestIndividualUserList = [];
          individualChatUserList?.map((el) => {
            if (
              el.vRoomId === sendIndividualMessageResponse?.data?.data?.vRoomId
            ) {
              return latestIndividualUserList.push({
                ...el,
                eDelete: "No",
                LastMessage:
                  sendIndividualMessageResponse?.data?.data?.vMessage,
                AddedTime:
                  sendIndividualMessageResponse?.data?.data?.dAddedTime,
                eType: sendIndividualMessageResponse?.data?.data?.eType,
              });
            }
            return latestIndividualUserList.push(el);
          });
          setIndividualChatUserList(latestIndividualUserList);
          socket.emit(
            "single_message",
            JSON.stringify({
              ...sendIndividualMessageResponse?.data?.data,
              iReceivedById: chatHeaderInformation?.receviedUserId,
              isFormateReceiveTime: isFormateReceiveTime,
            })
          );
        }
        setChatFormValues(initialChatValues);
        // message scroll basis...
        // setIsLastMessageCalled(true);
      } else if (
        gifData !== null &&
        gifData !== undefined &&
        gifData !== "" &&
        chatHeaderInformation?.individualOrTeam === "teamone"
      ) {
        const formData = new FormData();
        formData.append(
          "iChatUserManagementId",
          chatHeaderInformation?.ChatUserManagementId
        );
        formData.append("dAddedTime", addedDateTime);
        formData.append("vRoomId", chatHeaderInformation?.roomId);
        formData.append("vUserName", chatHeaderInformation?.teamName);
        formData.append("iAddedById", sessionStorage.getItem("id"));
        formData.append("iTeamId", chatHeaderInformation?.groupTimeTeamId);
        if (gifData !== null && gifData !== undefined && gifData !== "") {
          formData.append("vLink", gifData);
        }
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        const sendingTeamsMessage = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/Chat/createChat`,
          formData,
          config
        );
        if (sendingTeamsMessage && sendingTeamsMessage?.data?.code === "200") {
          const lastMessage = moment(
            teamChatList[teamChatList?.length - 1]?.dAddedDate
          ).format(`${process.env.REACT_APP_DATE_FORMAT}`);
          const isFormateReceiveTime =
            lastMessage ===
            moment().format(`${process.env.REACT_APP_DATE_FORMAT}`)
              ? false
              : true;
          if (isFormateReceiveTime) {
            const dateObj = {
              type: "day",
              date: sendingTeamsMessage?.data?.data?.dAddedDate,
              id: sendingTeamsMessage?.data?.data?.dAddedDate,
            };
            setTeamsUserChatData((list) => [
              ...list,
              dateObj,
              sendingTeamsMessage?.data?.data,
            ]);
          } else {
            setTeamsUserChatData((list) => [
              ...list,
              sendingTeamsMessage?.data?.data,
            ]);
          }

          // last message related set up...
          const latestTeamUserList = [];
          teamChatList?.map((el) => {
            if (
              el?.chat_user_management?.vRoomId ===
              sendingTeamsMessage?.data?.data?.vRoomId
            ) {
              return latestTeamUserList.push({
                ...el,
                chat_user_management: {
                  ...el.chat_user_management,
                  LastMessage: sendingTeamsMessage?.data?.data?.vMessage,
                  AddedTime: sendingTeamsMessage?.data?.data?.dAddedTime,
                  AddedDate: sendingTeamsMessage?.data?.data?.AddedDate,
                  eType: sendingTeamsMessage?.data?.data?.eType,
                  eDelete: "No",
                },
              });
            }
            return latestTeamUserList.push(el);
          });

          setTeamChatList(latestTeamUserList);

          socket.emit(
            "send_message",
            JSON.stringify({
              ...sendingTeamsMessage?.data?.data,
              isFormateReceiveTime: isFormateReceiveTime,
            })
          );
          // message scroll basis...
          // setIsLastMessageCalled(true);
        }
      }
      setIsOpenFileSharingOptions(false);
      setIsOpenImageVideoSelection(false);
      setIsOpenDocumentSelection(false);
      setIsEmojiMartShow(false);
      setIsShowGifPicker(false);
      setChatFormValues(initialChatValues);
    },
    [
      individualChatUserList,
      teamChatList,
      initialChatValues,
      userIndividualChatData,
      chatHeaderInformation,
      setTeamChatList,
      setIndividualChatUserList,
      setChatFormValues,
    ]
  );

  // when the user remove the files then used...
  const handleRemoveFiles = React.useCallback(
    (e) => {
      e.preventDefault();
      setChatFormValues((prev) => ({
        ...prev,
        chatApplicationFiles: "",
        chatApplicationPreview: "",
      }));
    },
    [setChatFormValues]
  );

  // when the user show shared media then used...
  const handleOpenMedialBox = React.useCallback(
    async (e) => {
      e.preventDefault();
      const values = {
        // iUserId: sessionStorage.getItem("id"),
        vRoomId: chatHeaderInformation?.roomId,
      };
      const resRoomMedialData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Chat/getAllMediaData`,
        values
      );
      window.$("#mediauplaodmodal").offcanvas("show");
      FirebaseLogEvents(
        EVENT_SCREENS.chat_media.event_name,
        EVENT_SCREENS.chat_media.event_description
      );
      if (resRoomMedialData?.data?.code === 0) {
        setChatMediaList({
          chatImages: resRoomMedialData?.data?.data?.filter(
            (res) => res.eType === "image"
          ),
          chatVideos: resRoomMedialData?.data?.data?.filter(
            (res) => res.eType === "video"
          ),
          chatDocuments: resRoomMedialData?.data?.data?.filter(
            (res) => res.eType === "Application"
          ),
        });
      } else {
        setChatMediaList(initialChatMedias);
      }
    },
    [chatHeaderInformation, initialChatMedias, setChatMediaList]
  );

  // when the user close the media box then used...
  const handleCloseMediaBox = (e) => {
    e.preventDefault();
    window.$("#mediauplaodmodal").offcanvas("hide");
    setChatMediaList(initialChatMedias);
  };

  return (
    <DashboardPageContainer>
      <Toast ref={toast} />
      <div className="chat-main-layer">
        <div className="chat-bg"></div>
        <div className="chat-body">
          <div className="chat-left-in">
            <div className="title-top">
              <h2>Chat</h2>
              <button
                type="button"
                className="btn"
                // data-bs-toggle="modal"
                // data-bs-target="#creategroup"
              >
                <i className="far fa-plus"></i>
                <span>Create Group</span>
              </button>
              <div
                className="modal fade modal-group-create"
                id="creategroup"
                tabIndex="-1"
                aria-labelledby="creategroupLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="creategroupLabel">
                        New Group
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="group-edited">
                          <div className="group-image">
                            <div className="image-box">
                              <img
                                src="assets/img/discover2.png"
                                alt="group-poto"
                              />
                            </div>
                            <div className="img-edit">
                              <i className="fal fa-camera"></i>
                              <input type="file" />
                            </div>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Enter Your Group Name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="add-member">
                          <h5>Add Members</h5>
                          <div className="inner-box">
                            <div className="box form-check">
                              <div className="left">
                                <div className="image-box">
                                  <img src="assets/img/discover1.png" alt="" />
                                </div>
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckmember1"
                                >
                                  Lauren Thompson
                                </label>
                              </div>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckmember1"
                              />
                            </div>
                            <div className="box form-check">
                              <div className="left">
                                <div className="image-box">
                                  <img src="assets/img/discover3.png" alt="" />
                                </div>
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckmember2"
                                >
                                  Jeffrey Harvey
                                </label>
                              </div>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckmember2"
                              />
                            </div>
                            <div className="box form-check">
                              <div className="left">
                                <div className="image-box">
                                  <img src="assets/img/discover4.png" alt="" />
                                </div>
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckmember3"
                                >
                                  Stephanie Johnson
                                </label>
                              </div>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckmember3"
                              />
                            </div>
                            <div className="box form-check">
                              <div className="left">
                                <div className="image-box">
                                  <img src="assets/img/discover4.png" alt="" />
                                </div>
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckmember4"
                                >
                                  Tamara Berger
                                </label>
                              </div>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckmember4"
                              />
                            </div>
                            <div className="box form-check">
                              <div className="left">
                                <div className="image-box">
                                  <img
                                    src="assets/img/cripple-basketball-player-wheelchair-holds-ball-open-gaming-ground.png"
                                    alt=""
                                  />
                                </div>
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckmember5"
                                >
                                  Tamara Berger
                                </label>
                              </div>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckmember5"
                              />
                            </div>
                            <div className="box form-check">
                              <div className="left">
                                <div className="image-box">
                                  <img
                                    src="assets/img/cripple-basketball-player-wheelchair-holds-ball-open-gaming-ground.png"
                                    alt=""
                                  />
                                </div>
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckmember6"
                                >
                                  Tamara Berger
                                </label>
                              </div>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckmember6"
                              />
                            </div>
                          </div>
                        </div>
                        <button type="button" className="btn submit-form mt-3">
                          Create Group
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="searchbar">
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    name="searchUser"
                    value={searchUser}
                    placeholder="Search"
                    onChange={(e) => handleSearchUsers(e)}
                  />
                  <div className="image-box">
                    <img
                      src="../assets/img/fi-rr-search.png"
                      alt="search-icon"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="select-chat">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {isUnder13 ? (
                  <></>
                ) : (
                  <li
                    className={isUnder13 ? "nav-item w-100" : "nav-item"}
                    role="presentation"
                  >
                    <button
                      className="nav-link active"
                      id="individualone-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#individualone"
                      type="button"
                      role="tab"
                      aria-controls="individualone"
                      aria-selected="true"
                      onClick={() => {
                        setActiveTabName("individualone");
                        // scroll down basis
                        // setIsLastMessageCalled(false);
                        // when the tabbing change then chatis open false
                        setIsChatOpen(false);
                      }}
                    >
                      <i className="fal fa-user"></i>
                      <span>Individual</span>
                    </button>
                  </li>
                )}
                <li className="nav-item" role="presentation">
                  <button
                    className={isUnder13 ? "nav-link active" : "nav-link"}
                    id="teamone-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#teamone"
                    type="button"
                    role="tab"
                    aria-controls="teamone"
                    aria-selected="false"
                    onClick={() => {
                      setActiveTabName("teamone");
                      // scroll down basis
                      // setIsLastMessageCalled(false);
                      // when the tabbing change then chatis open false
                      setIsChatOpen(false);
                    }}
                  >
                    <i className="fal fa-users"></i>
                    <span>Teams</span>
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="nav-tabContent">
                {isUnder13 ? (
                  <></>
                ) : (
                  <div
                    className="tab-pane fade show active"
                    id="individualone"
                    role="tabpanel"
                    aria-labelledby="individualone-tab"
                  >
                    <div className="select-chat-box">
                      {activeTabName === "individualone" ? (
                        <>
                          {individualChatUserList?.length > 0 ? (
                            <>
                              {individualChatUserList
                                ?.filter((val) =>
                                  val.user?.FullName?.toLowerCase().includes(
                                    searchUser?.toLowerCase()
                                  )
                                )
                                ?.sort((c, d) => {
                                  const a =
                                    c?.AddedTime !== null
                                      ? new Date(c?.AddedTime).getTime()
                                      : 0;
                                  const b =
                                    d?.AddedTime !== null
                                      ? new Date(d?.AddedTime).getTime()
                                      : 0;
                                  if (a === null) {
                                    return 1;
                                  }
                                  if (b === null) {
                                    return -1;
                                  }
                                  if (a === b) {
                                    return 0;
                                  }
                                  return a < b ? 1 : -1;
                                })
                                ?.map((el, elKey) => (
                                  <React.Fragment key={elKey}>
                                    <div
                                      className="boxes"
                                      // key={e}
                                      onClick={(e) =>
                                        handleJoinIndividualChatRoom(e, el)
                                      }
                                    >
                                      <div className="left">
                                        <div className="image-box">
                                          {el?.user?.vImage ===
                                          `${process.env.REACT_APP_GET_PUBLIC_PATH}/uploads/user/null` ? (
                                            <>
                                              <Avatar
                                                label={
                                                  el?.user?.vFirstName?.slice(
                                                    0,
                                                    1
                                                  ) +
                                                  el?.user?.vLastName?.slice(
                                                    0,
                                                    1
                                                  )
                                                }
                                                shape="circle"
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <Avatar
                                                image={el?.user?.vImage}
                                              />
                                            </>
                                          )}
                                        </div>
                                        <div className="user-t">
                                          <h3>{el?.user?.FullName}</h3>
                                          <p className="msg-last">
                                            {el.eDelete === "Yes" ? (
                                              <>
                                                <span>{`This Message Is Deleted`}</span>
                                              </>
                                            ) : (
                                              <>
                                                {el.image_Count > 0 ? (
                                                  <>
                                                    {el?.image_Count > 1 ? (
                                                      <>
                                                        <i className="far fa-photo-video"></i>
                                                        media
                                                      </>
                                                    ) : (
                                                      <>
                                                        {el.eType !== null &&
                                                        el.eType === "image" ? (
                                                          <>
                                                            <i className="fas fa-image"></i>
                                                            image
                                                          </>
                                                        ) : el.eType ===
                                                          "video" ? (
                                                          <>
                                                            <i className="fas fa-video"></i>
                                                            video
                                                          </>
                                                        ) : el.eType ===
                                                          "gif" ? (
                                                          <>
                                                            <i className="fad fa-gift-card"></i>
                                                            gif
                                                          </>
                                                        ) : (
                                                          <>
                                                            <i className="fas fa-file"></i>
                                                            file
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <> {el.LastMessage} </>
                                                )}
                                              </>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="right">
                                        <div>
                                          {el?.AddedTime !== null &&
                                          el?.AddedTime !== undefined ? (
                                            <>
                                              <p className="time">
                                                {getDateWeekFormat(
                                                  el?.AddedTime
                                                )}
                                              </p>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {el.eSeenCount > 0 && (
                                            <p className="count">
                                              {el.eSeenCount}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ))}
                            </>
                          ) : (
                            <>
                              <p className="no-chat-style">
                                You Do Not Have Any Follower.
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <>{/* something went wrong... */}</>
                      )}
                    </div>
                  </div>
                )}
                <div
                  className={
                    isUnder13 ? "tab-pane fade show active" : "tab-pane fade"
                  }
                  id="teamone"
                  role="tabpanel"
                  aria-labelledby="teamone-tab"
                >
                  <div className="select-chat-box">
                    {activeTabName === "teamone" ? (
                      <>
                        {teamChatList?.length > 0 ? (
                          <>
                            {teamChatList &&
                              teamChatList
                                ?.filter((val) =>
                                  val.team?.tTeamTagline
                                    ?.toLowerCase()
                                    ?.includes(searchUser?.toLowerCase())
                                )
                                ?.sort((c, d) => {
                                  const a =
                                    c?.chat_user_management?.AddedTime !== null
                                      ? new Date(
                                          c?.chat_user_management?.AddedTime
                                        ).getTime()
                                      : 0;
                                  const b =
                                    d?.chat_user_management?.AddedTime !== null
                                      ? new Date(
                                          d?.chat_user_management?.AddedTime
                                        ).getTime()
                                      : 0;
                                  if (a === null) {
                                    return 1;
                                  }
                                  if (b === null) {
                                    return -1;
                                  }
                                  if (a === b) {
                                    return 0;
                                  }
                                  return a < b ? 1 : -1;
                                })
                                ?.map((el, elKey) => (
                                  <div
                                    key={elKey}
                                    className="boxes"
                                    onClick={(e) =>
                                      handleJoinTeamChatRoom(e, el)
                                    }
                                  >
                                    <div className="left">
                                      <div className="image-box">
                                        {el?.team?.vTeamLogo ===
                                        `${process.env.REACT_APP_GET_PUBLIC_PATH}/uploads/teamlogo/null` ? (
                                          <>
                                            <Avatar
                                              icon="fal fa-users"
                                              shape="circle"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <Avatar
                                              image={el?.team?.vTeamLogo}
                                            />
                                          </>
                                        )}
                                      </div>
                                      <div className="user-t">
                                        <h3>{el?.team?.tTeamTagline}</h3>
                                        {/* <p className="time">30 min ago</p> */}
                                        <p className="msg-last">
                                          {el?.chat_user_management?.eDelete ===
                                          "Yes" ? (
                                            <>
                                              <span>{`This Message Is Deleted`}</span>
                                            </>
                                          ) : (
                                            <>
                                              {el?.chat_user_management
                                                ?.image_Count > 0 ? (
                                                <>
                                                  {el?.chat_user_management
                                                    ?.image_Count > 1 ? (
                                                    <>
                                                      <i className="far fa-photo-video"></i>
                                                      media
                                                    </>
                                                  ) : (
                                                    <>
                                                      {el?.chat_user_management
                                                        ?.eType !== null &&
                                                      el?.chat_user_management
                                                        ?.eType === "image" ? (
                                                        <>
                                                          <i className="fas fa-image"></i>
                                                          image
                                                        </>
                                                      ) : el
                                                          ?.chat_user_management
                                                          ?.eType ===
                                                        "video" ? (
                                                        <>
                                                          <i className="fas fa-video"></i>
                                                          video
                                                        </>
                                                      ) : el
                                                          ?.chat_user_management
                                                          ?.eType === "gif" ? (
                                                        <>
                                                          <i className="fad fa-gift-card"></i>
                                                          gif
                                                        </>
                                                      ) : (
                                                        <>
                                                          <i className="fas fa-file"></i>
                                                          file
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {
                                                    el?.chat_user_management
                                                      ?.LastMessage
                                                  }
                                                </>
                                              )}
                                            </>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="right">
                                      <div>
                                        {el?.chat_user_management?.AddedTime !==
                                          null &&
                                        el?.chat_user_management?.AddedTime !==
                                          undefined ? (
                                          <>
                                            <p className="time">
                                              {getDateWeekFormat(
                                                el?.chat_user_management
                                                  ?.AddedTime
                                              )}
                                            </p>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        {el?.chat_user_management?.eSeenCount >
                                          0 && (
                                          <p className="count">
                                            {
                                              el?.chat_user_management
                                                ?.eSeenCount
                                            }
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                          </>
                        ) : (
                          <>
                            <p className="no-chat-style">
                              You Do Not Have Any Team Follower.
                            </p>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isChatOpen ? (
            <>
              {/* Here Through Chat body should be start....  */}

              <div className="main-chat">
                {/* HERE IS CHAT HEADER START... */}
                <div className="main-chat-header">
                  <div className="user-chat-data">
                    <div className="image-box">
                      {
                        chatHeaderInformation?.individualOrTeam ===
                        "individualone" ? (
                          <>
                            {chatHeaderInformation?.roomLogo ===
                            `${process.env.REACT_APP_GET_PUBLIC_PATH}/uploads/user/null` ? (
                              <>
                                <Avatar
                                  label={chatHeaderInformation?.userShortName}
                                  shape="circle"
                                />
                              </>
                            ) : (
                              <>
                                <Avatar
                                  // image={roomLogo}
                                  image={chatHeaderInformation?.roomLogo}
                                />
                              </>
                            )}
                          </>
                        ) : chatHeaderInformation?.individualOrTeam ===
                          "teamone" ? (
                          <>
                            {chatHeaderInformation?.roomLogo ===
                            `${process.env.REACT_APP_GET_PUBLIC_PATH}/uploads/teamlogo/null` ? (
                              // "https://admin-3817264.hombolttech.dev/public/uploads/teamlogo/null"
                              <>
                                <Avatar icon="fal fa-users" shape="circle" />
                              </>
                            ) : (
                              <>
                                <Avatar
                                  image={chatHeaderInformation?.roomLogo}
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                        // roomLogo ===
                      }
                    </div>
                    <div className="names">
                      <h3>{chatHeaderInformation?.roomName}</h3>
                      {activeTabName === "individualone" ? (
                        <>
                          {onlineUserList?.length > 0 ? (
                            <>
                              <p>
                                {onlineUserList.includes(
                                  chatHeaderInformation?.userId
                                )
                                  ? "Online"
                                  : "Offline"}
                              </p>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="chat-info">
                    {activeTabName === "teamone" ? (
                      <>
                        <div
                          className="member-toggle"
                          type="button"
                          // data-bs-toggle="offcanvas"
                          // data-bs-target="#offcanvasRight"
                          // aria-controls="offcanvasRight"
                          onClick={(e) =>
                            handleGetGroupMember(e, chatHeaderInformation)
                          }
                        >
                          <i className="far fa-users"></i>
                          {/* <span>All Members</span> */}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div
                      className="media-box"
                      type="button"
                      // data-bs-toggle="offcanvas"
                      // data-bs-target="#mediauplaodmodal"
                      // aria-controls="mediauplaodmodal"
                      onClick={(e) => handleOpenMedialBox(e)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Group_16731"
                        data-name="Group 16731"
                        width="31.5"
                        height="31.5"
                        viewBox="0 0 31.5 31.5"
                      >
                        <path
                          id="Path_30430"
                          data-name="Path 30430"
                          d="M0,0H31.5V31.5H0Z"
                          fill="none"
                        />
                        <path
                          id="Path_30431"
                          data-name="Path 30431"
                          d="M15,8h.013"
                          transform="translate(4.688 2.5)"
                          fill="none"
                          stroke="#565555"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_30432"
                          data-name="Path 30432"
                          d="M3,6.938A3.937,3.937,0,0,1,6.938,3h15.75a3.937,3.937,0,0,1,3.938,3.938v15.75a3.937,3.937,0,0,1-3.937,3.938H6.938A3.937,3.937,0,0,1,3,22.688Z"
                          transform="translate(0.938 0.938)"
                          fill="none"
                          stroke="#565555"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_30433"
                          data-name="Path 30433"
                          d="M3,17.772l6.563-6.563a2.644,2.644,0,0,1,3.938,0l6.563,6.563"
                          transform="translate(0.938 3.228)"
                          fill="none"
                          stroke="#565555"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_30434"
                          data-name="Path 30434"
                          d="M14,14.522l1.313-1.312a2.644,2.644,0,0,1,3.938,0l3.938,3.938"
                          transform="translate(4.375 3.853)"
                          fill="none"
                          stroke="#565555"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {/* HERE IS CHAT HEADER END... */}
                {/* ---------------------------*******************---------------------------- */}

                {/* HERE IS FOR MEDIA,DOCS RELATED MODAL START... */}
                <div
                  className="add-member-side offcanvas offcanvas-end"
                  tabIndex="-1"
                  id="mediauplaodmodal"
                  aria-labelledby="mediauplaodmodalLabel"
                >
                  <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="mediauplaodmodalLabel">
                      Media, Doc And Videos
                    </h5>
                    <button
                      type="button"
                      className="btn-close text-reset"
                      // data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      onClick={(e) => handleCloseMediaBox(e)}
                    ></button>
                  </div>
                  <div className="tabbing">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="imagechat-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#imagechat"
                          type="button"
                          role="tab"
                          aria-controls="imagechat"
                          aria-selected="true"
                        >
                          Images
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="docchat-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#docchat"
                          type="button"
                          role="tab"
                          aria-controls="docchat"
                          aria-selected="false"
                        >
                          Documents
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="videochat-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#videochat"
                          type="button"
                          role="tab"
                          aria-controls="videochat"
                          aria-selected="false"
                        >
                          Videos
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="imagechat"
                        role="tabpanel"
                        aria-labelledby="imagechat-tab"
                      >
                        <div className="media-imagte-box">
                          {chatMediaList?.chatImages?.length > 0 ? (
                            <>
                              {chatMediaList?.chatImages?.map((el, elKey) => (
                                <div
                                  key={elKey}
                                  className="image-box"
                                  // key={el.iChatFileTransactionId}
                                >
                                  <img src={el.vFile} alt="media-img" />
                                </div>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="docchat"
                        role="tabpanel"
                        aria-labelledby="docchat-tab"
                      >
                        <div className="media-imagte-box">
                          {chatMediaList?.chatDocuments?.length > 0 ? (
                            <>
                              {chatMediaList?.chatDocuments?.map((el) => (
                                <div
                                  className="image-box"
                                  key={el.iChatFileTransactionId}
                                >
                                  <object className="doc-box">
                                    <i className="fas fa-file"></i>
                                    <p className="">{el.vFileName}</p>
                                    <p className="file-size">
                                      {Math.round(el?.vSize / 1024) > 1024
                                        ? `${Math.round(
                                            el?.vSize / 1024 / 1024
                                          )} MB`
                                        : `${Math.round(
                                            el?.vSize / 1024
                                          )} KB  `}
                                    </p>
                                  </object>
                                </div>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="videochat"
                        role="tabpanel"
                        aria-labelledby="videochat-tab"
                      >
                        <div className="media-imagte-box">
                          {chatMediaList?.chatVideos?.length > 0 ? (
                            <>
                              {chatMediaList?.chatVideos?.map((el) => (
                                <div
                                  className="image-box"
                                  key={el.iChatFileTransactionId}
                                >
                                  <video
                                    width="200"
                                    height="200"
                                    src={el.vFile}
                                    alt=""
                                    controls=""
                                  ></video>
                                </div>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* HERE IS FOR MEDIA,DOCS RELATED MODAL END... */}
                {/* ---------------------------*******************---------------------------- */}

                {/* HERE IS FOR CHECK GROUP MEMBERS MODAL START... */}
                <div
                  className="add-member-side offcanvas offcanvas-end"
                  tabIndex="-1"
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"
                >
                  <div className="offcanvas-header">
                    <h5
                      className="offcanvas-title"
                      id="offcanvasScrollingLabel"
                    >
                      All Members
                    </h5>
                    <button
                      type="button"
                      className="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      onClick={() => setAllTeamsChatMembersUserList([])}
                    ></button>
                  </div>
                  {/* <button
                                                type="button"
                                                className="btn btn-add-new"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <i className="fal fa-plus-circle"></i>
                                                <span>Add new Participation</span>
                                            </button> */}
                  <div className="added-member-list">
                    {allTeamsChatMemberUsersList?.length > 0 ? (
                      <>
                        {
                          <h4>{`There Are ${allTeamsChatMemberUsersList?.length} Members In This Team`}</h4>
                        }
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="inner-box">
                      {allTeamsChatMemberUsersList?.length > 0 ? (
                        <>
                          {allTeamsChatMemberUsersList?.map((item) => (
                            <ChatFollowUser
                              item={item}
                              key={item?.iTeamMemberId}
                            />
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                {/* HERE IS FOR NEW ADD GROUP MEMBERS MODAL START... */}

                <div
                  className="modal fade modal-group-create "
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Members
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <form>
                          <div className="add-member">
                            <div className="form-group pb-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                              />
                            </div>
                            <div className="inner-box">
                              <div className="box form-check">
                                <div className="left">
                                  <div className="image-box">
                                    <img
                                      src="assets/img/discover1.png"
                                      alt=""
                                    />
                                  </div>
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckmember1"
                                  >
                                    Lauren Thompson
                                  </label>
                                </div>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckmember1"
                                />
                              </div>
                              <div className="box form-check">
                                <div className="left">
                                  <div className="image-box">
                                    <img
                                      src="assets/img/discover3.png"
                                      alt=""
                                    />
                                  </div>
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckmember2"
                                  >
                                    Jeffrey Harvey
                                  </label>
                                </div>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckmember2"
                                />
                              </div>
                              <div className="box form-check">
                                <div className="left">
                                  <div className="image-box">
                                    <img
                                      src="assets/img/discover4.png"
                                      alt=""
                                    />
                                  </div>
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckmember3"
                                  >
                                    Stephanie Johnson
                                  </label>
                                </div>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckmember3"
                                />
                              </div>
                              <div className="box form-check">
                                <div className="left">
                                  <div className="image-box">
                                    <img
                                      src="assets/img/discover4.png"
                                      alt=""
                                    />
                                  </div>
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckmember4"
                                  >
                                    Tamara Berger
                                  </label>
                                </div>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckmember4"
                                />
                              </div>
                              <div className="box form-check">
                                <div className="left">
                                  <div className="image-box">
                                    <img
                                      src="assets/img/cripple-basketball-player-wheelchair-holds-ball-open-gaming-ground.png"
                                      alt=""
                                    />
                                  </div>
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckmember5"
                                  >
                                    Tamara Berger
                                  </label>
                                </div>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckmember5"
                                />
                              </div>
                              <div className="box form-check">
                                <div className="left">
                                  <div className="image-box">
                                    <img
                                      src="assets/img/cripple-basketball-player-wheelchair-holds-ball-open-gaming-ground.png"
                                      alt=""
                                    />
                                  </div>
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckmember6"
                                  >
                                    Tamara Berger
                                  </label>
                                </div>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckmember6"
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn submit-form mt-3"
                          >
                            Save
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* HERE IS FOR NEW ADD GROUP MEMBERS MODAL END... */}
                {/* HERE IS FOR CHECK GROUP MEMBERS MODAL END... */}

                {/* #####################################################################################*/}

                {/* HERE IS ALL THE USER'S CHATING ==>> CHAT BODY START... */}

                <div
                  className="main-chat-box"
                  style={{
                    backgroundImage: "url(../assets/img/onchat-banner.png)",
                  }}
                >
                  {/* <div className="new-chat-start">
                                                <div className="inner-box">
                                                    <div className="image-box">
                                                        <img src="assets/img/cripple-basketball-player-wheelchair-holds-ball-open-gaming-ground.png" alt="" />
                                                    </div>
                                                    <div className="text-box">
                                                        <p><span>Jaron White</span> Want to connect with you</p>
                                                        <div className="action-btn">
                                                            <button className="submit-form">Accept</button>
                                                            <button className="cancel-btn">Decline</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                  {isChatLoading ? (
                    <>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </>
                  ) : (
                    <>
                      {activeTabName === "individualone" ? (
                        <>
                          {userIndividualChatData?.length > 0 ? (
                            <>
                              {userIndividualChatData?.map((item, key) =>
                                item?.hasOwnProperty("type") ? (
                                  <React.Fragment
                                    key={key}
                                    // key={Math.random() * 1000 * key + 1}
                                  >
                                    {moment().format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                    moment(item?.date).format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ? (
                                      <div className="date-line">
                                        <span>Today</span>
                                      </div>
                                    ) : moment()
                                        .add(-1, "days")
                                        .format(
                                          `${process.env.REACT_APP_DATE_FORMAT}`
                                        ) ===
                                      moment(item?.date).format(
                                        `${process.env.REACT_APP_DATE_FORMAT}`
                                      ) ? (
                                      <div className="date-line">
                                        <span>Yesterday</span>
                                      </div>
                                    ) : moment()
                                        .add(-2, "days")
                                        .format(
                                          `${process.env.REACT_APP_DATE_FORMAT}`
                                        ) ===
                                      moment(item?.date).format(
                                        `${process.env.REACT_APP_DATE_FORMAT}`
                                      ) ? (
                                      <div className="date-line">
                                        <span>
                                          {moment()
                                            .add(-2, "days")
                                            .format("dddd")}
                                        </span>
                                      </div>
                                    ) : moment()
                                        .add(-3, "days")
                                        .format(
                                          `${process.env.REACT_APP_DATE_FORMAT}`
                                        ) ===
                                      moment(item?.date).format(
                                        `${process.env.REACT_APP_DATE_FORMAT}`
                                      ) ? (
                                      <div className="date-line">
                                        <span>
                                          {moment()
                                            .add(-3, "days")
                                            .format("dddd")}
                                        </span>
                                      </div>
                                    ) : moment()
                                        .add(-4, "days")
                                        .format(
                                          `${process.env.REACT_APP_DATE_FORMAT}`
                                        ) ===
                                      moment(item?.date).format(
                                        `${process.env.REACT_APP_DATE_FORMAT}`
                                      ) ? (
                                      <div className="date-line">
                                        <span>
                                          {moment()
                                            .add(-4, "days")
                                            .format("dddd")}
                                        </span>
                                      </div>
                                    ) : moment()
                                        .add(-5, "days")
                                        .format(
                                          `${process.env.REACT_APP_DATE_FORMAT}`
                                        ) ===
                                      moment(item?.date).format(
                                        `${process.env.REACT_APP_DATE_FORMAT}`
                                      ) ? (
                                      <div className="date-line">
                                        <span>
                                          {moment()
                                            .add(-5, "days")
                                            .format("dddd")}
                                        </span>
                                      </div>
                                    ) : moment()
                                        .add(-6, "days")
                                        .format(
                                          `${process.env.REACT_APP_DATE_FORMAT}`
                                        ) ===
                                      moment(item?.date).format(
                                        `${process.env.REACT_APP_DATE_FORMAT}`
                                      ) ? (
                                      <div className="date-line">
                                        <span>
                                          {moment()
                                            .add(-6, "days")
                                            .format("dddd")}
                                        </span>
                                      </div>
                                    ) : (
                                      <div className="date-line">
                                        <span>
                                          {moment(item?.date).format("MMM")}

                                          {moment(item?.date).format("DD")}
                                          {", "}
                                          {moment(item?.date).format("YYYY")}
                                        </span>
                                      </div>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment key={`message-${key}`}>
                                    {parseInt(item.iAddedById) ===
                                    parseInt(sessionStorage.getItem("id")) ? (
                                      <>
                                        <IndividualLatestChatRightBody
                                          item={item}
                                          index={key}
                                          setDeleteIndividualMessageTimeDetails={
                                            setDeleteIndividualMessageTimeDetails
                                          }
                                          deleteIndividualMessageTimeDetails={
                                            deleteIndividualMessageTimeDetails
                                          }
                                          userIndividualChatData={
                                            userIndividualChatData
                                          }
                                          setUserIndividualChatData={
                                            setUserIndividualChatData
                                          }
                                          socket={socket}
                                          chatHeaderInformation={
                                            chatHeaderInformation
                                          }
                                          individualChatUserList={
                                            individualChatUserList
                                          }
                                          setIndividualChatUserList={
                                            setIndividualChatUserList
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <IndividualLatestChatLeftBody
                                          item={item}
                                          index={key}
                                          setDeleteIndividualMessageTimeDetails={
                                            setDeleteIndividualMessageTimeDetails
                                          }
                                          deleteIndividualMessageTimeDetails={
                                            deleteIndividualMessageTimeDetails
                                          }
                                          userIndividualChatData={
                                            userIndividualChatData
                                          }
                                          setUserIndividualChatData={
                                            setUserIndividualChatData
                                          }
                                          socket={socket}
                                        />
                                      </>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : activeTabName === "teamone" ? (
                        <>
                          {teamsUserChatData?.length > 0 ? (
                            <>
                              {teamsUserChatData?.map((item, chatKey) =>
                                item?.hasOwnProperty("type") ? (
                                  <React.Fragment key={chatKey}>
                                    {moment().format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ===
                                    moment(item?.date).format(
                                      `${process.env.REACT_APP_DATE_FORMAT}`
                                    ) ? (
                                      <div className="date-line">
                                        <span>Today</span>
                                      </div>
                                    ) : moment()
                                        .add(-1, "days")
                                        .format(
                                          `${process.env.REACT_APP_DATE_FORMAT}`
                                        ) ===
                                      moment(item?.date).format(
                                        `${process.env.REACT_APP_DATE_FORMAT}`
                                      ) ? (
                                      <div className="date-line">
                                        <span>Yesterday</span>
                                      </div>
                                    ) : moment()
                                        .add(-2, "days")
                                        .format(
                                          `${process.env.REACT_APP_DATE_FORMAT}`
                                        ) ===
                                      moment(item?.date).format(
                                        `${process.env.REACT_APP_DATE_FORMAT}`
                                      ) ? (
                                      <div className="date-line">
                                        <span>
                                          {moment()
                                            .add(-2, "days")
                                            .format("dddd")}
                                        </span>
                                      </div>
                                    ) : moment()
                                        .add(-3, "days")
                                        .format(
                                          `${process.env.REACT_APP_DATE_FORMAT}`
                                        ) ===
                                      moment(item?.date).format(
                                        `${process.env.REACT_APP_DATE_FORMAT}`
                                      ) ? (
                                      <div className="date-line">
                                        <span>
                                          {moment()
                                            .add(-3, "days")
                                            .format("dddd")}
                                        </span>
                                      </div>
                                    ) : moment()
                                        .add(-4, "days")
                                        .format(
                                          `${process.env.REACT_APP_DATE_FORMAT}`
                                        ) ===
                                      moment(item?.date).format(
                                        `${process.env.REACT_APP_DATE_FORMAT}`
                                      ) ? (
                                      <div className="date-line">
                                        <span>
                                          {moment()
                                            .add(-4, "days")
                                            .format("dddd")}
                                        </span>
                                      </div>
                                    ) : moment()
                                        .add(-5, "days")
                                        .format(
                                          `${process.env.REACT_APP_DATE_FORMAT}`
                                        ) ===
                                      moment(item?.date).format(
                                        `${process.env.REACT_APP_DATE_FORMAT}`
                                      ) ? (
                                      <div className="date-line">
                                        <span>
                                          {moment()
                                            .add(-5, "days")
                                            .format("dddd")}
                                        </span>
                                      </div>
                                    ) : moment()
                                        .add(-6, "days")
                                        .format(
                                          `${process.env.REACT_APP_DATE_FORMAT}`
                                        ) ===
                                      moment(item?.date).format(
                                        `${process.env.REACT_APP_DATE_FORMAT}`
                                      ) ? (
                                      <div className="date-line">
                                        <span>
                                          {moment()
                                            .add(-6, "days")
                                            .format("dddd")}
                                        </span>
                                      </div>
                                    ) : (
                                      <div className="date-line">
                                        <span>
                                          {moment(item?.date).format("MMM")}

                                          {moment(item?.date).format("DD")}
                                          {", "}
                                          {moment(item?.date).format("YYYY")}
                                        </span>
                                      </div>
                                    )}
                                    <div ref={messagesEndRef} />
                                  </React.Fragment>
                                ) : parseInt(item.iAddedById) ===
                                  parseInt(sessionStorage.getItem("id")) ? (
                                  <React.Fragment key={chatKey}>
                                    <div className="text right-text">
                                      <div className="msg">
                                        <div className="msg-p">
                                          {item?.chat_transaction?.eDelete ===
                                            "Yes" || item?.eDelete === "Yes" ? (
                                            <>
                                              <span>{`This Message Is Deleted`}</span>
                                            </>
                                          ) : (
                                            <>
                                              {item?.vMessage !== null &&
                                              item?.vMessage !== "" &&
                                              item?.vMessage?.length > 0 &&
                                              item?.chat_file_transactions
                                                ?.length <= 0 ? (
                                                <React.Fragment
                                                  key={item?.iChatId}
                                                >
                                                  <span>{item?.vMessage}</span>
                                                </React.Fragment>
                                              ) : (
                                                <React.Fragment
                                                  key={item?.iChatId}
                                                >
                                                  {item?.vMessage !== null &&
                                                  item?.vMessage !== "" &&
                                                  item?.vMessage?.length > 0 ? (
                                                    <React.Fragment
                                                      key={`image-with-message-${item?.iChatId}`}
                                                    >
                                                      <span>
                                                        {item?.vMessage}
                                                      </span>
                                                    </React.Fragment>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {item?.chat_file_transactions
                                                    ?.length > 0 ? (
                                                    <React.Fragment>
                                                      {item
                                                        ?.chat_file_transactions
                                                        ?.length === 1 ? (
                                                        <React.Fragment>
                                                          {item?.chat_file_transactions?.map(
                                                            (el) =>
                                                              el.eType ===
                                                              "gif" ? (
                                                                <div
                                                                  className="msg-image-one image-up"
                                                                  key={
                                                                    el.iChatFileTransactionId
                                                                  }
                                                                >
                                                                  <img
                                                                    src={
                                                                      el.vLink
                                                                    }
                                                                    alt=""
                                                                  />
                                                                </div>
                                                              ) : el.eType ===
                                                                "image" ? (
                                                                <div
                                                                  className="msg-image-one image-up"
                                                                  key={
                                                                    el.iChatFileTransactionId
                                                                  }
                                                                >
                                                                  <a
                                                                    className="image-box"
                                                                    data-fancybox="gallery"
                                                                    href={
                                                                      el.vFile
                                                                    }
                                                                  >
                                                                    <img
                                                                      src={
                                                                        el.vFile
                                                                      }
                                                                      alt=""
                                                                    />
                                                                  </a>
                                                                </div>
                                                              ) : el.eType ===
                                                                "video" ? (
                                                                <div
                                                                  className="msg-image-one image-up"
                                                                  key={
                                                                    el.iChatFileTransactionId
                                                                  }
                                                                >
                                                                  <a
                                                                    className="image-box"
                                                                    data-fancybox="gallery"
                                                                    href={
                                                                      el.vFile
                                                                    }
                                                                  >
                                                                    <video
                                                                      src={
                                                                        el.vFile
                                                                      }
                                                                      alt=""
                                                                      controls
                                                                    ></video>
                                                                  </a>
                                                                </div>
                                                              ) : el.eType ===
                                                                "Application" ? (
                                                                <div
                                                                  className="upchat-image-box"
                                                                  key={
                                                                    el.iChatFileTransactionId
                                                                  }
                                                                >
                                                                  <div className="msg-image-one image-up">
                                                                    <object className="doc-box">
                                                                      <div className="left-sec">
                                                                        <i className="fas fa-file"></i>
                                                                        <div>
                                                                          <p className="name">
                                                                            {
                                                                              el?.vFileName
                                                                            }
                                                                          </p>
                                                                          <p className="file-size">
                                                                            {Math.round(
                                                                              parseInt(
                                                                                el?.vSize
                                                                              ) /
                                                                                1024
                                                                            ) >
                                                                            1024
                                                                              ? `${Math.round(
                                                                                  parseInt(
                                                                                    el?.vSize
                                                                                  ) /
                                                                                    1024 /
                                                                                    1024
                                                                                )} MB`
                                                                              : `${Math.round(
                                                                                  parseInt(
                                                                                    el?.vSize
                                                                                  ) /
                                                                                    1024
                                                                                )} KB `}
                                                                          </p>
                                                                        </div>
                                                                      </div>
                                                                      <div className="doc-download">
                                                                        <i className="fal fa-arrow-circle-down"></i>
                                                                      </div>
                                                                    </object>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <></>
                                                              )
                                                          )}
                                                        </React.Fragment>
                                                      ) : item
                                                          ?.chat_file_transactions
                                                          ?.length === 2 ||
                                                        item
                                                          ?.chat_file_transactions
                                                          ?.length === 4 ? (
                                                        <React.Fragment>
                                                          <div className="msg-image-more image-up">
                                                            {item?.chat_file_transactions?.map(
                                                              (el) =>
                                                                el.eType ===
                                                                "image" ? (
                                                                  <React.Fragment
                                                                    key={
                                                                      el.iChatFileTransactionId
                                                                    }
                                                                  >
                                                                    <a
                                                                      className="image-box"
                                                                      data-fancybox="gallery"
                                                                      href={
                                                                        el.vFile
                                                                      }
                                                                    >
                                                                      <img
                                                                        className="rounded"
                                                                        alt=""
                                                                        onError={(
                                                                          e
                                                                        ) => {
                                                                          e.target.onerror =
                                                                            null;
                                                                          e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                                                        }}
                                                                        src={
                                                                          el?.vFile
                                                                        }
                                                                      />
                                                                    </a>
                                                                  </React.Fragment>
                                                                ) : el.eType ===
                                                                  "video" ? (
                                                                  <React.Fragment
                                                                    key={
                                                                      el.iChatFileTransactionId
                                                                    }
                                                                  >
                                                                    <a
                                                                      className="image-box"
                                                                      data-fancybox="gallery"
                                                                      href={
                                                                        el.vFile
                                                                      }
                                                                    >
                                                                      <video
                                                                        src={
                                                                          el.vFile
                                                                        }
                                                                        alt=""
                                                                        controls
                                                                      ></video>
                                                                    </a>
                                                                  </React.Fragment>
                                                                ) : (
                                                                  <></>
                                                                )
                                                            )}
                                                          </div>
                                                        </React.Fragment>
                                                      ) : item
                                                          ?.chat_file_transactions
                                                          ?.length === 3 ? (
                                                        <React.Fragment>
                                                          <div className="msg-image-three image-up">
                                                            {item?.chat_file_transactions?.map(
                                                              (el) =>
                                                                el.eType ===
                                                                "image" ? (
                                                                  <React.Fragment
                                                                    key={
                                                                      el.iChatFileTransactionId
                                                                    }
                                                                  >
                                                                    <a
                                                                      className="image-box"
                                                                      data-fancybox="gallery"
                                                                      href={
                                                                        el.vFile
                                                                      }
                                                                    >
                                                                      <img
                                                                        className="rounded"
                                                                        alt=""
                                                                        onError={(
                                                                          e
                                                                        ) => {
                                                                          e.target.onerror =
                                                                            null;
                                                                          e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                                                        }}
                                                                        src={
                                                                          el?.vFile
                                                                        }
                                                                      />
                                                                    </a>
                                                                  </React.Fragment>
                                                                ) : el.eType ===
                                                                  "video" ? (
                                                                  <React.Fragment
                                                                    key={
                                                                      el.iChatFileTransactionId
                                                                    }
                                                                  >
                                                                    <a
                                                                      className="image-box"
                                                                      data-fancybox="gallery"
                                                                      href={
                                                                        el.vFile
                                                                      }
                                                                    >
                                                                      <video
                                                                        src={
                                                                          el.vFile
                                                                        }
                                                                        alt=""
                                                                        controls
                                                                      ></video>
                                                                    </a>
                                                                  </React.Fragment>
                                                                ) : (
                                                                  <></>
                                                                )
                                                            )}
                                                          </div>
                                                        </React.Fragment>
                                                      ) : item
                                                          ?.chat_file_transactions
                                                          ?.length >= 5 ? (
                                                        <React.Fragment>
                                                          <div className="msg-image-more image-up">
                                                            {item?.chat_file_transactions?.map(
                                                              (el, index) =>
                                                                el.eType ===
                                                                "image" ? (
                                                                  <React.Fragment
                                                                    key={
                                                                      el.iChatFileTransactionId
                                                                    }
                                                                  >
                                                                    <a
                                                                      class={`image-box ${
                                                                        index >=
                                                                        4
                                                                          ? "d-none"
                                                                          : ""
                                                                      }`}
                                                                      data-fancybox="gallery"
                                                                      href={
                                                                        el.vFile
                                                                      }
                                                                    >
                                                                      <img
                                                                        className="rounded"
                                                                        alt=""
                                                                        onError={(
                                                                          e
                                                                        ) => {
                                                                          e.target.onerror =
                                                                            null;
                                                                          e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                                                        }}
                                                                        src={
                                                                          el?.vFile
                                                                        }
                                                                      />
                                                                    </a>
                                                                  </React.Fragment>
                                                                ) : el.eType ===
                                                                  "video" ? (
                                                                  <React.Fragment
                                                                    key={
                                                                      el.iChatFileTransactionId
                                                                    }
                                                                  >
                                                                    <a
                                                                      class={`image-box ${
                                                                        index >=
                                                                        4
                                                                          ? "d-none"
                                                                          : ""
                                                                      }`}
                                                                      data-fancybox="gallery"
                                                                      href={
                                                                        el.vFile
                                                                      }
                                                                    >
                                                                      <video
                                                                        src={
                                                                          el.vFile
                                                                        }
                                                                        alt=""
                                                                        controls
                                                                      ></video>
                                                                    </a>
                                                                  </React.Fragment>
                                                                ) : (
                                                                  <></>
                                                                )
                                                            )}
                                                            <div className="more-image-count">
                                                              <span>
                                                                +
                                                                {item
                                                                  ?.chat_file_transactions
                                                                  ?.length - 4}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </React.Fragment>
                                                      ) : (
                                                        <React.Fragment></React.Fragment>
                                                      )}
                                                    </React.Fragment>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </React.Fragment>
                                              )}
                                              <div className="msg-drop-chat">
                                                <i
                                                  className="fas fa-ellipsis-v msgp-drop"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                ></i>
                                                <ul className="dropdown-menu msgp-dropdown">
                                                  <li
                                                    type="button"
                                                    data-bs-toggle="offcanvas"
                                                    data-bs-target="#messageinfomodal"
                                                    aria-controls="messageinfomodal"
                                                    onClick={(e) =>
                                                      handleOpenMessageInfoBox(
                                                        e,
                                                        item
                                                      )
                                                    }
                                                  >
                                                    <button className="dropdown-item">
                                                      Message Info
                                                    </button>
                                                  </li>
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#deleteGroupChatRightSide"
                                                    onClick={(e) =>
                                                      handleDeleteGroupMessageConformation(
                                                        e,
                                                        item
                                                      )
                                                    }
                                                  >
                                                    <button className="dropdown-item">
                                                      Delete
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                        <div className="msg-details">
                                          <p className="time">
                                            {moment
                                              .utc(
                                                moment(item?.dAddedDate).format(
                                                  "YYYY-MM-DD"
                                                ) +
                                                  " " +
                                                  moment(
                                                    item?.dAddedTime
                                                  ).format("hh:mm")
                                              )
                                              .local()
                                              .format("h:mm A")}
                                            {/* {moment(
                                              item?.dAddedTime,
                                              "YYYY-MM-DD, h:mm:ss a"
                                            ).format("h:mm A")} */}
                                          </p>
                                        </div>
                                        <div
                                          className="modal delete-chat-modal fade"
                                          id="deleteGroupChatRightSide"
                                          tabIndex="-1"
                                          aria-labelledby="deleteGroupRightLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <div
                                                  className="modal-title"
                                                  id="deleteGroupRightLabel"
                                                >
                                                  Delete message ?
                                                </div>
                                              </div>
                                              <div className="modal-body">
                                                <button
                                                  type="button"
                                                  className="btn submit-form"
                                                  onClick={
                                                    handleDeleteGroupMessageForEveryone
                                                  }
                                                >
                                                  Delete For Everyone
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn submit-form"
                                                  onClick={
                                                    handleDeleteGroupMessageForMe
                                                  }
                                                >
                                                  Delete For Me
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn submit-form"
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                  onClick={() =>
                                                    setDeleteGroupMessageTimeDetails(
                                                      ""
                                                    )
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="offcanvas messageinfo-canvas offcanvas-end"
                                          tabIndex="-1"
                                          id="messageinfomodal"
                                          aria-labelledby="messageinfomodalLabel"
                                        >
                                          <div className="offcanvas-header">
                                            <div
                                              id="messageinfomodalLabel"
                                              className="offcanvas-title"
                                            >
                                              Message Info
                                            </div>
                                            <button
                                              type="button"
                                              className="btn-close text-reset"
                                              data-bs-dismiss="offcanvas"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="offcanvas-body">
                                            {messageInfoValues?.readByUsers
                                              ?.length > 0 ? (
                                              <>
                                                <div className="message-canvas-box">
                                                  <div className="title-top">
                                                    <h3>Read by</h3>
                                                    <div className="read-icon">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="icon icon-tabler icon-tabler-checks"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      >
                                                        <path d="M7 12l5 5l10 -10"></path>
                                                        <path d="M2 12l5 5m5 -5l5 -5"></path>
                                                      </svg>
                                                    </div>
                                                  </div>
                                                  <div className="inner-box">
                                                    {messageInfoValues
                                                      ?.readByUsers?.length >
                                                    0 ? (
                                                      <>
                                                        {messageInfoValues?.readByUsers?.map(
                                                          (el) => (
                                                            <div className="box">
                                                              <div className="image-box">
                                                                {
                                                                  // roomLogo ===
                                                                  el?.user
                                                                    ?.vImage ===
                                                                  `${process.env.REACT_APP_GET_PUBLIC_PATH}/uploads/user/null` ? (
                                                                    <>
                                                                      <Avatar
                                                                        label={
                                                                          el?.user?.vFirstName?.slice(
                                                                            0,
                                                                            1
                                                                          ) +
                                                                          el?.user?.vLastName?.slice(
                                                                            0,
                                                                            1
                                                                          )
                                                                        }
                                                                        shape="circle"
                                                                      />
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <Avatar
                                                                        image={
                                                                          el
                                                                            ?.user
                                                                            ?.vImage
                                                                        }
                                                                      />
                                                                    </>
                                                                  )
                                                                }
                                                              </div>
                                                              <div className="text">
                                                                <h3>
                                                                  {el?.user
                                                                    ?.vFirstName +
                                                                    " " +
                                                                    el?.user
                                                                      ?.vLastName}
                                                                </h3>
                                                                <p>
                                                                  {moment
                                                                    .utc(
                                                                      moment(
                                                                        el?.dtAddedDate
                                                                      ).format(
                                                                        "YYYY-MM-DD"
                                                                      ) +
                                                                        " " +
                                                                        moment(
                                                                          el?.dtAddedDate
                                                                        ).format(
                                                                          "hh:mm"
                                                                        )
                                                                    )
                                                                    .local()
                                                                    .format(
                                                                      "h:mm A"
                                                                    )}
                                                                  {/* {moment(
                                                                    el?.dtAddedDate
                                                                  ).format(
                                                                    "MM-DD-YYYY hh:mm a"
                                                                  )} */}
                                                                </p>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {/* <div className="box">
                                                                                                                            <div className="image-box">
                                                                                                                                <img src="./assets/img/Ellipse 11.png" alt="" className="img-cover" />
                                                                                                                            </div>
                                                                                                                            <div className="text">
                                                                                                                                <h3>Christopher Hodges</h3>
                                                                                                                                <p>today at 12:01 pm</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="box">
                                                                                                                            <div className="image-box">
                                                                                                                                <img src="./assets/img/Ellipse 11.png" alt="" className="img-cover" />
                                                                                                                            </div>
                                                                                                                            <div className="text">
                                                                                                                                <h3>Christopher Hodges</h3>
                                                                                                                                <p>today at 12:01 pm</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="box">
                                                                                                                            <div className="image-box">
                                                                                                                                <img src="./assets/img/Ellipse 11.png" alt="" className="img-cover" />
                                                                                                                            </div>
                                                                                                                            <div className="text">
                                                                                                                                <h3>Christopher Hodges</h3>
                                                                                                                                <p>today at 12:01 pm</p>
                                                                                                                            </div>
                                                                                                                        </div> */}
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            {messageInfoValues?.deliveredByUsers
                                              ?.length > 0 ? (
                                              <>
                                                <div className="message-canvas-box">
                                                  <div className="title-top">
                                                    <h3>Delivered to</h3>
                                                    <div className="delivered-icon">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="icon icon-tabler icon-tabler-checks"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      >
                                                        <path d="M7 12l5 5l10 -10"></path>
                                                        <path d="M2 12l5 5m5 -5l5 -5"></path>
                                                      </svg>
                                                    </div>
                                                  </div>
                                                  <div className="inner-box">
                                                    {messageInfoValues
                                                      ?.deliveredByUsers
                                                      ?.length > 0 ? (
                                                      <>
                                                        {messageInfoValues?.deliveredByUsers?.map(
                                                          (el) => (
                                                            <div className="box">
                                                              <div className="image-box">
                                                                {el?.user
                                                                  ?.vImage ===
                                                                `${process.env.REACT_APP_GET_PUBLIC_PATH}/uploads/user/null` ? (
                                                                  <>
                                                                    <Avatar
                                                                      label={
                                                                        el?.user?.vFirstName?.slice(
                                                                          0,
                                                                          1
                                                                        ) +
                                                                        el?.user?.vLastName?.slice(
                                                                          0,
                                                                          1
                                                                        )
                                                                      }
                                                                      shape="circle"
                                                                    />
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    <Avatar
                                                                      image={
                                                                        el?.user
                                                                          ?.vImage
                                                                      }
                                                                    />
                                                                  </>
                                                                )}
                                                              </div>
                                                              <div className="text">
                                                                <h3>
                                                                  {el?.user
                                                                    ?.vFirstName +
                                                                    " " +
                                                                    el?.user
                                                                      ?.vLastName}
                                                                </h3>
                                                                <p>
                                                                  {moment
                                                                    .utc(
                                                                      moment(
                                                                        el?.dAddedTime
                                                                      ).format(
                                                                        "YYYY-MM-DD"
                                                                      ) +
                                                                        " " +
                                                                        moment(
                                                                          el?.dAddedTime
                                                                        ).format(
                                                                          "hh:mm"
                                                                        )
                                                                    )
                                                                    .local()
                                                                    .format(
                                                                      "h:mm A"
                                                                    )}
                                                                  {/* {moment(
                                                                    el?.dtAddedDate
                                                                  ).format(
                                                                    "YYYY-MM-DD, h:mm:ss a"
                                                                  )} */}
                                                                </p>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div ref={messagesEndRef} />
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment key={chatKey}>
                                    <div className="text left-text">
                                      <div className="user-img-box">
                                        {
                                          // roomLogo ===
                                          item?.user?.vImage ===
                                          `${process.env.REACT_APP_GET_PUBLIC_PATH}/uploads/user/null` ? (
                                            <>
                                              <Avatar
                                                label={
                                                  item?.user?.vFirstName?.slice(
                                                    0,
                                                    1
                                                  ) +
                                                  item?.user?.vLastName?.slice(
                                                    0,
                                                    1
                                                  )
                                                }
                                                shape="circle"
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <Avatar
                                                image={item?.user?.vImage}
                                              />
                                            </>
                                          )
                                        }
                                      </div>
                                      <div className="msg">
                                        <div className="msg-p">
                                          {item?.chat_transaction?.eDelete ===
                                            "Yes" || item?.eDelete === "Yes" ? (
                                            <>
                                              <span>{`This Message Is Deleted`}</span>
                                            </>
                                          ) : (
                                            <>
                                              {item?.vMessage !== null &&
                                              item?.vMessage !== "" &&
                                              item?.vMessage?.length > 0 &&
                                              item?.chat_file_transactions
                                                ?.length <= 0 ? (
                                                <React.Fragment
                                                  key={item?.iChatId}
                                                >
                                                  <span>{item?.vMessage}</span>
                                                </React.Fragment>
                                              ) : (
                                                <React.Fragment
                                                  key={item?.iChatId}
                                                >
                                                  {item?.vMessage !== null &&
                                                  item?.vMessage !== "" &&
                                                  item?.vMessage?.length > 0 ? (
                                                    <React.Fragment
                                                      key={`image-with-message-${item?.iChatId}`}
                                                    >
                                                      <span>
                                                        {item?.vMessage}
                                                      </span>
                                                    </React.Fragment>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {item?.chat_file_transactions
                                                    ?.length > 0 ? (
                                                    <React.Fragment>
                                                      {item
                                                        ?.chat_file_transactions
                                                        ?.length === 1 ? (
                                                        <React.Fragment>
                                                          {item?.chat_file_transactions?.map(
                                                            (el) =>
                                                              el.eType ===
                                                              "gif" ? (
                                                                <div
                                                                  className="msg-image-one image-up"
                                                                  key={
                                                                    el.iChatFileTransactionId
                                                                  }
                                                                >
                                                                  <img
                                                                    src={
                                                                      el.vLink
                                                                    }
                                                                    alt=""
                                                                  />
                                                                </div>
                                                              ) : el.eType ===
                                                                "image" ? (
                                                                <div
                                                                  className="msg-image-one image-up"
                                                                  key={
                                                                    el.iChatFileTransactionId
                                                                  }
                                                                >
                                                                  <a
                                                                    className="image-box"
                                                                    data-fancybox="gallery"
                                                                    href={
                                                                      el.vFile
                                                                    }
                                                                  >
                                                                    <img
                                                                      src={
                                                                        el.vFile
                                                                      }
                                                                      alt=""
                                                                    />
                                                                  </a>
                                                                </div>
                                                              ) : el.eType ===
                                                                "video" ? (
                                                                <div
                                                                  className="msg-image-one image-up"
                                                                  key={
                                                                    el.iChatFileTransactionId
                                                                  }
                                                                >
                                                                  <a
                                                                    className="image-box"
                                                                    data-fancybox="gallery"
                                                                    href={
                                                                      el.vFile
                                                                    }
                                                                  >
                                                                    <video
                                                                      src={
                                                                        el.vFile
                                                                      }
                                                                      alt=""
                                                                      controls
                                                                    ></video>
                                                                  </a>
                                                                </div>
                                                              ) : el.eType ===
                                                                "Application" ? (
                                                                <div
                                                                  className="upchat-image-box"
                                                                  key={
                                                                    el.iChatFileTransactionId
                                                                  }
                                                                >
                                                                  <div className="msg-image-one image-up">
                                                                    <object className="doc-box">
                                                                      <div className="left-sec">
                                                                        <i className="fas fa-file"></i>
                                                                        <div>
                                                                          <p className="name">
                                                                            {
                                                                              el?.vFileName
                                                                            }
                                                                          </p>
                                                                          <p className="file-size">
                                                                            {Math.round(
                                                                              parseInt(
                                                                                el?.vSize
                                                                              ) /
                                                                                1024
                                                                            ) >
                                                                            1024
                                                                              ? `${Math.round(
                                                                                  parseInt(
                                                                                    el?.vSize
                                                                                  ) /
                                                                                    1024 /
                                                                                    1024
                                                                                )} MB`
                                                                              : `${Math.round(
                                                                                  parseInt(
                                                                                    el?.vSize
                                                                                  ) /
                                                                                    1024
                                                                                )} KB `}
                                                                          </p>
                                                                        </div>
                                                                      </div>
                                                                      <div className="doc-download">
                                                                        <i className="fal fa-arrow-circle-down"></i>
                                                                      </div>
                                                                    </object>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <></>
                                                              )
                                                          )}
                                                        </React.Fragment>
                                                      ) : item
                                                          ?.chat_file_transactions
                                                          ?.length === 2 ||
                                                        item
                                                          ?.chat_file_transactions
                                                          ?.length === 4 ? (
                                                        <React.Fragment>
                                                          <div className="msg-image-more image-up">
                                                            {item?.chat_file_transactions?.map(
                                                              (el) =>
                                                                el.eType ===
                                                                "image" ? (
                                                                  <React.Fragment
                                                                    key={
                                                                      el.iChatFileTransactionId
                                                                    }
                                                                  >
                                                                    <a
                                                                      className="image-box"
                                                                      data-fancybox="gallery"
                                                                      href={
                                                                        el.vFile
                                                                      }
                                                                    >
                                                                      <img
                                                                        className="rounded"
                                                                        alt=""
                                                                        onError={(
                                                                          e
                                                                        ) => {
                                                                          e.target.onerror =
                                                                            null;
                                                                          e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                                                        }}
                                                                        src={
                                                                          el?.vFile
                                                                        }
                                                                      />
                                                                    </a>
                                                                  </React.Fragment>
                                                                ) : el.eType ===
                                                                  "video" ? (
                                                                  <React.Fragment
                                                                    key={
                                                                      el.iChatFileTransactionId
                                                                    }
                                                                  >
                                                                    <a
                                                                      className="image-box"
                                                                      data-fancybox="gallery"
                                                                      href={
                                                                        el.vFile
                                                                      }
                                                                    >
                                                                      <video
                                                                        src={
                                                                          el.vFile
                                                                        }
                                                                        alt=""
                                                                        controls
                                                                      ></video>
                                                                    </a>
                                                                  </React.Fragment>
                                                                ) : (
                                                                  <></>
                                                                )
                                                            )}
                                                          </div>
                                                        </React.Fragment>
                                                      ) : item
                                                          ?.chat_file_transactions
                                                          ?.length === 3 ? (
                                                        <React.Fragment>
                                                          <div className="msg-image-three image-up">
                                                            {item?.chat_file_transactions?.map(
                                                              (el) =>
                                                                el.eType ===
                                                                "image" ? (
                                                                  <React.Fragment
                                                                    key={
                                                                      el.iChatFileTransactionId
                                                                    }
                                                                  >
                                                                    <a
                                                                      className="image-box"
                                                                      data-fancybox="gallery"
                                                                      href={
                                                                        el.vFile
                                                                      }
                                                                    >
                                                                      <img
                                                                        className="rounded"
                                                                        alt=""
                                                                        onError={(
                                                                          e
                                                                        ) => {
                                                                          e.target.onerror =
                                                                            null;
                                                                          e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                                                        }}
                                                                        src={
                                                                          el?.vFile
                                                                        }
                                                                      />
                                                                    </a>
                                                                  </React.Fragment>
                                                                ) : el.eType ===
                                                                  "video" ? (
                                                                  <React.Fragment
                                                                    key={
                                                                      el.iChatFileTransactionId
                                                                    }
                                                                  >
                                                                    <a
                                                                      className="image-box"
                                                                      data-fancybox="gallery"
                                                                      href={
                                                                        el.vFile
                                                                      }
                                                                    >
                                                                      <video
                                                                        src={
                                                                          el.vFile
                                                                        }
                                                                        alt=""
                                                                        controls
                                                                      ></video>
                                                                    </a>
                                                                  </React.Fragment>
                                                                ) : (
                                                                  <></>
                                                                )
                                                            )}
                                                          </div>
                                                        </React.Fragment>
                                                      ) : item
                                                          ?.chat_file_transactions
                                                          ?.length >= 5 ? (
                                                        <React.Fragment>
                                                          <div className="msg-image-more image-up">
                                                            {item?.chat_file_transactions?.map(
                                                              (el, index) =>
                                                                el.eType ===
                                                                "image" ? (
                                                                  <React.Fragment
                                                                    key={
                                                                      el.iChatFileTransactionId
                                                                    }
                                                                  >
                                                                    <a
                                                                      class={`image-box ${
                                                                        index >=
                                                                        4
                                                                          ? "d-none"
                                                                          : ""
                                                                      }`}
                                                                      data-fancybox="gallery"
                                                                      href={
                                                                        el.vFile
                                                                      }
                                                                    >
                                                                      <img
                                                                        className="rounded"
                                                                        alt=""
                                                                        onError={(
                                                                          e
                                                                        ) => {
                                                                          e.target.onerror =
                                                                            null;
                                                                          e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                                                        }}
                                                                        src={
                                                                          el?.vFile
                                                                        }
                                                                      />
                                                                    </a>
                                                                  </React.Fragment>
                                                                ) : el.eType ===
                                                                  "video" ? (
                                                                  <React.Fragment
                                                                    key={
                                                                      el.iChatFileTransactionId
                                                                    }
                                                                  >
                                                                    <a
                                                                      class={`image-box ${
                                                                        index >=
                                                                        4
                                                                          ? "d-none"
                                                                          : ""
                                                                      }`}
                                                                      data-fancybox="gallery"
                                                                      href={
                                                                        el.vFile
                                                                      }
                                                                    >
                                                                      <video
                                                                        src={
                                                                          el.vFile
                                                                        }
                                                                        alt=""
                                                                        controls
                                                                      ></video>
                                                                    </a>
                                                                  </React.Fragment>
                                                                ) : (
                                                                  <></>
                                                                )
                                                            )}
                                                            <div className="more-image-count">
                                                              <span>
                                                                +
                                                                {item
                                                                  ?.chat_file_transactions
                                                                  ?.length - 4}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </React.Fragment>
                                                      ) : (
                                                        <React.Fragment></React.Fragment>
                                                      )}
                                                    </React.Fragment>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </React.Fragment>
                                              )}
                                              <div className="msg-drop-chat">
                                                <i
                                                  className="fas fa-ellipsis-v msgp-drop"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                ></i>
                                                <ul className="dropdown-menu msgp-dropdown">
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#deleteGroupMessageLeftSide"
                                                    onClick={(e) =>
                                                      handleDeleteGroupMessageConformation(
                                                        e,
                                                        item
                                                      )
                                                    }
                                                  >
                                                    <button className="dropdown-item">
                                                      Delete
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                        <div className="msg-details">
                                          <p className="time">
                                            {moment
                                              .utc(
                                                moment(item?.dAddedTime).format(
                                                  "YYYY-MM-DD"
                                                ) +
                                                  " " +
                                                  moment(
                                                    item?.dAddedTime
                                                  ).format("hh:mm")
                                              )
                                              .local()
                                              .format("h:mm A")}
                                            {/* {moment(
                                              item?.dAddedTime,
                                              "YYYY-MM-DD, h:mm:ss a"
                                            ).format("h:mm A")} */}
                                          </p>
                                        </div>
                                        <div
                                          className="modal delete-chat-modal fade"
                                          id="deleteGroupMessageLeftSide"
                                          tabIndex="-1"
                                          aria-labelledby="deleteGroupChatLeftLable"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <div
                                                  className="modal-title"
                                                  id="deleteGroupChatLeftLable"
                                                >
                                                  Delete message ?
                                                </div>
                                              </div>
                                              <div className="modal-body">
                                                <button
                                                  type="button"
                                                  className="btn submit-form"
                                                  onClick={
                                                    handleRightSideDeleteGroupMessageForMe
                                                  }
                                                >
                                                  Delete For Me
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn submit-form"
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                  onClick={() =>
                                                    setDeleteGroupMessageTimeDetails(
                                                      ""
                                                    )
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div ref={messagesEndRef} />
                                  </React.Fragment>
                                )
                              )}
                              <div ref={messagesEndRef} />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      <div ref={messagesEndRef} />
                    </>
                  )}
                </div>

                {/* HERE IS ALL THE USER'S CHATING ==>> CHAT BODY END... */}

                {/* /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/ */}

                {/* HERE THROUGH SEND MESSAGE REGARDING START... */}
                <div className="main-chat-footer">
                  {isOpenImageVideoSelection ? (
                    <>
                      <div className="post-upload-images">
                        {chatFormValues?.chatImagesPreviews?.length > 0 ? (
                          <>
                            <div className="inner-post-upload-images">
                              {chatFormValues?.chatImagesPreviews?.map(
                                (el, index) =>
                                  el.eType === "image" ? (
                                    <div className="five-upload" key={index}>
                                      <a
                                        className="image-box"
                                        data-fancybox={`gallery${index}`}
                                        id={index}
                                        href={el?.vFile}
                                        data-options='{"loop":true}'
                                      >
                                        <div className="images-box">
                                          <img src={el?.vFile} alt="" />
                                        </div>
                                      </a>
                                      <button className="btn btn-primary">
                                        <i
                                          className="fas fa-times"
                                          onClick={() =>
                                            handleRemoveImageVideoItems(index)
                                          }
                                        ></i>
                                      </button>
                                    </div>
                                  ) : el.eType === "video" ? (
                                    <div className="five-upload">
                                      <div className="images-box">
                                        <a
                                          className="image-box"
                                          data-fancybox={`gallery${index}`}
                                          id={index}
                                          href={el?.vFile}
                                          data-options='{"loop":true}'
                                        >
                                          <video
                                            allowFullScreen="false"
                                            id="video"
                                            src={el?.vFile}
                                            alt=""
                                            muted
                                          />
                                        </a>
                                      </div>
                                      <button className="btn btn-primary">
                                        <i
                                          className="fas fa-times"
                                          onClick={() =>
                                            handleRemoveImageVideoItems(index)
                                          }
                                        ></i>
                                      </button>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="five-upload" key={index}>
                                        <div id="pdfPreview"></div>
                                        <div
                                          className="images-box"
                                          id="test-id"
                                        >
                                          <object
                                            data={el.vFile}
                                            aria-labelledby="vFile"
                                          ></object>
                                        </div>
                                        <button className="btn btn-primary">
                                          <i
                                            className="fas fa-times"
                                            onClick={() =>
                                              handleRemoveImageVideoItems(index)
                                            }
                                          ></i>
                                        </button>
                                      </div>
                                    </>
                                  )
                              )}
                              <div className="five-upload add-image-box">
                                <i className="fal fa-plus"></i>
                                <input
                                  type="file"
                                  className="form-control"
                                  multiple={true}
                                  accept="video/*,image/*"
                                  onChange={(e) =>
                                    handleSelectImageVideoFiles(e)
                                  }
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="add-image-box big-added">
                              <i className="fal fa-plus"></i>
                              <input
                                type="file"
                                className="form-control"
                                multiple={true}
                                accept="video/*,image/*"
                                onChange={(e) => handleSelectImageVideoFiles(e)}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {isOpenDocumentSelection ? (
                    <>
                      <div className="post-upload-images">
                        {!chatFormValues?.chatApplicationPreview.hasOwnProperty(
                          "type"
                        ) ? (
                          <div className="add-image-box big-added">
                            <i className="fal fa-plus"></i>
                            <input
                              type="file"
                              className="form-control"
                              accept="text/plain,
                                                                                application/pdf,
                                                                                application/xlsx,
                                                                                application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              onChange={(e) => handleSelectDocumentFiles(e)}
                            />
                          </div>
                        ) : (
                          <div className="inner-post-upload-images">
                            {chatFormValues?.chatApplicationPreview.type ===
                            "application/pdf" ? (
                              <div className="five-upload">
                                <div id="pdfPreview"></div>
                                <div className="images-box" id="test-id">
                                  <object
                                    data={
                                      chatFormValues?.chatApplicationPreview
                                        ?.vFile
                                    }
                                    aria-labelledby="vFile"
                                  ></object>
                                </div>
                                <button className="btn btn-primary">
                                  <i
                                    className="fas fa-times"
                                    onClick={(e) => handleRemoveFiles(e)}
                                  ></i>
                                </button>
                              </div>
                            ) : (
                              <div className="doc-box">
                                <div id="pdfPreview"></div>
                                <div className="images-box" id="test-id">
                                  <object
                                    data={
                                      chatFormValues?.chatApplicationPreview
                                        ?.vFile
                                    }
                                    aria-labelledby="vFile"
                                  ></object>
                                </div>
                                <button
                                  className="btn btn-primary"
                                  onClick={(e) => handleRemoveFiles(e)}
                                >
                                  <i className="fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="main-chat-msgtype">
                    <div className="type-extra">
                      <div
                        className="box"
                        onClick={() => {
                          setIsEmojiMartShow((prev) => !prev);
                          setIsOpenFileSharingOptions(false);
                          setIsOpenImageVideoSelection(false);
                          setIsOpenDocumentSelection(false);
                          setIsShowGifPicker(false);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-mood-smile"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                          <path d="M9 10l.01 0"></path>
                          <path d="M15 10l.01 0"></path>
                          <path d="M9.5 15a3.5 3.5 0 0 0 5 0"></path>
                        </svg>
                      </div>
                      <div
                        className="box"
                        onClick={() => {
                          setIsShowGifPicker((prev) => !prev);
                          setIsEmojiMartShow(false);
                          setIsOpenFileSharingOptions(false);
                          setIsOpenImageVideoSelection(false);
                          setIsOpenDocumentSelection(false);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-gif"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M8 8h-3a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h3v-4h-1"></path>
                          <path d="M12 8v8"></path>
                          <path d="M16 16v-8h5"></path>
                          <path d="M20 12h-4"></path>
                        </svg>
                      </div>
                      <div className="box anim-icon">
                        {isOpenFileSharingOptions ? (
                          <>
                            <div
                              id="select-icon"
                              className="select-icon d-flex flex-column"
                            >
                              <div
                                className="image-video box"
                                onClick={() =>
                                  setIsOpenImageVideoSelection(true)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24.5"
                                  height="24.5"
                                  viewBox="0 0 24.5 24.5"
                                >
                                  <g
                                    id="fi-rr-picture"
                                    transform="translate(0.25 0.25)"
                                  >
                                    <path
                                      id="Path_140"
                                      data-name="Path 140"
                                      d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0ZM5,2H19a3,3,0,0,1,3,3V19a2.951,2.951,0,0,1-.3,1.285l-9.163-9.163a5,5,0,0,0-7.072,0L2,14.586V5A3,3,0,0,1,5,2ZM5,22a3,3,0,0,1-3-3V17.414l4.878-4.878a3,3,0,0,1,4.244,0L20.285,21.7A2.951,2.951,0,0,1,19,22Z"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="0.5"
                                    ></path>
                                    <path
                                      id="Path_141"
                                      data-name="Path 141"
                                      d="M16,10.5A3.5,3.5,0,1,0,12.5,7,3.5,3.5,0,0,0,16,10.5Zm0-5A1.5,1.5,0,1,1,14.5,7,1.5,1.5,0,0,1,16,5.5Z"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="0.5"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                              <div
                                className="doc box"
                                onClick={() => setIsOpenDocumentSelection(true)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20.5"
                                  height="24.5"
                                  viewBox="0 0 20.5 24.5"
                                >
                                  <path
                                    id="fi-rr-document"
                                    d="M17,14a1,1,0,0,1-1,1H8a1,1,0,0,1,0-2h8A1,1,0,0,1,17,14Zm-4,3H8a1,1,0,0,0,0,2h5a1,1,0,0,0,0-2Zm9-6.515V19a5.006,5.006,0,0,1-5,5H7a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,7,0h4.515a6.958,6.958,0,0,1,4.95,2.05l3.484,3.486A6.951,6.951,0,0,1,22,10.485ZM15.051,3.464A5.01,5.01,0,0,0,14,2.684V7a1,1,0,0,0,1,1h4.316a4.983,4.983,0,0,0-.781-1.05ZM20,10.485c0-.165-.032-.323-.047-.485H15a3,3,0,0,1-3-3V2.047C11.838,2.032,11.679,2,11.515,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3Z"
                                    transform="translate(-1.75 0.25)"
                                    fill="#fff"
                                    stroke="#fff"
                                    strokeWidth="0.5"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-paperclip"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          onClick={() => {
                            setIsOpenFileSharingOptions((prev) => !prev);
                            setIsShowGifPicker(false);
                            setIsEmojiMartShow(false);
                            setIsOpenImageVideoSelection(false);
                            setIsOpenDocumentSelection(false);
                            setChatFormValues((prev) => ({
                              ...prev,
                              chatApplicationFiles: "",
                              chatApplicationPreview: "",
                              chatImagesFiles: [],
                              chatImagesPreviews: [],
                            }));
                          }}
                        >
                          <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9l6.5 -6.5"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="input-msg">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Type something here..."
                          className="form-control"
                          value={chatFormValues?.chatTextMessage}
                          onChange={handleChatTextChanges}
                          onKeyUp={(e) => {
                            if (e.code === "Enter" || e.key === "Enter") {
                              handleSendMessage(e);
                            }
                          }}
                        />
                        <button
                          className="send-btn"
                          onClick={(e) => handleSendMessage(e)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Group_16734"
                            data-name="Group 16734"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              id="Path_30446"
                              data-name="Path 30446"
                              d="M10,14,21,3"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Path_30447"
                              data-name="Path 30447"
                              d="M21,3,14.5,21a.55.55,0,0,1-1,0L10,14,3,10.5a.55.55,0,0,1,0-1L21,3"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  {isShowGifPicker ? (
                    <>
                      <div
                        className="gif-box"
                        id="gif-section-className"
                        ref={gifBoxRef}
                      >
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search Gif"
                            onChange={(e) => setSearchedGifText(e.target.value)}
                          />
                        </div>
                        <div className="gif-images">
                          {gifLists?.length > 0 ? (
                            gifLists?.map((el) => (
                              <div
                                className="image-box"
                                onClick={(e) =>
                                  handleSelectGif(e, el.media[0].nanogif?.url)
                                }
                              >
                                <img src={el.media[0].nanogif?.url} alt="" />
                              </div>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {isEmojiMartShow ? (
                    <div className="picker-box">
                      <Picker
                        data={Data}
                        onEmojiSelect={handleSelectEmoji}
                        // perLine="10"
                        // emojiSize="15"
                        // emojiButtonSize="20"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {/* HERE THROUGH SEDN MESSAGE REGARDING END... */}
              </div>
            </>
          ) : (
            <>
              <div className="welcome-chat-box">
                <img src="assets/img/Group 16811.png" alt="" />
                <p>
                  Welcome to <span>Yo Playbook</span> Chat!...
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </DashboardPageContainer>
  );
}

export default LatestChat;

// /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/ *** NOTE *** \/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

// individualChatUserData => through get all the individaul user's list
// teamsChatListData => through get all the Teams list

// DELETE GROUP MESSAGE RELATED STUFFS
// handleRightSideDeleteGroupMessageForMe => when the group message side remove another user message delete for me through then used...
// handleDeleteGroupMessageForMe =>  when the the group side delete my message then used...
// handleDeleteGroupMessageForEveryone => when the group side delete my message for everyone basis then used...
// here i will set up when the we delete group message then team message side set { eDelete:"Yes" }

// image  => <i className="fas fa-image"></i>
// video  => <i className="fas fa-video"></i>
// document  => <i className="fas fa-file"></i>
// media  => <i className="far fa-photo-video"></i>
