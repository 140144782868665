import {
  GET_ALL_CITIES_FAILURE,
  GET_ALL_CITIES_REQUEST,
  GET_ALL_CITIES_SUCCESS,
  GET_CITIES_BY_ID_FAILURE,
  GET_CITIES_BY_ID_REQUEST,
  GET_CITIES_BY_ID_SUCCESS,
  GET_CITIES_LIST_FAILURE,
  GET_CITIES_LIST_REQUEST,
  GET_CITIES_LIST_SUCCESS,
} from "../constants/general";

export function generalReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_CITIES_REQUEST:
    case GET_CITIES_BY_ID_REQUEST:
    case GET_CITIES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    // case UPDATE_HOBBIES_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     updateHobbiesData: action.payload,
    //   };
    // case DELETE_HOBBIES_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     deleteHobbiesData: action.payload,
    //   };
    case GET_ALL_CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllCitiesData: action.payload,
      };
    case GET_CITIES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getCitiesByIdData: action.payload,
      };
    case GET_CITIES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getCitiesListData: action.payload,
      };
    // case UPDATE_HOBBIES_FAILURE:
    // case DELETE_HOBBIES_FAILURE:
    case GET_ALL_CITIES_FAILURE:
    case GET_CITIES_BY_ID_FAILURE:
    case GET_CITIES_LIST_FAILURE:
      return {
        ...state,
        loading: true,
      };
    // case RESET:
    //   return initialState;
    default:
      return state;
  }
}
