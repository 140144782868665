import {
  CANVAS_BY_ID_REQUEST,
  CANVAS_BY_ID_SUCCESS,
  CANVAS_BY_ID_FAILURE,
  ADD_CANVAS_REQUEST,
  ADD_CANVAS_SUCCESS,
  ADD_CANVAS_FAILURE,
  RESET_STATE,
  UPDATE_CANVAS_PLAYER_ID,
  SPEED_VALUE,
  FREE_DRAW_TYPE_VALUE,
  BALL_LINE_TYPE_VALUE,
  SET_IS_DATA,
  GET_CANVAS_FORMATIONS_REQUEST,
  GET_CANVAS_FORMATIONS_SUCCESS,
  GET_CANVAS_FORMATIONS_FAILURE,
  SET_TSHIRT_POSITION_DATA,
  GET_ANDROID_CANVAS_LIST_SUCCESS,
  GET_ANDROID_CANVAS_LIST_REQUEST,
  GET_ANDROID_CANVAS_LIST_FAILURE,
  GET_CANVAS_FORMATION_BY_ID_REQUEST,
  GET_CANVAS_FORMATION_BY_ID_SUCCESS,
  GET_CANVAS_FORMATION_BY_ID_FAILURE,
  FOLDER_ARRAY,
  UPDATE_CANVAS_REQUEST,
  UPDATE_CANVAS_SUCCESS,
  UPDATE_CANVAS_FAILURE,
} from "../constants/canvas";
import axios from "axios";
import { GET_SPORT_GROUND_BY_SPORT_ID_SUCCESS } from "../constants/sports";

export const GetPlayById = (values) => async (dispatch) => {
  try {
    dispatch({ type: CANVAS_BY_ID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/playbook/getSinglePlaybook`,
      values
    );
    dispatch({ type: CANVAS_BY_ID_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: CANVAS_BY_ID_FAILURE });
  }
};

export const AddAndroidCanvas = (values) => async (dispatch) => {
  try {
    dispatch({ type: ADD_CANVAS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/playbook/createTeamPlayBook`,
      values
    );
    dispatch({ type: ADD_CANVAS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: ADD_CANVAS_FAILURE });
  }
};

export const UpdatePlay = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CANVAS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/playbook/updateFolder`,
      values
    );
    dispatch({ type: UPDATE_CANVAS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: UPDATE_CANVAS_FAILURE });
  }
};

export const UpdateAndroidCanvas = (values) => async (dispatch) => {
  try {
    dispatch({ type: ADD_CANVAS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/playbook/updateFolder`,
      values
    );
    dispatch({ type: ADD_CANVAS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: ADD_CANVAS_FAILURE });
  }
};

export const ResetCanvasStore = () => async (dispatch) => {
  dispatch({ type: RESET_STATE });
};

export const UpdateCanvasId = (values) => async (dispatch) => {
  dispatch({
    type: UPDATE_CANVAS_PLAYER_ID,
    payload: values,
  });
};

export const SetSpeedValues = (values) => async (dispatch) => {
  dispatch({
    type: SPEED_VALUE,
    payload: values,
  });
};

export const SetFreeDrawTypeValues = (values) => async (dispatch) => {
  dispatch({
    type: FREE_DRAW_TYPE_VALUE,
    payload: values,
  });
};

export const SetBallLineTypeValues = (values) => async (dispatch) => {
  dispatch({
    type: BALL_LINE_TYPE_VALUE,
    payload: values,
  });
};

export const SetIsData = (values) => async (dispatch) => {
  dispatch({
    type: SET_IS_DATA,
    payload: values,
  });
};

export const GetCanvasFormations = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_CANVAS_FORMATIONS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/SportFormationCatgeory/getSportFormationCategory`,
      values
    );
    // const resData = await axios.post(
    //   `${process.env.REACT_APP_API_URL}/api/SportFormation/getAllSportFormationDataByID`,
    //   {
    //     iSportId: sessionStorage.getItem("sportId"),
    //   }
    // );
    dispatch({ type: GET_CANVAS_FORMATIONS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_CANVAS_FORMATIONS_FAILURE });
  }
};

export const GetCanvasFormationsById = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_CANVAS_FORMATION_BY_ID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/SportFormation/getAllSportFormation`,
      values
    );
    dispatch({
      type: GET_CANVAS_FORMATION_BY_ID_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_CANVAS_FORMATION_BY_ID_FAILURE });
  }
};

export const SetGroundData = () => async (dispatch) => {
  dispatch({
    type: GET_SPORT_GROUND_BY_SPORT_ID_SUCCESS,
    payload: {},
  });
};

export const SetPositionAndTshirtData = (values) => async (dispatch) => {
  dispatch({
    type: SET_TSHIRT_POSITION_DATA,
    payload: values,
  });
};

export const GetAndroidCanvasList = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ANDROID_CANVAS_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/CanvasAndroid/getAllCatgeoryById1`,
      values
    );
    dispatch({ type: GET_ANDROID_CANVAS_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ANDROID_CANVAS_LIST_FAILURE });
  }
};

export const AddFolderList = (values) => async (dispatch) => {
  dispatch({
    type: FOLDER_ARRAY,
    payload: values,
  });
};
