import {
  PAYMENT_SUBSCRIPTION_REQUEST,
  PAYMENT_SUBSCRIPTION_SUCCESS,
  PAYMENT_SUBSCRIPTION_FAILURE,
} from "../constants/paymentSubscription";

export function paymentSubscriptionReducer(state = {}, action) {
  switch (action.type) {
    case PAYMENT_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PAYMENT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentSubscriptionData: action.payload,
      };
    case PAYMENT_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
