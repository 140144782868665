export const VERIFY_BETACODE_REQUEST = "VERIFY_BETACODE_REQUEST";
export const VERIFY_BETACODE_SUCCESS = "VERIFY_BETACODE_SUCCESS";
export const VERIFY_BETACODE_FAILURE = "VERIFY_BETACODE_FAILURE";

export const CREATE_SUBSCRIPTION_REQUEST = "CREATE_SUBSCRIPTION_REQUEST";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILURE = "CREATE_SUBSCRIPTION_FAILURE";

export const PAYMENT_DETAILS = "PAYMENT_DETAILS";

export const PAYMENT_INDENT = "PAYMENT_INDENT";
export const RESET = "RESET";

export const GET_ALL_SUBSCRIPTION_PLAN_REQUEST = "GET_ALL_SUBSCRIPTION_PLAN_REQUEST";
export const GET_ALL_SUBSCRIPTION_PLAN_SUCCESS = "GET_ALL_SUBSCRIPTION_PLAN_SUCCESS";
export const GET_ALL_SUBSCRIPTION_PLAN_FAILURE = "GET_ALL_SUBSCRIPTION_PLAN_FAILURE";

export const GET_ACTIVE_PLAN_REQUEST = "GET_ACTIVE_PLAN_REQUEST";
export const GET_ACTIVE_PLAN_SUCCESS = "GET_ACTIVE_PLAN_SUCCESS";
export const GET_ACTIVE_PLAN_FAILURE = "GET_ACTIVE_PLAN_FAILURE";

export const GRANT_CREDIT_REQUEST = "GRANT_CREDIT_REQUEST";
export const GRANT_CREDIT_SUCCESS = "GRANT_CREDIT_SUCCESS";
export const GRANT_CREDIT_FAILURE = "GRANT_CREDIT_FAILURE";

export const GET_ACTIVE_PLAN_BY_TEAM_ID_REQUEST = "GET_ACTIVE_PLAN_BY_TEAM_ID_REQUEST";
export const GET_ACTIVE_PLAN_BY_TEAM_ID_SUCCESS = "GET_ACTIVE_PLAN_BY_TEAM_ID_SUCCESS";
export const GET_ACTIVE_PLAN_BY_TEAM_ID_FAILURE = "GET_ACTIVE_PLAN_BY_TEAM_ID_FAILURE";