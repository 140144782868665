import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { SetEventNotificationDetails } from "../../../store/action/otherAction";

function NotificationEventDetail() {
  const dispatch = useDispatch();
  const { eventNotificationDetailsData } = useSelector((state) => state.others);

  const handleClose = React.useCallback(() => {
    dispatch(SetEventNotificationDetails({}));
    window.$("#notificationEventDetails").modal("hide");
  }, [dispatch]);

  function nthDate(date = new Date()) {
    const finalDayName = moment(date).format("dddd");
    const startYear = moment(date).format("YYYY");
    const startMonth = moment(date).format("MM");
    const startDay = moment(date).format("DD");
    let nth = Math.ceil(
      moment(`${startYear}-${startMonth}-${startDay}`).format("DD") / 7
    );
    nth += ["st", "nd", "rd"][((((nth + 90) % 100) - 10) % 10) - 1] || "th";
    return `${nth} ${finalDayName}`;
  }

  return (
    <div
      className="modal fade  event-manage-pop "
      id="notificationEventDetails"
      aria-hidden="true"
      aria-labelledby="notificationEventDetailsLabel"
      tabIndex="-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="notificationEventDetailsLabel">
              Event
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </div>

          <div className="modal-body">
            {eventNotificationDetailsData &&
            Object.keys(eventNotificationDetailsData).length > 0 ? (
              <div className="text">
                <div>
                  <span>Title : &nbsp;</span>
                  {eventNotificationDetailsData.vEvent_name}
                </div>

                {eventNotificationDetailsData.vNote !== null &&
                eventNotificationDetailsData.vNote?.length > 0 ? (
                  <>
                    <p>
                      <span>Note :</span> {eventNotificationDetailsData.vNote}
                    </p>
                  </>
                ) : (
                  <></>
                )}

                <p>
                  <span>Start Date :</span>
                  {moment(eventNotificationDetailsData.vStart_Date).format(
                    `${process.env.REACT_APP_DATE_FORMAT}`
                  )}
                </p>
                {eventNotificationDetailsData?.eInfinite ===
                        "Yes" ? (
                          <></>
                        ) : (
                          <p>
                            <span>End Date :</span>
                            {moment(
                              eventNotificationDetailsData?.vEnd_Date
                            ).format(`${process.env.REACT_APP_DATE_FORMAT}`)}
                          </p>
                        )}
                <p>
                  <span>Recurring :</span>
                  {eventNotificationDetailsData.eRepeat}
                </p>
                <p>
                  <span>Start Time :</span>
                  {eventNotificationDetailsData.vFrom_time}
                </p>
                <p>
                  <span>End Time :</span>
                  {eventNotificationDetailsData.vTo_time}
                </p>
                {eventNotificationDetailsData.vCoachPrice ? (
                  <p>
                    <span>Price :</span>
                    {eventNotificationDetailsData.vCoachPrice}
                  </p>
                ) : (
                  <></>
                )}
                {eventNotificationDetailsData.vSlot ? (
                  <p>
                    <span>Slots Available :</span>
                    {eventNotificationDetailsData.vSlot}
                  </p>
                ) : (
                  <></>
                )}
                {/* {eventNotificationDetailsData.eWaitList ? (
                  <p>
                    <span>Waitlist :</span>
                    {eventNotificationDetailsData.eWaitList}
                  </p>
                ) : (
                  <></>
                )} */}
                {eventNotificationDetailsData.vWeeklyDay ? (
                  <p>
                    <span>Weekly Day :</span>
                    {eventNotificationDetailsData.vWeeklyDay &&
                      JSON.parse(eventNotificationDetailsData.vWeeklyDay)
                        .join(" ,")
                        .split(", ")
                        .map((day, index) => <span key={index}>{day}</span>)}
                  </p>
                ) : (
                  <></>
                )}
                {eventNotificationDetailsData.vMonthlyDay ? (
                  <p>
                    <span>Monthly Day :</span>
                    {parseInt(eventNotificationDetailsData.vMonthlyDay)
                      ? `${parseInt(
                          eventNotificationDetailsData.vMonthlyDay
                        )} ${moment().format("MMMM")}`
                      : nthDate(eventNotificationDetailsData.vStart_Date)}
                  </p>
                ) : (
                  <></>
                )}
                {eventNotificationDetailsData.vYearlyDay ? (
                  <p>
                    <span>Yearly Day :</span>
                    {parseInt(eventNotificationDetailsData.vYearlyDay)
                      ? `${parseInt(
                          eventNotificationDetailsData.vYearlyDay
                        )} ${moment().format("MMMM")}`
                      : `${nthDate(
                          eventNotificationDetailsData.vStart_Date
                        )} ${moment().format("MMMM")}`}
                  </p>
                ) : (
                  <></>
                )}
                {eventNotificationDetailsData.iFacilityId > 0 ? (
                  <div
                    className="set-cursor-pointer"
                    onClick={() => {
                      window.open(
                        `https://www.google.com/maps?q=${encodeURIComponent(
                          `${eventNotificationDetailsData.facility_master?.vLocation} ${eventNotificationDetailsData.facility_master?.vAddress}`
                        )}`
                      );
                    }}
                  >
                    <span>Location :</span>
                    <p>
                      {eventNotificationDetailsData.facility_master?.vLocation}
                      <br />
                      {eventNotificationDetailsData.facility_master?.vAddress}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {eventNotificationDetailsData.vLocation ||
                eventNotificationDetailsData.vAddress ? (
                  <div
                    className="set-cursor-pointer"
                    onClick={() => {
                      window.open(
                        `https://www.google.com/maps?q=${encodeURIComponent(
                          `${eventNotificationDetailsData.vLocation} ${eventNotificationDetailsData.vAddress}`
                        )}`
                      );
                    }}
                  >
                    <span>Location :</span>
                    {eventNotificationDetailsData.vLocation &&
                    eventNotificationDetailsData.vAddress ? (
                      <p>
                        {eventNotificationDetailsData.vLocation} <br />
                        {eventNotificationDetailsData.vAddress}
                      </p>
                    ) : eventNotificationDetailsData.vLocation ? (
                      <p>{eventNotificationDetailsData.vLocation} </p>
                    ) : eventNotificationDetailsData.vAddress ? (
                      <p>{eventNotificationDetailsData.vAddress} </p>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <p>
                  <span>Color :</span>
                  <div
                    style={{
                      backgroundColor: eventNotificationDetailsData.vColor,
                      padding: "10px",
                      borderRadius: "50%",
                    }}
                  ></div>
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="modal-footer  form-bottom-btn">
            <button
              className="btn submit-form"
              onClick={handleClose}
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationEventDetail;
