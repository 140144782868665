import React from "react";
import moment from "moment";
import axios from "axios";
function IndividualLatestChatRightBody({
  item,
  index,
  setDeleteIndividualMessageTimeDetails,
  deleteIndividualMessageTimeDetails,
  userIndividualChatData,
  setUserIndividualChatData,
  socket,
  chatHeaderInformation,
  individualChatUserList,
  setIndividualChatUserList,
}) {
  const messagesEndRef = React.useRef(null);

  const scrollToBottom = React.useCallback(() => {
    messagesEndRef.current?.scrollIntoView(false);
  }, []);

  React.useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  // when the user remove the Individual message then used...
  const handleDeleteIndividualMessageConformation = React.useCallback(
    (e, messageDetails) => {
      e.preventDefault();
      setDeleteIndividualMessageTimeDetails(messageDetails);
    },
    [setDeleteIndividualMessageTimeDetails]
  );

  // when the final group message delete for every-one then used...
  const handleDeleteIndividualMessageForEveryone =
    React.useCallback(async () => {
      const values = {
        iChatId: deleteIndividualMessageTimeDetails?.iChatId,
        vRoomId: deleteIndividualMessageTimeDetails?.vRoomId,
      };
      const delteIndividualMessateTimeResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Chat/individualChatDelete`,
        values
      );
      if (delteIndividualMessateTimeResponse?.data?.code === 0) {
        // here through we have update message when we delete the message then used...

        let updateIndividualChatArray = [];
        let removedMessageObj = "";
        userIndividualChatData?.map((el) => {
          if (el.iChatId === deleteIndividualMessageTimeDetails?.iChatId) {
            removedMessageObj = {
              ...el,
              chat_transaction: {
                ...el.chat_transaction,
                eDelete: "Yes",
              },
            };
            return updateIndividualChatArray.push({
              ...el,
              chat_transaction: {
                ...el.chat_transaction,
                eDelete: "Yes",
              },
              iReceivedById: chatHeaderInformation?.receviedUserId,
            });
          }
          return updateIndividualChatArray.push(el);
        });

        // last message update basis
        const latestIndividualUserList = [];
        individualChatUserList?.map((el) => {
          if (el.vRoomId === deleteIndividualMessageTimeDetails?.vRoomId) {
            return latestIndividualUserList.push({
              ...el,
              eDelete: "Yes",
              // LastMessage: sendIndividualMessageResponse?.data?.data?.vMessage,
              // AddedTime: sendIndividualMessageResponse?.data?.data?.dAddedTime
            });
          }
          return latestIndividualUserList.push(el);
        });
        setIndividualChatUserList(latestIndividualUserList);

        socket.emit(
          "individual_delete_single_message",
          JSON.stringify(removedMessageObj)
        );
        setUserIndividualChatData(updateIndividualChatArray);
        window.$("#deleteIndividualChatRightSide").modal("hide");
        setDeleteIndividualMessageTimeDetails("");
      }
    }, [
      socket,
      chatHeaderInformation?.receviedUserId,
      individualChatUserList,
      userIndividualChatData,
      setUserIndividualChatData,
      setIndividualChatUserList,
      setDeleteIndividualMessageTimeDetails,
      deleteIndividualMessageTimeDetails,
    ]);

  // when the final group message delete for me then used...
  const handleDeleteIndividualMessageForMe = React.useCallback(async () => {
    const values = {
      iChatId: deleteIndividualMessageTimeDetails?.iChatId,
      iUserId: sessionStorage.getItem("id"),
      vRoomId: deleteIndividualMessageTimeDetails?.vRoomId,
    };
    const delteIndividualMessateTimeResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Chat/individualChatDelete`,
      values
    );
    if (delteIndividualMessateTimeResponse?.data?.code === 0) {
      // here through we have update message when we delete the message then used...
      let updateIndividualChatArray = [];
      userIndividualChatData?.map((el) => {
        if (el.iChatId === deleteIndividualMessageTimeDetails?.iChatId) {
          return updateIndividualChatArray.push({
            ...el,
            chat_transaction: {
              ...el.chat_transaction,
              eDelete: "Yes",
            },
          });
        }
        return updateIndividualChatArray.push(el);
      });
      // last message update basis
      const latestIndividualUserList = [];
      individualChatUserList?.map((el) => {
        if (el.vRoomId === deleteIndividualMessageTimeDetails?.vRoomId) {
          return latestIndividualUserList.push({
            ...el,
            eDelete: "Yes",
            // LastMessage: sendIndividualMessageResponse?.data?.data?.vMessage,
            // AddedTime: sendIndividualMessageResponse?.data?.data?.dAddedTime
          });
        }
        return latestIndividualUserList.push(el);
      });
      setIndividualChatUserList(latestIndividualUserList);
      setUserIndividualChatData(updateIndividualChatArray);
      window.$("#deleteIndividualChatRightSide").modal("hide");
      setDeleteIndividualMessageTimeDetails("");
    }
  }, [
    individualChatUserList,
    setDeleteIndividualMessageTimeDetails,
    userIndividualChatData,
    deleteIndividualMessageTimeDetails,
    setUserIndividualChatData,
    setIndividualChatUserList,
  ]);

  return (
    <React.Fragment key={`message-${index}`}>
      {parseInt(item.iAddedById) === parseInt(sessionStorage.getItem("id")) ? (
        <>
          <div className="text right-text">
            <div className="msg">
              <div className="msg-p">
                {item?.chat_transaction?.eDelete === "Yes" ? (
                  <>
                    <span>{`This Message Is Deleted`}</span>
                  </>
                ) : (
                  <>
                    {item?.vMessage !== null &&
                    item?.vMessage !== "" &&
                    item?.vMessage?.length > 0 &&
                    item?.chat_file_transactions?.length <= 0 ? (
                      <React.Fragment key={item?.iChatId}>
                        <span>{item?.vMessage}</span>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={item?.iChatId}>
                        {item?.vMessage !== null &&
                        item?.vMessage !== "" &&
                        item?.vMessage?.length > 0 ? (
                          <React.Fragment
                            key={`image-with-message-${item?.iChatId}`}
                          >
                            <span>{item?.vMessage}</span>
                          </React.Fragment>
                        ) : (
                          <></>
                        )}
                        {item?.chat_file_transactions?.length > 0 ? (
                          <React.Fragment>
                            {item?.chat_file_transactions?.length === 1 ? (
                              <React.Fragment>
                                {item?.chat_file_transactions?.map((el) =>
                                  el.eType === "gif" ? (
                                    <>
                                      <div
                                        className="msg-image-one image-up"
                                        key={el.iChatFileTransactionId}
                                      >
                                        <img src={el.vLink} alt="" />
                                      </div>
                                    </>
                                  ) : el.eType === "image" ? (
                                    <div
                                      className="msg-image-one image-up"
                                      key={el.iChatFileTransactionId}
                                    >
                                      <a
                                        className="image-box"
                                        data-fancybox="gallery"
                                        href={el.vFile}
                                      >
                                        <img src={el.vFile} alt="" />
                                      </a>
                                    </div>
                                  ) : el.eType === "video" ? (
                                    <div
                                      className="msg-image-one image-up"
                                      key={el.iChatFileTransactionId}
                                    >
                                      <a
                                        className="image-box"
                                        data-fancybox="gallery"
                                        href={el.vFile}
                                      >
                                        <video
                                          src={el.vFile}
                                          alt=""
                                          controls
                                        ></video>
                                      </a>
                                    </div>
                                  ) : el.eType === "Application" ? (
                                    <div
                                      className="upchat-image-box"
                                      key={el.iChatFileTransactionId}
                                    >
                                      <div className="msg-image-one image-up">
                                        <object className="doc-box">
                                          <div className="left-sec">
                                            <i className="fas fa-file"></i>
                                            <div>
                                              <p className="name">
                                                {el?.vFileName}
                                              </p>
                                              <p className="file-size">
                                                {Math.round(
                                                  parseInt(el?.vSize) / 1024
                                                ) > 1024
                                                  ? `${Math.round(
                                                      parseInt(el?.vSize) /
                                                        1024 /
                                                        1024
                                                    )} MB`
                                                  : `${Math.round(
                                                      parseInt(el?.vSize) / 1024
                                                    )} KB `}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="doc-download">
                                            <i className="fal fa-arrow-circle-down"></i>
                                          </div>
                                        </object>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )
                                )}
                              </React.Fragment>
                            ) : item?.chat_file_transactions?.length === 2 ||
                              item?.chat_file_transactions?.length === 4 ? (
                              <React.Fragment>
                                <div className="msg-image-more image-up">
                                  {item?.chat_file_transactions?.map((el) =>
                                    el.eType === "image" ? (
                                      <React.Fragment
                                        key={el.iChatFileTransactionId}
                                      >
                                        <a
                                          className="image-box"
                                          data-fancybox="gallery"
                                          href={el.vFile}
                                        >
                                          <img
                                            className="rounded"
                                            alt=""
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                            }}
                                            src={el?.vFile}
                                          />
                                        </a>
                                      </React.Fragment>
                                    ) : el.eType === "video" ? (
                                      <React.Fragment
                                        key={el.iChatFileTransactionId}
                                      >
                                        <a
                                          className="image-box"
                                          data-fancybox="gallery"
                                          href={el.vFile}
                                        >
                                          <video
                                            src={el.vFile}
                                            alt=""
                                            controls
                                          ></video>
                                        </a>
                                      </React.Fragment>
                                    ) : (
                                      <></>
                                    )
                                  )}
                                </div>
                              </React.Fragment>
                            ) : item?.chat_file_transactions?.length === 3 ? (
                              <React.Fragment>
                                <div className="msg-image-three image-up">
                                  {item?.chat_file_transactions?.map((el) =>
                                    el.eType === "image" ? (
                                      <React.Fragment
                                        key={el.iChatFileTransactionId}
                                      >
                                        <a
                                          className="image-box"
                                          data-fancybox="gallery"
                                          href={el.vFile}
                                        >
                                          <img
                                            className="rounded"
                                            alt=""
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                            }}
                                            src={el?.vFile}
                                          />
                                        </a>
                                      </React.Fragment>
                                    ) : el.eType === "video" ? (
                                      <React.Fragment
                                        key={el.iChatFileTransactionId}
                                      >
                                        <a
                                          className="image-box"
                                          data-fancybox="gallery"
                                          href={el.vFile}
                                        >
                                          <video
                                            src={el.vFile}
                                            alt=""
                                            controls
                                          ></video>
                                        </a>
                                      </React.Fragment>
                                    ) : (
                                      <></>
                                    )
                                  )}
                                </div>
                              </React.Fragment>
                            ) : item?.chat_file_transactions?.length >= 5 ? (
                              <React.Fragment>
                                <div className="msg-image-more image-up">
                                  {item?.chat_file_transactions?.map(
                                    (el, index) =>
                                      el.eType === "image" ? (
                                        <React.Fragment
                                          key={el.iChatFileTransactionId}
                                        >
                                          <a
                                            class={`image-box ${
                                              index >= 4 ? "d-none" : ""
                                            }`}
                                            data-fancybox="gallery"
                                            href={el.vFile}
                                          >
                                            <img
                                              className="rounded"
                                              alt=""
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                              }}
                                              src={el?.vFile}
                                            />
                                          </a>
                                        </React.Fragment>
                                      ) : el.eType === "video" ? (
                                        <React.Fragment
                                          key={el.iChatFileTransactionId}
                                        >
                                          <a
                                            class={`image-box ${
                                              index >= 4 ? "d-none" : ""
                                            }`}
                                            data-fancybox="gallery"
                                            href={el.vFile}
                                          >
                                            <video
                                              src={el.vFile}
                                              alt=""
                                              controls
                                            ></video>
                                          </a>
                                        </React.Fragment>
                                      ) : (
                                        <></>
                                      )
                                  )}
                                  <div className="more-image-count">
                                    <span>
                                      +
                                      {item?.chat_file_transactions?.length - 4}
                                    </span>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </React.Fragment>
                        ) : (
                          <> </>
                        )}
                      </React.Fragment>
                    )}
                    <div className="msg-drop-chat">
                      <i
                        className="fas fa-ellipsis-v msgp-drop"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      ></i>
                      <ul className="dropdown-menu msgp-dropdown">
                        <li
                          data-bs-toggle="modal"
                          data-bs-target="#deleteIndividualChatRightSide"
                          onClick={(e) =>
                            handleDeleteIndividualMessageConformation(e, item)
                          }
                        >
                          <button className="dropdown-item">Delete</button>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <div className="msg-details">
                <p className="time">
                  {/* {moment(item?.dAddedTime, "YYYY-MM-DD, h:mm:ss a").format(
                    "h:mm A"
                  )} */}
                  {moment
                    .utc(
                      moment(item?.dAddedDate).format("YYYY-MM-DD") +
                        " " +
                        moment(item?.dAddedTime).format("hh:mm")
                    )
                    .local()
                    .format("h:mm A")}
                </p>
              </div>
              <div
                className="modal delete-chat-modal fade"
                id="deleteIndividualChatRightSide"
                tabIndex="-1"
                aria-labelledby="deleteIndividualRightSideLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        id="deleteIndividualRightSideLabel"
                      >
                        Delete message ?
                      </div>
                    </div>
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn submit-form"
                        onClick={handleDeleteIndividualMessageForEveryone}
                      >
                        Delete For Everyone
                      </button>
                      <button
                        type="button"
                        className="btn submit-form"
                        onClick={handleDeleteIndividualMessageForMe}
                      >
                        Delete For Me
                      </button>
                      <button
                        type="button"
                        className="btn submit-form"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                          setDeleteIndividualMessageTimeDetails("")
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div ref={messagesEndRef} />
    </React.Fragment>
  );
}

export default IndividualLatestChatRightBody;
