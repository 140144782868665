import {
  CREATE_MODULE_PERMISSION_REQUEST,
  CREATE_MODULE_PERMISSION_SUCCESS,
  CREATE_MODULE_PERMISSION_FAILURE,
  STORE_NAME_RESET,
  GET_ALL_PERMISSION_ROLE_REQUEST,
  GET_ALL_PERMISSION_ROLE_SUCCESS,
  GET_ALL_PERMISSION_ROLE_FAILURE,
  GET_PERMISSION_BY_IUSERID_REQUEST,
  GET_PERMISSION_BY_IUSERID_SUCCESS,
  GET_PERMISSION_BY_IUSERID_FAILURE,
  GET_USER_OTHER_ROLE_PERMISSION_REQUEST,
  GET_USER_OTHER_ROLE_PERMISSION_SUCCESS,
  GET_USER_OTHER_ROLE_PERMISSION_FAILURE,
} from "../constants/permission";

const initialState = {};

export function permissionReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_MODULE_PERMISSION_REQUEST:
    case GET_ALL_PERMISSION_ROLE_REQUEST:
    case GET_PERMISSION_BY_IUSERID_REQUEST:
    case GET_USER_OTHER_ROLE_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_MODULE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        createModulePermissionData: action.payload,
      };
    case GET_ALL_PERMISSION_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllPermissionByRoleData: action.payload,
      };
    case GET_PERMISSION_BY_IUSERID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllPermissionByUserData: action.payload,
      };
    case GET_USER_OTHER_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllPermissionByOtherRoleData: action.payload,
      };
    case GET_USER_OTHER_ROLE_PERMISSION_FAILURE:
    case GET_ALL_PERMISSION_ROLE_FAILURE:
    case CREATE_MODULE_PERMISSION_FAILURE:
    case GET_PERMISSION_BY_IUSERID_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case STORE_NAME_RESET:
      return initialState;
    default:
      return state;
  }
}
