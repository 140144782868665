import {
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  GET_ALL_EVENTS_BY_USERID_FAILURE,
  GET_ALL_EVENTS_BY_USERID_REQUEST,
  GET_ALL_EVENTS_BY_USERID_SUCCESS,
  GET_ALL_EVENTS_FAILURE,
  GET_ALL_EVENTS_REQUEST,
  GET_ALL_EVENTS_SUCCESS,
  GET_ALL_FACILTY_FAILURE,
  GET_ALL_FACILTY_REQUEST,
  GET_ALL_FACILTY_SUCCESS,
  RESET_STATE,
  EDIT_EVENT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  REMOVE_TIME_UPDATE_EVENT_REQUEST,
  REMOVE_TIME_UPDATE_EVENT_SUCCESS,
  REMOVE_TIME_UPDATE_EVENT_FAILURE,
  REMOVE_ENTIRE_EVENT_SUCCESS,
  REMOVE_ENTIRE_EVENT_REQUEST,
  REMOVE_ENTIRE_EVENT_FAILURE,
  TEAM_CALENDAR_SIDE_TEAM_SELECTION_SUCCESS,
  TEAM_CALENDAR_SIDE_TEAM_SELECTION_REQUEST,
  TEAM_CALENDAR_SIDE_TEAM_SELECTION_FAILURE,
  EDIT_EVENTS_DETAILS,
  GET_USER_INTEREST_LIST_REQEUST,
  GET_USER_INTEREST_LIST_SCCESS,
  GET_USER_INTEREST_LIST_FAILURE,
} from "../constants/event";

const initialState = {};

export function eventReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_EVENT_REQUEST:
    case GET_ALL_EVENTS_REQUEST:
    case GET_ALL_FACILTY_REQUEST:
    case GET_ALL_EVENTS_BY_USERID_REQUEST:
    case EDIT_EVENT_REQUEST:
    case REMOVE_TIME_UPDATE_EVENT_REQUEST:
    case REMOVE_ENTIRE_EVENT_REQUEST:
    case TEAM_CALENDAR_SIDE_TEAM_SELECTION_REQUEST:
    case GET_USER_INTEREST_LIST_REQEUST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        editEventData: action.payload,
      };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        createEventData: action.payload,
      };
    case GET_ALL_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allEventData: action.payload,
      };
    case GET_ALL_FACILTY_SUCCESS:
      return {
        ...state,
        loading: false,
        allFacilityData: action.payload,
      };
    case GET_ALL_EVENTS_BY_USERID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllEventsByIdData: action.payload,
      };
    case GET_USER_INTEREST_LIST_SCCESS:
      return {
        ...state,
        loading: false,
        getUserInterestData: action.payload,
      };
    case REMOVE_TIME_UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        removeTimeEventData: action.payload,
      };
    case REMOVE_ENTIRE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        removeEntireEventData: action.payload,
      };
    case TEAM_CALENDAR_SIDE_TEAM_SELECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        teamCalendarSideTeamData: action.payload,
      };
    case EDIT_EVENTS_DETAILS:
      return {
        ...state,
        loading: false,
        editEventDetails: action.payload,
      };
    case CREATE_EVENT_FAILURE:
    case GET_ALL_EVENTS_FAILURE:
    case GET_ALL_FACILTY_FAILURE:
    case GET_ALL_EVENTS_BY_USERID_FAILURE:
    case EDIT_EVENT_FAILURE:
    case REMOVE_TIME_UPDATE_EVENT_FAILURE:
    case REMOVE_ENTIRE_EVENT_FAILURE:
    case TEAM_CALENDAR_SIDE_TEAM_SELECTION_FAILURE:
    case GET_USER_INTEREST_LIST_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
