
// export const UPDATE_CITIES_REQUEST = "UPDATE_CITIES_REQUEST";
// export const UPDATE_CITIES_SUCCESS = "UPDATE_CITIES_SUCCESS";
// export const UPDATE_CITIES_FAILURE = "UPDATE_CITIES_FAILURE";

export const GET_ALL_CITIES_REQUEST = "GET_ALL_CITIES_REQUEST";
export const GET_ALL_CITIES_SUCCESS = "GET_ALL_CITIES_SUCCESS";
export const GET_ALL_CITIES_FAILURE = "GET_ALL_CITIES_FAILURE";

export const GET_CITIES_BY_ID_REQUEST = "GET_CITIES_BY_ID_REQUEST";
export const GET_CITIES_BY_ID_SUCCESS = "GET_CITIES_BY_ID_SUCCESS";
export const GET_CITIES_BY_ID_FAILURE = "GET_CITIES_BY_ID_FAILURE";

export const GET_CITIES_LIST_REQUEST = "GET_CITIES_LIST_REQUEST";
export const GET_CITIES_LIST_SUCCESS = "GET_CITIES_LIST_SUCCESS";
export const GET_CITIES_LIST_FAILURE = "GET_CITIES_LIST_FAILURE";

export const RESET = "RESET";
