import {
    INDIVIDAUL_CHAT_USERS_REQUEST,
    INDIVIDAUL_CHAT_USERS_SUCCESS,
    INDIVIDAUL_CHAT_USERS_FAILURE,
    TEAMS_CHAT_LIST_REQUEST,
    TEAMS_CHAT_LIST_SUCCESS,
    TEAMS_CHAT_LIST_FAILURE,
    GET_ALL_ROOM_CHAT_DATA_REQUEST,
    GET_ALL_ROOM_CHAT_DATA_SUCCESS,
    GET_ALL_ROOM_CHAT_DATA_FAILURE,
    CREATE_ROOM_CHAT_DATA_REQUEST,
    CREATE_ROOM_CHAT_DATA_SUCCESS,
    CREATE_ROOM_CHAT_DATA_FAILURE,
    GET_INDIVIDUAL_CHAT_DATA_REQUEST,
    GET_INDIVIDUAL_CHAT_DATA_SUCCESS,
    GET_INDIVIDUAL_CHAT_DATA_FAILURE,
    GET_TEAMS_CHAT_DATA_REQUEST,
    GET_TEAMS_CHAT_DATA_SUCCESS,
    GET_TEAMS_CHAT_DATA_FAILURE,
    SEND_TEAMS_MESSAGE_REQUEST,
    SEND_TEAMS_MESSAGE_SUCCESS,
    SEND_TEAMS_MESSAGE_FAILURE,
    GET_MESSAGE_INFO_TIME_DETAILS_REQUEST,
    GET_MESSAGE_INFO_TIME_DETAILS_SUCCESS,
    GET_MESSAGE_INFO_TIME_DETAILS_FAILURE,
    GET_ALL_TEAMS_GROUP_MEMBERS_REQUEST,
    GET_ALL_TEAMS_GROUP_MEMBERS_SUCCESS,
    GET_ALL_TEAMS_GROUP_MEMBERS_FAILURE,
    DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_REQUEST,
    DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_SUCCESS,
    DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_FAILURE,
    DELETE_CHAT_TEAMS_MESSAGE_REQUEST,
    DELETE_CHAT_TEAMS_MESSAGE_SUCCESS,
    DELETE_CHAT_TEAMS_MESSAGE_FAILURE,
    GET_CHAT_ROOM_MEDIA_REQUEST,
    GET_CHAT_ROOM_MEDIA_SUCCESS,
    GET_CHAT_ROOM_MEDIA_FAILURE,
} from '../constants/newChat';
import axios from 'axios';

export const IndividualChatUsersResult = (values) => async (dispatch) => {
    try {
        dispatch({ type: INDIVIDAUL_CHAT_USERS_REQUEST });
        const resData = await axios.post(`${process.env.REACT_APP_API_URL}/api/Chat/individualChatList`, values);
        dispatch({ type: INDIVIDAUL_CHAT_USERS_SUCCESS, payload: resData.data });
    } catch (err) {
        dispatch({ type: INDIVIDAUL_CHAT_USERS_FAILURE });
    }
}

export const TeamsChatlistResult = (values) => async (dispatch) => {
    try {
        dispatch({ type: TEAMS_CHAT_LIST_REQUEST });
        const resData = await axios.post(`${process.env.REACT_APP_API_URL}/api/chatUserManagement/getAllChatDataByiUserId`, values);
        dispatch({ type: TEAMS_CHAT_LIST_SUCCESS, payload: resData.data });
    } catch (err) {
        dispatch({ type: TEAMS_CHAT_LIST_FAILURE });
    }
}

export const GetAllRoomChatData = (values) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_ROOM_CHAT_DATA_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/Chat/getAllChatData`,
            values
        );
        dispatch({ type: GET_ALL_ROOM_CHAT_DATA_SUCCESS, payload: resData.data });
    } catch (err) {
        dispatch({ type: GET_ALL_ROOM_CHAT_DATA_FAILURE });
    }
};

export const CreateRoomChat = (values) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_ROOM_CHAT_DATA_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/Chat/createChat`,
            values
        );
        dispatch({ type: CREATE_ROOM_CHAT_DATA_SUCCESS, payload: resData.data });
    } catch (err) {
        dispatch({ type: CREATE_ROOM_CHAT_DATA_FAILURE });
    }
}


// ------>>>>> HERE IS NEW WAY TO IMPLEMENT CHAT <<<<<------------

// when the get all the individual user's list then used... 
export const IndividualChatUserLists = (values) => async (dispatch) => {
    try {
        dispatch({ type: INDIVIDAUL_CHAT_USERS_REQUEST });
        const resData = await axios.post(`${process.env.REACT_APP_API_URL}/api/Chat/individualChatList`, values);
        dispatch({ type: INDIVIDAUL_CHAT_USERS_SUCCESS, payload: resData.data });
    } catch (err) {
        dispatch({ type: INDIVIDAUL_CHAT_USERS_FAILURE });
    }
}

//  when the user get all the team chat list then used...
export const GetAllTeamChatList = (values) => async (dispatch) => {
    try {
        dispatch({ type: TEAMS_CHAT_LIST_REQUEST });
        const resData = await axios.post(`${process.env.REACT_APP_API_URL}/api/chatUserManagement/getAllChatDataByiUserId`, values);
        dispatch({ type: TEAMS_CHAT_LIST_SUCCESS, payload: resData.data });
    } catch (err) {
        dispatch({ type: TEAMS_CHAT_LIST_FAILURE });
    }
}

// when the get individual user's chat tne used... 
export const GetIndividualUserChat = (values) => async (dispatch) => {
    try {
        dispatch({ type: GET_INDIVIDUAL_CHAT_DATA_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/Chat/getAllOneToOneChatList`,
            values
        );
        dispatch({ type: GET_INDIVIDUAL_CHAT_DATA_SUCCESS, payload: resData.data });
    } catch (err) {
        dispatch({ type: GET_INDIVIDUAL_CHAT_DATA_FAILURE });
    }
}

// when the get teams chat then used... 
export const GetTeamsUserChat = (values) => async (dispatch) => {
    try {
        dispatch({ type: GET_TEAMS_CHAT_DATA_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/Chat/getAllChatData`,
            values
        );
        dispatch({ type: GET_TEAMS_CHAT_DATA_SUCCESS, payload: resData.data });
    } catch (err) {
        dispatch({ type: GET_TEAMS_CHAT_DATA_FAILURE });
    }
}

// when the send message for the Teams or group then used...
export const SendTeamMessage = (values) => async (dispatch) => {
    dispatch({ type: SEND_TEAMS_MESSAGE_REQUEST });
    try {
        const config = {
            headers: { "content-type": "multipart/form-data" },
        };
        // dispatch({ type: CREATE_MESSAGE_REQUEST });
        const resData = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/Chat/createChat`,
            values,
            config
        );
        dispatch({ type: SEND_TEAMS_MESSAGE_SUCCESS, payload: resData.data });
    } catch (err) {
        dispatch({ type: SEND_TEAMS_MESSAGE_FAILURE });
    }
}

//  when the user want to see the message info and check which
//  people should be seen my messages then used...

export const GetMessageInfoTimeDetails = (values) => async (dispatch) => {
    dispatch({ type: GET_MESSAGE_INFO_TIME_DETAILS_REQUEST })
    try {
        const resData = await axios.post(`${process.env.REACT_APP_API_URL}/api/Chat/MessageSeenOrNot`, values)
        dispatch({ type: GET_MESSAGE_INFO_TIME_DETAILS_SUCCESS, payload: resData.data })

    } catch (err) {
        dispatch({ type: GET_MESSAGE_INFO_TIME_DETAILS_FAILURE })

    }
}

// when the user get group member listing then used...
export const GetAllChatTeamMembers = (values) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_TEAMS_GROUP_MEMBERS_REQUEST });
        const resData = await axios.post(`${process.env.REACT_APP_API_URL}/api/teamMember/getAllChatGroupMemberData`, values);
        dispatch({ type: GET_ALL_TEAMS_GROUP_MEMBERS_SUCCESS, payload: resData.data, });
    } catch (err) {
        dispatch({ type: GET_ALL_TEAMS_GROUP_MEMBERS_FAILURE });
    }
};

// when the user delete Teams message then used... 
export const DeleteTeamChatMessage = (values) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_REQUEST });
        const resData = await axios.post(`${process.env.REACT_APP_API_URL}/api/Chat/groupChatDelete`, values);
        dispatch({ type: DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_SUCCESS, payload: resData.data, });
    } catch (err) {
        dispatch({ type: DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_FAILURE });
    }
}

// when the user delete Individual User's message then used... 
export const DeleteIndividualUserChatMessage = (values) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_CHAT_TEAMS_MESSAGE_REQUEST });
        const resData = await axios.post(`${process.env.REACT_APP_API_URL}/api/Chat/individualChatDelete`
            , values);
        dispatch({ type: DELETE_CHAT_TEAMS_MESSAGE_SUCCESS, payload: resData.data, });
    } catch (err) {
        dispatch({ type: DELETE_CHAT_TEAMS_MESSAGE_FAILURE });
    }
}

// when the user get the sended media list then used... 
export const GetRoomChatMedias = (values) => async (dispatch) => {
    try {
        dispatch({ type: GET_CHAT_ROOM_MEDIA_REQUEST });
        const resData = await axios.post(`${process.env.REACT_APP_API_URL}/api/Chat/getAllMediaData`
            , values);
        dispatch({ type: GET_CHAT_ROOM_MEDIA_SUCCESS, payload: resData.data, });
    } catch (err) {
        dispatch({ type: GET_CHAT_ROOM_MEDIA_FAILURE });
    }
}