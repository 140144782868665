import {
  COMPANY_REQUEST,
  COMPANY_SUCCESS,
  COMPANY_FAILURE,
  APPEARANCE_REQUEST,
  APPEARANCE_SUCCESS,
  APPEARANCE_FAILURE,
} from "../constants/company";
import axios from "axios";

export const GetCompany = (values) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/index/getconfig`,
      values
    );
    dispatch({ type: COMPANY_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: COMPANY_FAILURE });
  }
};

export const GetAppearance = (values) => async (dispatch) => {
  try {
    dispatch({ type: APPEARANCE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/index/getconfig`,
      values
    );
    dispatch({ type: APPEARANCE_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: APPEARANCE_FAILURE });
  }
};
