import React from "react";
import PropsTypes from "prop-types";
import { Dropdown } from "primereact/dropdown";

function DropDown({
  title,
  data,
  name,
  field,
  fieldId,
  isError,
  placeHolder,
  isValidate,
  isSetValue,
  setConditionValue,
  filter,
  newClass,
  isClear,
  disabled,
  onChange,
  changeParams,
}) {
  const [error, setError] = React.useState({ type: false, message: "" });
  const [submitError, setSubmitError] = React.useState(false);
  const [value, SetValue] = React.useState("");
  const [option, setOptions] = React.useState([]);

  React.useEffect(() => {
    let options = [];
    if (data && data.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        options.push({
          value: fieldId ? data[i][fieldId] : data[i],
          label: field ? data[i][field] : data[i],
        });
      }
    }
    if (options.length > 0) {
      setOptions(options);
    }
  }, [data, fieldId, field]);
  // <option value={options.value} >{options.label}</option>

  React.useEffect(() => {
    setSubmitError(isError);
  }, [setSubmitError, isError]);

  React.useEffect(() => {
    if (isSetValue) {
      SetValue(placeHolder);
    }
  }, [SetValue, isSetValue, placeHolder]);

  const handleValidate = React.useCallback(
    (e) => {
      setSubmitError(false);
      if (!e.target.value && isValidate) {
        setError({
          type: true,
          message: `${title} Is Required.`,
        });
        SetValue("");
      } else {
        setError({ type: false, message: "" });
        SetValue(e.target.value);
      }
      if (setConditionValue) {
        setConditionValue(e.target.value);
      }
      if (onChange) {
        onChange(e, changeParams);
      }
    },
    [
      setError,
      isValidate,
      title,
      setConditionValue,
      setSubmitError,
      onChange,
      changeParams,
      SetValue,
    ]
  );

  React.useEffect(() => {
    if (isClear) {
      SetValue("");
    }
  }, [isClear, SetValue]);

  return (
    <>
      <Dropdown
        value={value}
        options={option}
        onChange={handleValidate}
        filter={filter}
        showClear={value ? true : false}
        filterBy="label"
        placeholder={title}
        name={name}
        id={name}
        optionValue={"value"}
        optionLabel={"label"}
        // onBlur={handleValidate}
        className={`form-control d-inline-flex ${newClass}`}
        disabled={disabled || false}
      />
      {isValidate ? (
        submitError ? (
          <p className="error">{title} Is Required.</p>
        ) : error.type ? (
          <p className="error">{error.message} </p>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
}

DropDown.propTypes = {
  title: PropsTypes.any,
  data: PropsTypes.array,
  name: PropsTypes.string,
  isError: PropsTypes.any,
  field: PropsTypes.string,
  fieldId: PropsTypes.string,
  isValidate: PropsTypes.bool,
  isSetValue: PropsTypes.bool,
  placeHolder: PropsTypes.any,
  setConditionValue: PropsTypes.any,
  filter: PropsTypes.bool,
  isClear: PropsTypes.bool,
  newClass: PropsTypes.string,
  onChange: PropsTypes.func
};

export default DropDown;
