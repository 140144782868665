import {
  CREATE_GROUP_EVENT_REQUEST,
  CREATE_GROUP_EVENT_SUCCESS,
  CREATE_GROUP_EVENT_FAILURE,
  GET_ALL_GROUP_EVENTS_REQUEST,
  GET_ALL_GROUP_EVENTS_SUCCESS,
  GET_ALL_GROUP_EVENTS_FAILURE,
  GET_ALL_GROUP_EVENTS_BY_USERID_REQUEST,
  GET_ALL_GROUP_EVENTS_BY_USERID_SUCCESS,
  GET_ALL_GROUP_EVENTS_BY_USERID_FAILURE,
  EDIT_GROUP_EVENT_REQUEST,
  EDIT_GROUP_EVENT_SUCCESS,
  EDIT_GROUP_EVENT_FAILURE,
  REMOVE_TIME_UPDATE_GROUP_EVENT_REQUEST,
  REMOVE_TIME_UPDATE_GROUP_EVENT_SUCCESS,
  REMOVE_TIME_UPDATE_GROUP_EVENT_FAILURE,
  REMOVE_ENTIRE_GROUP_EVENT_REQUEST,
  REMOVE_ENTIRE_GROUP_EVENT_SUCCESS,
  REMOVE_ENTIRE_GROUP_EVENT_FAILURE,
  RESET_STATE,
  GET_GROUP_USER_INTEREST_LIST_REQEUST,
  GET_GROUP_USER_INTEREST_LIST_SCCESS,
  GET_GROUP_USER_INTEREST_LIST_FAILURE,
  EDIT_GROUP_EVENTS_DETAILS,
} from "../constants/groupEvent";

const initialState = {};

export function groupEventReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_GROUP_EVENT_REQUEST:
    case GET_ALL_GROUP_EVENTS_REQUEST:
    case GET_ALL_GROUP_EVENTS_BY_USERID_REQUEST:
    case EDIT_GROUP_EVENT_REQUEST:
    case REMOVE_TIME_UPDATE_GROUP_EVENT_REQUEST:
    case REMOVE_ENTIRE_GROUP_EVENT_REQUEST:
    case GET_GROUP_USER_INTEREST_LIST_REQEUST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_GROUP_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        editGroupEventData: action.payload,
      };
    case CREATE_GROUP_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        createGroupEventData: action.payload,
      };
    case GET_ALL_GROUP_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allGroupEventData: action.payload,
      };
    case GET_ALL_GROUP_EVENTS_BY_USERID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllGroupEventsByIdData: action.payload,
      };
    case GET_GROUP_USER_INTEREST_LIST_SCCESS:
      return {
        ...state,
        loading: false,
        getGroupUserInterestData: action.payload,
      };
    case REMOVE_TIME_UPDATE_GROUP_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        removeTimeGroupEventData: action.payload,
      };
    case REMOVE_ENTIRE_GROUP_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        removeEntireGroupEventData: action.payload,
      };
    case EDIT_GROUP_EVENTS_DETAILS:
      return {
        ...state,
        loading: false,
        editGroupEventDetails: action.payload,
      };
    case CREATE_GROUP_EVENT_FAILURE:
    case GET_ALL_GROUP_EVENTS_FAILURE:
    case GET_ALL_GROUP_EVENTS_BY_USERID_FAILURE:
    case EDIT_GROUP_EVENT_FAILURE:
    case REMOVE_TIME_UPDATE_GROUP_EVENT_FAILURE:
    case REMOVE_ENTIRE_GROUP_EVENT_FAILURE:
    case GET_GROUP_USER_INTEREST_LIST_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
