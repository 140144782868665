import {
    GET_ALL_RECENT_USER_POST_REQUEST,
    GET_ALL_RECENT_USER_POST_SUCCESS,
    GET_ALL_RECENT_USER_POST_FAILURE,
} from '../constants/recentUserPost'

export function RecentUserPostReducer(state = {}, action) {
    switch (action.type) {
        case GET_ALL_RECENT_USER_POST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_ALL_RECENT_USER_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                recentUserPostData: action.payload
            }
        case GET_ALL_RECENT_USER_POST_FAILURE:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}