import {
  ADD_PLAYER_REQUEST,
  ADD_PLAYER_SUCCESS,
  ADD_PLAYER_FAILURE,
  DELETE_PLAYER_REQUEST,
  DELETE_PLAYER_SUCCESS,
  DELETE_PLAYER_FAILURE,
  UPDATE_PLAYER_REQUEST,
  UPDATE_PLAYER_SUCCESS,
  UPDATE_PLAYER_FAILURE,
  TEAM_PLAYER_LIST_REQUEST,
  TEAM_PLAYER_LIST_SUCCESS,
  EXIST_PLAYER_ADD_REQUEST,
  EXIST_PLAYER_ADD_SUCCESS,
  EXIST_PLAYER_ADD_FAILURE,
  VERIFY_PLAYER_EMAIL_REQUEST,
  VERIFY_PLAYER_EMAIL_SUCCESS,
  VERIFY_PLAYER_EMAIL_FAILURE,
  VERIFY_EXIST_PLAYER_EMAIL_REQUEST,
  VERIFY_EXIST_PLAYER_EMAIL_SUCCESS,
  VERIFY_EXIST_PLAYER_EMAIL_FAILURE,
  UPDATE_MEMBER_EMAIL_REQUEST,
  UPDATE_MEMBER_EMAIL_SUCCESS,
  UPDATE_MEMBER_EMAIL_FAILURE,
  UPDATE_MEMBER_PARENT_EMAIL_REQUEST,
  UPDATE_MEMBER_PARENT_EMAIL_SUCCESS,
  UPDATE_MEMBER_PARENT_EMAIL_FAILURE,
  RESEND_MEMBER_EMAIL_REQUEST,
  RESEND_MEMBER_EMAIL_SUCCESS,
  RESEND_MEMBER_EMAIL_FAILURE,
  RESEND_MEMBER_PARENT_EMAIL_REQUEST,
  RESEND_MEMBER_PARENT_EMAIL_SUCCESS,
  RESEND_MEMBER_PARENT_EMAIL_FAILURE,
  VERIFY_PARENT_EMAIL_REQUEST,
  VERIFY_PARENT_EMAIL_SUCCESS,
  VERIFY_PARENT_EMAIL_FAILURE,
} from "../constants/teamPlayer";

import axios from "axios";

export const AddTeamPlayer = (values) => async (dispatch) => {
  try {
    // dispatch({ type: EMAIL_CHECK_REQUEST });
    // const emailcheckRes = await axios.post(
    //   `${process.env.REACT_APP_API_URL}/api/auth/checkEmailExists`,
    //   values
    // );
    // dispatch({ type: EMAIL_CHECK_SUCCESS, payload: emailcheckRes.data });
    // if (emailcheckRes.data.status === true) {
    dispatch({ type: ADD_PLAYER_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/addedSingleAndMultipleMembers`,
      values
    );
    dispatch({ type: ADD_PLAYER_SUCCESS, payload: resData.data });
    // }
  } catch (err) {
    dispatch({ type: ADD_PLAYER_FAILURE });
    // dispatch({ type: EMAIL_CHECK_FAILURE });
  }
};

export const DeletePlayer = (values) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PLAYER_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/deletingMemberData`,
      values
    );
    dispatch({ type: DELETE_PLAYER_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: DELETE_PLAYER_FAILURE });
  }
};

// export const DeletePlayer = (values) => async (dispatch) => {
//   try {
//     dispatch({ type: DELETE_PLAYER_REQUEST });
//     const resData = await axios.post(
//       `${process.env.REACT_APP_API_URL}/api/teamMember/deleteTeamMember`,
//       values
//     );
//     dispatch({ type: DELETE_PLAYER_SUCCESS, payload: resData.data });
//   } catch (err) {
//     dispatch({ type: DELETE_PLAYER_FAILURE });
//   }
// };

export const UpdatePlayer = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PLAYER_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/updateTeamMember`,
      values
    );
    dispatch({ type: UPDATE_PLAYER_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: UPDATE_PLAYER_FAILURE });
  }
};

export const UpdateMemberEmail = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MEMBER_EMAIL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/alreadyCreateMemberSendMailAndValuesUpdate`,
      values
    );
    dispatch({ type: UPDATE_MEMBER_EMAIL_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: UPDATE_MEMBER_EMAIL_FAILURE });
  }
};

export const UpdateMemberParentEmail = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MEMBER_PARENT_EMAIL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/sendingParentMail`,
      values
    );
    dispatch({
      type: UPDATE_MEMBER_PARENT_EMAIL_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: UPDATE_MEMBER_PARENT_EMAIL_FAILURE });
  }
};

export const GetTeamPlayerList = (values) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_PLAYER_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/getAllTeamMemberData`,
      values
    );
    dispatch({ type: TEAM_PLAYER_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: TEAM_PLAYER_LIST_SUCCESS, payload: {} });
  }
};

export const AddExistingTeamPlayer = (values) => async (dispatch) => {
  try {
    dispatch({ type: EXIST_PLAYER_ADD_REQUEST });
    const emailcheck = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/teamMemberExists`,
      values
    );
    dispatch({ type: EXIST_PLAYER_ADD_SUCCESS, payload: emailcheck.data });
    // dispatch({ type: EMAIL_CHECK_SUCCESS, payload: {} });
  } catch (err) {
    dispatch({ type: EXIST_PLAYER_ADD_FAILURE });
  }
};

export const VerifyPlayer = (values) => async (dispatch) => {
  try {
    const data = {
      verifyToken: values,
    };
    dispatch({ type: VERIFY_PLAYER_EMAIL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/verifyUserAndTeamMember`,
      data
    );
    dispatch({ type: VERIFY_PLAYER_EMAIL_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: VERIFY_PLAYER_EMAIL_FAILURE });
  }
};

export const VerifyExistPlayer = (values) => async (dispatch) => {
  try {
    const data = {
      verifyToken: values,
    };
    dispatch({ type: VERIFY_EXIST_PLAYER_EMAIL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/verifyTeamMember`,
      data
    );
    dispatch({
      type: VERIFY_EXIST_PLAYER_EMAIL_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: VERIFY_EXIST_PLAYER_EMAIL_FAILURE });
  }
};

export const ResendMemberEmail = (values) => async (dispatch) => {
  try {
    dispatch({ type: RESEND_MEMBER_EMAIL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/sendMailToMemberAndParent`,
      values
    );
    dispatch({ type: RESEND_MEMBER_EMAIL_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: RESEND_MEMBER_EMAIL_FAILURE });
  }
};

export const ResendParentEmail = (values) => async (dispatch) => {
  try {
    dispatch({ type: RESEND_MEMBER_PARENT_EMAIL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/sendMailToMemberAndParent`,
      values
    );
    dispatch({
      type: RESEND_MEMBER_PARENT_EMAIL_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: RESEND_MEMBER_PARENT_EMAIL_FAILURE });
  }
};

export const VerifyParentEmail = (values) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_PARENT_EMAIL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/sendMailToMemberAndParent`,
      values
    );
    dispatch({
      type: VERIFY_PARENT_EMAIL_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: VERIFY_PARENT_EMAIL_FAILURE });
  }
};
