import {
  GET_ALL_SUB_CATEGORY_DATA_FAILURE,
  GET_ALL_SUB_CATEGORY_DATA_REQUEST,
  GET_ALL_SUB_CATEGORY_DATA_SUCCESS,
} from "../constants/attribute";

export function attributeReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_SUB_CATEGORY_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SUB_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        allSubCategoryData: action.payload,
      };
    case GET_ALL_SUB_CATEGORY_DATA_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
