import {
  RESET,
  FOLLOW_TEAM_REQUEST,
  FOLLOW_TEAM_SUCCESS,
  FOLLOW_TEAM_FAILURE,
} from "../constants/teamFollow";

export function teamFollowReducer(state = {}, action) {
  switch (action.type) {
    case FOLLOW_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FOLLOW_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        followTeamData: action.payload,
      };

    case FOLLOW_TEAM_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET:
      return {};
    default:
      return state;
  }
}
