import {
  GET_INTEREST_LIST_DATA_REQUEST,
  GET_INTEREST_LIST_DATA_SUCCESS,
  GET_INTEREST_LIST_DATA_FAILURE,
  CREATE_USER_INTEREST_REQUEST,
  CREATE_USER_INTEREST_SUCCESS,
  CREATE_USER_INTEREST_FAILURE,
  RESET,
} from "../constants/userInterest";

const initialState = {};

export function userInterestReducer(state = {}, action) {
  switch (action.type) {
    case GET_INTEREST_LIST_DATA_REQUEST:
    case CREATE_USER_INTEREST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_INTEREST_LIST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        allInterestListData: action.payload,
      };
    case CREATE_USER_INTEREST_SUCCESS:
      return {
        ...state,
        loading: false,
        createUserInterestData: action.payload,
      };
    case CREATE_USER_INTEREST_FAILURE:
    case GET_INTEREST_LIST_DATA_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
