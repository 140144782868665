import {
  GET_ALL_FACILITY_REQUEST,
  GET_ALL_FACILITY_SUCCESS,
  GET_ALL_FACILITY_FAILURE,
  CREATE_FACILITY_REQUEST,
  CREATE_FACILITY_SUCCESS,
  CREATE_FACILITY_FAILURE,
  UPDATE_FACILITY_REQUEST,
  UPDATE_FACILITY_SUCCESS,
  UPDATE_FACILITY_FAILURE,
  DELETE_FACILITY_REQUEST,
  DELETE_FACILITY_SUCCESS,
  DELETE_FACILITY_FAILURE,
  GET_FACILITY_BY_FACILITYID_REQUEST,
  GET_FACILITY_BY_FACILITYID_SUCCESS,
  GET_FACILITY_BY_FACILITYID_FAILURE,
  RESET_STATE,
} from "../constants/facility";

const initialState = {};

export function facilityReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_FACILITY_REQUEST:
    case CREATE_FACILITY_REQUEST:
    case UPDATE_FACILITY_REQUEST:
    case DELETE_FACILITY_REQUEST:
    case GET_FACILITY_BY_FACILITYID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_FACILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllFacilityData: action.payload,
      };
    case CREATE_FACILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        createFacilityData: action.payload,
      };
    case UPDATE_FACILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        updateFacilityData: action.payload,
      };
    case DELETE_FACILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteFacilityData: action.payload,
      };
    case GET_FACILITY_BY_FACILITYID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllFacilityByfacilityData: action.payload,
      };

    case GET_ALL_FACILITY_FAILURE:
    case CREATE_FACILITY_FAILURE:
    case UPDATE_FACILITY_FAILURE:
    case DELETE_FACILITY_FAILURE:
    case GET_FACILITY_BY_FACILITYID_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
