export const CREATE_LANGUAGE_REQUEST = "CREATE_LANGUAGE_REQUEST";
export const CREATE_LANGUAGE_SUCCESS = "CREATE_LANGUAGE_SUCCESS";
export const CREATE_LANGUAGE_FAILURE = "CREATE_LANGUAGE_FAILURE";

export const UPDATE_LANGUAGE_REQUEST = "UPDATE_LANGUAGE_REQUEST";
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS";
export const UPDATE_LANGUAGE_FAILURE = "UPDATE_LANGUAGE_FAILURE";


export const DELETE_LANGUAGE_REQUEST = "DELETE_LANGUAGE_REQUEST";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_FAILURE = "DELETE_LANGUAGE_FAILURE";

export const GET_ALL_LANGUAGE_REQUEST = "GET_ALL_LANGUAGE_REQUEST";
export const GET_ALL_LANGUAGE_SUCCESS = "GET_ALL_LANGUAGE_SUCCESS";
export const GET_ALL_LANGUAGE_FAILURE = "GET_ALL_LANGUAGE_FAILURE";

export const GET_LANGUAGE_BY_ID_REQUEST = "GET_LANGUAGE_BY_ID_REQUEST";
export const GET_LANGUAGE_BY_ID_SUCCESS = "GET_LANGUAGE_BY_ID_SUCCESS";
export const GET_LANGUAGE_BY_ID_FAILURE = "GET_LANGUAGE_BY_ID_FAILURE";

export const ALL_LANGUAGE_LIST_REQUEST = "ALL_LANGUAGE_LIST_REQUEST";
export const ALL_LANGUAGE_LIST_SUCCESS = "ALL_LANGUAGE_LIST_SUCCESS";
export const ALL_LANGUAGE_LIST_FAILURE = "ALL_LANGUAGE_LIST_FAILURE";

export const RESET_LANG = "RESET_LANG";