import axios from "axios";
import {
  IS_CONFIRM_FOLLOW_TO_USER_REQUEST,
  IS_CONFIRM_FOLLOW_TO_USER_SUCCESS,
  IS_CONFIRM_FOLLOW_TO_USER_FAILURE,
  IS_DELETE_USER_COMING_REQUEST_REQUEST,
  IS_DELETE_USER_COMING_REQUEST_SUCCESS,
  IS_DELETE_USER_COMING_REQUEST_FAILURE,
  IS_USER_CANCEL_REQUEST_REQUEST,
  IS_USER_CANCEL_REQUEST_SUCCESS,
  IS_USER_CANCEL_REQUEST_FAILURE,
  IS_FOLLOW_NEW_USER_REQUEST,
  IS_FOLLOW_NEW_USER_SUCCESS,
  IS_FOLLOW_NEW_USER_FAILURE,
  IS_REMOVE_USER_FOR_SUGGESTION_REQUEST,
  IS_REMOVE_USER_FOR_SUGGESTION_SUCCESS,
  IS_REMOVE_USER_FOR_SUGGESTION_FAILURE,
  IS_UNFOLLOW_USER_REQUEST,
  IS_UNFOLLOW_USER_SUCCESS,
  IS_UNFOLLOW_USER_FAILURE,
  IS_BLOCK_USER_REQUEST,
  IS_BLOCK_USER_SUCCESS,
  IS_BLOCK_USER_FAILURE,
  IS_FOLLOW_BACK_USER_REQUEST,
  IS_FOLLOW_BACK_USER_SUCCESS,
  IS_FOLLOW_BACK_USER_FAILURE,
  ALL_COMING_REQUEST_FOR_USER_REQUEST,
  ALL_COMING_REQUEST_FOR_USER_SUCCESS,
  ALL_COMING_REQUEST_FOR_USER_FAILURE,
  ALL_REQUESTED_USERS_REQUEST,
  ALL_REQUESTED_USERS_SUCCESS,
  ALL_REQUESTED_USERS_FAILURE,
  ALL_USERS_SUGGESTIONS_REQUEST,
  ALL_USERS_SUGGESTIONS_SUCCESS,
  ALL_USERS_SUGGESTIONS_FAILURE,
  ALL_USER_FOLLOWERS_REQUEST,
  ALL_USER_FOLLOWERS_SUCCESS,
  ALL_USER_FOLLOWERS_FAILURE,
  ALL_USER_FOLLOWING_LIST_REQUEST,
  ALL_USER_FOLLOWING_LIST_SUCCESS,
  ALL_USER_FOLLOWING_LIST_FAILURE,
  ALL_SEARCHED_USER_LIST_REQUEST,
  ALL_SEARCHED_USER_LIST_SUCCESS,
  ALL_SEARCHED_USER_LIST_FAILURE,
  DIRECT_FOLLOW_USER_REQUEST,
  DIRECT_FOLLOW_USER_SUCCESS,
  DIRECT_FOLLOW_USER_FAILURE,
  RESET,
  GET_ALL_FOLLOWER_USER_REQUEST,
  GET_ALL_FOLLOWER_USER_SUCCESS,
  GET_ALL_FOLLOWER_USER_FAILURE,
  GET_ALL_FOLLOW_FOLLOWING_COUNT_REQUEST,
  GET_ALL_FOLLOW_FOLLOWING_COUNT_SUCCESS,
  GET_ALL_FOLLOW_FOLLOWING_COUNT_FAILURE,
  GET_ALL_FOLLOW_STATUS_WISE_USERS_REQUEST,
  GET_ALL_FOLLOW_STATUS_WISE_USERS_SUCCESS,
  GET_ALL_FOLLOW_STATUS_WISE_USERS_FAILURE,
  ALL_USER_FOLLOW_FOLLOWERS_REQUEST,
  ALL_USER_FOLLOW_FOLLOWERS_SUCCESS,
  ALL_USER_FOLLOW_FOLLOWERS_FAILURE,
  GET_TEAM_FOLLOW_REQUEST,
  GET_TEAM_FOLLOW_SUCCESS,
  GET_TEAM_FOLLOW_FAILURE,
  GET_ALL_TEAM_FOLLOWERS_REQUEST,
  GET_ALL_TEAM_FOLLOWERS_SUCCESS,
  GET_ALL_TEAM_FOLLOWERS_FAILURE,
} from "../constants/follow";

// acceptAndDeleteRequest

// when the user confirm requst then used...
export const ConfirmUserRequest = (values) => async (dispatch) => {
  dispatch({ type: IS_CONFIRM_FOLLOW_TO_USER_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/acceptAndDeleteRequest`,
      values
    );
    dispatch({
      type: IS_CONFIRM_FOLLOW_TO_USER_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: IS_CONFIRM_FOLLOW_TO_USER_FAILURE });
  }
};

// when the user delete the coming request then used...
export const DeleteUserComingRequest = (values) => async (dispatch) => {
  // IS_DELETE_USER_COMING_REQUEST_REQUEST
  dispatch({ type: IS_DELETE_USER_COMING_REQUEST_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/DeclineAndRequested`,
      values
    );
    dispatch({
      type: IS_DELETE_USER_COMING_REQUEST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: IS_DELETE_USER_COMING_REQUEST_FAILURE });
  }
};

// when the user send request and cancel request then used...
export const CancelUserRequest = (values) => async (dispatch) => {
  // IS_USER_CANCEL_REQUEST_REQUEST
  dispatch({ type: IS_USER_CANCEL_REQUEST_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/createOrCancelFollowRequest`,
      values
    );
    dispatch({ type: IS_USER_CANCEL_REQUEST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: IS_USER_CANCEL_REQUEST_FAILURE });
  }
};

//  when the user follow to new user then used...
export const FollowNewUser = (values) => async (dispatch) => {
  // IS_FOLLOW_NEW_USER_REQUEST

  dispatch({ type: IS_FOLLOW_NEW_USER_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/createOrCancelFollowRequest`,
      values
    );
    dispatch({ type: IS_FOLLOW_NEW_USER_SUCCESS, payload: resData.data });
    dispatch(
      GetAllFollowStatusWiseUsers({ iUserId: sessionStorage.getItem("id") })
    );
  } catch (err) {
    dispatch({ type: IS_FOLLOW_NEW_USER_FAILURE });
  }
};

// when the user Remove for the suggestion then used...
export const RemoveUserForSuggestionList = (values) => async (dispatch) => {
  // IS_REMOVE_USER_FOR_SUGGESTION_REQUEST

  dispatch({ type: IS_REMOVE_USER_FOR_SUGGESTION_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}`,
      values
    );
    dispatch({
      type: IS_REMOVE_USER_FOR_SUGGESTION_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: IS_REMOVE_USER_FOR_SUGGESTION_FAILURE });
  }
};

// when the user unfollow following user then used...
export const UnfollowUser = (values) => async (dispatch) => {
  // IS_UNFOLLOW_USER_REQUEST

  dispatch({ type: IS_UNFOLLOW_USER_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/UnFollowFollowingUser`,
      values
    );
    dispatch({ type: IS_UNFOLLOW_USER_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: IS_UNFOLLOW_USER_FAILURE });
  }
};

// when the user block the another user then used...
export const BlockUser = (values) => async (dispatch) => {
  // IS_BLOCK_USER_REQUEST

  dispatch({ type: IS_BLOCK_USER_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}`,
      values
    );
    dispatch({ type: IS_BLOCK_USER_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: IS_BLOCK_USER_FAILURE });
  }
};

// when the user follow back  the another user then used...
export const FollowBackUser = (values) => async (dispatch) => {
  // IS_BLOCK_USER_REQUEST

  dispatch({ type: IS_FOLLOW_BACK_USER_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/FollowBackUserRequest`,
      values
    );
    dispatch({ type: IS_FOLLOW_BACK_USER_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: IS_FOLLOW_BACK_USER_FAILURE });
  }
};

// when the user get all the comming request then used...
export const GetAllComingRequest = (values) => async (dispatch) => {
  // ALL_COMING_REQUEST_FOR_USER_REQUEST

  dispatch({ type: ALL_COMING_REQUEST_FOR_USER_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/CommingFollowReuestList`,
      values
    );
    dispatch({
      type: ALL_COMING_REQUEST_FOR_USER_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: ALL_COMING_REQUEST_FOR_USER_FAILURE });
  }
};

// when the user get all the request user list then used...
export const GetAllRequestedUsersList = (values) => async (dispatch) => {
  // ALL_REQUESTED_USERS_REQUEST
  dispatch({ type: ALL_REQUESTED_USERS_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/SendFollowRequestList`,
      values
    );
    dispatch({ type: ALL_REQUESTED_USERS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: ALL_REQUESTED_USERS_FAILURE });
  }
};

// when the user get all the suggestion list then used...
export const GetAllSuggestedUsers = (values) => async (dispatch) => {
  // ALL_USERS_SUGGESTIONS_REQUEST

  dispatch({ type: ALL_USERS_SUGGESTIONS_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/getAllUserData`,
      values
    );
    dispatch({ type: ALL_USERS_SUGGESTIONS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: ALL_USERS_SUGGESTIONS_FAILURE });
  }
};

// when the get all user's follower list then used...
export const GetAllUserFollowers = (values) => async (dispatch) => {
  // ALL_USER_FOLLOWERS_REQUEST

  dispatch({ type: ALL_USER_FOLLOWERS_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/MyFollowersUserCount`,
      values
    );
    dispatch({ type: ALL_USER_FOLLOWERS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: ALL_USER_FOLLOWERS_FAILURE });
  }
};

export const GetAllUserFollowFollowers = (values) => async (dispatch) => {
  // ALL_USER_FOLLOWERS_REQUEST

  dispatch({ type: ALL_USER_FOLLOW_FOLLOWERS_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/getAllFollowAndFollowingUserList`,
      values
    );
    dispatch({
      type: ALL_USER_FOLLOW_FOLLOWERS_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: ALL_USER_FOLLOW_FOLLOWERS_FAILURE });
  }
};
// when the get all user's following list then used...
export const GetAllUserFollowingList = (values) => async (dispatch) => {
  // ALL_USER_FOLLOWING_LIST_REQUEST

  dispatch({ type: ALL_USER_FOLLOWING_LIST_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/followUserCount`,
      values
    );
    dispatch({ type: ALL_USER_FOLLOWING_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: ALL_USER_FOLLOWING_LIST_FAILURE });
  }
};

// when the suggested user with searching  /api/follower/searchUserDetails
export const GetAllSearchedUserDetails = (values) => async (dispatch) => {
  dispatch({ type: ALL_SEARCHED_USER_LIST_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/searchUserDetails`,
      values
    );
    dispatch({ type: ALL_SEARCHED_USER_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: ALL_SEARCHED_USER_LIST_FAILURE });
  }
};

//  when the both user private then direct follow then used...
export const DirectFollowUser = (values) => async (dispatch) => {
  // api/follower/directFollow
  dispatch({ type: DIRECT_FOLLOW_USER_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/directFollow`,
      values
    );
    dispatch({ type: DIRECT_FOLLOW_USER_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: DIRECT_FOLLOW_USER_FAILURE });
  }
};

export const GetAllFollowerByiUserId = (values) => async (dispatch) => {
  dispatch({ type: GET_ALL_FOLLOWER_USER_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/getAllFollowerByiUserId`,
      values
    );
    dispatch({ type: GET_ALL_FOLLOWER_USER_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_FOLLOWER_USER_FAILURE });
  }
};

export const GetAllTeamFollowers = (values) => async (dispatch) => {
  dispatch({ type: GET_ALL_TEAM_FOLLOWERS_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Team/getAllTeamsFollowingUserList`,
      values
    );
    dispatch({ type: GET_ALL_TEAM_FOLLOWERS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_TEAM_FOLLOWERS_FAILURE });
  }
};

export const ResetFollowState = (values) => async (dispatch) => {
  dispatch({ type: RESET });
};

// when the get all the user follower and following and coming request count then used...
export const FollowFollowingCount = (values) => async (dispatch) => {
  dispatch({ type: GET_ALL_FOLLOW_FOLLOWING_COUNT_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/getAllFollowAndFollowersCount`,
      values
    );
    dispatch({
      type: GET_ALL_FOLLOW_FOLLOWING_COUNT_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_FOLLOW_FOLLOWING_COUNT_FAILURE });
  }
};

export const GetAllFollowStatusWiseUsers = (values) => async (dispatch) => {
  dispatch({ type: GET_ALL_FOLLOW_STATUS_WISE_USERS_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/postfollowing`,
      values
    );
    dispatch({
      type: GET_ALL_FOLLOW_STATUS_WISE_USERS_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_FOLLOW_STATUS_WISE_USERS_FAILURE });
  }
};

export const GetTeamFollowStatus = (values) => async (dispatch) => {
  dispatch({ type: GET_TEAM_FOLLOW_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/follower/postfollowing`,
      values
    );
    dispatch({
      type: GET_TEAM_FOLLOW_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_TEAM_FOLLOW_FAILURE });
  }
};
