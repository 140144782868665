export const EVENT_SCREENS = {
  teams: {
    event_name: "teams",
    event_description: " Viewed team listing",
  },
  team_area: {
    event_name: "team_area",
    event_description: "Viewed team's detail",
  },
  create_team: {
    event_name: "create_team",
    event_description: "Goes for to create team",
  },
  create_organization: {
    event_name: "create_organization",
    event_description: "Goes for to create organization",
  },
  user_details: {
    event_name: "user_details",
    event_description: "Checks other user's profile",
  },
  organization_details: {
    event_name: "organization_details",
    event_description: "Viewed organization details",
  },
  organization_settings: {
    event_name: "organization_settings",
    event_description: "Goes on organization setting",
  },
  organization_details_about: {
    event_name: "organization_details_about",
    event_description: "Viewed organization details",
  },
  team_post: {
    event_name: "team_post",
    event_description: "Creating team post",
  },
  team_members: {
    event_name: "team_members",
    event_description: "Viewing members of a team",
  },
  team_followers: {
    event_name: "team_followers",
    event_description: "Viewing followers of a team",
  },
  organization_calendar: {
    event_name: "organization_calendar",
    event_description: "Opening organization's calendar",
  },
  submit_organization_attendance: {
    event_name: "submit_organization_attendance",
    event_description:
      "Submitting their attendance for the organization calendar event",
  },
  create_master_event: {
    event_name: "create_master_event",
    event_description: "Creating event from master calendar",
  },
  create_master_practice: {
    event_name: "create_master_practice",
    event_description: "Creating practice from master calendar",
  },
  create_master_game: {
    event_name: "create_master_game",
    event_description: "Creating game from master calendar",
  },
  team_playbook: {
    event_name: "team_playbook",
    event_description: "Viewing list of team playbook",
  },
  submit_team_attendance: {
    event_name: "submit_team_attendance",
    event_description:
      "Submitting their attendance for the team calendar event",
  },
  team_videos: {
    event_name: "team_videos",
    event_description: "Viewing list of team videos",
  },
  add_coach: {
    event_name: "add_coach",
    event_description: "Adding coach for the team",
  },
  edit_coach: {
    event_name: "edit_coach",
    event_description: "Editing coach for the team",
  },
  add_player: {
    event_name: "add_player",
    event_description: "Adding player for the team",
  },
  edit_player: {
    event_name: "edit_player",
    event_description: "Editing player for the team",
  },
  team_calendar: {
    event_name: "team_calendar",
    event_description: "Viewing team calendar",
  },
  create_team_event: {
    event_name: "create_team_event",
    event_description: "Creating event for team",
  },
  create_team_practice: {
    event_name: "create_team_practice",
    event_description: "Creating practice for team",
  },
  create_team_game: {
    event_name: "create_team_game",
    event_description: "Creating game for team",
  },
  notification: {
    event_name: "notification",
    event_description: "Viewing notifications list",
  },
  chat_listing: {
    event_name: "chat_listing",
    event_description: "Viewing listing either individual or team",
  },
  chat: {
    event_name: "chat",
    event_description: "Chatting with another user",
  },
  chat_media: {
    event_name: "chat_media",
    event_description: "Viewing media which is sent in chat",
  },
  group_feed: {
    event_name: "group_feed",
    event_description: "Viewing  group feeds",
  },
  group_feed_comment: {
    event_name: "group_feed_comment",
    event_description: "Commenting on the group post",
  },
  group_feed_report: {
    event_name: "group_feed_report",
    event_description: "Reporting a group post",
  },
  create_group_post: {
    event_name: "create_group_post",
    event_description: "Creating a group post",
  },
  create_group: {
    event_name: "create_group",
    event_description: "Creating a group",
  },
  all_groups: {
    event_name: "all_groups",
    event_description: "Viewing group listing",
  },
  group_details: {
    event_name: "group_details",
    event_description: "Viewing group details",
  },
  group_members: {
    event_name: "group_members",
    event_description: "Viewing group members",
  },
  add_member: {
    event_name: "add_member",
    event_description: "Adding members to group",
  },
  group_calendar: {
    event_name: "group_calendar",
    event_description: "Viewing group calendar",
  },
  create_group_event: {
    event_name: "create_group_event",
    event_description: "Creating event for group calendar",
  },
  group_settings: {
    event_name: "group_settings",
    event_description: "Checking group setting",
  },
  group_all_members: {
    event_name: "group_all_members",
    event_description: "Viewing group members",
  },
  group_banned_members: {
    event_name: "group_banned_members",
    event_description: "Viewing banned group members",
  },
  group_reported_posts: {
    event_name: "group_reported_posts",
    event_description: "Viewing reported group posts",
  },
  my_plays: {
    event_name: "my_plays",
    event_description: "Viewing personal plays",
  },
  team_plays: {
    event_name: "team_plays",
    event_description: "Viewing team plays",
  },
  create_my_play: {
    event_name: "create_my_play",
    event_description: "Creating personal play",
  },
  create_team_play: {
    event_name: "create_team_play",
    event_description: "Creating team play",
  },
  create_my_whiteboard: {
    event_name: "create_my_whiteboard",
    event_description: "Viewing personal whiteboard",
  },
  create_team_whiteboard: {
    event_name: "create_team_whiteboard",
    event_description: "Viewing team whiteboard",
  },
  create_my_folder: {
    event_name: "create_my_folder",
    event_description: "Viewing personal folder",
  },
  create_team_folder: {
    event_name: "create_team_folder",
    event_description: "Viewing team folder",
  },
  personal_calendar: {
    event_name: "personal_calendar",
    event_description: "Viewing personal calendar",
  },
  create_personal_event: {
    event_name: "create_personal_event",
    event_description: "Creating personal event",
  },
  update_personal_event: {
    event_name: "update_personal_event",
    event_description: "Updating personal event",
  },
  delete_personal_event: {
    event_name: "delete_personal_event",
    event_description: "Deleting personal event",
  },
  message_board: {
    event_name: "message_board",
    event_description: "Viewing message-board feeds",
  },
  report_message_board_post: {
    event_name: "report_message_board_post",
    event_description: "Reporting message-board post",
  },
  message_board_comments: {
    event_name: "message_board_comments",
    event_description: "Commenting on a message-board post",
  },
  create_message_board_post: {
    event_name: "create_message_board_post",
    event_description: "Creating a message-board post",
  },
  message_board_all_members: {
    event_name: "message_board_all_members",
    event_description: "Viewing message-board all members",
  },
  message_board_banned_members: {
    event_name: "message_board_banned_members",
    event_description: "Viewing banned message-board members",
  },
  message_board_reported_posts: {
    event_name: "message_board_reported_posts",
    event_description: "Viewing message-board's reported posts",
  },
  social: {
    event_name: "social",
    event_description: "Viewing social posts",
  },
  comment: {
    event_name: "comment",
    event_description: "Commenting on a social post",
  },
  report_social_post: {
    event_name: "report_social_post",
    event_description: "Reporting on the social post",
  },
  create_social_post: {
    event_name: "create_social_post",
    event_description: "Creating for social",
  },
  share: {
    event_name: "share",
    event_description: "Sharing a social post",
  },
  // burger_menu: {
  //   event_name: "burger_menu",
  //   event_description: "Viewing side menu",
  // },
  edit_bio: {
    event_name: "edit_bio",
    event_description: "Editing their bio",
  },
  edit_profile: {
    event_name: "edit_profile",
    event_description: "Editing personal info",
  },
  settings: {
    event_name: "settings",
    event_description: "On the Setting screen",
  },
  general_settings: {
    event_name: "general_settings",
    event_description: "Changing general settings",
  },
  change_password: {
    event_name: "change_password",
    event_description: "Changing password",
  },
  two_step_verification: {
    event_name: "two_step_verification",
    event_description: "Enabling/Disabling two step verification",
  },
  delete_account: {
    event_name: "delete_account",
    event_description: "Deleting account",
  },
  team_highlight: {
    event_name: "team_highlight",
    event_description: "Adding/Removing team from hightlight",
  },
  followers: {
    event_name: "followers",
    event_description: "Viewng followers",
  },
  general_all_search: {
    event_name: "general_all_search",
    event_description: "Searching for all",
  },
  general_post_search: {
    event_name: "general_post_search",
    event_description: "Searching for post",
  },
  general_people_search: {
    event_name: "general_people_search",
    event_description: "Searching for people",
  },
  general_teams_search: {
    event_name: "general_teams_search",
    event_description: "Searching for team",
  },
  thank_you: {
    event_name: "thank_you",
    event_description: "Seeing thankyou message after registration",
  },
};
