export const GET_ALL_FACILITY_REQUEST = 'GET_ALL_FACILITY_REQUEST'
export const GET_ALL_FACILITY_SUCCESS = 'GET_ALL_FACILITY_SUCCESS'
export const GET_ALL_FACILITY_FAILURE = 'GET_ALL_FACILITY_FAILURE'

export const CREATE_FACILITY_REQUEST = 'CREATE_FACILITY_REQUEST'
export const CREATE_FACILITY_SUCCESS = 'CREATE_FACILITY_SUCCESS'
export const CREATE_FACILITY_FAILURE = 'CREATE_FACILITY_FAILURE'

export const UPDATE_FACILITY_REQUEST = 'UPDATE_FACILITY_REQUEST'
export const UPDATE_FACILITY_SUCCESS = 'UPDATE_FACILITY_SUCCESS'
export const UPDATE_FACILITY_FAILURE = 'UPDATE_FACILITY_FAILURE'

export const DELETE_FACILITY_REQUEST = 'DELETE_FACILITY_REQUEST'
export const DELETE_FACILITY_SUCCESS = 'DELETE_FACILITY_SUCCESS'
export const DELETE_FACILITY_FAILURE = 'DELETE_FACILITY_FAILURE'

export const GET_FACILITY_BY_FACILITYID_REQUEST = 'GET_FACILITY_BY_FACILITYID_REQUEST'
export const GET_FACILITY_BY_FACILITYID_SUCCESS = 'GET_FACILITY_BY_FACILITYID_SUCCESS'
export const GET_FACILITY_BY_FACILITYID_FAILURE = 'GET_FACILITY_BY_FACILITYID_FAILURE'

export const RESET_STATE = 'RESET_STATE'