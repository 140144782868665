import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PropsTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllNotificationList,
  GetGroupNotificationCount,
  GetMessageBoardNotificationCount,
  GetNotificationCount,
} from "../../../store/action/notificationsAction";
import { useDayChecker } from "../../../hooks/useDayChecker";
import NotificationFollowButtons from "./TopBanner/NotificationFollowButtons";
import axios from "axios";
import NotificationUserInviteButtons from "./TopBanner/NotificationUserInviteButtons";
import { VIEW_MORET_TIME_POST_ID } from "../../../store/constants/post";
// import NotificationEventDetail from "./NotificationEventDetail";
import { SetEventNotificationDetails } from "../../../store/action/otherAction";

function TopBanner() {
  // function TopBanner({ isNotificationClose, setIsNotificationClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAllNotificationsData, getNotificationCountData } = useSelector(
    (state) => state.notifications
  );
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);

  React.useEffect(() => {
    dispatch(
      GetAllNotificationList({ iVisibleId: sessionStorage.getItem("id") })
    );
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(
      GetNotificationCount({ iVisibleId: sessionStorage.getItem("id") })
    );
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(
      GetMessageBoardNotificationCount({
        iVisibleId: sessionStorage.getItem("id"),
      })
    );
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(
      GetGroupNotificationCount({ iVisibleId: sessionStorage.getItem("id") })
    );
  }, [dispatch]);

  // React.useEffect(() => {
  //   if (isNotificationClose && isNotificationOpen) {
  //     setIsNotificationOpen(false);
  //     setIsNotificationClose(false);
  //   }
  // }, [isNotificationClose, setIsNotificationClose, isNotificationOpen]);

  const handleNotificationMove = React.useCallback(
    (item) => {
      const logInUserId = sessionStorage.getItem("id");
      if (item.iUserId.toString() === logInUserId) {
        navigate("/profile");
      } else {
        navigate(`/auth/${item.user.vPersonalSlug}`);
      }
    },
    [navigate]
  );

  const handlePostClick = React.useCallback(
    (item) => {
      dispatch({
        type: VIEW_MORET_TIME_POST_ID,
        payload: {
          isShowModal: true,
          POST_ID: item.iPostId,
          renderedApiTabName: "",
        },
      });
      window.$("#commentbox").modal("show");
    },
    [dispatch]
  );

  const handleEventClick = React.useCallback(
    (item) => {
      if (Object.keys(item).length > 0 && item.calendar) {
        dispatch(SetEventNotificationDetails(item.calendar));
        window.$("#notificationEventDetails").modal("show");
      }
    },
    [dispatch]
  );

  const NotificationBlock = ({ item }) => {
    // console.log("++++ NotificationBlock", item);
    return (
      <div className="box">
        <div className="image-box">
          {item.user && Object.keys(item.user).length > 0 ? (
            <img
              className="set-cursor-pointer"
              onClick={
                () => handleNotificationMove(item)

                // navigate(`/auth/${item.user.vPersonalSlug}`);
              }
              src={
                item.user.vImage && item.user.vImage.split("/").pop() !== null
                  ? item.user.vImage
                  : `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`
              }
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
              }}
            />
          ) : item.team && Object.keys(item.team).length > 0 ? (
            <img
              className="set-cursor-pointer"
              onClick={() => {
                sessionStorage.setItem("teamId", item.team.iParentId);
                navigate(`/team/auth/${item.team.vTeamSlug}`, {
                  state: {
                    iTeamId: item.team.iParentId,
                  },
                });
              }}
              src={
                item.team.vTeamLogo &&
                item.team.vTeamLogo.split("/").pop() !== null
                  ? item.team.vTeamLogo
                  : `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`
              }
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="notifi-msg">
          <div className="msg-p">
            {item.eNotificationType === "teamInvite" ? (
              <>
                <span
                  onClick={
                    item.eNotificationType === "post"
                      ? () => handlePostClick(item)
                      : () => {}
                  }
                  className={
                    item.eNotificationType === "post"
                      ? "msg-text set-cursor-pointer"
                      : ""
                  }
                >
                  {item.tMessage}
                </span>
                <span
                  className="user-name set-cursor-pointer"
                  onClick={
                    item.user && Object.keys(item.user).length > 0
                      ? () => {
                          handleNotificationMove(item);
                          // navigate(`/auth/${item.user.vPersonalSlug}`);
                        }
                      : item.team && Object.keys(item.team).length > 0
                      ? () => {
                          sessionStorage.setItem("teamId", item.team.iParentId);
                          navigate(`/team/auth/${item.team.vTeamSlug}`, {
                            state: {
                              iTeamId: item.team?.iParentId,
                            },
                          });
                        }
                      : () => {}
                  }
                >
                  {item.user && Object.keys(item.user).length > 0
                    ? `${item.user.vFirstName} ${
                        item.user.vLastName && item.user.vLastName.length > 0
                          ? item.user.vLastName
                          : ""
                      } `
                    : item.team && Object.keys(item.team).length > 0
                    ? item.team.tTeamTagline
                    : ""}
                </span>
              </>
            ) : (
              <>
                <span
                  className="user-name set-cursor-pointer"
                  onClick={
                    item.user && Object.keys(item.user).length > 0
                      ? () => {
                          handleNotificationMove(item);
                          // navigate(`/auth/${item.user.vPersonalSlug}`);
                        }
                      : item.team && Object.keys(item.team).length > 0
                      ? () => {
                          sessionStorage.setItem("teamId", item.team.iParentId);
                          navigate(`/team/auth/${item.team.vTeamSlug}`, {
                            state: {
                              iTeamId: item.team?.iParentId,
                            },
                          });
                        }
                      : () => {}
                  }
                >
                  {item.user && Object.keys(item.user).length > 0
                    ? `${item.user.vFirstName} ${
                        item.user.vLastName && item.user.vLastName.length > 0
                          ? item.user.vLastName
                          : ""
                      } `
                    : item.team && Object.keys(item.team).length > 0
                    ? item.team.tTeamTagline
                    : ""}
                </span>
                <span
                  onClick={
                    item.eNotificationType === "post"
                      ? () => handlePostClick(item)
                      : item.eNotificationType === "event"
                      ? () => handleEventClick(item)
                      : () => {}
                  }
                  className={
                    item.eNotificationType === "post" ||
                    item.eNotificationType === "event"
                      ? "msg-text set-cursor-pointer"
                      : ""
                  }
                >
                  {item.tMessage}
                </span>
              </>
            )}

            <span className="notifi-time">
              {useDayChecker(item.dtAddedDate)} ago
            </span>
          </div>
          {item.user &&
          Object.keys(item.user).length > 0 &&
          item.eNotificationType === "follow" ? (
            <NotificationFollowButtons item={item} />
          ) : (
            <></>
          )}
          {item.eNotificationType === "teamInvite" &&
          item.vAuthCode !== null &&
          item.iRoleId !== null ? (
            <NotificationUserInviteButtons item={item} />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const handleNotificationSeen = React.useCallback(async () => {
    // dispatch(SetNotificationSeen({ iVisibleId: sessionStorage.getItem("id") }));
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/notification/seenNotification`,
      { iVisibleId: sessionStorage.getItem("id") }
    );
    if (resData.status === 200 && resData.data && resData.data.code === "200") {
      dispatch(
        GetAllNotificationList({ iVisibleId: sessionStorage.getItem("id") })
      );
      dispatch(
        GetNotificationCount({ iVisibleId: sessionStorage.getItem("id") })
      );
    }
    setIsNotificationOpen(!isNotificationOpen);
  }, [dispatch, isNotificationOpen]);

  return (
    <div className="top-header login-top-header" id={"notification-btn"}>
      <div className="container-fluid">
        <div className="top-header-inner">
          <div className="left-option">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#quicksidebarContent"
              aria-controls="quicksidebarContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <i className="fas fa-chevron-right"></i> */}
              <i className="fal fa-horizontal-rule"></i>
              <i className="fal fa-horizontal-rule"></i>
            </button>
            {/* <ul>
              <li>
                <Link to={"/profile"}>Team Management</Link>
              </li>
              <li>
                <Link to={"/profile"}>Interactive Playbook</Link>
              </li>
              <li>
                <Link to={"/profile"}>Social Media</Link>
              </li>
            </ul> */}
          </div>
          <div className="top-header-right">
            <div
              className={
                getNotificationCountData &&
                getNotificationCountData.code === "200" &&
                getNotificationCountData.data &&
                getNotificationCountData.data.eSeenCount &&
                getNotificationCountData.data.eSeenCount > 0
                  ? "notification-btn active"
                  : "notification-btn"
              }
            >
              {getNotificationCountData &&
              getNotificationCountData.code === "200" &&
              getNotificationCountData.data &&
              getNotificationCountData.data.eSeenCount ? (
                getNotificationCountData.data.eSeenCount === 0 ? (
                  <></>
                ) : (
                  <span className="notification-count">
                    {getNotificationCountData.data.eSeenCount}sss
                  </span>
                )
              ) : (
                <></>
              )}

              <img
                className="no-notification"
                src="/assets/img/system-outline-46-notification-bell.png"
                onClick={() => setIsNotificationOpen(!isNotificationOpen)}
                alt="notification"
              />
              <img
                className="in-notification"
                src="/assets/img/system-outline-46-notification-bell.gif"
                onClick={handleNotificationSeen}
                alt="notification"
              />
              {isNotificationOpen ? (
                <div className="notification-box">
                  <div className="title-top">
                    <h3>Notifications</h3>
                  </div>
                  <div className="inner-box">
                    {getAllNotificationsData &&
                    getAllNotificationsData.code === "200" &&
                    getAllNotificationsData.data &&
                    getAllNotificationsData.data.length > 0 ? (
                      getAllNotificationsData.data
                        .slice(0, 19)
                        .map((item, key) => (
                          <NotificationBlock item={item} key={key} />
                        ))
                    ) : (
                      <div className="box">
                        <div className="image-box">
                          <img
                            src={"/assets/img/loader.png"}
                            alt=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                            }}
                          />
                        </div>
                        <div className="notifi-msg">
                          <div className="msg-p">
                            <span className="msg-text">
                              Welcome To Yo Playbook
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <Link className="cart-box" to={"/cart"}>
              <div className="image-left">
                <span className="image-box">
                  <img
                    src="/assets/img/feather-shopping-cart.svg"
                    width="15"
                    height="15"
                    alt="shopping-cart"
                    title="Cart"
                  />
                </span>
                <span>Cart</span>
              </div>
              <div className="count">
                <span>10</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

TopBanner.propTypes = {
  isNotificationClose: PropsTypes.any,
  setIsNotificationClose: PropsTypes.func,
};

export default TopBanner;
