import {
    GET_ALL_POST_COMMENT_REQUEST,
    GET_ALL_POST_COMMENT_SUCCESS,
    GET_ALL_POST_COMMENT_FAILURE,
    ADD_POST_COMMENT_REQUEST,
    ADD_POST_COMMENT_SUCCESS,
    ADD_POST_COMMENT_FAILURE,
    CLEAR_ADD_POST_COMMENT_DATA,
    REMOVE_POST_COMMENT_REQUEST,
    REMOVE_POST_COMMENT_SUCCESS,
    REMOVE_POST_COMMENT_FAILURE,
    CLEAR_REMOVE_POST_COMMENT_DATA,
    REPLAY_POST_COMMENT_REQUEST,
    REPLAY_POST_COMMENT_SUCCESS,
    REPLAY_POST_COMMENT_FAILURE,
    CLEAR_REPLAY_POST_COMMENT_DATA,
    POST_COMMENT_LIKE_REQUEST,
    POST_COMMENT_LIKE_SUCCESS,
    POST_COMMENT_LIKE_FAILURE,
    POST_COMMENT_LIKE_CLEAR_DATA,
    GET_POST_ALL_LIKED_COMMENT_USERS_REQUEST,
    GET_POST_ALL_LIKED_COMMENT_USERS_SUCCESS,
    GET_POST_ALL_LIKED_COMMENT_USERS_FAILURE,
    REPORT_POST_COMMENT_REQUEST,
    REPORT_POST_COMMENT_SUCCESS,
    REPORT_POST_COMMENT_FAILURE,
    HIDE_POST_COMMENT_REQUEST,
    HIDE_POST_COMMENT_SUCCESS,
    HIDE_POST_COMMENT_FAILURE,
    CLEAR_HIDE_POST_COMMENT_DATA,
    UNHIDE_POST_COMMENT_REQUEST,
    UNHIDE_POST_COMMENT_SUCCESS,
    UNHIDE_POST_COMMENT_FAILURE,
    CLEAR_UNHIDE_POST_COMMENT_DATA,
    RESET_COMMENT,
    REPORT_POST_AND_COMMENT_TIME_DATA,

} from '../constants/comment';

export function commentReduer(state = {}, action) {
    switch (action.type) {
        case GET_ALL_POST_COMMENT_REQUEST:
        case ADD_POST_COMMENT_REQUEST:
        case REMOVE_POST_COMMENT_REQUEST:
        case REPLAY_POST_COMMENT_REQUEST:
        case POST_COMMENT_LIKE_REQUEST:
        case GET_POST_ALL_LIKED_COMMENT_USERS_REQUEST:
        case REPORT_POST_COMMENT_REQUEST:
        case HIDE_POST_COMMENT_REQUEST:
        case UNHIDE_POST_COMMENT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case REPORT_POST_AND_COMMENT_TIME_DATA:
            return {
                ...state,
                reportTimePostAndCommentData: action.payload
            }

        case GET_ALL_POST_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                allPostCommentData: action.payload
            }
        case ADD_POST_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                addPostCommentData: action.payload
            }
        case REMOVE_POST_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                removePostCommentData: action.payload
            }
        case REPLAY_POST_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                replayPostCommentData: action.payload
            }
        case POST_COMMENT_LIKE_SUCCESS:
            return {
                ...state,
                loading: false,
                likePostCommentData: action.payload
            }

        case GET_POST_ALL_LIKED_COMMENT_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                postAllLikeCommentUserData: action.payload
            }
        case REPORT_POST_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                reportPostCommentData: action.payload
            }
        case HIDE_POST_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                hidePostCommentData: action.payload
            }
        case UNHIDE_POST_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                unHidePostCommentData: action.payload
            }

        case CLEAR_ADD_POST_COMMENT_DATA:
            return {
                ...state,
                loading: false,
                addPostCommentData: ''
            }
        case CLEAR_REMOVE_POST_COMMENT_DATA:
            return {
                ...state,
                loading: false,
                removePostCommentData: ''
            }
        case CLEAR_REPLAY_POST_COMMENT_DATA:
            return {
                ...state,
                loading: false,
                replayPostCommentData: ''
            }
        case CLEAR_HIDE_POST_COMMENT_DATA:
            return {
                ...state,
                loading: false,
                hidePostCommentData: ''
            }

        case CLEAR_UNHIDE_POST_COMMENT_DATA:
            return {
                ...state,
                loading: false,
                unHidePostCommentData: ''
            }

        case POST_COMMENT_LIKE_CLEAR_DATA:
            return {
                ...state,
                loading: false,
                likePostCommentData: ''
            }


        case GET_ALL_POST_COMMENT_FAILURE:
        case ADD_POST_COMMENT_FAILURE:
        case REMOVE_POST_COMMENT_FAILURE:
        case REPLAY_POST_COMMENT_FAILURE:
        case POST_COMMENT_LIKE_FAILURE:
        case GET_POST_ALL_LIKED_COMMENT_USERS_FAILURE:
        case REPORT_POST_COMMENT_FAILURE:
        case HIDE_POST_COMMENT_FAILURE:
        case UNHIDE_POST_COMMENT_FAILURE:
            return {
                ...state,
                loading: false
            }
        case RESET_COMMENT:
            return {}
        default:
            return state
    }
}