export const CREATE_BUSINESS_PROFILE_DATA_REQUEST =
  "CREATE_BUSINESS_PROFILE_DATA_REQUEST";
export const CREATE_BUSINESS_PROFILE_DATA_SUCCESS =
  "CREATE_BUSINESS_PROFILE_DATA_SUCCESS";
export const CREATE_BUSINESS_PROFILE_DATA_FAILURE =
  "CREATE_BUSINESS_PROFILE_DATA_FAILURE";

export const GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_REQUEST =
  "GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_REQUEST";

export const GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_SUCCESS =
  "GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_SUCCESS";

export const GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_FAILURE =
  "GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_FAILURE";

export const UPDATE_BUSINESS_PROFILE_DATA_REQUEST =
  "UPDATE_BUSINESS_PROFILE_DATA_REQUEST";

export const UPDATE_BUSINESS_PROFILE_DATA_SUCCESS =
  "UPDATE_BUSINESS_PROFILE_DATA_SUCCESS";

export const UPDATE_BUSINESS_PROFILE_DATA_FAILURE =
  "UPDATE_BUSINESS_PROFILE_DATA_FAILURE";

export const RESET_STATE = "RESET_STATE";
