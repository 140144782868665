import {
    GET_ALL_HASHTAG_REQUEST,
    GET_ALL_HASHTAG_SUCCESS,
    GET_ALL_HASHTAG_FAILURE,
} from '../constants/hashtag'

export function hashtagReducer(state = {}, action) {
    switch (action.payload) {
        case GET_ALL_HASHTAG_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_ALL_HASHTAG_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllHashtagData: action.payload
            }
        case GET_ALL_HASHTAG_FAILURE:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}