import {
  COMPANY_REQUEST,
  COMPANY_SUCCESS,
  COMPANY_FAILURE,
  APPEARANCE_REQUEST,
  APPEARANCE_SUCCESS,
  APPEARANCE_FAILURE,
} from "../constants/company";

export function companyReducer(state = {}, action) {
  switch (action.type) {
    case COMPANY_REQUEST:
    case APPEARANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        companyData: action.payload,
      };
    case APPEARANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        appearanceData: action.payload,
      };
    case APPEARANCE_FAILURE:
    case COMPANY_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
