import {
  GET_ALL_SEARCH_POST_DATA_FAILURE,
  GET_ALL_SEARCH_POST_DATA_REQUEST,
  GET_ALL_SEARCH_POST_DATA_SUCCESS,
  GET_ALL_SEARCH_TEAMS_DATA_FAILURE,
  GET_ALL_SEARCH_TEAMS_DATA_REQUEST,
  GET_ALL_SEARCH_TEAMS_DATA_SUCCESS,
  GET_ALL_SEARCH_RESULT_REQUEST,
  GET_ALL_SEARCH_RESULT_SUCCESS,
  GET_ALL_SEARCH_RESULT_FAILURE,
} from "../constants/search";

export function searchReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_SEARCH_POST_DATA_REQUEST:
    case GET_ALL_SEARCH_TEAMS_DATA_REQUEST:
    case GET_ALL_SEARCH_RESULT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SEARCH_POST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        searchPostData: action.payload,
      };
    case GET_ALL_SEARCH_TEAMS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        searchTeamsData: action.payload,
      };
    case GET_ALL_SEARCH_RESULT_SUCCESS:
      return {
        ...state,
        loading: true,
        getAllSearchResultData: action.payload
      }

    case GET_ALL_SEARCH_POST_DATA_FAILURE:
    case GET_ALL_SEARCH_TEAMS_DATA_FAILURE:
    case GET_ALL_SEARCH_RESULT_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
