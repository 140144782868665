import {
  CREATE_BUSINESS_PROFILE_DATA_FAILURE,
  CREATE_BUSINESS_PROFILE_DATA_REQUEST,
  CREATE_BUSINESS_PROFILE_DATA_SUCCESS,
  GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_FAILURE,
  GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_REQUEST,
  GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_SUCCESS,
  RESET_STATE,
  UPDATE_BUSINESS_PROFILE_DATA_FAILURE,
  UPDATE_BUSINESS_PROFILE_DATA_REQUEST,
  UPDATE_BUSINESS_PROFILE_DATA_SUCCESS,
} from "../constants/business";

const initialState = {};

export function businessReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_BUSINESS_PROFILE_DATA_REQUEST:
    case GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_REQUEST:
    case UPDATE_BUSINESS_PROFILE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_BUSINESS_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        createBusinessProfileData: action.payload,
      };
    case GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        getBusinessProfileByTransIdData: action.payload,
      };
    case UPDATE_BUSINESS_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        updateBusinessProfileData: action.payload,
      };

    case CREATE_BUSINESS_PROFILE_DATA_FAILURE:
    case GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_FAILURE:
    case UPDATE_BUSINESS_PROFILE_DATA_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
