import {
    USER_LOGOUT
} from '../constants/logout'

export function UserLogoutReducer(state = {}, action) {
    switch (action.type) {
        case USER_LOGOUT:
            return state
        default:
            return state
    }
}