import {
  GET_ALL_MODULE_REQUEST,
  GET_ALL_MODULE_SUCCESS,
  GET_ALL_MODULE_FAILURE,
  RESET_STATE,
  GET_MODULE_LIST_REQUEST,
  GET_MODULE_LIST_SUCCESS,
  GET_MODULE_LIST_FAILURE,
} from "../constants/moduleMaster";

const initialState = {};

export function moduleMasterReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_MODULE_REQUEST:
    case GET_MODULE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllModuleListData: action.payload,
      };
    case GET_MODULE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllModulesData: action.payload,
      };
    case GET_MODULE_LIST_FAILURE:
    case GET_ALL_MODULE_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
