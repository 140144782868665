import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
  CLEAR_PROFILE_UPDATE_DATA,
  USER_ROLE_REQUEST,
  USER_ROLE_SUCCESS,
  USER_ROLE_FAILURE,
  SPORTS_LIST_REQUEST,
  SPORTS_LIST_SUCCESS,
  SPORTS_LIST_FAILURE,
  COUNTRY_LIST_REQUEST,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_LIST_FAILURE,
  TEAM_TYPE_REQUEST,
  TEAM_TYPE_SUCCESS,
  TEAM_TYPE_FAILURE,
  SEASONS_REQUEST,
  SEASONS_SUCCESS,
  SEASONS_FAILURE,
  STATE_LIST_REQUEST,
  STATE_LIST_SUCCESS,
  STATE_LIST_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  EMAIL_CHECK_REQUEST,
  EMAIL_CHECK_SUCCESS,
  EMAIL_CHECK_FAILURE,
  STORE_NAME_RESET,
  FORGOT_PASSWORD_SUCCESS,
  PROFILE_BANNER_UPDATE_REQUEST,
  PROFILE_BANNER_UPDATE_SUCCESS,
  PROFILE_BANNER_UPDATE_FAILURE,
  PROFILE_BY_AUTHCODE_REQUEST,
  PROFILE_BY_AUTHCODE_SUCCESS,
  PROFILE_BY_AUTHCODE_FAILURE,
  PROFILE_IROLEID,
  VERIFY_PERSONAL_USER_SLUG_REQUEST,
  VERIFY_PERSONAL_USER_SLUG_FAILURE,
  VERIFY_PERSONAL_USER_SLUG_SUCCESS,
  VERIFY_BUSINESS_USER_SLUG_REQUEST,
  VERIFY_BUSINESS_USER_SLUG_SUCCESS,
  VERIFY_BUSINESS_USER_SLUG_FAILURE,
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILURE,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAILURE,
  SEND_2STEP_CODE_REQUEST,
  SEND_2STEP_CODE_SUCCESS,
  SEND_2STEP_CODE_FAILURE,
  MOBILE_CHECK_REQUEST,
  MOBILE_CHECK_SUCCESS,
  MOBILE_CHECK_FAILURE,
  VERIFY_EMAIL_CODE_REQUEST,
  VERIFY_EMAIL_CODE_SUCCESS,
  VERIFY_EMAIL_CODE_FAILURE,
  BLOCK_USER_REQUEST,
  BLOCK_USER_SUCCESS,
  BLOCK_USER_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  AUTH_PROFILE_SUCCESS,
  AUTH_PROFILE_REQUEST,
  FOLLOWER_AUTH_PROFILE_REQUEST,
  FOLLOWER_AUTH_PROFILE_SUCCESS,
  USER_PASSWORD_CHECK_REQUEST,
  USER_PASSWORD_CHECK_SUCCESS,
  USER_PASSWORD_CHECK_FAILURE,
} from "./../constants/user";

const initialState = {};

export function userReducer(state = {}, action) {
  switch (action.type) {
    case USER_PASSWORD_CHECK_REQUEST:
    case LOGIN_REQUEST:
    case MOBILE_CHECK_REQUEST:
    case SIGNUP_REQUEST:
    case PROFILE_REQUEST:
    case PROFILE_UPDATE_REQUEST:
    case USER_ROLE_REQUEST:
    case SPORTS_LIST_REQUEST:
    case COUNTRY_LIST_REQUEST:
    case TEAM_TYPE_REQUEST:
    case SEASONS_REQUEST:
    case STATE_LIST_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case VERIFY_EMAIL_REQUEST:
    case EMAIL_CHECK_REQUEST:
    case PROFILE_BANNER_UPDATE_REQUEST:
    case PROFILE_BY_AUTHCODE_REQUEST:
    case VERIFY_PERSONAL_USER_SLUG_REQUEST:
    case VERIFY_BUSINESS_USER_SLUG_REQUEST:
    case OTP_VERIFY_REQUEST:
    case SEND_OTP_REQUEST:
    case SEND_2STEP_CODE_REQUEST:
    case VERIFY_EMAIL_CODE_REQUEST:
    case BLOCK_USER_REQUEST:
    case DELETE_ACCOUNT_REQUEST:
    case AUTH_PROFILE_REQUEST:
    case FOLLOWER_AUTH_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };
    case USER_PASSWORD_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        userPasswordCheckData: action.payload,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        userSignupData: action.payload,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotPasswordData: action.payload,
      };
    case PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfileData: action.payload,
      };
    case AUTH_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userAuthProfileData: action.payload,
      };
    case FOLLOWER_AUTH_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userAuthFollowerProfileData: action.payload,
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfileUpdateData: action.payload,
      };
    case USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload,
      };
    case SPORTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sports: action.payload,
      };
    case COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
      };
    case TEAM_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        teamTypes: action.payload,
      };
    case SEASONS_SUCCESS:
      return {
        ...state,
        loading: false,
        seasons: action.payload,
      };
    case STATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        states: action.payload,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        resetPasswordData: action.payload,
      };
    case VERIFY_EMAIL_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        verifyEmailCodeData: action.payload,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        verifyEmail: action.payload,
      };
    case EMAIL_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        emailCheckData: action.payload,
      };
    case PROFILE_BANNER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileBannerUpdateData: action.payload,
      };
    case PROFILE_BY_AUTHCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileDataByAuthcode: action.payload,
      };
    case VERIFY_PERSONAL_USER_SLUG_SUCCESS:
      return {
        ...state,
        loading: false,
        personalSlugData: action.payload,
      };
    case VERIFY_BUSINESS_USER_SLUG_SUCCESS:
      return {
        ...state,
        loading: false,
        businessSlugData: action.payload,
      };
    case PROFILE_IROLEID:
      return {
        ...state,
        loading: false,
        userRole: action.payload,
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        sendOTPData: action.payload,
      };
    case OTP_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        otpVerifyData: action.payload,
      };
    case SEND_2STEP_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        send2StepCdeData: action.payload,
      };
    case MOBILE_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        checkMobileNumberData: action.payload,
      };
    case BLOCK_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        blockUserData: action.payload,
      };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteAccountData: action.payload,
      };
    case CLEAR_PROFILE_UPDATE_DATA:
      return {
        ...state,
        loading: false,
        userProfileUpdateData: "",
      };
    case DELETE_ACCOUNT_FAILURE:
    case BLOCK_USER_FAILURE:
    case MOBILE_CHECK_FAILURE:
    case SEND_2STEP_CODE_FAILURE:
    case SEND_OTP_FAILURE:
    case OTP_VERIFY_FAILURE:
    case LOGIN_FAILURE:
    case SIGNUP_FAILURE:
    case PROFILE_FAILURE:
    case PROFILE_UPDATE_FAILURE:
    case USER_ROLE_FAILURE:
    case SPORTS_LIST_FAILURE:
    case COUNTRY_LIST_FAILURE:
    case TEAM_TYPE_FAILURE:
    case SEASONS_FAILURE:
    case STATE_LIST_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
    case RESET_PASSWORD_FAILURE:
    case VERIFY_EMAIL_FAILURE:
    case PROFILE_BANNER_UPDATE_FAILURE:
    case EMAIL_CHECK_FAILURE:
    case PROFILE_BY_AUTHCODE_FAILURE:
    case VERIFY_PERSONAL_USER_SLUG_FAILURE:
    case VERIFY_BUSINESS_USER_SLUG_FAILURE:
    case VERIFY_EMAIL_CODE_FAILURE:
    case USER_PASSWORD_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case STORE_NAME_RESET:
      return initialState;
    default:
      return state;
  }
}
