export const CREATE_SOCIAL_LINKS_REQUEST = "CREATE_SOCIAL_LINKS_REQUEST";
export const CREATE_SOCIAL_LINKS_SUCCESS = "CREATE_SOCIAL_LINKS_SUCCESS";
export const CREATE_SOCIAL_LINKS_FAILURE = "CREATE_SOCIAL_LINKS_FAILURE";

export const UPDATE_SOCIAL_LINKS_REQUEST = "UPDATE_SOCIAL_LINKS_REQUEST";
export const UPDATE_SOCIAL_LINKS_SUCCESS = "UPDATE_SOCIAL_LINKS_SUCCESS";
export const UPDATE_SOCIAL_LINKS_FAILURE = "UPDATE_SOCIAL_LINKS_FAILURE";

export const DELETE_SOCIAL_LINKS_REQUEST = "DELETE_SOCIAL_LINKS_REQUEST";
export const DELETE_SOCIAL_LINKS_SUCCESS = "DELETE_SOCIAL_LINKS_SUCCESS";
export const DELETE_SOCIAL_LINKS_FAILURE = "DELETE_SOCIAL_LINKS_FAILURE";

export const GET_ALL_SOCIAL_LINKS_REQUEST = "GET_ALL_SOCIAL_LINKS_REQUEST";
export const GET_ALL_SOCIAL_LINKS_SUCCESS = "GET_ALL_SOCIAL_LINKS_SUCCESS";
export const GET_ALL_SOCIAL_LINKS_FAILURE = "GET_ALL_SOCIAL_LINKS_FAILURE";

export const GET_SOCIAL_LINKS_BY_ID_REQUEST = "GET_SOCIAL_LINKS_BY_ID_REQUEST";
export const GET_SOCIAL_LINKS_BY_ID_SUCCESS = "GET_SOCIAL_LINKS_BY_ID_SUCCESS";
export const GET_SOCIAL_LINKS_BY_ID_FAILURE = "GET_SOCIAL_LINKS_BY_ID_FAILURE";

export const GET_SOCIAL_LINKS_LIST_REQUEST = "GET_SOCIAL_LINKS_LIST_REQUEST";
export const GET_SOCIAL_LINKS_LIST_SUCCESS = "GET_SOCIAL_LINKS_LIST_SUCCESS";
export const GET_SOCIAL_LINKS_LIST_FAILURE = "GET_SOCIAL_LINKS_LIST_FAILURE";

export const RESET = "RESET";
