export const GET_ALL_SEARCH_POST_DATA_REQUEST =
  "GET_ALL_SEARCH_POST_DATA_REQUEST";
export const GET_ALL_SEARCH_POST_DATA_SUCCESS =
  "GET_ALL_SEARCH_POST_DATA_SUCCESS";
export const GET_ALL_SEARCH_POST_DATA_FAILURE =
  "GET_ALL_SEARCH_POST_DATA_FAILURE";

export const GET_ALL_SEARCH_TEAMS_DATA_REQUEST =
  "GET_ALL_SEARCH_TEAMS_DATA_REQUEST";
export const GET_ALL_SEARCH_TEAMS_DATA_SUCCESS =
  "GET_ALL_SEARCH_TEAMS_DATA_SUCCESS";
export const GET_ALL_SEARCH_TEAMS_DATA_FAILURE =
  "GET_ALL_SEARCH_TEAMS_DATA_FAILURE";


export const GET_ALL_SEARCH_RESULT_REQUEST = "GET_ALL_SEARCH_RESULT_REQUEST"
export const GET_ALL_SEARCH_RESULT_SUCCESS = "GET_ALL_SEARCH_RESULT_SUCCESS"
export const GET_ALL_SEARCH_RESULT_FAILURE = "GET_ALL_SEARCH_RESULT_FAILURE"