import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../components/CustomComponents/PageContainer/PageContainer";
import { GetPrivacyPolicy } from "../store/action/policyAction";
import parse from "html-react-parser";

function TermsOfUse() {
  const dispatch = useDispatch();
  const { privacyPolicyData } = useSelector((state) => state.policy);

  React.useEffect(() => {
    dispatch(
      GetPrivacyPolicy({
        vTitle: "Terms of Use",
      })
    );
  }, [dispatch]);

  return (
    <PageContainer>
      <div className="middle-space cart-section bg-2 pb-space pt-space">
        <div className="container">
          <div className="privacy-text">
            {privacyPolicyData &&
            privacyPolicyData.code === "200" &&
            privacyPolicyData.data &&
            privacyPolicyData.data.tDescription ? (
              <>{parse(privacyPolicyData.data.tDescription)}</>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default TermsOfUse;
