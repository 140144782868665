import {
  CREATE_LEAGUE_FAILURE,
  CREATE_LEAGUE_REQUEST,
  CREATE_LEAGUE_SUCCESS,
  DELETE_LEAGUE_FAILURE,
  DELETE_LEAGUE_REQUEST,
  DELETE_LEAGUE_SUCCESS,
  EDIT_LEAGUE_FAILURE,
  EDIT_LEAGUE_REQUEST,
  EDIT_LEAGUE_SUCCESS,
  GET_ALL_LEAGUE_BY_ID_FAILURE,
  GET_ALL_LEAGUE_BY_ID_REQUEST,
  GET_ALL_LEAGUE_BY_ID_SUCCESS,
  GET_ALL_LEAGUE_FAILURE,
  GET_ALL_LEAGUE_REQUEST,
  GET_ALL_LEAGUE_SUCCESS,
  RESET_CREATE_LEAGUE_DATA,
  RESET_EDIT_LEAGUE_DATA,
  RESET_ALL_LEAGUE_BY_ID_DATA
} from "../constants/league";

export function leagueReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_LEAGUE_REQUEST:
    case GET_ALL_LEAGUE_BY_ID_REQUEST:
    case GET_ALL_LEAGUE_REQUEST:
    case DELETE_LEAGUE_REQUEST:
    case EDIT_LEAGUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_LEAGUE_SUCCESS:
      return {
        ...state,
        loading: false,
        createLeagueData: action.payload,
      };
    case EDIT_LEAGUE_SUCCESS:
      return {
        ...state,
        loading: false,
        editLeagueData: action.payload,
      };
    case DELETE_LEAGUE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteLeagueData: action.payload,
      };

    case GET_ALL_LEAGUE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllLeagueByIdData: action.payload,
      };
    case GET_ALL_LEAGUE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllLeagueData: action.payload,
      };

    case RESET_CREATE_LEAGUE_DATA:
      return {
        ...state,
        loading: false,
        createLeagueData: {},
      };
    case RESET_EDIT_LEAGUE_DATA:
      return {
        ...state,
        loading: false,
        editLeagueData: {},
      };
      case RESET_ALL_LEAGUE_BY_ID_DATA:
        return {
          ...state,
          loading: false,
          getAllLeagueByIdData: {},
        };
    case GET_ALL_LEAGUE_BY_ID_FAILURE:
    case CREATE_LEAGUE_FAILURE:
    case GET_ALL_LEAGUE_FAILURE:
    case EDIT_LEAGUE_FAILURE:
    case DELETE_LEAGUE_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
