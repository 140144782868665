import DashboardPageContainer from "../components/CustomComponents/DashboardPageContainer/DashboardPageContainer";
import CreateFacilityTab from "../components/MasterCalendar/CreateFacilityTab";

function FacilityManagement() {
  return (
    <>
      <DashboardPageContainer>
        <div className="middle-section">
        <div className="sub-banner-create">
            <CreateFacilityTab />
        </div>
        </div>
      </DashboardPageContainer>
    </>
  );
}

export default FacilityManagement;
