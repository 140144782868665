import axios from "axios";
import {
  ADD_MEMBER_BY_EMAIL_FAILURE,
  ADD_MEMBER_BY_EMAIL_REQUEST,
  ADD_MEMBER_BY_EMAIL_SUCCESS,
  GET_ALL_GROUP_DATA_FAILURE,
  GET_ALL_GROUP_DATA_REQUEST,
  GET_ALL_GROUP_DATA_SUCCESS,
  GET_ALL_GROUP_MATES_FAILURE,
  GET_ALL_GROUP_MATES_REQUEST,
  GET_ALL_GROUP_MATES_SUCCESS,
  GET_ALL_GROUP_MESSAGE_BOARD_POST_FAILURE,
  GET_ALL_GROUP_MESSAGE_BOARD_POST_REQUEST,
  GET_ALL_GROUP_MESSAGE_BOARD_POST_SUCCESS,
  JOIN_GROUP_BY_ACCESS_CODE_FAILURE,
  JOIN_GROUP_BY_ACCESS_CODE_REQUEST,
  JOIN_GROUP_BY_ACCESS_CODE_SUCCESS,
  SINGLE_POST_GROUP_ALL_LIKES_FAILURE,
  SINGLE_POST_GROUP_ALL_LIKES_REQUEST,
  SINGLE_POST_GROUP_ALL_LIKES_SUCCESS,
  UPDATE_GROUP_PROFILE_FAILURE,
  UPDATE_GROUP_PROFILE_REQUEST,
  UPDATE_GROUP_PROFILE_SUCCESS,
  GET_ALL_BANNED_GROUP_MATES_REQUEST,
  GET_ALL_BANNED_GROUP_MATES_SUCCESS,
  GET_ALL_BANNED_GROUP_MATES_FAILURE,
  UPDATE_GROUP_SETTINGS_REQUEST,
  UPDATE_GROUP_SETTINGS_SUCCESS,
  UPDATE_GROUP_SETTINGS_FAILURE,
} from "../constants/group";

export const GetAllGroupById = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_GROUP_DATA_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/group/getAllGroupData`,
      {
        iUserId: sessionStorage.getItem("id"),
      }
    );
    dispatch({
      type: GET_ALL_GROUP_DATA_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_GROUP_DATA_FAILURE });
  }
};

export const UpdateProfile = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_GROUP_PROFILE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/group/updateGroup`,
      values
    );
    dispatch({
      type: UPDATE_GROUP_PROFILE_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: UPDATE_GROUP_PROFILE_FAILURE });
  }
};

export const AddMemberByEmail = (values) => async (dispatch) => {
  try {
    dispatch({ type: ADD_MEMBER_BY_EMAIL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/group/addMembersInGroup`,
      values
    );
    dispatch({
      type: ADD_MEMBER_BY_EMAIL_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: ADD_MEMBER_BY_EMAIL_FAILURE });
  }
};

export const JoinGroupByAccessCode = (values) => async (dispatch) => {
  try {
    dispatch({ type: JOIN_GROUP_BY_ACCESS_CODE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/group/joinGroup`,
      values
    );
    dispatch({
      type: JOIN_GROUP_BY_ACCESS_CODE_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: JOIN_GROUP_BY_ACCESS_CODE_FAILURE });
  }
};

export const GroupMatesListing = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_GROUP_MATES_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/group/groupMatesList`,
      values
    );
    dispatch({
      type: GET_ALL_GROUP_MATES_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_GROUP_MATES_FAILURE });
  }
};

export const GetAllGroupMessageBoardPost = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_GROUP_MESSAGE_BOARD_POST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/group/getAllGroupPostData`,
      values
    );
    dispatch({
      type: GET_ALL_GROUP_MESSAGE_BOARD_POST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_GROUP_MESSAGE_BOARD_POST_FAILURE });
  }
};

export const GroupBannedMatesListing = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_BANNED_GROUP_MATES_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/group/banUnbanGroupMembersListing`,
      values
    );
    dispatch({
      type: GET_ALL_BANNED_GROUP_MATES_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_BANNED_GROUP_MATES_FAILURE });
  }
};

export const SinglePostAllLikesGroup = (values) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_POST_GROUP_ALL_LIKES_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/group/getPostLikeUsers`,
      values
    );
    dispatch({
      type: SINGLE_POST_GROUP_ALL_LIKES_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: SINGLE_POST_GROUP_ALL_LIKES_FAILURE });
  }
};

export const UpdateGroupSettings = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_GROUP_SETTINGS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/group/updateGroupSetting`,
      values
    );
    dispatch({
      type: UPDATE_GROUP_SETTINGS_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: UPDATE_GROUP_SETTINGS_FAILURE });
  }
};
