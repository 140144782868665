export const GET_ALL_GROUP_DATA_REQUEST = "GET_ALL_GROUP_DATA_REQUEST";
export const GET_ALL_GROUP_DATA_SUCCESS = "GET_ALL_GROUP_DATA_SUCCESS";
export const GET_ALL_GROUP_DATA_FAILURE = "GET_ALL_GROUP_DATA_FAILURE";

export const UPDATE_GROUP_PROFILE_REQUEST = "UPDATE_GROUP_PROFILE_REQUEST";
export const UPDATE_GROUP_PROFILE_SUCCESS = "UPDATE_GROUP_PROFILE_SUCCESS";
export const UPDATE_GROUP_PROFILE_FAILURE = "UPDATE_GROUP_PROFILE_FAILURE";

export const SELECTED_GROUP_OBJ = "SELECTED_GROUP_OBJ";

export const ADD_MEMBER_BY_EMAIL_REQUEST = "ADD_MEMBER_BY_EMAIL_REQUEST";
export const ADD_MEMBER_BY_EMAIL_SUCCESS = "ADD_MEMBER_BY_EMAIL_SUCCESS";
export const ADD_MEMBER_BY_EMAIL_FAILURE = "ADD_MEMBER_BY_EMAIL_FAILURE";

export const JOIN_GROUP_BY_ACCESS_CODE_REQUEST =
  "JOIN_GROUP_BY_ACCESS_CODE_REQUEST";
export const JOIN_GROUP_BY_ACCESS_CODE_SUCCESS =
  "JOIN_GROUP_BY_ACCESS_CODE_SUCCESS";
export const JOIN_GROUP_BY_ACCESS_CODE_FAILURE =
  "JOIN_GROUP_BY_ACCESS_CODE_FAILURE";

export const GET_ALL_GROUP_MATES_REQUEST = "GET_ALL_GROUP_MATES_REQUEST";
export const GET_ALL_GROUP_MATES_SUCCESS = "GET_ALL_GROUP_MATES_SUCCESS";
export const GET_ALL_GROUP_MATES_FAILURE = "GET_ALL_GROUP_MATES_FAILURE";

export const SELECTED_GROUP_FOR_FEED = "SELECTED_GROUP_FOR_FEED";

export const SELECTED_GROUP_FOR_CREATE_POST = "SELECTED_GROUP_FOR_CREATE_POST";

export const POST_DATA_ARRAY_GROUP = "POST_DATA_ARRAY_GROUP";

export const GET_ALL_GROUP_MESSAGE_BOARD_POST_REQUEST =
  "GET_ALL_GROUP_MESSAGE_BOARD_POST_REQUEST";
export const GET_ALL_GROUP_MESSAGE_BOARD_POST_SUCCESS =
  "GET_ALL_GROUP_MESSAGE_BOARD_POST_SUCCESS";
export const GET_ALL_GROUP_MESSAGE_BOARD_POST_FAILURE =
  "GET_ALL_GROUP_MESSAGE_BOARD_POST_FAILURE";

export const CREATE_GROUP_POST_SUCCESS = "CREATE_GROUP_POST_SUCCESS";

export const GET_ALL_BANNED_GROUP_MATES_REQUEST =
  "GET_ALL_BANNED_GROUP_MATES_REQUEST";
export const GET_ALL_BANNED_GROUP_MATES_SUCCESS =
  "GET_ALL_BANNED_GROUP_MATES_SUCCESS";
export const GET_ALL_BANNED_GROUP_MATES_FAILURE =
  "GET_ALL_BANNED_GROUP_MATES_FAILURE";

export const SINGLE_POST_GROUP_ALL_LIKES_REQUEST =
  "SINGLE_POST_GROUP_ALL_LIKES_REQUEST";
export const SINGLE_POST_GROUP_ALL_LIKES_SUCCESS =
  "SINGLE_POST_GROUP_ALL_LIKES_SUCCESS";
export const SINGLE_POST_GROUP_ALL_LIKES_FAILURE =
  "SINGLE_POST_GROUP_ALL_LIKES_FAILURE";

export const REPORT_POST_GROUP_VALUES = "REPORT_POST_GROUP_VALUES";

export const UPDATE_GROUP_SETTINGS_REQUEST = "UPDATE_GROUP_SETTINGS_REQUEST";
export const UPDATE_GROUP_SETTINGS_SUCCESS = "UPDATE_GROUP_SETTINGS_SUCCESS";
export const UPDATE_GROUP_SETTINGS_FAILURE = "UPDATE_GROUP_SETTINGS_FAILURE";

export const UPLOADED_VIDEO_BLOB =  "UPLOADED_VIDEO_BLOB";
