export const CREATE_LEAGUE_REQUEST = "CREATE_LEAGUE_REQUEST";
export const CREATE_LEAGUE_SUCCESS = "CREATE_LEAGUE_SUCCESS";
export const CREATE_LEAGUE_FAILURE = "CREATE_LEAGUE_FAILURE";
export const RESET_CREATE_LEAGUE_DATA = "RESET_CREATE_LEAGUE_DATA";

export const GET_ALL_LEAGUE_BY_ID_REQUEST = "GET_ALL_LEAGUE_BY_ID_REQUEST";
export const GET_ALL_LEAGUE_BY_ID_SUCCESS = "GET_ALL_LEAGUE_BY_ID_SUCCESS";
export const GET_ALL_LEAGUE_BY_ID_FAILURE = "GET_ALL_LEAGUE_BY_ID_FAILURE";
export const RESET_ALL_LEAGUE_BY_ID_DATA = "RESET_ALL_LEAGUE_BY_ID_DATA";

export const GET_ALL_LEAGUE_REQUEST = "GET_ALL_LEAGUE_REQUEST";
export const GET_ALL_LEAGUE_SUCCESS = "GET_ALL_LEAGUE_SUCCESS";
export const GET_ALL_LEAGUE_FAILURE = "GET_ALL_LEAGUE_FAILURE";

export const EDIT_LEAGUE_REQUEST = "EDIT_LEAGUE_REQUEST";
export const EDIT_LEAGUE_SUCCESS = "EDIT_LEAGUE_SUCCESS";
export const EDIT_LEAGUE_FAILURE = "EDIT_LEAGUE_FAILURE";
export const RESET_EDIT_LEAGUE_DATA = "RESET_EDIT_LEAGUE_DATA";

export const DELETE_LEAGUE_REQUEST = "DELETE_LEAGUE_REQUEST";
export const DELETE_LEAGUE_SUCCESS = "DELETE_LEAGUE_SUCCESS";
export const DELETE_LEAGUE_FAILURE = "DELETE_LEAGUE_FAILURE";
