import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMobileBrowserCheck } from "../../../hooks/useMobileBrowserCheck";

function Footer() {
  const { getCategorywiseSubCategoryData } = useSelector((state) => state.home);
  const isMobile = useMobileBrowserCheck();

  return (
    <footer className="new-footer">
      <div className="container">
        <div className="inner-footer">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="footer-logo">
                <div className="image-box">
                  <img
                    src="assets/img/footer-logo.png"
                    alt="logo"
                    width="95"
                    height="95"
                    title="Sport Logo"
                  />
                </div>
                <p>Advancing Team Development In Sports</p>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="row footer-menu g-sm-0 g-4">
                <div className="col-sm-3 col-6">
                  <p className="title">COMPANY</p>
                  <ul>
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Sport Playbook</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Team Management</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Social Media</Link>
                    </li>
                    <li>
                      <Link to={"/contact-us"}>For Business</Link>
                    </li>
                    <li>
                      <Link
                        to={
                          isMobile
                            ? "/privacy-policy-mobile"
                            : "/privacy-policy"
                        }
                      >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-12">
                  <p className="title sport-title-f">TEAM SPORTS</p>
                  <ul className="sport-footer-link">
                    <li>
                      <Link to={"/"}>Baseball</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Softball</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Basketball</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Football</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Soccer</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Ice Hockey</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Lacrosse</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Cricket</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Rugby</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Volleyball</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Field Hockey</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Tennis</Link>
                    </li>
                  </ul>
                </div>
                {getCategorywiseSubCategoryData &&
                getCategorywiseSubCategoryData.code === "200" ? (
                  <div className="col-sm-3 col-6">
                    <p className="title">Category</p>
                    <ul>
                      {getCategorywiseSubCategoryData.data.map((item, key) => (
                        <li key={key}>
                          <Link to={"/products"}>{item.vTitle}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <p>Copyright © 2024 Yo Playbook. All Rights Reserved.</p>
          <div className="social">
            <Link className="image-box" to={"/"}>
              <img
                src="assets/img/new-fb.png"
                alt="facebook"
                width="33"
                height="33"
                title="Facebook"
              />
            </Link>
            <Link className="image-box" to={"/"}>
              <img
                src="assets/img/new-insta.png"
                alt="instagram"
                width="33"
                height="33"
                title="Instagram"
              />
            </Link>
            <Link className="image-box" to={"/"}>
              <img
                src="assets/img/new-twitter.png"
                alt="twitter"
                width="33"
                height="33"
                title="Twitter"
              />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
