import {
  GET_BAN_UNBAN_LIST_REQUEST,
  GET_BAN_UNBAN_LIST_SUCCESS,
  GET_BAN_UNBAN_LIST_FAILURE,
} from "../constants/messageBoardSettings";

export function messageBoardSettingsReducer(state = {}, action) {
  switch (action.type) {
    case GET_BAN_UNBAN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BAN_UNBAN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        banUnbaneListData: action.payload,
      };
    case GET_BAN_UNBAN_LIST_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
