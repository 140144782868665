import React from "react";

const MasterCalendarSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="fi-rr-calendar"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_157"
        data-name="Path 157"
        d="M19,2H18V1a1,1,0,0,0-2,0V2H8V1A1,1,0,0,0,6,1V2H5A5.006,5.006,0,0,0,0,7V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7A5.006,5.006,0,0,0,19,2ZM2,7A3,3,0,0,1,5,4H19a3,3,0,0,1,3,3V8H2ZM19,22H5a3,3,0,0,1-3-3V10H22v9A3,3,0,0,1,19,22Z"
      />
      <circle
        id="Ellipse_24"
        data-name="Ellipse 24"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(10.5 13.5)"
      />
      <circle
        id="Ellipse_25"
        data-name="Ellipse 25"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(5.5 13.5)"
      />
      <circle
        id="Ellipse_26"
        data-name="Ellipse 26"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(15.5 13.5)"
      />
    </svg>
  );
};

export default MasterCalendarSVG;
