export const ADD_PLAYER_REQUEST = "ADD_PLAYER_REQUEST";
export const ADD_PLAYER_SUCCESS = "ADD_PLAYER_SUCCESS";
export const ADD_PLAYER_FAILURE = "ADD_PLAYER_FAILURE";
export const ADD_PLAYER_RESET = "ADD_PLAYER_RESET";

export const DELETE_PLAYER_REQUEST = "DELETE_PLAYER_REQUEST";
export const DELETE_PLAYER_SUCCESS = "DELETE_PLAYER_SUCCESS";
export const DELETE_PLAYER_FAILURE = "DELETE_PLAYER_FAILURE";
export const DELETE_PLAYER_RESET = "DELETE_PLAYER_RESET";

export const UPDATE_PLAYER_REQUEST = "UPDATE_PLAYER_REQUEST";
export const UPDATE_PLAYER_SUCCESS = "UPDATE_PLAYER_SUCCESS";
export const UPDATE_PLAYER_FAILURE = "UPDATE_PLAYER_FAILURE";

export const GET_PLAYER_BY_ID_REQUEST = "GET_PLAYER_BY_ID_REQUEST";
export const GET_PLAYER_BY_ID_SUCCESS = "GET_PLAYER_BY_ID_SUCCESS";
export const GET_PLAYER_BY_ID_FAILURE = "GET_PLAYER_BY_ID_FAILURE";

export const TEAM_PLAYER_LIST_REQUEST = "TEAM_PLAYER_LIST_REQUEST";
export const TEAM_PLAYER_LIST_SUCCESS = "TEAM_PLAYER_LIST_SUCCESS";
export const TEAM_PLAYER_LIST_FAILURE = "TEAM_PLAYER_LIST_FAILURE";

export const EXIST_PLAYER_ADD_REQUEST = "EXIST_PLAYER_ADD_REQUEST";
export const EXIST_PLAYER_ADD_SUCCESS = "EXIST_PLAYER_ADD_SUCCESS";
export const EXIST_PLAYER_ADD_FAILURE = "EXIST_PLAYER_ADD_FAILURE";

export const VERIFY_PLAYER_EMAIL_REQUEST = "VERIFY_PLAYER_EMAIL_REQUEST";
export const VERIFY_PLAYER_EMAIL_SUCCESS = "VERIFY_PLAYER_EMAIL_SUCCESS";
export const VERIFY_PLAYER_EMAIL_FAILURE = "VERIFY_PLAYER_EMAIL_FAILURE";

export const VERIFY_EXIST_PLAYER_EMAIL_REQUEST =
  "VERIFY_EXIST_PLAYER_EMAIL_REQUEST";
export const VERIFY_EXIST_PLAYER_EMAIL_SUCCESS =
  "VERIFY_EXIST_PLAYER_EMAIL_SUCCESS";
export const VERIFY_EXIST_PLAYER_EMAIL_FAILURE =
  "VERIFY_EXIST_PLAYER_EMAIL_FAILURE";

export const UPDATE_MEMBER_EMAIL_REQUEST = "UPDATE_MEMBER_EMAIL_REQUEST";
export const UPDATE_MEMBER_EMAIL_SUCCESS = "UPDATE_MEMBER_EMAIL_SUCCESS";
export const UPDATE_MEMBER_EMAIL_FAILURE = "UPDATE_MEMBER_EMAIL_FAILURE";
export const UPDATE_MEMBER_EMAIL_RESET = "UPDATE_MEMBER_EMAIL_RESET";

export const UPDATE_MEMBER_PARENT_EMAIL_REQUEST = "UPDATE_MEMBER_PARENT_EMAIL_REQUEST";
export const UPDATE_MEMBER_PARENT_EMAIL_SUCCESS = "UPDATE_MEMBER_PARENT_EMAIL_SUCCESS";
export const UPDATE_MEMBER_PARENT_EMAIL_FAILURE = "UPDATE_MEMBER_PARENT_EMAIL_FAILURE";
export const UPDATE_MEMBER_PARENT_EMAIL_RESET = "UPDATE_MEMBER_PARENT_EMAIL_RESET";

export const RESEND_MEMBER_EMAIL_REQUEST = "RESEND_MEMBER_EMAIL_REQUEST";
export const RESEND_MEMBER_EMAIL_SUCCESS = "RESEND_MEMBER_EMAIL_SUCCESS";
export const RESEND_MEMBER_EMAIL_FAILURE = "RESEND_MEMBER_EMAIL_FAILURE";

export const RESEND_MEMBER_PARENT_EMAIL_REQUEST = "RESEND_MEMBER_PARENT_EMAIL_REQUEST";
export const RESEND_MEMBER_PARENT_EMAIL_SUCCESS = "RESEND_MEMBER_PARENT_EMAIL_SUCCESS";
export const RESEND_MEMBER_PARENT_EMAIL_FAILURE = "RESEND_MEMBER_PARENT_EMAIL_FAILURE";

export const VERIFY_PARENT_EMAIL_REQUEST = "VERIFY_PARENT_EMAIL_REQUEST";
export const VERIFY_PARENT_EMAIL_SUCCESS = "VERIFY_PARENT_EMAIL_SUCCESS";
export const VERIFY_PARENT_EMAIL_FAILURE = "VERIFY_PARENT_EMAIL_FAILURE";
