export const TEAM_NEWS_REQUEST = "TEAM_NEWS_REQUEST";
export const TEAM_NEWS_SUCCESS = "TEAM_NEWS_SUCCESS";
export const TEAM_NEWS_FAILURE = "TEAM_NEWS_FAILURE";

export const ADD_TEAM_NEWS_REQUEST = "ADD_TEAM_NEWS_REQUEST";
export const ADD_TEAM_NEWS_SUCCESS = "ADD_TEAM_NEWS_SUCCESS";
export const ADD_TEAM_NEWS_FAILURE = "ADD_TEAM_NEWS_FAILURE";

export const EDIT_TEAM_NEWS_REQUEST = "EDIT_TEAM_NEWS_REQUEST";
export const EDIT_TEAM_NEWS_SUCCESS = "EDIT_TEAM_NEWS_SUCCESS";
export const EDIT_TEAM_NEWS_FAILURE = "EDIT_TEAM_NEWS_FAILURE";

export const DELETE_TEAM_NEWS_REQUEST = "DELETE_TEAM_NEWS_REQUEST";
export const DELETE_TEAM_NEWS_SUCCESS = "DELETE_TEAM_NEWS_SUCCESS";
export const DELETE_TEAM_NEWS_FAILURE = "DELETE_TEAM_NEWS_FAILURE";

export const GET_TEAM_NEWS_BY_ID_REQUEST = "GET_TEAM_NEWS_BY_ID_REQUEST";
export const GET_TEAM_NEWS_BY_ID_SUCCESS = "GET_TEAM_NEWS_BY_ID_SUCCESS";
export const GET_TEAM_NEWS_BY_ID_FAILURE = "GET_TEAM_NEWS_BY_ID_FAILURE";
