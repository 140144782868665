import {
  SPORTS_PLAYBOOK_REQUEST,
  SPORTS_PLAYBOOK_SUCCESS,
  SPORTS_PLAYBOOK_FAILURE,
  SELECTED_SPORT,
  STORE_NAME_RESET,
  TEAM_NEWS_REQUEST,
  TEAM_NEWS_SUCCESS,
  TEAM_NEWS_FAILURE,
  ADD_TEAM_NEWS_REQUEST,
  ADD_TEAM_NEWS_SUCCESS,
  ADD_TEAM_NEWS_FAILURE,
  EDIT_TEAM_NEWS_REQUEST,
  EDIT_TEAM_NEWS_SUCCESS,
  EDIT_TEAM_NEWS_FAILURE,
  GET_TEAM_NEWS_BY_ID_REQUEST,
  GET_TEAM_NEWS_BY_ID_SUCCESS,
  GET_TEAM_NEWS_BY_ID_FAILURE,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
  UPDATE_USER_ID,
  GET_PLAYER_BY_ID_REQUEST,
  GET_PLAYER_BY_ID_SUCCESS,
  GET_PLAYER_BY_ID_FAILURE,
  SEND_INVITATION_REQUEST,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_FAILURE,
  DELETE_TEAM_NEWS_REQUEST,
  DELETE_TEAM_NEWS_SUCCESS,
  DELETE_TEAM_NEWS_FAILURE,
  GET_SPORT_GROUND_BY_SPORT_ID_REQUEST,
  GET_SPORT_GROUND_BY_SPORT_ID_SUCCESS,
  GET_SPORT_GROUND_BY_SPORT_ID_FAILURE,
  UPDATE_USER,
  GET_SPORTWISE_POSITIONS_REQUEST,
  GET_SPORTWISE_POSITIONS_SUCCESS,
  GET_SPORTWISE_POSITIONS_FAILURE,
} from "../constants/sports";

import {
  EMAIL_CHECK_REQUEST,
  EMAIL_CHECK_SUCCESS,
  EMAIL_CHECK_FAILURE,
} from "../constants/user";

import axios from "axios";

export const GetSports = (values) => async (dispatch) => {
  try {
    dispatch({ type: SPORTS_PLAYBOOK_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/sport/findSport`,
      values
    );
    dispatch(SetSelectedSport());
    dispatch({ type: SPORTS_PLAYBOOK_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: SPORTS_PLAYBOOK_FAILURE });
  }
};

export const SetSelectedSport = (values) => async (dispatch) => {
  dispatch({ type: SELECTED_SPORT, payload: values });
};

export const ResetState = () => async (dispatch) => {
  dispatch({ type: STORE_NAME_RESET });
};

export const GetPlayerById = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_PLAYER_BY_ID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/profile/getUserByIdAndAuthcode`,
      values
    );
    dispatch({ type: GET_PLAYER_BY_ID_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_PLAYER_BY_ID_FAILURE });
  }
};

export const GetTeamNews = (values) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_NEWS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamNews/getAllNewData`,
      values
    );
    dispatch({ type: TEAM_NEWS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: TEAM_NEWS_FAILURE });
  }
};

export const AddTeamNewsData = (values) => async (dispatch) => {
  try {
    dispatch({ type: ADD_TEAM_NEWS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamNews/createTeamNews`,
      values
    );
    dispatch({ type: ADD_TEAM_NEWS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: ADD_TEAM_NEWS_FAILURE });
  }
};

export const EditTeamNews = (values) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_TEAM_NEWS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamNews/updateTeamNews`,
      values
    );
    dispatch({ type: EDIT_TEAM_NEWS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: EDIT_TEAM_NEWS_FAILURE });
  }
};

export const DeleteTeamNews = (values) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TEAM_NEWS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamNews/deleteTeamNewsById`,
      values
    );
    dispatch({ type: DELETE_TEAM_NEWS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: DELETE_TEAM_NEWS_FAILURE });
  }
};

export const GetTeamNewsByNewsId = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_TEAM_NEWS_BY_ID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamNews/getTeamnewsById`,
      values
    );
    dispatch({ type: GET_TEAM_NEWS_BY_ID_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_TEAM_NEWS_BY_ID_FAILURE });
  }
};

export const DeleteTeam = (values) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TEAM_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Team/deleteTeam`,
      values
    );
    dispatch({ type: DELETE_TEAM_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: DELETE_TEAM_FAILURE });
  }
};

export const SetEditPlayerId = (values) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_ID, payload: values });
};

export const SetEditPlayer = (values) => async (dispatch) => {
  dispatch({ type: UPDATE_USER, payload: values });
};

export const sendInvitationToPlayres = (values) => async (dispatch) => {
  try {
    dispatch({ type: SEND_INVITATION_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/sendAllEmail`,
      values
    );
    dispatch({ type: SEND_INVITATION_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: SEND_INVITATION_FAILURE });
  }
};

export const CheckEmail = (values) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_CHECK_REQUEST });
    const emailcheck = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/checkEmailExists`,
      {
        vEmail: values,
      }
    );
    dispatch({ type: EMAIL_CHECK_SUCCESS, payload: emailcheck.data });
  } catch (err) {
    dispatch({ type: EMAIL_CHECK_FAILURE });
  }
};

export const GetSportsGroundBySportsId = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_SPORT_GROUND_BY_SPORT_ID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/SportGround/getAllSportGroundDataById`,
      values
    );
    dispatch({
      type: GET_SPORT_GROUND_BY_SPORT_ID_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_SPORT_GROUND_BY_SPORT_ID_FAILURE });
  }
};

export const GetSportWisePositions = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_SPORTWISE_POSITIONS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/SportPosition/getSportPositionData`,
      values
    );
    dispatch({
      type: GET_SPORTWISE_POSITIONS_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_SPORTWISE_POSITIONS_FAILURE });
  }
};
