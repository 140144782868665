import {
  GET_ALL_COLORS_DATA_REQUEST,
  GET_ALL_COLORS_DATA_SUCCESS,
  GET_ALL_COLORS_DATA_FAILURE,
} from "../constants/color";

export function colorsReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_COLORS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_COLORS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllColorsData: action.payload,
      };
    case GET_ALL_COLORS_DATA_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
