import {
  ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_CLEAR_DATA,
  ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_FAILURE,
  ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_REQUEST,
  ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_SUCCESS,
  CREATE_TEAM_POST_FAILURE,
  CREATE_TEAM_POST_REQUEST,
  CREATE_TEAM_POST_SUCCESS,
  GET_ALL_TEAM_MESSAGE_BOARD_POST_FAILURE,
  GET_ALL_TEAM_MESSAGE_BOARD_POST_REQUEST,
  GET_ALL_TEAM_MESSAGE_BOARD_POST_SUCCESS,
  POST_DATA_ARRAY,
  REPORT_POST_TEAM_FAILURE,
  REPORT_POST_TEAM_REQUEST,
  REPORT_POST_TEAM_RESET,
  REPORT_POST_TEAM_SUCCESS,
  REPORT_POST_TEAM_VALUES,
  SELECTED_TEAM_FOR_CREATE_POST,
  SINGLE_POST_TEAM_ALL_LIKES_FAILURE,
  SINGLE_POST_TEAM_ALL_LIKES_REQUEST,
  SINGLE_POST_TEAM_ALL_LIKES_SUCCESS,
  TEAM_AND_SPORT_OF_USER_FAILURE,
  TEAM_AND_SPORT_OF_USER_REQUEST,
  TEAM_AND_SPORT_OF_USER_SUCCESS,
  TEMPORARY_STORE_TEAM_POST_LIKE_CLEAR_DATA,
  TEMPORARY_STORE_TEAM_POST_LIKE_DATA_FAILURE,
  TEMPORARY_STORE_TEAM_POST_LIKE_DATA_REQUEST,
  TEMPORARY_STORE_TEAM_POST_LIKE_DATA_SUCCESS,
  VIEW_SINGLE_POST_BY_OBJECT,
  VIEW_SINGLE_POST_BY_OBJECT_RESET,
} from "../constants/teamMessageBoard";

export function teamMessageBoardReducer(
  state = { temporaryStoreTeamLikedPostsData: [] },
  action
) {
  switch (action.type) {
    case CREATE_TEAM_POST_REQUEST:
    case GET_ALL_TEAM_MESSAGE_BOARD_POST_REQUEST:
    case ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_REQUEST:
    case TEMPORARY_STORE_TEAM_POST_LIKE_DATA_REQUEST:
    case SINGLE_POST_TEAM_ALL_LIKES_REQUEST:
    case REPORT_POST_TEAM_REQUEST:
    case TEAM_AND_SPORT_OF_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_TEAM_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        createTeamPostData: action.payload,
      };
    case GET_ALL_TEAM_MESSAGE_BOARD_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllTeamMessagePosts: action.payload,
      };

    case ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        postLoveItDataTeam: action.payload,
      };

    case TEMPORARY_STORE_TEAM_POST_LIKE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        temporaryStoreTeamLikedPostsData: [
          action.payload,
          ...state.temporaryStoreTeamLikedPostsData.filter(
            (res) =>
              parseInt(res.iTeamPostMessageBoardId) !==
              parseInt(action.payload.iTeamPostMessageBoardId)
          ),
        ],
      };

    case SINGLE_POST_TEAM_ALL_LIKES_SUCCESS:
      return {
        ...state,
        loading: false,
        singlePostAllLikesDataTeam: action.payload,
      };

    case REPORT_POST_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        reportPostTeamData: action.payload,
      };

    case VIEW_SINGLE_POST_BY_OBJECT:
      return {
        ...state,
        loading: false,
        singlePostModalData: action.payload,
      };

    case TEAM_AND_SPORT_OF_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssociatedTeamsAndSportsOfUser: action.payload,
      };

    case SELECTED_TEAM_FOR_CREATE_POST:
      return {
        ...state,
        loading: false,
        selectedTeam: action.payload,
      };
    case REPORT_POST_TEAM_VALUES:
      return {
        ...state,
        loading: false,
        report: action.payload,
      };
    case POST_DATA_ARRAY:
      return {
        ...state,
        loading: false,
        postDataArray: action.payload,
      };

    case GET_ALL_TEAM_MESSAGE_BOARD_POST_FAILURE:
    case CREATE_TEAM_POST_FAILURE:
    case ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_FAILURE:
    case TEMPORARY_STORE_TEAM_POST_LIKE_DATA_FAILURE:
    case SINGLE_POST_TEAM_ALL_LIKES_FAILURE:
    case REPORT_POST_TEAM_FAILURE:
    case TEAM_AND_SPORT_OF_USER_FAILURE:
      return {
        ...state,
        loading: true,
      };

    case ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_CLEAR_DATA:
      return {
        ...state,
        loading: false,
        postLoveItDataTeam: "",
      };
    case TEMPORARY_STORE_TEAM_POST_LIKE_CLEAR_DATA:
      return {
        ...state,
        loading: false,
        temporaryStoreTeamLikedPostsData: [],
      };
    case VIEW_SINGLE_POST_BY_OBJECT_RESET:
      return {
        ...state,
        loading: false,
        singlePostModalData: {},
      };
    case REPORT_POST_TEAM_RESET:
      return {
        ...state,
        loading: false,
        reportPostTeamData: {},
      };
    default:
      return state;
  }
}
