import {
  ADD_CANVAS_FOLDER_REQUEST,
  ADD_CANVAS_FOLDER_SUCCESS,
  ADD_CANVAS_FOLDER_FAILURE,
  GET_CANVAS_FOLDER_LIST_REQUEST,
  GET_CANVAS_FOLDER_LIST_SUCCESS,
  GET_CANVAS_FOLDER_LIST_FAILURE,
  RESET_STATE,
} from "../constants/canvasFolder";
import axios from "axios";

export const CreateCanvasFolder = (values) => async (dispatch) => {
  try {
    dispatch({ type: ADD_CANVAS_FOLDER_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/PlayBookFolder/createPlaybookFolder`,
      values
    );
    dispatch({
      type: ADD_CANVAS_FOLDER_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: ADD_CANVAS_FOLDER_FAILURE });
  }
};

export const GetAllFolderList = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_CANVAS_FOLDER_LIST_REQUEST });
    let resData;
    let data = {
      ePlaybookType: "My Playbook",
      iUserId: values.iUserId,
    };
    if (parseInt(values.iParentId) !== 0) {
      data = {
        ...data,
        iParentId: values.iParentId,
      };
    }
    if (values?.ePlaybookType === "Team Playbook") {
      data = {
        ...data,
        iTeamIds: JSON.stringify(values.iTeamIds),
        ePlaybookType: "Team Playbook",
      };
    }
    if (values?.ePlaybookType) {
      resData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/playbook/getSharedTeam`,
        data
      );
    } else {
      resData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/playbook/getSharedTeam`,
        {
          ...data,
          ePlaybookType: "Team Playbook",
        }
      );
    }
    dispatch({
      type: GET_CANVAS_FOLDER_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_CANVAS_FOLDER_LIST_FAILURE });
  }
};

export const GetAllFolderListOld = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_CANVAS_FOLDER_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/PlayBookFolder/getAllDataByiUserAndiTeamid`,
      values
    );
    dispatch({
      type: GET_CANVAS_FOLDER_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_CANVAS_FOLDER_LIST_FAILURE });
  }
};

export const ResetCanvasFolderStore = () => async (dispatch) => {
  dispatch({ type: RESET_STATE });
};
