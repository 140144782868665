import {
  ADD_PLAYER_REQUEST,
  ADD_PLAYER_SUCCESS,
  ADD_PLAYER_FAILURE,
  DELETE_PLAYER_REQUEST,
  DELETE_PLAYER_SUCCESS,
  DELETE_PLAYER_FAILURE,
  UPDATE_PLAYER_REQUEST,
  UPDATE_PLAYER_SUCCESS,
  UPDATE_PLAYER_FAILURE,
  TEAM_PLAYER_LIST_REQUEST,
  TEAM_PLAYER_LIST_SUCCESS,
  TEAM_PLAYER_LIST_FAILURE,
  EXIST_PLAYER_ADD_REQUEST,
  EXIST_PLAYER_ADD_SUCCESS,
  EXIST_PLAYER_ADD_FAILURE,
  VERIFY_PLAYER_EMAIL_REQUEST,
  VERIFY_PLAYER_EMAIL_SUCCESS,
  VERIFY_PLAYER_EMAIL_FAILURE,
  VERIFY_EXIST_PLAYER_EMAIL_REQUEST,
  VERIFY_EXIST_PLAYER_EMAIL_SUCCESS,
  VERIFY_EXIST_PLAYER_EMAIL_FAILURE,
  UPDATE_MEMBER_EMAIL_REQUEST,
  UPDATE_MEMBER_EMAIL_SUCCESS,
  UPDATE_MEMBER_EMAIL_FAILURE,
  UPDATE_MEMBER_PARENT_EMAIL_REQUEST,
  UPDATE_MEMBER_PARENT_EMAIL_SUCCESS,
  UPDATE_MEMBER_PARENT_EMAIL_FAILURE,
  RESEND_MEMBER_EMAIL_REQUEST,
  RESEND_MEMBER_EMAIL_SUCCESS,
  RESEND_MEMBER_EMAIL_FAILURE,
  RESEND_MEMBER_PARENT_EMAIL_REQUEST,
  RESEND_MEMBER_PARENT_EMAIL_SUCCESS,
  RESEND_MEMBER_PARENT_EMAIL_FAILURE,
  VERIFY_PARENT_EMAIL_REQUEST,
  VERIFY_PARENT_EMAIL_SUCCESS,
  VERIFY_PARENT_EMAIL_FAILURE,
  ADD_PLAYER_RESET,
  DELETE_PLAYER_RESET,
  UPDATE_MEMBER_EMAIL_RESET,
  UPDATE_MEMBER_PARENT_EMAIL_RESET,
} from "../constants/teamPlayer";

import { STORE_NAME_RESET } from "../constants/sports";

const initialState = {};

export function teamPlayerReducer(state = {}, action) {
  switch (action.type) {
    case ADD_PLAYER_REQUEST:
    case DELETE_PLAYER_REQUEST:
    case UPDATE_PLAYER_REQUEST:
    case TEAM_PLAYER_LIST_REQUEST:
    case EXIST_PLAYER_ADD_REQUEST:
    case VERIFY_PLAYER_EMAIL_REQUEST:
    case VERIFY_EXIST_PLAYER_EMAIL_REQUEST:
    case UPDATE_MEMBER_EMAIL_REQUEST:
    case UPDATE_MEMBER_PARENT_EMAIL_REQUEST:
    case RESEND_MEMBER_EMAIL_REQUEST:
    case RESEND_MEMBER_PARENT_EMAIL_REQUEST:
    case VERIFY_PARENT_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        addPlayerData: action.payload,
      };
    case DELETE_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        deletePlayerData: action.payload,
      };
    case UPDATE_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        updatePlayerData: action.payload,
      };
    case TEAM_PLAYER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        teamPlayerListData: action.payload,
      };
    case EXIST_PLAYER_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        existPlayerAddData: action.payload,
      };
    case VERIFY_PLAYER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        verifyPlayerData: action.payload,
      };
    case VERIFY_EXIST_PLAYER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        verifyExistPlayerData: action.payload,
      };
    case UPDATE_MEMBER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        updateMemberEmailData: action.payload,
      };
    case UPDATE_MEMBER_PARENT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        updateMemberParentEmailData: action.payload,
      };
    case RESEND_MEMBER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        resendMemberEmailData: action.payload,
      };
    case RESEND_MEMBER_PARENT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        resendParentEmailData: action.payload,
      };
    case VERIFY_PARENT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        verifyParentEmailData: action.payload,
      };
    case ADD_PLAYER_RESET:
      return {
        ...state,
        loading: false,
        addPlayerData: {},
      };
    case DELETE_PLAYER_RESET:
      return {
        ...state,
        loading: false,
        deletePlayerData: {},
      };
    case VERIFY_PARENT_EMAIL_FAILURE:
    case RESEND_MEMBER_PARENT_EMAIL_FAILURE:
    case RESEND_MEMBER_EMAIL_FAILURE:
    case UPDATE_MEMBER_PARENT_EMAIL_FAILURE:
    case UPDATE_MEMBER_EMAIL_FAILURE:
    case ADD_PLAYER_FAILURE:
    case DELETE_PLAYER_FAILURE:
    case UPDATE_PLAYER_FAILURE:
    case TEAM_PLAYER_LIST_FAILURE:
    case EXIST_PLAYER_ADD_FAILURE:
    case VERIFY_PLAYER_EMAIL_FAILURE:
    case VERIFY_EXIST_PLAYER_EMAIL_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MEMBER_PARENT_EMAIL_RESET:
      return {
        ...state,
        loading: false,
        updateMemberParentEmailData: {},
      };
    case UPDATE_MEMBER_EMAIL_RESET:
      return {
        ...state,
        loading: false,
        updateMemberEmailData: {},
      };
    case STORE_NAME_RESET:
      return initialState;
    default:
      return state;
  }
}
