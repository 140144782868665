export const CREATE_MEMBER_REQUEST = "CREATE_MEMBER_REQUEST";
export const CREATE_MEMBER_SUCCESS = "CREATE_MEMBER_SUCCESS";
export const CREATE_MEMBER_FAILURE = "CREATE_MEMBER_FAILURE";

export const EDIT_MEMBER_REQUEST = "EDIT_MEMBER_REQUEST";
export const EDIT_MEMBER_SUCCESS = "EDIT_MEMBER_SUCCESS";
export const EDIT_MEMBER_FAILURE = "EDIT_MEMBER_FAILURE";

export const DELETE_MEMBER_REQUEST = "DELETE_MEMBER_REQUEST";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_FAILURE = "DELETE_MEMBER_FAILURE";

export const GET_MEMBER_BY_MEMBERID_REQUEST = "GET_MEMBER_BY_MEMBERID_REQUEST";
export const GET_MEMBER_BY_MEMBERID_SUCCESS = "GET_MEMBER_BY_MEMBERID_SUCCESS";
export const GET_MEMBER_BY_MEMBERID_FAILURE = "GET_MEMBER_BY_MEMBERID_FAILURE";

export const GET_MEMBERS_REQUEST = "GET_MEMBERS_REQUEST";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_FAILURE = "GET_MEMBERS_FAILURE";

export const GET_MEMBER_BY_ROLEID_REQUEST = "GET_MEMBER_BY_ROLEID_REQUEST";
export const GET_MEMBER_BY_ROLEID_SUCCESS = "GET_MEMBER_BY_ROLEID_SUCCESS";
export const GET_MEMBER_BY_ROLEID_FAILURE = "GET_MEMBER_BY_ROLEID_FAILURE";

export const STORE_NAME_RESET = "STORE_NAME_RESET";
