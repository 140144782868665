import React from "react";
import { Avatar } from "primereact/avatar";
import axios from "axios";
import { useGetUnder13User } from "../../hooks/useGetUnder13User";

function ChatFollowUser({ item }) {
  const isUnder13 = useGetUnder13User();
  const [userFollowStatus, setUserFollowStatus] = React.useState(
    item?.eRequesterStatus
  );
  // when the user follow group member then used...
  const handleFollowChatUser = React.useCallback(
    async (e, userData) => {
      e.preventDefault();
      // here through we have to follow user then used..
      const values = {
        iUserId: sessionStorage.getItem("id"),
        iRequesterId: userData?.iUserId,
        userAccountType: sessionStorage.getItem("userProfileType"),
        requestUserAccountType: userData?.eUserAccountType,
      };
      const resUserFollowRequest = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/follower/createOrCancelFollowRequest`,
        values
      );

      if (resUserFollowRequest && resUserFollowRequest?.data?.code === "200") {
        setUserFollowStatus(resUserFollowRequest?.data?.data?.eRequesterStatus);
      }
    },
    [setUserFollowStatus]
  );

  return (
    <>
      <div className="box">
        <div className="left">
          <div className="image-box">
            {item?.user?.vImage ===
            `${process.env.REACT_APP_GET_PUBLIC_PATH}/uploads/user/null` ? (
              <>
                <Avatar
                  label={
                    item?.user?.vFirstName.slice(0, 1) +
                    item?.user?.vLastName.slice(0, 1)
                  }
                  shape="circle"
                />
              </>
            ) : (
              <>
                <Avatar image={item?.user?.vImage} />
              </>
            )}
          </div>
          <p>{item?.user?.vFirstName + " " + item?.user?.vLastName}</p>
        </div>
        {isUnder13 ? (
          <></>
        ) : (
          <div className="right">
            {userFollowStatus === null &&
            parseInt(item?.iUserId) ===
              parseInt(sessionStorage.getItem("id")) ? (
              <>
                {"(You)"}
                {/* <div className="action-btn"><button className="decline-btn abtn">Following</button></div> */}
                {/* <div className="msg-btn">You</div> */}
              </>
            ) : userFollowStatus === "Following" ? (
              <>
                <div className="msg-btn">Following</div>
              </>
            ) : userFollowStatus === null &&
              parseInt(item?.iUserId) !==
                parseInt(sessionStorage.getItem("id")) ? (
              <>
                <button
                  className="msg-btn submit-form"
                  onClick={(e) => handleFollowChatUser(e, item?.user)}
                >
                  Follow
                </button>
              </>
            ) : userFollowStatus !== null &&
              userFollowStatus !== "Following" &&
              item?.iUserId !== sessionStorage.getItem("id") ? (
              <>
                <div className="msg-btn">Requested</div>
              </>
            ) : (
              <></>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.001"
              height="18.024"
              viewBox="0 0 24.001 24.024"
            >
              <g id="fi-rr-comment" transform="translate(0.001 0.024)">
                <path
                  id="Path_461"
                  data-name="Path 461"
                  d="M24,11.247A12.012,12.012,0,1,0,12.017,24H19a5.005,5.005,0,0,0,5-5ZM22,19a3,3,0,0,1-3,3H12.017a10.041,10.041,0,0,1-7.476-3.343,9.917,9.917,0,0,1-2.476-7.814,10.043,10.043,0,0,1,8.656-8.761A10.564,10.564,0,0,1,12.021,2,9.921,9.921,0,0,1,18.4,4.3,10.041,10.041,0,0,1,22,11.342Z"
                ></path>
                <path
                  id="Path_462"
                  data-name="Path 462"
                  d="M8,9h4a1,1,0,0,0,0-2H8A1,1,0,0,0,8,9Z"
                ></path>
                <path
                  id="Path_463"
                  data-name="Path 463"
                  d="M16,11H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"
                ></path>
                <path
                  id="Path_464"
                  data-name="Path 464"
                  d="M16,15H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"
                ></path>
              </g>
            </svg>
            {/* <div className="msg-btn">Message</div> */}
            {/* <button className="delete-btn">
                                                                                            <i className="far fa-trash-alt"></i>
                                                                                        </button> */}
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(ChatFollowUser);
