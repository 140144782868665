export const INDIVIDAUL_CHAT_USERS_REQUEST = "INDIVIDAUL_CHAT_USERS_REQUEST"
export const INDIVIDAUL_CHAT_USERS_SUCCESS = "INDIVIDAUL_CHAT_USERS_SUCCESS"
export const INDIVIDAUL_CHAT_USERS_FAILURE = "INDIVIDAUL_CHAT_USERS_FAILURE"

export const TEAMS_CHAT_LIST_REQUEST = "TEAMS_CHAT_LIST_REQUEST"
export const TEAMS_CHAT_LIST_SUCCESS = "TEAMS_CHAT_LIST_SUCCESS"
export const TEAMS_CHAT_LIST_FAILURE = "TEAMS_CHAT_LIST_FAILURE"

export const GET_ALL_ROOM_CHAT_DATA_REQUEST = "GET_ALL_ROOM_CHAT_DATA_REQUEST"
export const GET_ALL_ROOM_CHAT_DATA_SUCCESS = "GET_ALL_ROOM_CHAT_DATA_SUCCESS"
export const GET_ALL_ROOM_CHAT_DATA_FAILURE = "GET_ALL_ROOM_CHAT_DATA_FAILURE"

export const CREATE_ROOM_CHAT_DATA_REQUEST = "CREATE_ROOM_CHAT_DATA_REQUEST"
export const CREATE_ROOM_CHAT_DATA_SUCCESS = "CREATE_ROOM_CHAT_DATA_SUCCESS"
export const CREATE_ROOM_CHAT_DATA_FAILURE = "CREATE_ROOM_CHAT_DATA_FAILURE"

export const GET_INDIVIDUAL_CHAT_DATA_REQUEST = "GET_INDIVIDUAL_CHAT_DATA_REQUEST"
export const GET_INDIVIDUAL_CHAT_DATA_SUCCESS = "GET_INDIVIDUAL_CHAT_DATA_SUCCESS"
export const GET_INDIVIDUAL_CHAT_DATA_FAILURE = "GET_INDIVIDUAL_CHAT_DATA_FAILURE"

export const GET_TEAMS_CHAT_DATA_REQUEST = "GET_TEAMS_CHAT_DATA_REQUEST"
export const GET_TEAMS_CHAT_DATA_SUCCESS = "GET_TEAMS_CHAT_DATA_SUCCESS"
export const GET_TEAMS_CHAT_DATA_FAILURE = "GET_TEAMS_CHAT_DATA_FAILURE"

export const SEND_TEAMS_MESSAGE_REQUEST = "SEND_TEAMS_MESSAGE_REQUEST"
export const SEND_TEAMS_MESSAGE_SUCCESS = "SEND_TEAMS_MESSAGE_SUCCESS"
export const SEND_TEAMS_MESSAGE_FAILURE = "SEND_TEAMS_MESSAGE_FAILURE"

export const GET_MESSAGE_INFO_TIME_DETAILS_REQUEST = "GET_MESSAGE_INFO_TIME_DETAILS_REQUEST"
export const GET_MESSAGE_INFO_TIME_DETAILS_SUCCESS = "GET_MESSAGE_INFO_TIME_DETAILS_SUCCESS"
export const GET_MESSAGE_INFO_TIME_DETAILS_FAILURE = "GET_MESSAGE_INFO_TIME_DETAILS_FAILURE"

export const GET_ALL_TEAMS_GROUP_MEMBERS_REQUEST = "GET_ALL_TEAMS_GROUP_MEMBERS_REQUEST"
export const GET_ALL_TEAMS_GROUP_MEMBERS_SUCCESS = "GET_ALL_TEAMS_GROUP_MEMBERS_SUCCESS"
export const GET_ALL_TEAMS_GROUP_MEMBERS_FAILURE = "GET_ALL_TEAMS_GROUP_MEMBERS_FAILURE"

export const DELETE_CHAT_TEAMS_MESSAGE_REQUEST = "DELETE_CHAT_TEAMS_MESSAGE_REQUEST"
export const DELETE_CHAT_TEAMS_MESSAGE_SUCCESS = "DELETE_CHAT_TEAMS_MESSAGE_SUCCESS"
export const DELETE_CHAT_TEAMS_MESSAGE_FAILURE = "DELETE_CHAT_TEAMS_MESSAGE_FAILURE"

export const DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_REQUEST = "DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_REQUEST"
export const DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_SUCCESS = "DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_SUCCESS"
export const DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_FAILURE = "DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_FAILURE"

export const GET_CHAT_ROOM_MEDIA_REQUEST = "GET_CHAT_ROOM_MEDIA_REQUEST"
export const GET_CHAT_ROOM_MEDIA_SUCCESS = "GET_CHAT_ROOM_MEDIA_SUCCESS"
export const GET_CHAT_ROOM_MEDIA_FAILURE = "GET_CHAT_ROOM_MEDIA_FAILURE"
