import React from "react";
import PropsTypes from "prop-types";

function Input({
  ref,
  value,
  type,
  title,
  placeHolder,
  name,
  isSetValue,
  isError,
  readOnly,
  newClass,
  isClear,
  setConditionValue,
  isValidate,
  min,
  max,
  setLoadError,
  loadError,
  onChange,
  changeParams,
  autocomplete,
  isPassword,
}) {
  const [newValue, setNewValue] = React.useState("");
  const [error, setError] = React.useState({ type: false, message: "" });
  const [submitError, setSubmitError] = React.useState(false);
  // const max = new Date().toISOString().split("T")[0];

  React.useEffect(() => {
    if (isSetValue) {
      setNewValue(placeHolder);
    }
  }, [setNewValue, isSetValue, placeHolder]);

  React.useEffect(() => {
    setSubmitError(isError);
  }, [setSubmitError, isError]);

  const handleValidate = React.useCallback(
    (e) => {
      setSubmitError(false);
      if (isValidate) {
        if (e.target.value === "") {
          if (setLoadError) {
            setLoadError({ ...loadError, name: true });
          }
          setError({ type: true, message: `${title} Is Required` });
        } else if (type === "email" || name === "vZipCode") {
          if (name === "vZipCode") {
            // var filter = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
            var filter = /^\d{5}$|^\d{4}$|^\d{4}-\d{4}$/;
          } else {
            filter =
              /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
          }
          if (!filter.test(e.target.value)) {
            if (setLoadError) {
              setLoadError({ ...loadError, name: true });
            }
            setError({ type: true, message: `Invalid ${title}.` });
          } else {
            if (setLoadError) {
              setLoadError({});
            }
            setError({ type: false, message: "" });
          }
          // } else if (type === "password") {
        } else if (isPassword) {
          if (e.target.value.length <= "7" || e.target.value.length >= "20") {
            if (setLoadError) {
              setLoadError({ ...loadError, name: true });
            }
            setError({
              type: true,
              message: `${title} Must Be 8-20 Characters.`,
            });
          } else {
            if (setLoadError) {
              setLoadError({});
            }
            setError({ type: false, message: "" });
          }
        } else if (type === "date") {
          if (setLoadError) {
            setLoadError({});
          }
          setError({ type: false, message: "" });
          setNewValue(e.target.value);
        } else if (type === "file") {
          if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|svg)$/)) {
            if (setLoadError) {
              setLoadError({ ...loadError, name: true });
            }
            setError({ type: true, message: `Invalid Image.` });
          }
        } else {
          if (type === "file") {
            // console.log("++++ file", e.target.files[0]);
            const fReader = new FileReader();
            fReader.readAsDataURL(e.target.files[0]);
            fReader.onloadend = function (event) {
              setNewValue(event.target.result);
              const img = document.getElementById("profileImage");
              img.src = event.target.result;
            };
            if (setLoadError) {
              setLoadError({});
            }
            setError({ type: false, message: "" });
          } else {
            setNewValue(e.target.value);
            if (setConditionValue) {
              setConditionValue(e.target.value);
            }
            if (setLoadError) {
              setLoadError({});
            }
            setError({ type: false, message: "" });
          }
          if (setLoadError) {
            setLoadError({});
          }
          setError({ type: false, message: "" });
        }
      } else {
        setNewValue(e.target.value);
        if (setConditionValue) {
          setConditionValue(e.target.value);
        }
      }
    },
    [
      isValidate,
      type,
      name,
      isPassword,
      setLoadError,
      title,
      loadError,
      setConditionValue,
    ]
  );

  React.useEffect(() => {
    if (isClear) {
      setNewValue("");
      setError({ type: false, message: "" });
    }
  }, [isClear, setNewValue]);
  // console.log(error);
  // console.log(isClear, value);
  // /[2-9]{2}\d{8}/
  return (
    <>
      <input
        id={name}
        ref={ref}
        type={type || "text"}
        name={name}
        placeholder={placeHolder}
        className={newClass || `form-control`}
        onBlur={handleValidate}
        // onLoad={handleValidate}
        onChange={(e) => {
          setNewValue(
            name === "vSlug"
              ? e.target.value.replace(
                  /[`\s~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi,
                  ""
                )
              : name === "vLastName" || name === "vFirstName"
              ? e.target.value.replace(
                  /[`~0-9!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi,
                  ""
                )
              : name === "vSecondaryNumber"
              ? e.target.value.replace(/[^0-9]/g, "")
              : e.target.value
          );
          if (onChange) {
            onChange(e, changeParams);
          }
        }}
        value={value || newValue || ""}
        readOnly={readOnly}
        max={max}
        min={min}
        autoComplete={autocomplete}
      />
      {submitError ? (
        <p className="error">
          {title === "Zip-Code"
            ? `Please enter valid ${title}`
            : `${title} Is Required.`}
        </p>
      ) : error.type ? (
        <p className="error">{error.message} </p>
      ) : (
        <></>
      )}
    </>
  );
}

Input.propTypes = {
  placeHolder: PropsTypes.any,
  name: PropsTypes.string,
  type: PropsTypes.string,
  title: PropsTypes.string,
  isError: PropsTypes.bool,
  isSetValue: PropsTypes.bool,
  readOnly: PropsTypes.bool,
  isClear: PropsTypes.bool,
  newClass: PropsTypes.string,
  setConditionValue: PropsTypes.any,
  isValidate: PropsTypes.bool,
  min: PropsTypes.any,
  max: PropsTypes.any,
  setLoadError: PropsTypes.any,
  loadError: PropsTypes.any,
};

export default Input;
