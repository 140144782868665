export const RESET_STATE = "RESET_STATE";

export const ADD_CANVAS_FOLDER_REQUEST = "ADD_CANVAS_FOLDER_REQUEST";
export const ADD_CANVAS_FOLDER_SUCCESS = "ADD_CANVAS_FOLDER_SUCCESS";
export const ADD_CANVAS_FOLDER_FAILURE = "ADD_CANVAS_FOLDER_FAILURE";

export const GET_CANVAS_FOLDER_LIST_REQUEST = "GET_CANVAS_FOLDER_LIST_REQUEST";
export const GET_CANVAS_FOLDER_LIST_SUCCESS = "GET_CANVAS_FOLDER_LIST_SUCCESS";
export const GET_CANVAS_FOLDER_LIST_FAILURE = "GET_CANVAS_FOLDER_LIST_FAILURE";

export const RESET_ADD_CANVAS_SUCCESS = "RESET_ADD_CANVAS_SUCCESS"

