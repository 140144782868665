import {
    INDIVIDAUL_CHAT_USERS_REQUEST,
    INDIVIDAUL_CHAT_USERS_SUCCESS,
    INDIVIDAUL_CHAT_USERS_FAILURE,
    TEAMS_CHAT_LIST_REQUEST,
    TEAMS_CHAT_LIST_SUCCESS,
    TEAMS_CHAT_LIST_FAILURE,
    GET_ALL_ROOM_CHAT_DATA_REQUEST,
    GET_ALL_ROOM_CHAT_DATA_SUCCESS,
    GET_ALL_ROOM_CHAT_DATA_FAILURE,
    CREATE_ROOM_CHAT_DATA_REQUEST,
    CREATE_ROOM_CHAT_DATA_SUCCESS,
    CREATE_ROOM_CHAT_DATA_FAILURE,
    GET_INDIVIDUAL_CHAT_DATA_REQUEST,
    GET_INDIVIDUAL_CHAT_DATA_SUCCESS,
    GET_INDIVIDUAL_CHAT_DATA_FAILURE,
    GET_TEAMS_CHAT_DATA_REQUEST,
    GET_TEAMS_CHAT_DATA_SUCCESS,
    GET_TEAMS_CHAT_DATA_FAILURE,
    SEND_TEAMS_MESSAGE_REQUEST,
    SEND_TEAMS_MESSAGE_SUCCESS,
    SEND_TEAMS_MESSAGE_FAILURE,
    GET_MESSAGE_INFO_TIME_DETAILS_REQUEST,
    GET_MESSAGE_INFO_TIME_DETAILS_SUCCESS,
    GET_MESSAGE_INFO_TIME_DETAILS_FAILURE,
    GET_ALL_TEAMS_GROUP_MEMBERS_REQUEST,
    GET_ALL_TEAMS_GROUP_MEMBERS_SUCCESS,
    GET_ALL_TEAMS_GROUP_MEMBERS_FAILURE,
    DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_REQUEST,
    DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_SUCCESS,
    DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_FAILURE,
    DELETE_CHAT_TEAMS_MESSAGE_REQUEST,
    DELETE_CHAT_TEAMS_MESSAGE_SUCCESS,
    DELETE_CHAT_TEAMS_MESSAGE_FAILURE,
    GET_CHAT_ROOM_MEDIA_REQUEST,
    GET_CHAT_ROOM_MEDIA_SUCCESS,
    GET_CHAT_ROOM_MEDIA_FAILURE,
} from '../constants/newChat';

export function newChatReducer(state = {}, action) {
    switch (action.type) {
        case INDIVIDAUL_CHAT_USERS_REQUEST:
        case TEAMS_CHAT_LIST_REQUEST:
        case GET_ALL_ROOM_CHAT_DATA_REQUEST:
        case CREATE_ROOM_CHAT_DATA_REQUEST:
        case GET_INDIVIDUAL_CHAT_DATA_REQUEST:
        case GET_TEAMS_CHAT_DATA_REQUEST:
        case SEND_TEAMS_MESSAGE_REQUEST:
        case GET_MESSAGE_INFO_TIME_DETAILS_REQUEST:
        case GET_ALL_TEAMS_GROUP_MEMBERS_REQUEST:
        case DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_REQUEST:
        case DELETE_CHAT_TEAMS_MESSAGE_REQUEST:
        case GET_CHAT_ROOM_MEDIA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case INDIVIDAUL_CHAT_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                individualChatUserData: action.payload
            }
        case TEAMS_CHAT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                teamsChatListData: action.payload
            }
        case GET_ALL_ROOM_CHAT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                allChatData: action.payload
            }
        case CREATE_ROOM_CHAT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                createRoomChatData: action.payload
            }
        case GET_INDIVIDUAL_CHAT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                individualUserChatRecords: action.payload
            }
        case GET_TEAMS_CHAT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                teamUserChatRecords: action.payload
            }
        case SEND_TEAMS_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                sendTeamsMessageData: action.payload
            }
        case GET_MESSAGE_INFO_TIME_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                getMessageInfoTimeData: action.payload
            }
        case GET_ALL_TEAMS_GROUP_MEMBERS_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllTeamsGroupMembersData: action.payload
            }
        case DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteChatIndividualUserData: action.payload
            }
        case DELETE_CHAT_TEAMS_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteChatTeamsUserData: action.payload
            }
        case GET_CHAT_ROOM_MEDIA_SUCCESS:
            return {
                ...state,
                loading: false,
                getRoomChatMediaResult: action.payload
            }
        case INDIVIDAUL_CHAT_USERS_FAILURE:
        case TEAMS_CHAT_LIST_FAILURE:
        case GET_ALL_ROOM_CHAT_DATA_FAILURE:
        case CREATE_ROOM_CHAT_DATA_FAILURE:
        case GET_INDIVIDUAL_CHAT_DATA_FAILURE:
        case GET_TEAMS_CHAT_DATA_FAILURE:
        case SEND_TEAMS_MESSAGE_FAILURE:
        case GET_MESSAGE_INFO_TIME_DETAILS_FAILURE:
        case GET_ALL_TEAMS_GROUP_MEMBERS_FAILURE:
        case DELETE_CHAT_INDIVIDUAL_USER_MESSAGE_FAILURE:
        case DELETE_CHAT_TEAMS_MESSAGE_FAILURE:
        case GET_CHAT_ROOM_MEDIA_FAILURE:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}