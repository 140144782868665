import {
  IS_CONFIRM_FOLLOW_TO_USER_REQUEST,
  IS_CONFIRM_FOLLOW_TO_USER_SUCCESS,
  IS_CONFIRM_FOLLOW_TO_USER_FAILURE,
  IS_DELETE_USER_COMING_REQUEST_REQUEST,
  IS_DELETE_USER_COMING_REQUEST_SUCCESS,
  IS_DELETE_USER_COMING_REQUEST_FAILURE,
  IS_USER_CANCEL_REQUEST_REQUEST,
  IS_USER_CANCEL_REQUEST_SUCCESS,
  IS_USER_CANCEL_REQUEST_FAILURE,
  IS_FOLLOW_NEW_USER_REQUEST,
  IS_FOLLOW_NEW_USER_SUCCESS,
  IS_FOLLOW_NEW_USER_FAILURE,
  IS_REMOVE_USER_FOR_SUGGESTION_REQUEST,
  IS_REMOVE_USER_FOR_SUGGESTION_SUCCESS,
  IS_REMOVE_USER_FOR_SUGGESTION_FAILURE,
  IS_UNFOLLOW_USER_REQUEST,
  IS_UNFOLLOW_USER_SUCCESS,
  IS_UNFOLLOW_USER_FAILURE,
  IS_BLOCK_USER_REQUEST,
  IS_BLOCK_USER_SUCCESS,
  IS_BLOCK_USER_FAILURE,
  IS_FOLLOW_BACK_USER_REQUEST,
  IS_FOLLOW_BACK_USER_SUCCESS,
  IS_FOLLOW_BACK_USER_FAILURE,
  ALL_COMING_REQUEST_FOR_USER_REQUEST,
  ALL_COMING_REQUEST_FOR_USER_SUCCESS,
  ALL_COMING_REQUEST_FOR_USER_FAILURE,
  ALL_REQUESTED_USERS_REQUEST,
  ALL_REQUESTED_USERS_SUCCESS,
  ALL_REQUESTED_USERS_FAILURE,
  ALL_USERS_SUGGESTIONS_REQUEST,
  ALL_USERS_SUGGESTIONS_SUCCESS,
  ALL_USERS_SUGGESTIONS_FAILURE,
  ALL_USER_FOLLOWERS_REQUEST,
  ALL_USER_FOLLOWERS_SUCCESS,
  ALL_USER_FOLLOWERS_FAILURE,
  ALL_USER_FOLLOWING_LIST_REQUEST,
  ALL_USER_FOLLOWING_LIST_SUCCESS,
  ALL_USER_FOLLOWING_LIST_FAILURE,
  ALL_SEARCHED_USER_LIST_REQUEST,
  ALL_SEARCHED_USER_LIST_SUCCESS,
  ALL_SEARCHED_USER_LIST_FAILURE,
  DIRECT_FOLLOW_USER_REQUEST,
  DIRECT_FOLLOW_USER_SUCCESS,
  DIRECT_FOLLOW_USER_FAILURE,
  GET_ALL_FOLLOW_FOLLOWING_COUNT_REQUEST,
  GET_ALL_FOLLOW_FOLLOWING_COUNT_SUCCESS,
  GET_ALL_FOLLOW_FOLLOWING_COUNT_FAILURE,
  GET_ALL_FOLLOW_STATUS_WISE_USERS_REQUEST,
  GET_ALL_FOLLOW_STATUS_WISE_USERS_SUCCESS,
  GET_ALL_FOLLOW_STATUS_WISE_USERS_FAILURE,
  ALL_USER_FOLLOW_FOLLOWERS_SUCCESS,
  ALL_USER_FOLLOW_FOLLOWERS_REQUEST,
  GET_TEAM_FOLLOW_REQUEST,
  GET_TEAM_FOLLOW_SUCCESS,
  GET_TEAM_FOLLOW_FAILURE,
  GET_ALL_TEAM_FOLLOWERS_REQUEST,
  GET_ALL_TEAM_FOLLOWERS_SUCCESS,
  GET_ALL_TEAM_FOLLOWERS_FAILURE,
} from "../constants/follow";

export function FollowerReducer(state = {}, action) {
  switch (action.type) {
    case ALL_USER_FOLLOWING_LIST_REQUEST:
    case ALL_USER_FOLLOWERS_REQUEST:
    case ALL_USERS_SUGGESTIONS_REQUEST:
    case ALL_REQUESTED_USERS_REQUEST:
    case ALL_COMING_REQUEST_FOR_USER_REQUEST:
    case IS_BLOCK_USER_REQUEST:
    case IS_UNFOLLOW_USER_REQUEST:
    case IS_REMOVE_USER_FOR_SUGGESTION_REQUEST:
    case IS_FOLLOW_NEW_USER_REQUEST:
    case IS_USER_CANCEL_REQUEST_REQUEST:
    case IS_DELETE_USER_COMING_REQUEST_REQUEST:
    case IS_CONFIRM_FOLLOW_TO_USER_REQUEST:
    case IS_FOLLOW_BACK_USER_REQUEST:
    case ALL_SEARCHED_USER_LIST_REQUEST:
    case DIRECT_FOLLOW_USER_REQUEST:
    case GET_ALL_FOLLOW_FOLLOWING_COUNT_REQUEST:
    case GET_ALL_FOLLOW_STATUS_WISE_USERS_REQUEST:
    case ALL_USER_FOLLOW_FOLLOWERS_REQUEST:
    case GET_TEAM_FOLLOW_REQUEST:
    case GET_ALL_TEAM_FOLLOWERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case IS_CONFIRM_FOLLOW_TO_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        confirmFollowUserData: action.payload,
      };
    case ALL_USER_FOLLOWING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allUsersFollowingData: action.payload,
      };
    case ALL_USER_FOLLOWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allUserFollowersData: action.payload,
      };
    case ALL_USER_FOLLOW_FOLLOWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allUserFollowFollowersData: action.payload,
      };
    case ALL_USERS_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allSuggestedUsers: action.payload,
      };
    case ALL_REQUESTED_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allRequestedUsers: action.payload,
      };
    case ALL_COMING_REQUEST_FOR_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        allComingRequestForUserData: action.payload,
      };
    case IS_BLOCK_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        blockUserData: action.payload,
      };
    case IS_UNFOLLOW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        unfollowUserData: action.payload,
      };
    case IS_REMOVE_USER_FOR_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        removeUserForSuggestionData: action.payload,
      };
    case IS_FOLLOW_NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        followNewUserData: action.payload,
      };
    case IS_USER_CANCEL_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        cancelUserRequestData: action.payload,
      };
    case IS_DELETE_USER_COMING_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteUserComingRequestData: action.payload,
      };
    case IS_FOLLOW_BACK_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        followBackUserData: action.payload,
      };
    case ALL_SEARCHED_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allSearchedUserData: action.payload,
      };
    case DIRECT_FOLLOW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        directFollowUserData: action.payload,
      };
    case GET_ALL_FOLLOW_FOLLOWING_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllFollowFollowingCountData: action.payload,
      };
    case GET_ALL_FOLLOW_STATUS_WISE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllFollowStatusWiseUsersData: action.payload,
      };
    case GET_TEAM_FOLLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        getUserFollowTeamData: action.payload,
      };

      case GET_ALL_TEAM_FOLLOWERS_SUCCESS:
        return {
          ...state,
          loading: false,
          getAllTeamFollowersData: action.payload,
        };
    case GET_TEAM_FOLLOW_FAILURE:
    case ALL_USER_FOLLOWING_LIST_FAILURE:
    case ALL_USER_FOLLOWERS_FAILURE:
    case ALL_USERS_SUGGESTIONS_FAILURE:
    case ALL_REQUESTED_USERS_FAILURE:
    case ALL_COMING_REQUEST_FOR_USER_FAILURE:
    case IS_BLOCK_USER_FAILURE:
    case IS_UNFOLLOW_USER_FAILURE:
    case IS_REMOVE_USER_FOR_SUGGESTION_FAILURE:
    case IS_FOLLOW_NEW_USER_FAILURE:
    case IS_USER_CANCEL_REQUEST_FAILURE:
    case IS_DELETE_USER_COMING_REQUEST_FAILURE:
    case IS_CONFIRM_FOLLOW_TO_USER_FAILURE:
    case IS_FOLLOW_BACK_USER_FAILURE:
    case ALL_SEARCHED_USER_LIST_FAILURE:
    case DIRECT_FOLLOW_USER_FAILURE:
    case GET_ALL_FOLLOW_FOLLOWING_COUNT_FAILURE:
    case GET_ALL_FOLLOW_STATUS_WISE_USERS_FAILURE:
      case GET_ALL_TEAM_FOLLOWERS_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
