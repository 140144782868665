import {
  GET_ALL_CATEGORYWISE_SUBCATEGORY_REQUEST,
  GET_ALL_CATEGORYWISE_SUBCATEGORY_SUCCESS,
  GET_ALL_CATEGORYWISE_SUBCATEGORY_FAILURE,
} from "../constants/home";

export function homeReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_CATEGORYWISE_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CATEGORYWISE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        getCategorywiseSubCategoryData: action.payload,
      };
    case GET_ALL_CATEGORYWISE_SUBCATEGORY_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
