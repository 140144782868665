import {
  VERIFY_BETACODE_REQUEST,
  VERIFY_BETACODE_SUCCESS,
  VERIFY_BETACODE_FAILURE,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,
  PAYMENT_DETAILS,
  PAYMENT_INDENT,
  RESET,
  GET_ALL_SUBSCRIPTION_PLAN_REQUEST,
  GET_ALL_SUBSCRIPTION_PLAN_SUCCESS,
  GET_ALL_SUBSCRIPTION_PLAN_FAILURE,
  GET_ACTIVE_PLAN_REQUEST,
  GET_ACTIVE_PLAN_SUCCESS,
  GET_ACTIVE_PLAN_FAILURE,
  GRANT_CREDIT_REQUEST,
  GRANT_CREDIT_SUCCESS,
  GRANT_CREDIT_FAILURE,
  GET_ACTIVE_PLAN_BY_TEAM_ID_REQUEST,
  GET_ACTIVE_PLAN_BY_TEAM_ID_SUCCESS,
  GET_ACTIVE_PLAN_BY_TEAM_ID_FAILURE,
} from "../constants/subscription";

const initial = {};

export function subscriptionReducer(state = {}, action) {
  switch (action.type) {
    case VERIFY_BETACODE_REQUEST:
    case CREATE_SUBSCRIPTION_REQUEST:
    case GET_ALL_SUBSCRIPTION_PLAN_REQUEST:
    case GET_ACTIVE_PLAN_REQUEST:
    case GRANT_CREDIT_REQUEST:
    case GET_ACTIVE_PLAN_BY_TEAM_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_BETACODE_SUCCESS:
      return {
        ...state,
        loading: false,
        verifyBetacodeData: action.payload,
      };
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        createSubscriptionData: action.payload,
      };
    case PAYMENT_INDENT:
      return {
        ...state,
        loading: false,
        paymentIndentData: action.payload,
      };
    case PAYMENT_DETAILS:
      return {
        ...state,
        loading: false,
        paymentDetailsData: action.payload,
      };
    case GET_ALL_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllSubscriptionPlansData: action.payload,
      };
    case GET_ACTIVE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        getActivePlanData: action.payload,
      };
    case GRANT_CREDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        grantAccessToTeamData: action.payload,
      };
    case GET_ACTIVE_PLAN_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getActivePlanByTeamIdData: action.payload,
      };
    case GET_ACTIVE_PLAN_BY_TEAM_ID_FAILURE:
    case GRANT_CREDIT_FAILURE:
    case GET_ACTIVE_PLAN_FAILURE:
    case GET_ALL_SUBSCRIPTION_PLAN_FAILURE:
    case CREATE_SUBSCRIPTION_FAILURE:
    case VERIFY_BETACODE_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET:
      return initial;
    default:
      return state;
  }
}
