import {
  TEAM_COACH_REQUEST,
  TEAM_COACH_SUCCESS,
  TEAM_COACH_FAILURE,
  GET_ALL_TEAM_COACH_REQUEST,
  GET_ALL_TEAM_COACH_SUCCESS,
  GET_ALL_TEAM_COACH_FAILURE,
  RESET_STATE,
} from "../constants/coach";

const initialState = {};

export function coachReducer(state = {}, action) {
  switch (action.type) {
    case TEAM_COACH_REQUEST:
    case GET_ALL_TEAM_COACH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TEAM_COACH_SUCCESS:
      return {
        ...state,
        loading: false,
        teamCoachData: action.payload,
      };
    case GET_ALL_TEAM_COACH_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllTeamCoachData: action.payload,
      };
    case GET_ALL_TEAM_COACH_FAILURE:
    case TEAM_COACH_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
