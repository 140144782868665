export const SPORTS_PLAYBOOK_REQUEST = "SPORTS_PLAYBOOK_REQUEST";
export const SPORTS_PLAYBOOK_SUCCESS = "SPORTS_PLAYBOOK_SUCCESS";
export const SPORTS_PLAYBOOK_FAILURE = "SPORTS_PLAYBOOK_FAILURE";

export const SELECTED_SPORT = "SELECTED_SPORT";

export const STORE_NAME_RESET = "STORE_NAME_RESET";

export const GET_PLAYER_BY_ID_REQUEST = "GET_PLAYER_BY_ID_REQUEST";
export const GET_PLAYER_BY_ID_SUCCESS = "GET_PLAYER_BY_ID_SUCCESS";
export const GET_PLAYER_BY_ID_FAILURE = "GET_PLAYER_BY_ID_FAILURE";

export const TEAM_NEWS_REQUEST = "TEAM_NEWS_REQUEST";
export const TEAM_NEWS_SUCCESS = "TEAM_NEWS_SUCCESS";
export const TEAM_NEWS_FAILURE = "TEAM_NEWS_FAILURE";

export const ADD_TEAM_NEWS_REQUEST = "ADD_TEAM_NEWS_REQUEST";
export const ADD_TEAM_NEWS_SUCCESS = "ADD_TEAM_NEWS_SUCCESS";
export const ADD_TEAM_NEWS_FAILURE = "ADD_TEAM_NEWS_FAILURE";

export const EDIT_TEAM_NEWS_REQUEST = "EDIT_TEAM_NEWS_REQUEST";
export const EDIT_TEAM_NEWS_SUCCESS = "EDIT_TEAM_NEWS_SUCCESS";
export const EDIT_TEAM_NEWS_FAILURE = "EDIT_TEAM_NEWS_FAILURE";

export const DELETE_TEAM_NEWS_REQUEST = "DELETE_TEAM_NEWS_REQUEST";
export const DELETE_TEAM_NEWS_SUCCESS = "DELETE_TEAM_NEWS_SUCCESS";
export const DELETE_TEAM_NEWS_FAILURE = "DELETE_TEAM_NEWS_FAILURE";

export const GET_TEAM_NEWS_BY_ID_REQUEST = "GET_TEAM_NEWS_BY_ID_REQUEST";
export const GET_TEAM_NEWS_BY_ID_SUCCESS = "GET_TEAM_NEWS_BY_ID_SUCCESS";
export const GET_TEAM_NEWS_BY_ID_FAILURE = "GET_TEAM_NEWS_BY_ID_FAILURE";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE";

export const UPDATE_USER_ID = "UPDATE_USER_ID";
export const RESET_USER_ID = "RESET_USER_ID";
export const UPDATE_USER = "UPDATE_USER";

export const SEND_INVITATION_REQUEST = "SEND_INVITATION_REQUEST";
export const SEND_INVITATION_SUCCESS = "SEND_INVITATION_SUCCESS";
export const SEND_INVITATION_FAILURE = "SEND_INVITATION_FAILURE";

export const GET_SPORT_GROUND_BY_SPORT_ID_REQUEST =
  "GET_SPORT_GROUND_BY_SPORT_ID_REQUEST";
export const GET_SPORT_GROUND_BY_SPORT_ID_SUCCESS =
  "GET_SPORT_GROUND_BY_SPORT_ID_SUCCESS";
export const GET_SPORT_GROUND_BY_SPORT_ID_FAILURE =
  "GET_SPORT_GROUND_BY_SPORT_ID_FAILURE";

export const GET_SPORTWISE_POSITIONS_REQUEST = "GET_SPORTWISE_POSITIONS_REQUEST";
export const GET_SPORTWISE_POSITIONS_SUCCESS = "GET_SPORTWISE_POSITIONS_SUCCESS";
export const GET_SPORTWISE_POSITIONS_FAILURE = "GET_SPORTWISE_POSITIONS_FAILURE";