import {
  CREATE_HOBBIES_FAILURE,
  CREATE_HOBBIES_REQUEST,
  CREATE_HOBBIES_SUCCESS,
  DELETE_HOBBIES_FAILURE,
  DELETE_HOBBIES_REQUEST,
  DELETE_HOBBIES_SUCCESS,
  GET_ALL_HOBBIES_FAILURE,
  GET_ALL_HOBBIES_REQUEST,
  GET_ALL_HOBBIES_SUCCESS,
  GET_HOBBIES_BY_ID_FAILURE,
  GET_HOBBIES_BY_ID_REQUEST,
  GET_HOBBIES_BY_ID_SUCCESS,
  UPDATE_HOBBIES_FAILURE,
  UPDATE_HOBBIES_REQUEST,
  UPDATE_HOBBIES_SUCCESS,
  GET_HOBBIES_LIST_REQUEST,
  GET_HOBBIES_LIST_SUCCESS,
  GET_HOBBIES_LIST_FAILURE,
  RESET,
} from "../constants/hobbies";

const initialState = {};

export function hobbiesReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_HOBBIES_REQUEST:
    case UPDATE_HOBBIES_REQUEST:
    case DELETE_HOBBIES_REQUEST:
    case GET_ALL_HOBBIES_REQUEST:
    case GET_HOBBIES_BY_ID_REQUEST:
    case GET_HOBBIES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_HOBBIES_SUCCESS:
      return {
        ...state,
        loading: false,
        createHobbiesData: action.payload,
      };
    case UPDATE_HOBBIES_SUCCESS:
      return {
        ...state,
        loading: false,
        updateHobbiesData: action.payload,
      };
    case DELETE_HOBBIES_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteHobbiesData: action.payload,
      };
    case GET_ALL_HOBBIES_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllHobbiesData: action.payload,
      };
    case GET_HOBBIES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getHobbiesByIdData: action.payload,
      };
    case GET_HOBBIES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getHobbiesListData: action.payload,
      };
    case CREATE_HOBBIES_FAILURE:
    case UPDATE_HOBBIES_FAILURE:
    case DELETE_HOBBIES_FAILURE:
    case GET_ALL_HOBBIES_FAILURE:
    case GET_HOBBIES_BY_ID_FAILURE:
    case GET_HOBBIES_LIST_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
