export const CREATE_PAYEMENT_CREDENTIAL_REQUEST = "CREATE_PAYEMENT_CREDENTIAL_REQUEST"
export const CREATE_PAYEMENT_CREDENTIAL_SUCCESS = "CREATE_PAYEMENT_CREDENTIAL_SUCCESS"
export const CREATE_PAYEMENT_CREDENTIAL_FAILURE = "CREATE_PAYEMENT_CREDENTIAL_FAILURE"

export const UPDATE_PAYEMENT_CREDENTIAL_REQUEST = "UPDATE_PAYEMENT_CREDENTIAL_REQUEST"
export const UPDATE_PAYEMENT_CREDENTIAL_SUCCESS = "UPDATE_PAYEMENT_CREDENTIAL_SUCCESS"
export const UPDATE_PAYEMENT_CREDENTIAL_FAILURE = "UPDATE_PAYEMENT_CREDENTIAL_FAILURE"

export const GET_ALL_PAYEMENT_CREDENTIAL_BY_ID_REQUEST = "GET_ALL_PAYEMENT_CREDENTIAL_BY_ID_REQUEST"
export const GET_ALL_PAYEMENT_CREDENTIAL_BY_ID_SUCCESS = "GET_ALL_PAYEMENT_CREDENTIAL_BY_ID_SUCCESS"
export const GET_ALL_PAYEMENT_CREDENTIAL_BY_ID_FAILURE = "GET_ALL_PAYEMENT_CREDENTIAL_BY_ID_FAILURE"

export const GET_PAYEMENT_CREDENTIAL_BY_USER_ID_REQUEST = "GET_PAYEMENT_CREDENTIAL_BY_USER_ID_REQUEST"
export const GET_PAYEMENT_CREDENTIAL_BY_USER_ID_SUCCESS = "GET_PAYEMENT_CREDENTIAL_BY_USER_ID_SUCCESS"
export const GET_PAYEMENT_CREDENTIAL_BY_USER_ID_FAILURE = "GET_PAYEMENT_CREDENTIAL_BY_USER_ID_FAILURE"

export const REMOVE_PAYEMENT_CREDENTIAL_REQUEST = "REMOVE_PAYEMENT_CREDENTIAL_REQUEST"
export const REMOVE_PAYEMENT_CREDENTIAL_SUCCESS = "REMOVE_PAYEMENT_CREDENTIAL_SUCCESS"
export const REMOVE_PAYEMENT_CREDENTIAL_FAILURE = "REMOVE_PAYEMENT_CREDENTIAL_FAILURE"

export const VERIFY_PAYEMENT_CREDENTIAL_REQUEST = "VERIFY_PAYEMENT_CREDENTIAL_REQUEST"
export const VERIFY_PAYEMENT_CREDENTIAL_SUCCESS = "VERIFY_PAYEMENT_CREDENTIAL_SUCCESS"
export const VERIFY_PAYEMENT_CREDENTIAL_FAILURE = "VERIFY_PAYEMENT_CREDENTIAL_FAILURE"

export const RESET_PAYEMENT_CREDENTIAL_FAILURE = "RESET_PAYEMENT_CREDENTIAL_FAILURE"

