import moment from "moment";
import React from "react";
import FileSaver from "file-saver";

export default function RightChatBody({ chatData }) {
  const messagesEndRef = React.useRef(null);
  const scrollToBottom = React.useCallback(() => {
    messagesEndRef.current?.scrollIntoView(false);
  }, []);

  React.useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  const handleDownload = React.useCallback((path) => {
    const pathArray = path.split("/");
    const fileName = pathArray[pathArray.length - 1];
    FileSaver.saveAs(path, fileName);
  }, []);

  // const download = (url) => {
  //   var element = document.createElement("a");
  //   var file = new Blob([url], { type: "image/*" });
  //   element.href = URL.createObjectURL(file);
  //   element.download = "image.jpg";
  //   element.click();
  // };
  return (
    <>
      {chatData?.chat_transaction?.eDelete === "Yes" ||
      chatData?.eDelete === "Yes" ? (
        <div className="text right-text">
          <div className="msg">
            <span className="msg-p">
              <i>{`This Message Is Deleted`}</i>
            </span>
            <div className="msg-details">
              <p className="time">
                {moment
                  .utc(
                    moment(chatData?.dAddedDate).format("YYYY-MM-DD") +
                      " " +
                      moment(chatData?.dAddedTime).format("hh:mm")
                  )
                  .local()
                  .format("h:mm A")}
                {/* {moment(chatData?.dAddedTime, "YYYY-MM-DD, h:mm:ss a").format(
                  "h:mm A"
                )} */}
              </p>
            </div>
          </div>
        </div>
      ) : chatData && chatData?.chat_file_transactions?.length === 0 ? (
        <>
          <div className="text right-text ">
            <div className="msg">
              <span className="msg-p">{chatData?.vMessage}</span>
              <div className="msg-details">
                <p className="time text-white">
                  {/* {moment(chatData?.dAddedTime, "YYYY-MM-DD, h:mm:ss a").format(
                    "h:mm A"
                  )} */}
                  {moment
                    .utc(
                      moment(chatData?.dAddedDate).format("YYYY-MM-DD") +
                        " " +
                        moment(chatData?.dAddedTime).format("hh:mm")
                    )
                    .local()
                    .format("h:mm A")}
                  {/* {moment(chatData?.dAddedTime, "YYYY-MM-DD, h:mm:ss a").format(
                    "h:mm A"
                  )} */}
                </p>
                <div className="seen-icon">
                  {chatData?.chat_transaction?.eSeen === "No" ? (
                    <i className="far fa-check"></i>
                  ) : (
                    <i className="fal fa-check-double"></i>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : chatData?.eType === "image" ? (
        <>
          <div className="text right-text">
            <div className="msg">
              <span className="msg-p">{chatData?.vMessage}</span>
              {/* start setup image and video  */}

              {chatData?.chat_file_transactions?.length === 1 ? (
                <>
                  <div className="msg-image-one image-up">
                    {chatData?.chat_file_transactions?.map((imgData, key) =>
                      imgData?.eType === "video" ? (
                        <React.Fragment>
                          <div className="right-sec">
                            <a
                              className="image-box"
                              data-fancybox={`gallery${imgData?.iChatId}`}
                              href={imgData?.vFile}
                            >
                              <div className="image-box">
                                <video
                                  allowFullScreen="false"
                                  id="video"
                                  src={imgData?.vFile}
                                  alt=""
                                  muted
                                />
                              </div>
                            </a>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <a
                            className="image-box"
                            data-fancybox={`gallery${chatData?.iChatId}`}
                            href={imgData?.vFile}
                          >
                            <img
                              className="rounded"
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                              }}
                              src={imgData?.vFile}
                            />
                          </a>
                          {/* <a
                            href={imgData.vFile}
                            download
                            onClick={() => download(imgData.vFile)}
                            target="_blank"
                          >
                            <i className="fa fa-download" />
                            download
                          </a> */}
                        </React.Fragment>
                      )
                    )}
                  </div>
                </>
              ) : chatData?.chat_file_transactions?.length === 3 ? (
                <>
                  <div className="msg-image-three image-up">
                    {chatData?.chat_file_transactions?.map((imgData, key) =>
                      imgData?.eType === "video" ? (
                        <React.Fragment>
                          <div className="right-sec">
                            <a
                              className="image-box"
                              data-fancybox={`gallery${imgData?.iChatId}`}
                              id={key}
                              href={imgData?.vFile}
                              data-options='{"loop":true}'
                            >
                              <div className="image-box">
                                <video
                                  allowFullScreen="false"
                                  id="video"
                                  src={imgData?.vFile}
                                  alt=""
                                  muted
                                />
                              </div>
                            </a>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <a
                            className="image-box"
                            data-fancybox={`gallery${chatData?.iChatId}`}
                            id={key}
                            href={imgData?.vFile}
                            data-options='{"loop":true}'
                          >
                            <img
                              className="rounded"
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                              }}
                              src={imgData?.vFile}
                            />
                          </a>
                        </React.Fragment>
                      )
                    )}
                  </div>
                </>
              ) : chatData?.chat_file_transactions?.length === 2 ? (
                <>
                  <div className="msg-image-more image-up">
                    {chatData?.chat_file_transactions?.map((imgData, key) =>
                      imgData?.eType === "video" ? (
                        <React.Fragment>
                          <div className="right-sec">
                            <a
                              className="image-box"
                              data-fancybox={`gallery${imgData?.iChatId}`}
                              href={imgData?.vFile}
                              data-options='{"loop":true}'
                            >
                              <div className="image-box">
                                <video
                                  allowFullScreen="false"
                                  id="video"
                                  src={imgData?.vFile}
                                  alt=""
                                  muted
                                />
                              </div>
                            </a>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <a
                            className="image-box"
                            data-fancybox={`gallery${chatData?.iChatId}`}
                            id={key}
                            href={imgData?.vFile}
                            data-options='{"loop":true}'
                          >
                            <img
                              className="rounded"
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                              }}
                              src={imgData?.vFile}
                            />
                          </a>
                        </React.Fragment>
                      )
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="msg-image-more image-up">
                    {chatData?.chat_file_transactions
                      ? chatData?.chat_file_transactions.map((image, key) =>
                          image?.eType === "video" ? (
                            <React.Fragment>
                              <div className="right-sec">
                                <a
                                  className={key > 3 ? `d-none` : `image-box`}
                                  data-fancybox={`gallery${image?.iChatId}`}
                                  href={image?.vFile}
                                  data-options='{"loop":true}'
                                >
                                  <div className="image-box">
                                    <video
                                      allowFullScreen="false"
                                      id="video"
                                      src={image?.vFile}
                                      alt=""
                                      muted
                                    />
                                  </div>
                                </a>
                                {key === 3 &&
                                chatData?.chat_file_transactions?.length > 4 ? (
                                  <div
                                    className="more-image-count"
                                    data-fancybox="gallery"
                                    href={image?.vFile}
                                  >
                                    <span>
                                      {chatData?.chat_file_transactions
                                        .length >= 5
                                        ? `+ ${
                                            chatData?.chat_file_transactions
                                              .length - 4
                                          }`
                                        : ""}
                                    </span>
                                  </div>
                                ) : key === 3 &&
                                  chatData?.chat_file_transactions?.length ===
                                    4 ? (
                                  <></>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <a
                                className={key > 3 ? `d-none` : `image-box`}
                                data-fancybox={`gallery${chatData?.iChatId}`}
                                id={key}
                                href={image?.vFile}
                                data-options='{"loop":true}'
                              >
                                <img
                                  className="rounded"
                                  alt=""
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                  }}
                                  src={image?.vFile}
                                />
                              </a>
                              {key === 3 &&
                              chatData?.chat_file_transactions?.length > 4 ? (
                                <div
                                  className="more-image-count"
                                  data-fancybox="gallery"
                                  href={image?.vFile}
                                >
                                  <span>
                                    {chatData?.chat_file_transactions.length >=
                                    5
                                      ? `+ ${
                                          chatData?.chat_file_transactions
                                            .length - 4
                                        }`
                                      : ""}
                                  </span>
                                </div>
                              ) : key === 3 &&
                                chatData?.chat_file_transactions?.length ===
                                  4 ? (
                                <></>
                              ) : (
                                <></>
                              )}
                            </React.Fragment>
                          )
                        )
                      : chatData?.chat_file_transactions.map((item, index) =>
                          item?.eType === "video" ? (
                            <React.Fragment>
                              <div className="right-sec">
                                <a
                                  className="image-box"
                                  data-fancybox={`gallery${item?.iChatId}`}
                                  href={item?.vFile}
                                  data-options='{"loop":true}'
                                >
                                  <div className="image-box">
                                    <video
                                      allowFullScreen="false"
                                      id="video"
                                      src={item?.vFile}
                                      alt=""
                                      muted
                                    />
                                  </div>
                                </a>
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment
                            // key={
                            //   Math.random() *
                            //   100000 *
                            //   key
                            // }
                            >
                              <a
                                className="image-box"
                                data-fancybox={`gallery${chatData?.iChatId}`}
                                // id={key}
                                href={item?.vFile}
                                data-options='{"loop":true}'
                              >
                                <img
                                  className="rounded"
                                  alt=""
                                  //   id={key}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                  }}
                                  src={item?.vFile}
                                />
                              </a>
                            </React.Fragment>
                          )
                        )}
                    {/* <div ref={messagesEndRef} /> */}
                  </div>
                </>
              )}

              {/* end setup image and video  */}

              <div className="msg-details">
                <p className="time">
                  {moment
                    .utc(
                      moment(chatData?.dAddedDate).format("YYYY-MM-DD") +
                        " " +
                        moment(chatData?.dAddedTime).format("hh:mm")
                    )
                    .local()
                    .format("h:mm A")}
                  {/* {moment(chatData?.dAddedTime, "YYYY-MM-DD, h:mm:ss a").format(
                    "h:mm A"
                  )} */}
                </p>
                <div className="seen-icon">
                  {chatData?.chat_transaction?.eSeen === "No" ? (
                    <i className="far fa-check"></i>
                  ) : (
                    <i className="fal fa-check-double"></i>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : chatData?.eType === "Application" ? (
        <>
          <div className="text right-text">
            <div className="msg">
              <span className="msg-p">{chatData?.vMessage}</span>
              <div className="upchat-image-box">
                <div className="msg-image-more image-up">
                  {chatData?.chat_file_transactions?.length > 0 &&
                    chatData?.chat_file_transactions?.map((docData, index) => (
                      <object className="doc-box">
                        <div className="right-sec">
                          <i className="fas fa-file"></i>
                          <div>
                            <p className="name">{docData?.vFileName}</p>

                            <p className="file-size">
                              {Math.round(docData?.vSize / 1024) > 1024
                                ? `${Math.round(
                                    docData?.vSize / 1024 / 1024
                                  )} MB`
                                : `${Math.round(docData?.vSize / 1024)} KB  `}
                            </p>
                          </div>
                        </div>
                        <div
                          className="doc-download"
                          onClick={() => handleDownload(docData?.vFile)}
                        >
                          <i className="fal fa-arrow-circle-down"></i>
                        </div>
                      </object>
                    ))}
                </div>
              </div>
              <div className="msg-details">
                <p className="time text-white">
                  {moment
                    .utc(
                      moment(chatData?.dAddedDate).format("YYYY-MM-DD") +
                        " " +
                        moment(chatData?.dAddedTime).format("hh:mm")
                    )
                    .local()
                    .format("h:mm A")}
                  {/* {moment(chatData?.dAddedTime, "YYYY-MM-DD, h:mm:ss a").format(
                    "h:mm A"
                  )} */}
                </p>
                <div className="seen-icon">
                  {chatData?.chat_transaction?.eSeen === "No" ? (
                    <i className="far fa-check"></i>
                  ) : (
                    <i className="fal fa-check-double"></i>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div ref={messagesEndRef} /> */}
        </>
      ) : chatData?.eType === "video" ? (
        <>
          <div className="text right-text">
            <div className="msg">
              <span className="msg-p">{chatData?.vMessage}</span>
              {/* start setup image and video  */}

              {chatData?.chat_file_transactions?.length === 1 ? (
                <>
                  <div className="msg-image-one image-up">
                    {chatData?.chat_file_transactions?.map((imgData, key) =>
                      imgData?.eType === "video" ? (
                        <React.Fragment>
                          <div className="right-sec">
                            <a
                              className="image-box"
                              data-fancybox={`gallery${imgData?.iChatId}`}
                              href={imgData?.vFile}
                            >
                              <div className="image-box">
                                <video
                                  allowFullScreen="false"
                                  id="video"
                                  src={imgData?.vFile}
                                  alt=""
                                  muted
                                />
                              </div>
                            </a>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <a
                            className="image-box"
                            data-fancybox={`gallery${chatData?.iChatId}`}
                            href={imgData?.vFile}
                          >
                            <img
                              className="rounded"
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                              }}
                              src={imgData?.vFile}
                            />
                          </a>
                        </React.Fragment>
                      )
                    )}
                  </div>
                </>
              ) : chatData?.chat_file_transactions?.length === 3 ? (
                <>
                  <div className="msg-image-three image-up">
                    {chatData?.chat_file_transactions?.map((imgData, key) =>
                      imgData?.eType === "video" ? (
                        <React.Fragment>
                          <div className="right-sec">
                            <a
                              className="image-box"
                              data-fancybox={`gallery${imgData?.iChatId}`}
                              id={key}
                              href={imgData?.vFile}
                              data-options='{"loop":true}'
                            >
                              <div className="image-box">
                                <video
                                  allowFullScreen="false"
                                  id="video"
                                  src={imgData?.vFile}
                                  alt=""
                                  muted
                                />
                              </div>
                            </a>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <a
                            className="image-box"
                            data-fancybox={`gallery${chatData?.iChatId}`}
                            id={key}
                            href={imgData?.vFile}
                            data-options='{"loop":true}'
                          >
                            <img
                              className="rounded"
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                              }}
                              src={imgData?.vFile}
                            />
                          </a>
                        </React.Fragment>
                      )
                    )}
                  </div>
                </>
              ) : chatData?.chat_file_transactions?.length === 2 ? (
                <>
                  <div className="msg-image-more image-up">
                    {chatData?.chat_file_transactions?.map((imgData, key) =>
                      imgData?.eType === "video" ? (
                        <React.Fragment>
                          <div className="right-sec">
                            <a
                              className="image-box"
                              data-fancybox={`gallery${imgData?.iChatId}`}
                              href={imgData?.vFile}
                              data-options='{"loop":true}'
                            >
                              <div className="image-box">
                                <video
                                  allowFullScreen="false"
                                  id="video"
                                  src={imgData?.vFile}
                                  alt=""
                                  muted
                                />
                              </div>
                            </a>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <a
                            className="image-box"
                            data-fancybox={`gallery${chatData?.iChatId}`}
                            id={key}
                            href={imgData?.vFile}
                            data-options='{"loop":true}'
                          >
                            <img
                              className="rounded"
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                              }}
                              src={imgData?.vFile}
                            />
                          </a>
                        </React.Fragment>
                      )
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="msg-image-more image-up">
                    {chatData?.chat_file_transactions.map((item, key) =>
                      item?.eType === "video" ? (
                        <React.Fragment>
                          <div className="right-sec">
                            <a
                              className={key > 3 ? `d-none` : `image-box`}
                              data-fancybox={`gallery${item?.iChatId}`}
                              href={item?.vFile}
                              data-options='{"loop":true}'
                            >
                              <div className="image-box">
                                <video
                                  allowFullScreen="false"
                                  id="video"
                                  src={item?.vFile}
                                  alt=""
                                  muted
                                />
                              </div>
                            </a>
                            {key === 3 &&
                            chatData?.chat_file_transactions?.length > 4 ? (
                              <div
                                className="more-image-count"
                                data-fancybox="gallery"
                                href={item?.vFile}
                              >
                                <span>
                                  {chatData?.chat_file_transactions.length >= 5
                                    ? `+ ${
                                        chatData?.chat_file_transactions
                                          .length - 4
                                      }`
                                    : ""}
                                </span>
                              </div>
                            ) : key === 3 &&
                              chatData?.chat_file_transactions?.length === 4 ? (
                              <></>
                            ) : (
                              <></>
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <a
                            className={key > 3 ? `d-none` : `image-box`}
                            data-fancybox={`gallery${chatData?.iChatId}`}
                            // id={key}
                            href={item?.vFile}
                            data-options='{"loop":true}'
                          >
                            <img
                              className="rounded"
                              alt=""
                              //   id={key}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                              }}
                              src={item?.vFile}
                            />
                          </a>
                          {key === 3 &&
                          chatData?.chat_file_transactions?.length > 4 ? (
                            <div
                              className="more-image-count"
                              data-fancybox="gallery"
                              href={item?.vFile}
                            >
                              <span>
                                {chatData?.chat_file_transactions.length >= 5
                                  ? `+ ${
                                      chatData?.chat_file_transactions.length -
                                      4
                                    }`
                                  : ""}
                              </span>
                            </div>
                          ) : key === 3 &&
                            chatData?.chat_file_transactions?.length === 4 ? (
                            <></>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      )
                    )}
                    {/* <div ref={messagesEndRef} /> */}
                  </div>
                </>
              )}

              {/* end setup image and video  */}

              <div className="msg-details">
                <p className="time">
                  {moment
                    .utc(
                      moment(chatData?.dAddedDate).format("YYYY-MM-DD") +
                        " " +
                        moment(chatData?.dAddedTime).format("hh:mm")
                    )
                    .local()
                    .format("h:mm A")}
                  {/* {moment(chatData?.dAddedTime, "YYYY-MM-DD, h:mm:ss a").format(
                    "h:mm A"
                  )} */}
                </p>
                <div className="seen-icon">
                  {chatData?.chat_transaction?.eSeen === "No" ? (
                    <i className="far fa-check"></i>
                  ) : (
                    <i className="fal fa-check-double"></i>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div ref={messagesEndRef} /> */}
        </>
      ) : chatData?.eType === "gif" ? (
        <div className="text right-text">
          <div className="msg">
            {chatData?.chat_file_transactions?.length > 0 &&
              chatData?.chat_file_transactions.map((gif) => (
                <img src={gif?.vLink} alt="gif" />
              ))}

            <div className="msg-details">
              <p className="time">
                {moment
                  .utc(
                    moment(chatData?.dAddedDate).format("YYYY-MM-DD") +
                      " " +
                      moment(chatData?.dAddedTime).format("hh:mm")
                  )
                  .local()
                  .format("h:mm A")}
                {/* {moment(chatData?.dAddedTime, "YYYY-MM-DD, h:mm:ss a").format(
                  "h:mm A"
                )} */}
              </p>
              <div className="seen-icon">
                {chatData?.chat_transaction?.eSeen === "No" ? (
                  <i className="far fa-check"></i>
                ) : (
                  <i className="fal fa-check-double"></i>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div ref={messagesEndRef} />
    </>
  );
}
