export const RESET = "RESET";

export const GET_SELECTED_TEAM = "GET_SELECTED_TEAM";

// when the get team id wise head-coach, coaches and players
export const GET_TEAM_WISE_DETAILS_REQUEST = "GET_TEAM_WISE_DETAILS_REQUEST"
export const GET_TEAM_WISE_DETAILS_SUCCESS = "GET_TEAM_WISE_DETAILS_SUCCESS"
export const GET_TEAM_WISE_DETAILS_FAILURE = "GET_TEAM_WISE_DETAILS_FAILURE"

export const ALL_TEAM_LIST_REQUEST = "ALL_TEAM_LIST_REQUEST"
export const ALL_TEAM_LIST_SUCCESS = "ALL_TEAM_LIST_SUCCESS"
export const ALL_TEAM_LIST_FAILURE = "ALL_TEAM_LIST_FAILURE"

export const GET_TEAM_DATA_BY_TEAM_SLUG_REQUEST = "GET_TEAM_DATA_BY_TEAM_SLUG_REQUEST"
export const GET_TEAM_DATA_BY_TEAM_SLUG_SUCCESS = "GET_TEAM_DATA_BY_TEAM_SLUG_SUCCESS"
export const GET_TEAM_DATA_BY_TEAM_SLUG_FAILURE = "GET_TEAM_DATA_BY_TEAM_SLUG_FAILURE"
