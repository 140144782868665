import React from "react";
import WhiteBoard from "../components/AddWhiteboard/WhiteBoard";

function AddWhiteBoard() {
  return (
    <>
      <WhiteBoard />
    </>
  );
}

export default AddWhiteBoard;
