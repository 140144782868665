export const CANVAS_BY_ID_REQUEST = "CANVAS_BY_ID_REQUEST";
export const CANVAS_BY_ID_SUCCESS = "CANVAS_BY_ID_SUCCESS";
export const CANVAS_BY_ID_FAILURE = "CANVAS_BY_ID_FAILURE";

export const ADD_CANVAS_REQUEST = "ADD_CANVAS_REQUEST";
export const ADD_CANVAS_SUCCESS = "ADD_CANVAS_SUCCESS";
export const ADD_CANVAS_FAILURE = "ADD_CANVAS_FAILURE";

export const UPDATE_CANVAS_REQUEST = "UPDATE_CANVAS_REQUEST";
export const UPDATE_CANVAS_SUCCESS = "UPDATE_CANVAS_SUCCESS";
export const UPDATE_CANVAS_FAILURE = "UPDATE_CANVAS_FAILURE";
export const RESET_UPDATE_CANVAS_SUCCESS = "RESET_UPDATE_CANVAS_SUCCESS"

export const RESET_STATE = "RESET_STATE";

export const UPDATE_CANVAS_PLAYER_ID = "UPDATE_CANVAS_PLAYER_ID";

export const SPEED_VALUE = "SPEED_VALUE";

export const FREE_DRAW_TYPE_VALUE = "FREE_DRAW_TYPE_VALUE";

export const BALL_LINE_TYPE_VALUE = "BALL_LINE_TYPE_VALUE";

export const SET_IS_DATA = "SET_IS_DATA";

export const GET_CANVAS_FORMATIONS_REQUEST = "GET_CANVAS_FORMATIONS_REQUEST";
export const GET_CANVAS_FORMATIONS_SUCCESS = "GET_CANVAS_FORMATIONS_SUCCESS";
export const GET_CANVAS_FORMATIONS_FAILURE = "GET_CANVAS_FORMATIONS_FAILURE";

export const SET_TSHIRT_POSITION_DATA = "SET_TSHIRT_POSITION_DATA";

export const GET_ANDROID_CANVAS_LIST_SUCCESS = "GET_ANDROID_CANVAS_LIST_SUCCESS";
export const GET_ANDROID_CANVAS_LIST_REQUEST = "GET_ANDROID_CANVAS_LIST_REQUEST";
export const GET_ANDROID_CANVAS_LIST_FAILURE = "GET_ANDROID_CANVAS_LIST_FAILURE";

export const ADD_CANVAS_FOLDER_REQUEST = "ADD_CANVAS_FOLDER_REQUEST";
export const ADD_CANVAS_FOLDER_SUCCESS = "ADD_CANVAS_FOLDER_SUCCESS";
export const ADD_CANVAS_FOLDER_FAILURE = "ADD_CANVAS_FOLDER_FAILURE";

export const GET_CANVAS_FORMATION_BY_ID_REQUEST = "GET_CANVAS_FORMATION_BY_ID_REQUEST";
export const GET_CANVAS_FORMATION_BY_ID_SUCCESS = "GET_CANVAS_FORMATION_BY_ID_SUCCESS";
export const GET_CANVAS_FORMATION_BY_ID_FAILURE = "GET_CANVAS_FORMATION_BY_ID_FAILURE";

export const FOLDER_ARRAY = "FOLDER_ARRAY";