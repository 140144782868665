export const GET_INTEREST_LIST_DATA_REQUEST = "GET_INTEREST_LIST_DATA_REQUEST";
export const GET_INTEREST_LIST_DATA_SUCCESS = "GET_INTEREST_LIST_DATA_SUCCESS";
export const GET_INTEREST_LIST_DATA_FAILURE = "GET_INTEREST_LIST_DATA_FAILURE";

export const CREATE_USER_INTEREST_REQUEST = "CREATE_USER_INTEREST_REQUEST";
export const CREATE_USER_INTEREST_SUCCESS = "CREATE_USER_INTEREST_SUCCESS";
export const CREATE_USER_INTEREST_FAILURE = "CREATE_USER_INTEREST_FAILURE";

export const RESET = "RESET";

