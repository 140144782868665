import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import {
  getAllSports,
  getCountries,
  getStates,
  getTeamTypes,
  // ResetState,
  // UserProfile,
  // UserProfileUpdate,
} from "../../../store/action/userAction";
import { GetProfileListData } from "../../../store/action/profileListAction";
import Input from "../Input/Input";
import { Toast } from "primereact/toast";
import { useLocation, useNavigate } from "react-router";
import DropDown from "../DropDown/DropDown";
import {
  CreateParentTeam,
  GetAllParentTeamDataByUserID,
  GetAllSubTeamByParentID,
  GetTeamById,
  TeamResetState,
} from "../../../store/action/teamAction";
import {
  BusinessResetState,
  CreateBusinessProfile,
  // GetBusinessProfileByTransId,
} from "../../../store/action/businessAction";
import { GetAllPermissionByOtherRole } from "../../../store/action/permissionAction";
import { SetCountryDialog } from "../../../store/action/otherAction";

function MySocialCreate() {
  const dispatch = useDispatch();
  const location = useLocation();
  const toast = React.useRef(null);
  const navigate = useNavigate();

  const [openForm, setOpenForm] = React.useState({ type: false, name: "" });
  // const [showModel, setShowModel] = React.useState(false);
  // const wrapperRef = React.useRef(null);

  const [checkError, setCheckError] = React.useState();
  const [countryId, setCountryId] = React.useState(223);
  const [visible, setVisible] = React.useState(false);

  const [isClear, setIsClear] = React.useState(false);
  const {
    userProfileData,
    // userProfileUpdateData,
    teamTypes,
    countries,
    states,
    sports,
  } = useSelector((state) => state.user);
  const { createParentTeamData, getAllParentTeamsByUserData } = useSelector(
    (state) => state.team
  );
  const { createBusinessProfileData } = useSelector(
    (state) => state.businessProfile
  );
  const { countryDialogData } = useSelector((state) => state.others);

  // console.log("createBusinessProfileData", createBusinessProfileData);

  // const [formData,setFormData] = React.useState(new FormData());
  const formData = React.useMemo(() => new FormData(), []);

  React.useEffect(() => {
    if (!states) {
      dispatch(getStates("223"));
    }
  }, [states, dispatch, countryId]);

  React.useEffect(() => {
    if (countries && countries.length > 0 && !countryId) {
      setCountryId(223);
    }
  }, [countries, countryId]);

  React.useEffect(() => {
    if (!getAllParentTeamsByUserData) {
      dispatch(GetAllParentTeamDataByUserID());
    }
  }, [dispatch, getAllParentTeamsByUserData]);
  React.useEffect(() => {
    if (!teamTypes) {
      dispatch(getTeamTypes());
    }
  }, [teamTypes, dispatch]);

  React.useEffect(() => {
    if (createParentTeamData && createParentTeamData?.code === 0) {
      toast.current.show({
        severity: "success",
        summary: createParentTeamData?.message,
        // detail: emailCheckData?.message,
        life: 1000,
      });
      sessionStorage.setItem(
        "teamId",
        createParentTeamData.childTeam
          ? createParentTeamData.childTeam.iTeamId
          : createParentTeamData?.data?.iTeamId
      );
      dispatch(TeamResetState());
      formData.delete("teamId");
      formData.delete("vTeamName");
      formData.delete("iTeamTypeId");
      formData.delete("iCountryId");
      formData.delete("iUserId");
      formData.delete("iRoleId");
      formData.delete("iSportId");
      if (location.pathname === "/teams-details") {
        const values = {
          iTeamId: createParentTeamData?.data.iTeamId,
          iUserId: sessionStorage.getItem("id"),
        };
        if (parseInt(values.iUserId) > 0 && parseInt(values.iTeamId) > 0) {
          dispatch(GetTeamById(values));
        }
        dispatch(
          GetAllSubTeamByParentID({
            iParentId: createParentTeamData?.data.iParentId,
          })
        );
        dispatch(GetProfileListData({ iUserId: sessionStorage.getItem("id") }));

        setOpenForm({ type: false, name: "" });
      }
      //  else {
      //   navigate("/teams-details", {
      //     state: { iTeamId: createParentTeamData?.data.iTeamId },
      //   });
      // }

      // setTimeout(() => navigate("/teams-details"), 3000);
      // setTimeout(() => {
      //   navigate("/teams-details", {
      //     state: {
      //       iTeamId: createParentTeamData?.data?.iTeamId,
      //     },
      //   });
      // }, 1000);
      setTimeout(() => {
        const values = {
          iRoleId: 1,
          iUserId: sessionStorage.getItem("id"),
          iTeamId: createParentTeamData.childTeam
            ? createParentTeamData.childTeam.iTeamId
            : createParentTeamData?.data?.iTeamId,
        };
        dispatch(GetAllPermissionByOtherRole(values));
        navigate(
          location.pathname === "/create-team"
            ? "/add-team-member"
            : "/teams-details",
          {
            state: {
              iTeamId: createParentTeamData.childTeam
                ? createParentTeamData.childTeam.iTeamId
                : createParentTeamData?.data?.iTeamId,
            },
          }
        );
      }, 1200);

      dispatch(SetCountryDialog(false));
      // window.$("#socialaddpop").modal("hide");
    }
  }, [createParentTeamData, dispatch, navigate, formData, location]);

  const handleOpenBusiness = React.useCallback(() => {
    setOpenForm({ type: true, name: "Business" });
  }, [setOpenForm]);

  const handleClose = React.useCallback(() => {
    dispatch(SetCountryDialog(false));
    setOpenForm({ type: false, name: "" });
  }, [setOpenForm, dispatch]);

  const handleOpenTeam = React.useCallback(() => {
    dispatch(SetCountryDialog(true));
    setOpenForm({ type: true, name: "Team" });
  }, [dispatch]);

  React.useEffect(() => {
    if (!sports) {
      dispatch(getAllSports());
    }
  }, [dispatch, sports]);

  React.useEffect(() => {
    if (!countries) {
      dispatch(getCountries());
    }
  }, [countries, dispatch]);

  React.useEffect(() => {
    if (
      createBusinessProfileData &&
      createBusinessProfileData?.code === "200"
    ) {
      // const values = {
      //   iUserId: sessionStorage.getItem("id"),
      // };
      toast.current.show({
        severity: "success",
        summary: createBusinessProfileData?.message,
        life: 3000,
      });
      dispatch(BusinessResetState());
      dispatch(GetProfileListData({ iUserId: sessionStorage.getItem("id") }));
      // dispatch(UserProfile(values));
      if (location.pathname !== "/business-profile") {
        // dispatch(GetBusinessProfileByTransId({
        //   iBussinesTranId: location.state.iBussinesTranId,
        // }));
        setTimeout(
          () =>
            navigate("/business-profile", {
              state: {
                iBussinesTranId:
                  createBusinessProfileData?.data?.iBussinesTranId,
              },
            }),
          3000
        );
      }

      dispatch(SetCountryDialog(false));
      // window.$("#socialaddpop").modal("hide");
      if (location.pathname === "/business-profile") {
        setOpenForm({ type: false, name: "" });
      }
    }
  }, [createBusinessProfileData, dispatch, navigate, setOpenForm, location]);

  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      event.preventDefault();
      // setOpenForm({type:false,name:""})
      // window.$("#socialaddpop").modal("hide")
      let values = {};
      let errors = {};
      for (let i = 0; i < event.target.length - 1; i++) {
        if (event.target[i].value === "" && event.target[i].name !== "") {
          if (
            event.target[i].value === "" ||
            event.target[i].value === "NaN/NaN/NaN"
          ) {
            errors = {
              ...errors,
              [event.target[i].name]: true,
            };
          }
          //   if (event.target[i].name !== "" && event.target[i].name !== "vPartner") {
          //     errors = { ...errors, [event.target[i].name]: true };
          //   }
        } else {
          if (event.target[i].name !== "") {
            values = {
              ...values,
              [event.target[i].name]: event.target[i].value,
            };
          }
        }
      }

      setCheckError(errors);

      if (Object.keys(errors).length === 0) {
        const formData = new FormData();
        formData.append("iUserId", sessionStorage.getItem("id"));
        formData.append("vBusinessName", values.vBusinessName);
        // formData.append("iBussinesTranId", values.iBussinesTranId);
        // formData.append("vBussinesDocuments", values.vBussinesDocuments);

        // formData.append("vFirstName", values.vFirstName);
        // formData.append("vLastName", values.vLastName);

        dispatch(CreateBusinessProfile(formData));
      }
      setIsClear(true);
    },
    [dispatch]
  );

  const handleTeamSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      let errors = {};
      // const formData = new FormData();
      for (let i = 0; i < event.target.length - 1; i++) {
        if (event.target[i].name !== "") {
          if (
            event.target[i].value === "" ||
            event.target[i].value === "NaN/NaN/NaN"
          ) {
            errors = {
              ...errors,
              [event.target[i].name]: true,
            };
          }
          formData.append([event.target[i].name], event.target[i].value);
        }
      }

      if (formData.get("iCountryId").length <= 0) {
        formData.append("iCountryId", 223);
      }

      formData.append("iUserId", sessionStorage.getItem("id"));
      formData.append(
        "iRoleId",
        userProfileData && userProfileData.code === "200"
          ? userProfileData?.data.iRoleId === 9 ||
            userProfileData?.data.iRoleId === null
            ? 1
            : userProfileData?.data.iRoleId
          : "1"
      );
      setCheckError(errors);

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      if (Object.keys(errors).length === 0) {
        setOpenForm({ type: true, name: "selectSport" });
      }
    },
    [userProfileData, setCheckError, formData]
  );
  // console.log("formData",formData)

  const handleSubmitTeams = React.useCallback(
    (event) => {
      event.preventDefault();
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      dispatch(CreateParentTeam(formData));
    },
    [formData, dispatch]
  );

  const handleSelectedSport = React.useCallback(
    (icon, name, id, eSportType) => {
      formData.append("iSportId", id);
      formData.append("eSportType", eSportType);
    },
    [formData]
  );

  return (
    <>
      <Toast ref={toast} />
      {countryDialogData ? (
        <div>
          <Dialog
            header=" + Create Page"
            visible={countryDialogData}
            draggable={false}
            style={{ width: "50vw" }}
            onHide={handleClose}
            // footer={renderFooter("displayBasic")}
            // onHide={() => dispatch(SetCountryDialog(false))}
            className="create-team-popup"
          >
            {openForm.type && openForm.name === "selectSport" ? (
              <></>
            ) : (
              <div className="steps-box">
                <div className={`steps active`}>1</div>
                <div className={`${openForm.type ? "steps active" : "steps"}`}>
                  2
                </div>
              </div>
            )}
            <div className="inner-body">
              {openForm.type ? (
                <></>
              ) : (
                <div className="page-select">
                  <button onClick={handleOpenBusiness}>
                    Create Business page
                  </button>
                  <button onClick={handleOpenTeam}>Create Team Page</button>
                  <button
                    onMouseEnter={() => setVisible(true)}
                    onMouseLeave={() => setVisible(false)}
                  >
                    Create League
                    {visible ? (
                      <div className="cloud">
                        <img
                          alt=""
                          src="../assets/img/cloud.gif"
                          className="img-fluid"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </button>
                </div>
              )}
              {/* <div className="form-page w-100"> */}
              <div
                className={`${
                  openForm.type && openForm.name === "selectSport"
                    ? "form-page w-100"
                    : "form-page"
                }`}
              >
                {openForm.type && openForm?.name === "Business" ? (
                  <form method="post" onSubmit={handleSubmit}>
                    <h4 className="title">
                      <button>
                        <i
                          className="far fa-long-arrow-alt-left"
                          onClick={() => setOpenForm({ type: false, name: "" })}
                        ></i>
                      </button>
                      Business page
                    </h4>
                    <div className="row g-3">
                      {/* <div className="col-12">
                             <div className="form-group">
                               <label>First Name</label>
                               <Input
                                 placeHolder={"First name"}
                                 name="vFirstName"
                                 type="text"
                                 title="First Name"
                                 isError={checkError?.vFirstName}
                                 isValidate={true}
                                 isClear={isClear}
                               />
                             </div>
                           </div>
                           <div className="col-12">
                             <div className="form-group">
                               <label>Last Name</label>
 
                               <Input
                                 placeHolder={"Last name"}
                                 name="vLastName"
                                 type="text"
                                 title="Last Name"
                                 isError={checkError?.vLastName}
                                 isValidate={true}
                                 isClear={isClear}
                               />
                             </div>
                           </div> */}
                      <div className="col-12">
                        <div className="form-group">
                          <label>Company Name</label>

                          <Input
                            placeHolder={"Company Name"}
                            name="vBusinessName"
                            type="text"
                            title="Company Name"
                            isError={checkError?.vBusinessName}
                            isValidate={true}
                            isClear={isClear}
                          />
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn submit-btn">
                      Submit
                    </button>
                  </form>
                ) : (
                  <></>
                )}

                {openForm.type && openForm?.name === "Team" ? (
                  <form method="post" onSubmit={handleTeamSubmit}>
                    <div className="inner-create-team-box">
                      <h4 className="title">
                        <button>
                          <i
                            className="far fa-long-arrow-alt-left"
                            onClick={() =>
                              setOpenForm({ type: false, name: "" })
                            }
                          ></i>
                        </button>
                        Team page
                      </h4>
                      <div className="row g-3">
                        <div className="col-12">
                          <div className="form-group">
                            <label>Team Name</label>
                            <Input
                              placeHolder={"Team Name"}
                              name="vTeamName"
                              title="Team Name"
                              isError={checkError?.vTeamName}
                              isValidate={true}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            {/* custom search with select start */}
                            {/* <div className="search-with-select">
                                     <div className="select-added">
                                       <span>test</span>
                                       <i className="far fa-chevron-down"></i>
                                       <i className="far fa-times"></i>
                                     </div>
                                     <div className="open-select">
                                       <div className="searchbar">
                                         <input type="search" placeholder="Search..."/>
                                       </div>
                                       <div className="search-results">
                                         <ul className="results-box">
                                           <li className="box">test</li>
                                           <li className="box">test 2</li>
                                           <li className="box">test 3</li>
                                           <li className="box">test 4</li>
                                         </ul>
                                         <p className="no-search">No available options</p>
                                       </div>
                                     </div>
                                   </div> */}
                            {/* custom search with select end */}
                            <label>Team Type</label>
                            <DropDown
                              title={"Team Type"}
                              name={"iTeamTypeId"}
                              field={"vTitle"}
                              fieldId={"iTeamTypeId"}
                              data={teamTypes}
                              isValidate={true}
                              isSetValue={false}
                              placeHolder={"Team Type"}
                              isError={checkError?.iTeamTypeId}
                              filter={true}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Country</label>
                            <DropDown
                              className="dropdown"
                              title={"Country"}
                              name={"iCountryId"}
                              field={"vCountry"}
                              fieldId={"iCountryId"}
                              data={countries}
                              isValidate={true}
                              isSetValue={true}
                              placeHolder={223 || "Country"}
                              isError={checkError?.iCountryId}
                              filter={true}
                              setConditionValue={setCountryId}
                              // value={countries[221].iCountryId}
                              value={countryId}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {openForm.type && openForm.name === "selectSport" ? (
                             <button
                           type="button"
                           className="btn submit-btn"
                           data-dismiss="modal"
 
 
                           aria-label="Close"
                         >
                           Submit
                         </button>
                         ) : (
                           <></>
                         )} */}

                    <div className="col-12">
                      <button
                        // type="button"
                        className="btn submit-btn"
                        // onClick={handleSelectSport}
                      >
                        Save & Select Sport
                      </button>
                    </div>
                  </form>
                ) : (
                  <></>
                )}

                {openForm.type && openForm.name === "selectSport" ? (
                  <>
                    <form method="post" onSubmit={handleSubmitTeams}>
                      <ul
                        className="nav nav-pills mb-5 mt-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <button className="back-btn">
                          <i
                            className="far fa-long-arrow-alt-left"
                            onClick={() =>
                              setOpenForm({ type: true, name: "Team" })
                            }
                          ></i>
                        </button>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="pills-withinteractive-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-withinteractive"
                            type="button"
                            role="tab"
                            aria-controls="pills-withinteractive"
                            aria-selected="true"
                          >
                            With Playbook
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-withoutinteractive-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-withoutinteractive"
                            type="button"
                            role="tab"
                            aria-controls="pills-withoutinteractive"
                            aria-selected="false"
                          >
                            Without Playbook
                          </button>
                        </li>
                      </ul>

                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-withinteractive"
                          role="tabpanel"
                          aria-labelledby="pills-withinteractive-tab"
                        >
                          <div className="sport-list cst-select-sport">
                            <ul>
                              {sports?.map((value, key) => {
                                return value.eSportType === "Interactive" ? (
                                  <li key={key}>
                                    <label>
                                      <input type="radio" name="select-sport" />
                                      <div className="icon-box">
                                        <div
                                          className="box-img"
                                          onClick={() =>
                                            handleSelectedSport(
                                              value.vImage,
                                              value.vTitle,
                                              value.iSportId,
                                              value.eSportType
                                            )
                                          }
                                        >
                                          <img
                                            src={value.vImage}
                                            className="img-fluid"
                                            alt=""
                                          />
                                        </div>
                                        <p>{value.vTitle}</p>
                                      </div>
                                    </label>
                                  </li>
                                ) : (
                                  <React.Fragment key={key}></React.Fragment>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-withoutinteractive"
                          role="tabpanel"
                          aria-labelledby="pills-withoutinteractive-tab"
                        >
                          <div className="sport-list cst-select-sport">
                            <ul>
                              {sports?.map((value, key) => {
                                return value.eSportType === "NonInteractive" ? (
                                  <li key={key}>
                                    <label>
                                      <input type="radio" name="select-sport" />
                                      <div className="icon-box">
                                        <div
                                          className="box-img"
                                          onClick={() =>
                                            handleSelectedSport(
                                              value.vImage,
                                              value.vTitle,
                                              value.iSportId,
                                              value.eSportType
                                            )
                                          }
                                        >
                                          <img
                                            src={value.vImage}
                                            className="img-fluid"
                                            alt=""
                                          />
                                        </div>
                                        <p>{value.vTitle}</p>
                                      </div>
                                    </label>
                                  </li>
                                ) : (
                                  <React.Fragment key={key}></React.Fragment>
                                );
                              })}
                            </ul>
                            {/* <ul>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679635736.CompetitiveDancing.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Competitive Dancing</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679893589.Cheerleading.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Cheerleading</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679635961.Figureskating.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Figure skating</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679636302.Gymnastics.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Gymnastics</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679636497.SkiingSnowboarding.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Skiing &amp; Snowboarding</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679636686.WaterPolo.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Water Polo</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679636860.Karate.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Karate</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679637780.SwimmingDiving.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Swimming &amp; Diving</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679637841.Wrestling.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Wrestling</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679637906.Fencing.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Fencing</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679638406.Sailing.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Sailing</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679638704.Rowing.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Rowing</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679638759.Squash.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Squash</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679639144.Badminton.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Badminton</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679639313.ExerciseWellness.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Exercise &amp; Wellness</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679639411.TrackField.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Track &amp; Field</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679639507.Cross Country.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Cross Country</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679919626.Golf.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Golf</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679919683.DiscGolf.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Disc Golf</p>
                                     </div>
                                   </label>
                                 </li>
                                 <li>
                                   <label>
                                     <input type="radio" name="select-sport" />
                                     <div className="icon-box">
                                       <div className="box-img">
                                         <img
                                           src="process.env.REACT_APP_GET_ASSET_PATHsport/1679919716.Cycling.png"
                                           className="img-fluid"
                                           alt=""
                                         />
                                       </div>
                                       <p>Cycling</p>
                                     </div>
                                   </label>
                                 </li>
                               </ul> */}
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn submit-btn"
                        // onClick={handleSubmitTeams}
                      >
                        Submit
                      </button>
                    </form>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Dialog>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default MySocialCreate;
