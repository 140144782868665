import {
  CREATE_MODULE_PERMISSION_REQUEST,
  CREATE_MODULE_PERMISSION_SUCCESS,
  CREATE_MODULE_PERMISSION_FAILURE,
  STORE_NAME_RESET,
  GET_ALL_PERMISSION_ROLE_REQUEST,
  GET_ALL_PERMISSION_ROLE_SUCCESS,
  GET_ALL_PERMISSION_ROLE_FAILURE,
  GET_PERMISSION_BY_IUSERID_REQUEST,
  GET_PERMISSION_BY_IUSERID_SUCCESS,
  GET_PERMISSION_BY_IUSERID_FAILURE,
  GET_USER_OTHER_ROLE_PERMISSION_REQUEST,
  GET_USER_OTHER_ROLE_PERMISSION_SUCCESS,
  GET_USER_OTHER_ROLE_PERMISSION_FAILURE,
} from "../constants/permission";
import axios from "axios";

export const CreateModulePermission = (values) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_MODULE_PERMISSION_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/permission/createPermission`,
      values
    );
    dispatch({
      type: CREATE_MODULE_PERMISSION_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: CREATE_MODULE_PERMISSION_FAILURE });
  }
};

export const ResetState = () => async (dispatch) => {
  dispatch({ type: STORE_NAME_RESET });
};

export const GetAllPermissionByRole = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PERMISSION_ROLE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/permission/getAllPermissionById`,
      values
    );
    dispatch({
      type: GET_ALL_PERMISSION_ROLE_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_PERMISSION_ROLE_FAILURE });
  }
};

export const GetAllPermissionByUser = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_PERMISSION_BY_IUSERID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/permissiontrans/getAllPermissionTransactionData`,
      values
    );
    dispatch({
      type: GET_PERMISSION_BY_IUSERID_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_PERMISSION_BY_IUSERID_FAILURE });
  }
};

export const GetAllPermissionByOtherRole = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_USER_OTHER_ROLE_PERMISSION_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/permission/getAllPermissionData`,
      values
    );
    dispatch({
      type: GET_USER_OTHER_ROLE_PERMISSION_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_USER_OTHER_ROLE_PERMISSION_FAILURE });
  }
};
