import React from "react";

const TeamPlaybookSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_316" data-name="Group 316" transform="translate(-4131 832)">
        <path
          id="fi-rr-square"
          d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0Zm3,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H19a3,3,0,0,1,3,3Z"
          transform="translate(4131 -832)"
        />
        <g
          id="Group_309"
          data-name="Group 309"
          transform="translate(4423.876 -934.535)"
        >
          <path
            id="Path_465"
            data-name="Path 465"
            d="M-112.788,125.026a4.933,4.933,0,0,1-.2.6,1.854,1.854,0,0,1-2.095,1,1.858,1.858,0,0,1-1.439-1.885,1.864,1.864,0,0,1,1.716-1.792,1.866,1.866,0,0,1,1.988,1.58.264.264,0,0,0,.024.057Zm-1.886.862a1.088,1.088,0,0,0,1.1-1.065,1.092,1.092,0,0,0-1.072-1.1,1.085,1.085,0,0,0-1.094,1.073A1.081,1.081,0,0,0-114.674,125.889Z"
            transform="translate(-160.151 -13.632)"
            stroke="#000"
            strokeWidth="0.3"
          />
          <path
            id="Path_466"
            data-name="Path 466"
            d="M-279.745,127.577l-.653-.57c-.056-.049-.113-.1-.166-.146a.387.387,0,0,1-.046-.557.385.385,0,0,1,.558-.029c.239.2.473.408.708.613l.805.7a.4.4,0,0,1,.051.642q-.652.752-1.305,1.5a.388.388,0,0,1-.442.137.37.37,0,0,1-.262-.31.4.4,0,0,1,.117-.344l.632-.726c.033-.038.064-.077.106-.127a6.076,6.076,0,0,0-5.2,4.327,5.976,5.976,0,0,1,.621.4,1.86,1.86,0,0,1-.5,3.128,1.871,1.871,0,0,1-2.576-1.859,1.87,1.87,0,0,1,1.527-1.7.178.178,0,0,0,.161-.149,7.284,7.284,0,0,1,1.259-2.389,6.53,6.53,0,0,1,3.223-2.229c.4-.127.824-.2,1.237-.293C-279.851,127.6-279.809,127.589-279.745,127.577Zm-5.709,8a1.081,1.081,0,0,0,1.1-1.063,1.088,1.088,0,0,0-1.062-1.1,1.1,1.1,0,0,0-1.1,1.066A1.088,1.088,0,0,0-285.455,135.578Z"
            transform="translate(0 -15.436)"
            stroke="#000"
            strokeWidth="0.3"
          />
          <path
            id="Path_467"
            data-name="Path 467"
            d="M-279.07,110.94l.489-.491c.162-.163.324-.326.488-.487a.4.4,0,0,1,.589-.033.4.4,0,0,1-.038.589c-.32.323-.643.643-.972.972.033.036.059.068.088.1l.91.911a.383.383,0,0,1,.1.418.369.369,0,0,1-.316.256.4.4,0,0,1-.35-.134q-.441-.442-.883-.883c-.033-.033-.068-.065-.123-.117-.113.122-.219.241-.331.354-.216.219-.433.438-.654.652a.385.385,0,0,1-.568.015.385.385,0,0,1,.019-.569c.326-.33.656-.656.994-.993-.037-.039-.067-.073-.1-.106q-.441-.442-.883-.883a.4.4,0,0,1-.138-.373.368.368,0,0,1,.257-.3.388.388,0,0,1,.417.106l.9.9C-279.142,110.873-279.111,110.9-279.07,110.94Z"
            transform="translate(-6.546 -2)"
            stroke="#000"
            strokeWidth="0.3"
          />
          <path
            id="Path_468"
            data-name="Path 468"
            d="M-186.538,249.337c.333.333.655.655.978.976a.406.406,0,0,1,.141.384.369.369,0,0,1-.262.3.384.384,0,0,1-.406-.1q-.45-.451-.9-.9c-.031-.031-.055-.067-.089-.11-.048.045-.082.075-.115.108-.3.3-.6.6-.9.9a.4.4,0,0,1-.466.084.368.368,0,0,1-.2-.412.593.593,0,0,1,.146-.252c.283-.294.573-.579.863-.866a.935.935,0,0,1,.129-.089l-.532-.53c-.169-.169-.34-.334-.5-.508a.388.388,0,0,1,0-.55.382.382,0,0,1,.549,0c.3.3.6.6.9.9a1.184,1.184,0,0,1,.093.123c.055-.051.09-.081.122-.113.3-.3.6-.6.9-.9a.378.378,0,0,1,.4-.107.376.376,0,0,1,.265.294.4.4,0,0,1-.137.386l-.883.883C-186.471,249.264-186.5,249.3-186.538,249.337Z"
            transform="translate(-91.259 -130.089)"
            stroke="#000"
            strokeWidth="0.3"
          />
        </g>
      </g>
    </svg>
  );
};
export default TeamPlaybookSVG;
