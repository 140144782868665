import React from "react";
import { GetAllNotificationList } from "../../../../store/action/notificationsAction";
import { useDispatch } from "react-redux";
import axios from "axios";

function NotificationFollowButtons({ item }) {
  const dispatch = useDispatch();

  const handleFollowBackUser = React.useCallback(
    async (data) => {
      if (data && Object.keys(data).length > 0) {
        const resData = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/follower/FollowBackUserRequest`,
          {
            iUserId: sessionStorage.getItem("id"),
            iRequesterId: data.iUserId,
            userAccountType: sessionStorage.getItem("userProfileType"),
            requestUserAccountType: data.eUserAccountType,
            // eRequesterStatus: postData?.user?.eUserAccountType === "Private" ? "Requested" : "Accept"
          }
        );
        if (
          resData.status === 200 &&
          resData.data &&
          resData.data.code === "200"
        ) {
          dispatch(
            GetAllNotificationList({ iVisibleId: sessionStorage.getItem("id") })
          );
        }
      }
    },
    [dispatch]
  );

  //  when the user accept the user request then used..
  const handleAcceptUserRequest = React.useCallback(
    async (data) => {
      if (data && Object.keys(data).length > 0) {
        const reqData = {
          iUserId: sessionStorage.getItem("id"),
          iRequesterId: data.iRequesterId,
          userAccountType: sessionStorage.getItem("userProfileType"),
          requestUserAccountType: data.eUserAccountType,
          eRequesterStatus:
            data.eUserAccountType === "Private" &&
            data.RequesterStatus === "Pending"
              ? "Accept"
              : data.eUserAccountType === "Public" &&
                data.RequesterStatus === "Double Follow"
              ? "Accept"
              : "Requested",
        };
        const resData = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/follower/acceptAndDeleteRequest`,
          reqData
        );
        if (
          resData.status === 200 &&
          resData.data &&
          resData.data.code === "200"
        ) {
          dispatch(
            GetAllNotificationList({ iVisibleId: sessionStorage.getItem("id") })
          );
        }
      }
    },
    [dispatch]
  );

  //  when the user decline the user request then used...
  const handleDeclineUserRequest = React.useCallback(
    async (data) => {
      if (data && Object.keys(data).length > 0) {
        const reqData = {
          iUserId: sessionStorage.getItem("id"),
          iRequesterId: data.iRequesterId,
          eRequesterStatus:
            data.RequesterStatus === "Double Follow" ? "Decline" : "",
        };

        const resData = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/follower/DeclineAndRequested`,
          reqData
        );
        if (
          resData.status === 200 &&
          resData.data &&
          resData.data.code === "200"
        ) {
          dispatch(
            GetAllNotificationList({ iVisibleId: sessionStorage.getItem("id") })
          );
        }
      }
    },
    [dispatch]
  );

  return (
    <div className="action-btn">
      {item.user.RequesterStatus === "Accept" ? (
        <button
          className="follow-btn btn"
          onClick={() => handleFollowBackUser(item.user)}
        >
          Follow Back
        </button>
      ) : item.user.RequesterStatus === "Pending" ? (
        <>
          <button
            className="accept-btn btn"
            onClick={() => handleAcceptUserRequest(item.user)}
          >
            Accept
          </button>
          <button
            className="cancel-btn btn"
            onClick={() => handleDeclineUserRequest(item.user)}
          >
            Decline
          </button>
        </>
      ) : item.user.RequesterStatus === "Following" ? (
        <button className="following-btn btn">Following</button>
      ) : item.user.RequesterStatus === "Requested" ? (
        <button className="following-btn btn">Requested</button>
      ) : item.user.RequesterStatus !== "Accept" ? (
        <>
          <button
            className="accept-btn btn"
            onClick={() => handleAcceptUserRequest(item.user)}
          >
            Accept
          </button>
          <button
            className="cancel-btn btn"
            onClick={() => handleDeclineUserRequest(item.user)}
          >
            Decline
          </button>
        </>
      ) : (
        <></>
      )}

      {/* <button className="follow-btn btn">Follow</button> */}
      {/* <button className="follow-btn btn">Follow Back</button> */}
      {/* <button className="following-btn btn">Following</button> */}
      {/* <button className="following-btn btn">Requested</button> */}
      {/* <button className="accept-btn btn">Accept</button> */}
      {/* <button className="cancel-btn btn">Cancel</button> */}
    </div>
  );
}

export default NotificationFollowButtons;
