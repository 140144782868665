import {
  GET_ALL_ROOM_REQUEST,
  GET_ALL_ROOM_SUCCESS,
  GET_ALL_ROOM_FAILURE,
  // CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE_SUCCESS,
  // CREATE_MESSAGE_FAILURE,
  GET_ALL_CHAT_BY_ROOM_REQUEST,
  GET_ALL_CHAT_BY_ROOM_SUCCESS,
  GET_ALL_CHAT_BY_ROOM_FAILURE,
  GET_ALL_MEDIA_BY_ROOM_REQUEST,
  GET_ALL_MEDIA_BY_ROOM_SUCCESS,
  GET_ALL_MEDIA_BY_ROOM_FAILURE,
  GET_ALL_CHAT_GROUP_MEMBER_REQUEST,
  GET_ALL_CHAT_GROUP_MEMBER_SUCCESS,
  GET_ALL_CHAT_GROUP_MEMBER_FAILURE,
  GET_ALL_CHAT_BY_ROOMID_REQUEST,
  GET_ALL_CHAT_BY_ROOMID_SUCCESS,
  GET_ALL_CHAT_CHAT_BY_ROOMID_FAILURE,
  GET_CHAT_USER_FOLLOWER_REQUEST,
  GET_CHAT_USER_FOLLOWER_SUCCESS,
  GET_CHAT_USER_FOLLOWER_FAILURE,
  UPDATE_CHAT_SEEN_MESSAGES_REQUEST,
  UPDATE_CHAT_SEEN_MESSAGES_SUCCESS,
  UPDATE_CHAT_SEEN_MESSAGES_FAILURE,
  GET_ALL_CHAT_BY_ROOMID_RESET,
  GET_ALL_UNSEEN_COUNT_MESSAGE_REQUEST,
  GET_ALL_UNSEEN_COUNT_MESSAGE_SUCCESS,
  GET_ALL_UNSEEN_COUNT_MESSAGE_FAILURE,
} from "../constants/chat";

export function chatReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_ROOM_REQUEST:
    case GET_ALL_CHAT_BY_ROOM_REQUEST:
    case GET_ALL_MEDIA_BY_ROOM_REQUEST:
    case GET_ALL_CHAT_GROUP_MEMBER_REQUEST:
    case GET_ALL_CHAT_BY_ROOMID_REQUEST:
    case GET_CHAT_USER_FOLLOWER_REQUEST:
    case UPDATE_CHAT_SEEN_MESSAGES_REQUEST:
    case GET_ALL_UNSEEN_COUNT_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllRoomByUserData: action.payload,
      };
    case GET_ALL_MEDIA_BY_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllMediaByRoomData: action.payload,
      };
    case GET_ALL_CHAT_GROUP_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllChatGroupMemberData: action.payload,
      };
    case CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        createMessageData: action.payload,
      };
    case GET_ALL_CHAT_BY_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllChatByRoomData: action.payload,
      };
    case GET_ALL_CHAT_BY_ROOMID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllChatByRoomIdData: action.payload,
      };
    case GET_CHAT_USER_FOLLOWER_SUCCESS:
      return {
        ...state,
        loading: false,
        getChatFollowerData: action.payload,
      };
    case UPDATE_CHAT_SEEN_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        updateChatSeenMsgData: action.payload,
      };
    case GET_ALL_UNSEEN_COUNT_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllUnseenCountData: action.payload,
      };
    case GET_ALL_CHAT_BY_ROOM_FAILURE:
    case GET_ALL_MEDIA_BY_ROOM_FAILURE:
    case GET_ALL_ROOM_FAILURE:
    case GET_ALL_CHAT_GROUP_MEMBER_FAILURE:
    case GET_ALL_CHAT_CHAT_BY_ROOMID_FAILURE:
    case GET_CHAT_USER_FOLLOWER_FAILURE:
    case UPDATE_CHAT_SEEN_MESSAGES_FAILURE:
    case GET_ALL_UNSEEN_COUNT_MESSAGE_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CHAT_BY_ROOMID_RESET:
      return {
        ...state,
        loading: false,
        getAllChatByRoomIdData: {},
      };
    default:
      return state;
  }
}
