import React from "react";
import AuthHeader from "./AuthHeader";
// import Banner from "./Banner";
import { useNavigate } from "react-router-dom";
import LeftList from "./LeftList";
import CreatPost from "./CreatPost";
import { useLocation } from "react-router-dom";
import TopBanner from "./TopBanner";
import TeamChatList from "./TeamChatList";
import MySocialCreate from "./MySocialCreate";
import ErrorBoundary from "./ErrorBoundary";
import DownloadLinksModal from "./DownloadLinksModal";
// import AccessLock from "./AccessLock";
import NotificationEventDetail from "./NotificationEventDetail";
import Input from "../Input/Input";
import {
  UserProfile,
  UserProfileUpdate,
} from "../../../store/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import axios from "axios";
import { useGetUnder13User } from "../../../hooks/useGetUnder13User";
import CreatePostTeam from "./CreatePostTeam";
import CreatePostGroup from "./CreatePostGroup";

// import { useSelector } from "react-redux";

function DashboardPageContainer(props) {
  const toast = React.useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const isUnder13 = useGetUnder13User();

  const { userProfileData, userProfileUpdateData } = useSelector(
    (state) => state.user
  );
  const navigate = useNavigate();
  // const [isMenuOpen, setIsMenuOpen] = React.useState({ id: 0, type: +false });
  const [checkError, setCheckError] = React.useState();
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(+false);

  React.useEffect(() => {
    if (
      !sessionStorage.getItem("id") ||
      sessionStorage.getItem("id") === "" ||
      sessionStorage.getItem("id") === null
    ) {
      navigate("/login");
    }
  }, [navigate]);

  React.useEffect(() => {
    if (userProfileUpdateData && userProfileUpdateData?.code === "200") {
      toast.current.show({
        severity: "success",
        summary: userProfileUpdateData?.message,
        // detail: emailCheckData?.message,
        life: 3000,
      });
      // dispatch(ResetState());
      const values = {
        iUserId: sessionStorage.getItem("id"),
      };
      dispatch(UserProfile(values));
      window.$("#staticBackdrop").modal("hide");
    }
  }, [dispatch, userProfileUpdateData]);

  React.useEffect(() => {
    if (
      sessionStorage.getItem("id") !== null &&
      userProfileData &&
      userProfileData.code === "200" &&
      userProfileData.data.iUserId.toString() ===
        sessionStorage.getItem("id") &&
      userProfileData.data.vEmail === null
    ) {
      window.$("#staticBackdrop").modal("show");
    }
  }, [userProfileData]);

  const handleOutSideClick = React.useCallback(() => {
    // var menucls = document.querySelector("#newclass-for-test");
    var element = document.querySelector("#notification-btn");
    document.body.addEventListener("click", function (event) {
      // if (!menucls.contains(event.target)) {
      //   setIsMenuOpen({
      //     id: 0,
      //     type: +false,
      //   });
      // }
      if (!element.contains(event.target)) {
        setIsNotificationOpen(+true);
      }
    });
  }, []);

  const handleSubmit = React.useCallback(
    async (event) => {
      const formData = new FormData();
      event.preventDefault();
      let errors = {};
      for (let i = 0; i < event.target.length - 1; i++) {
        if (event.target[i].name !== "") {
          if (
            event.target[i].value === "" ||
            event.target[i].value === "NaN/NaN/NaN"
          ) {
            errors = {
              ...errors,
              [event.target[i].name]: true,
            };
          }
          formData.append([event.target[i].name], event.target[i].value);
        }
      }

      formData.append("iUserId", sessionStorage.getItem("id"));

      setCheckError(errors);
      if (Object.keys(errors).length === 0) {
        const emailcheck = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/checkEmailExists`,
          {
            vEmail: event.target["vEmail"].value,
          }
        );

        if (
          emailcheck.status === 200 &&
          emailcheck.data &&
          Object.keys(emailcheck.data).length > 0 &&
          emailcheck.data.code === "200"
        ) {
          dispatch(UserProfileUpdate(formData));
        } else if (
          emailcheck.status === 200 &&
          emailcheck.data &&
          Object.keys(emailcheck.data).length > 0 &&
          emailcheck.data.code === "404"
        ) {
          toast.current.show({
            severity: "warn",
            summary: emailcheck.data.message,
            life: 1000,
          });
        }
      }
    },
    [dispatch, setCheckError]
  );

  return (
    <ErrorBoundary>
      {sessionStorage.getItem("id") ? (
        <>
          {location.pathname === "/team-chat" ? (
            <></>
          ) : (
            <TopBanner
            // isNotificationClose={isNotificationOpen}
            // setIsNotificationClose={setIsNotificationOpen}
            />
          )}
          <div
            onClick={handleOutSideClick}
            className={
              location.pathname === "/team-chat"
                ? "main-layer left-panel-impop"
                : "main-layer"
            }
          >
            <LeftList />
            <DownloadLinksModal />
            {location.pathname === "/team-chat" ? (
              props.children
            ) : (
              <div className="middle-layer">
                {/* <AuthHeader isMenuClose={isMenuOpen} /> */}
                <AuthHeader
                  isNotificationClose={isNotificationOpen}
                  setIsNotificationClose={setIsNotificationOpen}
                />

                {/* {location.pathname === "/chat" || isUnder13 ? (
                  <></>
                ) : (
                  <TeamChatList />
                )} */}
                {location.pathname !== "/chat" &&
                location.pathname === "/groups" &&
                !isUnder13 ? (
                  <TeamChatList />
                ) : (
                  <></>
                )}

                <div
                  className="modal fade"
                  id="staticBackdrop"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <Toast ref={toast} />
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body">
                        <form method="post" onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label>Email Address</label>
                            <Input
                              placeHolder={"Enter Your Email"}
                              name="vEmail"
                              type="email"
                              title="Email"
                              isError={checkError?.vEmail}
                              isValidate={true}
                              newClass={"form-control"}
                            />
                          </div>
                          <div className="modal-footer border-0">
                            <button type="submit" className="bg-btn">
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                {props.children}
              </div>
            )}
            <MySocialCreate />
            <CreatPost />
            <CreatePostTeam />
            <CreatePostGroup />
          </div>
        </>
      ) : (
        <></>
      )}
      {/* <AccessLock /> */}
      <NotificationEventDetail />
    </ErrorBoundary>
  );
}

export default DashboardPageContainer;
