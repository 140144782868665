import React from "react";
import Footer from "./Footer";
import Header from "./Header";

function PageContainer(props) {
  const [isMenuOpen, setIsMenuOpen] = React.useState({ id: 0, type: false });

  const handleOutSideClick = React.useCallback(() => {
    var menucls =
      document.querySelector("#newclass-for-test");
    document.body.addEventListener("click", function (event) {
      if (!menucls.contains(event.target)) {
        setIsMenuOpen({
          id: 0,
          type: false,
        });

        // console.log("clicked outside");
      }
    });
  }, []);
  return (
    <div onClick={handleOutSideClick}>
      <Header isMenuClose={isMenuOpen} />
      <div>{props.children}</div>
      <Footer />
    </div>
  );
}

export default PageContainer;
