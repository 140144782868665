import {
  GET_ALL_SCHEDULE_DATA_FAILURE,
  GET_ALL_SCHEDULE_DATA_REQUEST,
  GET_ALL_SCHEDULE_DATA_SUCCESS,
  GET_SHEDULE_TEAM_ID_REQUEST,
  GET_SHEDULE_TEAM_ID_SUCCESS,
  GET_SHEDULE_TEAM_ID_FAILURE,
} from "../constants/schedule";

export function scheduleReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_SCHEDULE_DATA_REQUEST:
      case GET_SHEDULE_TEAM_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SCHEDULE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        allScheduleData: action.payload,
      };
      case GET_SHEDULE_TEAM_ID_SUCCESS :
        return{
          ...state,
          loading: false,
          shcedulTimeTeamId:action.payload
        }
    case GET_ALL_SCHEDULE_DATA_FAILURE:
      return {
        ...state,
        loading: true,
      };
      case GET_SHEDULE_TEAM_ID_FAILURE :
        return{
          ...state,
          loading :true,
          shcedulTimeTeamId:''
        }
    default:
      return state;
  }
}
