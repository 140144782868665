import {
  GET_ALL_FACILITY_REQUEST,
  GET_ALL_FACILITY_SUCCESS,
  GET_ALL_FACILITY_FAILURE,
  CREATE_FACILITY_REQUEST,
  CREATE_FACILITY_SUCCESS,
  CREATE_FACILITY_FAILURE,
  UPDATE_FACILITY_REQUEST,
  UPDATE_FACILITY_SUCCESS,
  UPDATE_FACILITY_FAILURE,
  DELETE_FACILITY_REQUEST,
  DELETE_FACILITY_SUCCESS,
  DELETE_FACILITY_FAILURE,
  GET_FACILITY_BY_FACILITYID_REQUEST,
  GET_FACILITY_BY_FACILITYID_SUCCESS,
  GET_FACILITY_BY_FACILITYID_FAILURE,
  RESET_STATE
} from "../constants/facility";
import axios from "axios";

export const CreateFacility = (values) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_FACILITY_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Facility/createFacility`,
      values
    );
    dispatch({ type: CREATE_FACILITY_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: CREATE_FACILITY_FAILURE });
  }
};

export const UpdateFacility = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_FACILITY_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Facility/updateFacility`,
      values
    );
    dispatch({ type: UPDATE_FACILITY_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: UPDATE_FACILITY_FAILURE });
  }
};

export const DeleteFacility = (values) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_FACILITY_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Facility/deleteFacility`,
      values
    );
    dispatch({ type: DELETE_FACILITY_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: DELETE_FACILITY_FAILURE });
  }
};

export const GetFacilityByFacilityId = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_FACILITY_BY_FACILITYID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Facility/getAllFacilityById`,
      values
    );
    dispatch({
      type: GET_FACILITY_BY_FACILITYID_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_FACILITY_BY_FACILITYID_FAILURE });
  }
};

export const GetAllFacility = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_FACILITY_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Facility/getAllFacilityDataiUserId`,
      values
    );
    dispatch({ type: GET_ALL_FACILITY_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_FACILITY_FAILURE });
  }
};

export const FacilityResetState = () => async (dispatch) => {
  dispatch({ type: RESET_STATE });
};