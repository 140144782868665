import {
    CREATE_PAYEMENT_CREDENTIAL_REQUEST,
    CREATE_PAYEMENT_CREDENTIAL_SUCCESS,
    CREATE_PAYEMENT_CREDENTIAL_FAILURE,
    UPDATE_PAYEMENT_CREDENTIAL_REQUEST,
    UPDATE_PAYEMENT_CREDENTIAL_SUCCESS,
    UPDATE_PAYEMENT_CREDENTIAL_FAILURE,
    GET_ALL_PAYEMENT_CREDENTIAL_BY_ID_REQUEST,
    GET_ALL_PAYEMENT_CREDENTIAL_BY_ID_SUCCESS,
    GET_ALL_PAYEMENT_CREDENTIAL_BY_ID_FAILURE,
    GET_PAYEMENT_CREDENTIAL_BY_USER_ID_REQUEST,
    GET_PAYEMENT_CREDENTIAL_BY_USER_ID_SUCCESS,
    GET_PAYEMENT_CREDENTIAL_BY_USER_ID_FAILURE,
    REMOVE_PAYEMENT_CREDENTIAL_REQUEST,
    REMOVE_PAYEMENT_CREDENTIAL_SUCCESS,
    REMOVE_PAYEMENT_CREDENTIAL_FAILURE,
    VERIFY_PAYEMENT_CREDENTIAL_REQUEST,
    VERIFY_PAYEMENT_CREDENTIAL_SUCCESS,
    VERIFY_PAYEMENT_CREDENTIAL_FAILURE,
    RESET_PAYEMENT_CREDENTIAL_FAILURE,
} from '../constants/paymentCredential';

const initialState = {};

export function PaymentCredentialReducer(state = {}, action) {
    switch (action.type) {
        case CREATE_PAYEMENT_CREDENTIAL_REQUEST:
        case UPDATE_PAYEMENT_CREDENTIAL_REQUEST:
        case GET_ALL_PAYEMENT_CREDENTIAL_BY_ID_REQUEST:
        case GET_PAYEMENT_CREDENTIAL_BY_USER_ID_REQUEST:
        case REMOVE_PAYEMENT_CREDENTIAL_REQUEST:
        case VERIFY_PAYEMENT_CREDENTIAL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_PAYEMENT_CREDENTIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                createPaymentCredentialData: action.payload,
            }
        case UPDATE_PAYEMENT_CREDENTIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                updatePaymentCredentialData: action.payload,
            }
        case GET_ALL_PAYEMENT_CREDENTIAL_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllPaymentCredentialData: action.payload,
            }
        case GET_PAYEMENT_CREDENTIAL_BY_USER_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                getPaymentCredentialData: action.payload,
            }
        case REMOVE_PAYEMENT_CREDENTIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                removePaymentCredentialData: action.payload,
            }
        case VERIFY_PAYEMENT_CREDENTIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                verifyPaymentCredentialData: action.payload
            }

        case CREATE_PAYEMENT_CREDENTIAL_FAILURE:
        case UPDATE_PAYEMENT_CREDENTIAL_FAILURE:
        case GET_ALL_PAYEMENT_CREDENTIAL_BY_ID_FAILURE:
        case GET_PAYEMENT_CREDENTIAL_BY_USER_ID_FAILURE:
        case REMOVE_PAYEMENT_CREDENTIAL_FAILURE:
        case VERIFY_PAYEMENT_CREDENTIAL_FAILURE:
            return {
                ...state,
                loading: true
            }
        case RESET_PAYEMENT_CREDENTIAL_FAILURE:
            return initialState
        default:
            return state
    }
} 