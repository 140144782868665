export const CREATE_SECONDARY_SCHOOL_REQUEST =
  "CREATE_SECONDARY_SCHOOL_REQUEST";
export const CREATE_SECONDARY_SCHOOL_SUCCESS =
  "CREATE_SECONDARY_SCHOOL_SUCCESS";
export const CREATE_SECONDARY_SCHOOL_FAILURE =
  "CREATE_SECONDARY_SCHOOL_FAILURE";
  export const RESET_SECONDARY_SCHOOL_CREATE_DATA =
  "RESET_SECONDARY_SCHOOL_CREATE_DATA";

export const UPDATE_SECONDARY_SCHOOL_REQUEST =
  "UPDATE_SECONDARY_SCHOOL_REQUEST";
export const UPDATE_SECONDARY_SCHOOL_SUCCESS =
  "UPDATE_SECONDARY_SCHOOL_SUCCESS";
export const UPDATE_SECONDARY_SCHOOL_FAILURE =
  "UPDATE_SECONDARY_SCHOOL_FAILURE";
export const RESET_UPDATE_SECONDARY_SCHOOL = "RESET_UPDATE_SECONDARY_SCHOOL"

export const DELETE_SECONDARY_SCHOOL_REQUEST =
  "DELETE_SECONDARY_SCHOOL_REQUEST";
export const DELETE_SECONDARY_SCHOOL_SUCCESS =
  "DELETE_SECONDARY_SCHOOL_SUCCESS";
export const DELETE_SECONDARY_SCHOOL_FAILURE =
  "DELETE_SECONDARY_SCHOOL_FAILURE";

export const GET_ALL_SECONDARY_SCHOOL_REQUEST =
  "GET_ALL_SECONDARY_SCHOOL_REQUEST";
export const GET_ALL_SECONDARY_SCHOOL_SUCCESS =
  "GET_ALL_SECONDARY_SCHOOL_SUCCESS";
export const GET_ALL_SECONDARY_SCHOOL_FAILURE =
  "GET_ALL_SECONDARY_SCHOOL_FAILURE";

export const GET_SECONDARY_SCHOOL_BY_ID_REQUEST =
  "GET_SECONDARY_SCHOOL_BY_ID_REQUEST";
export const GET_SECONDARY_SCHOOL_BY_ID_SUCCESS =
  "GET_SECONDARY_SCHOOL_BY_ID_SUCCESS";
export const GET_SECONDARY_SCHOOL_BY_ID_FAILURE =
  "GET_SECONDARY_SCHOOL_BY_ID_FAILURE";
  export const GET_SECONDARY_SCHOOL_BY_ID_RESET =
  "GET_SECONDARY_SCHOOL_BY_ID_RESET";
