import {
  CREATE_MEMBER_REQUEST,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAILURE,
  EDIT_MEMBER_REQUEST,
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_FAILURE,
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,
  GET_MEMBER_BY_MEMBERID_REQUEST,
  GET_MEMBER_BY_MEMBERID_SUCCESS,
  GET_MEMBER_BY_MEMBERID_FAILURE,
  GET_MEMBERS_REQUEST,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAILURE,
  GET_MEMBER_BY_ROLEID_REQUEST,
  GET_MEMBER_BY_ROLEID_SUCCESS,
  GET_MEMBER_BY_ROLEID_FAILURE,
  STORE_NAME_RESET
} from "../constants/teamMember";

const initialState = {}

export function teamMemberReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_MEMBER_REQUEST:
    case EDIT_MEMBER_REQUEST:
    case DELETE_MEMBER_REQUEST:
    case GET_MEMBER_BY_MEMBERID_REQUEST:
    case GET_MEMBERS_REQUEST:
    case GET_MEMBER_BY_ROLEID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        createMemberData: action.payload,
      };
    case EDIT_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        editMemberData: action.payload,
      };
    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteMemberData: action.payload,
      };
    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllMemberData: action.payload,
      };
    case GET_MEMBER_BY_MEMBERID_SUCCESS:
      return {
        ...state,
        loading: false,
        getMemberByMemberIDData: action.payload,
      };
    case GET_MEMBER_BY_ROLEID_SUCCESS:
      return {
        ...state,
        loading: false,
        getMemberByRoleIDData: action.payload,
      };
    case CREATE_MEMBER_FAILURE:
    case EDIT_MEMBER_FAILURE:
    case DELETE_MEMBER_FAILURE:
    case GET_MEMBERS_FAILURE:
    case GET_MEMBER_BY_MEMBERID_FAILURE:
    case GET_MEMBER_BY_ROLEID_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case STORE_NAME_RESET:
      return initialState
    default:
      return state;
  }
}
