import {
  CREATE_EDUCATION_FAILURE,
  CREATE_EDUCATION_REQUEST,
  CREATE_EDUCATION_SUCCESS,
  DELETE_EDUCATION_FAILURE,
  DELETE_EDUCATION_REQUEST,
  DELETE_EDUCATION_SUCCESS,
  GET_ALL_EDUCATION_FAILURE,
  GET_ALL_EDUCATION_REQUEST,
  GET_ALL_EDUCATION_SUCCESS,
  GET_EDUCATION_BY_ID_FAILURE,
  GET_EDUCATION_BY_ID_REQUEST,
  GET_EDUCATION_BY_ID_SUCCESS,
  GET_EDUCATION_BY_ID_RESET_STATE,
  UPDATE_EDUCATION_FAILURE,
  UPDATE_EDUCATION_REQUEST,
  UPDATE_EDUCATION_SUCCESS,
  UPDATE_EDUCATION_RESET_STATE,
  CREATE_EDUCATION_RESET,
} from "../constants/education";
// const initialState = {};

export function educationReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_EDUCATION_REQUEST:
    case UPDATE_EDUCATION_REQUEST:
    case DELETE_EDUCATION_REQUEST:
    case GET_ALL_EDUCATION_REQUEST:
    case GET_EDUCATION_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        createEducationData: action.payload,
      };
    case UPDATE_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        updateEducationData: action.payload,
      };
    case DELETE_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteEducationData: action.payload,
      };
    case GET_ALL_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllEducationData: action.payload,
      };
    case GET_EDUCATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getEducationByIdData: action.payload,
      };
    case CREATE_EDUCATION_FAILURE:
    case UPDATE_EDUCATION_FAILURE:
    case DELETE_EDUCATION_FAILURE:
    case GET_ALL_EDUCATION_FAILURE:
    case GET_EDUCATION_BY_ID_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_EDUCATION_RESET_STATE:
      return {
        ...state,
        loading: false,
        updateEducationData: {},
      };
    case CREATE_EDUCATION_RESET:
      return {
        ...state,
        loading: false,
        createEducationData: {},
      };
    case GET_EDUCATION_BY_ID_RESET_STATE:
      return {
        ...state,
        loading: false,
        getEducationByIdData: {},
      };
    default:
      return state;
  }
}
