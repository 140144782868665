export const RESET = "RESET"

// when the user confirm requst then used...
export const IS_CONFIRM_FOLLOW_TO_USER_REQUEST = "IS_CONFIRM_FOLLOW_TO_USER_REQUEST";
export const IS_CONFIRM_FOLLOW_TO_USER_SUCCESS = "IS_CONFIRM_FOLLOW_TO_USER_SUCCESS";
export const IS_CONFIRM_FOLLOW_TO_USER_FAILURE = "IS_CONFIRM_FOLLOW_TO_USER_FAILURE";

// when the user delete the coming request then used...
export const IS_DELETE_USER_COMING_REQUEST_REQUEST = "IS_DELETE_USER_COMING_REQUEST_REQUEST";
export const IS_DELETE_USER_COMING_REQUEST_SUCCESS = "IS_DELETE_USER_COMING_REQUEST_SUCCESS";
export const IS_DELETE_USER_COMING_REQUEST_FAILURE = "IS_DELETE_USER_COMING_REQUEST_FAILURE";

// when the user send request and cancel request then used...
export const IS_USER_CANCEL_REQUEST_REQUEST = "IS_USER_CANCEL_REQUEST_REQUEST";
export const IS_USER_CANCEL_REQUEST_SUCCESS = "IS_USER_CANCEL_REQUEST_SUCCESS";
export const IS_USER_CANCEL_REQUEST_FAILURE = "IS_USER_CANCEL_REQUEST_FAILURE";

//  when the user follow to new user then used...
export const IS_FOLLOW_NEW_USER_REQUEST = "IS_FOLLOW_NEW_USER_REQUEST";
export const IS_FOLLOW_NEW_USER_SUCCESS = "IS_FOLLOW_NEW_USER_SUCCESS";
export const IS_FOLLOW_NEW_USER_FAILURE = "IS_FOLLOW_NEW_USER_FAILURE";

// when the user Remove for the suggestion then used...
export const IS_REMOVE_USER_FOR_SUGGESTION_REQUEST = "IS_REMOVE_USER_FOR_SUGGESTION_REQUEST";
export const IS_REMOVE_USER_FOR_SUGGESTION_SUCCESS = "IS_REMOVE_USER_FOR_SUGGESTION_SUCCESS";
export const IS_REMOVE_USER_FOR_SUGGESTION_FAILURE = "IS_REMOVE_USER_FOR_SUGGESTION_FAILURE";

// when the user unfollow following user then used...
export const IS_UNFOLLOW_USER_REQUEST = "IS_UNFOLLOW_USER_REQUEST";
export const IS_UNFOLLOW_USER_SUCCESS = "IS_UNFOLLOW_USER_SUCCESS";
export const IS_UNFOLLOW_USER_FAILURE = "IS_UNFOLLOW_USER_FAILURE";

// when the user block the another user then used...
export const IS_BLOCK_USER_REQUEST = "IS_BLOCK_USER_REQUEST";
export const IS_BLOCK_USER_SUCCESS = "IS_BLOCK_USER_SUCCESS";
export const IS_BLOCK_USER_FAILURE = "IS_BLOCK_USER_FAILURE";

// when the user follow bach the another user then used...
export const IS_FOLLOW_BACK_USER_REQUEST = "IS_FOLLOW_BACK_USER_REQUEST";
export const IS_FOLLOW_BACK_USER_SUCCESS = "IS_FOLLOW_BACK_USER_SUCCESS";
export const IS_FOLLOW_BACK_USER_FAILURE = "IS_FOLLOW_BACK_USER_FAILURE";

// when the user get all the comming request then used...
export const ALL_COMING_REQUEST_FOR_USER_REQUEST = "ALL_COMING_REQUEST_FOR_USER_REQUEST";
export const ALL_COMING_REQUEST_FOR_USER_SUCCESS = "ALL_COMING_REQUEST_FOR_USER_SUCCESS";
export const ALL_COMING_REQUEST_FOR_USER_FAILURE = "ALL_COMING_REQUEST_FOR_USER_FAILURE";

// when the user get all the request user list then used...
export const ALL_REQUESTED_USERS_REQUEST = "ALL_REQUESTED_USERS_REQUEST";
export const ALL_REQUESTED_USERS_SUCCESS = "ALL_REQUESTED_USERS_SUCCESS";
export const ALL_REQUESTED_USERS_FAILURE = "ALL_REQUESTED_USERS_FAILURE";

// when the user get all the suggestion list then used...
export const ALL_USERS_SUGGESTIONS_REQUEST = "ALL_USERS_SUGGESTIONS_REQUEST";
export const ALL_USERS_SUGGESTIONS_SUCCESS = "ALL_USERS_SUGGESTIONS_SUCCESS";
export const ALL_USERS_SUGGESTIONS_FAILURE = "ALL_USERS_SUGGESTIONS_FAILURE";

// when the get all user's follower list then used...
export const ALL_USER_FOLLOWERS_REQUEST = "ALL_USER_FOLLOWERS_REQUEST";
export const ALL_USER_FOLLOWERS_SUCCESS = "ALL_USER_FOLLOWERS_SUCCESS";
export const ALL_USER_FOLLOWERS_FAILURE = "ALL_USER_FOLLOWERS_FAILURE";

export const ALL_USER_FOLLOW_FOLLOWERS_REQUEST = "ALL_USER_FOLLOW_FOLLOWERS_REQUEST";
export const ALL_USER_FOLLOW_FOLLOWERS_SUCCESS = "ALL_USER_FOLLOW_FOLLOWERS_SUCCESS";
export const ALL_USER_FOLLOW_FOLLOWERS_FAILURE = "ALL_USER_FOLLOW_FOLLOWERS_FAILURE";

// when the get all user's following list then used...
export const ALL_USER_FOLLOWING_LIST_REQUEST = "ALL_USER_FOLLOWING_LIST_REQUEST";
export const ALL_USER_FOLLOWING_LIST_SUCCESS = "ALL_USER_FOLLOWING_LIST_SUCCESS";
export const ALL_USER_FOLLOWING_LIST_FAILURE = "ALL_USER_FOLLOWING_LIST_FAILURE";

// when the get all user and serached user list then used...
export const ALL_SEARCHED_USER_LIST_REQUEST = "ALL_SEARCHED_USER_LIST_REQUEST"
export const ALL_SEARCHED_USER_LIST_SUCCESS = "ALL_SEARCHED_USER_LIST_SUCCESS"
export const ALL_SEARCHED_USER_LIST_FAILURE = "ALL_SEARCHED_USER_LIST_FAILURE"

// when the both user private then user direct follow another user then used..
export const DIRECT_FOLLOW_USER_REQUEST = "DIRECT_FOLLOW_USER_REQUEST"
export const DIRECT_FOLLOW_USER_SUCCESS = "DIRECT_FOLLOW_USER_SUCCESS"
export const DIRECT_FOLLOW_USER_FAILURE = "DIRECT_FOLLOW_USER_FAILURE"

export const GET_ALL_FOLLOWER_USER_REQUEST = "GET_ALL_FOLLOWER_USER_REQUEST"
export const GET_ALL_FOLLOWER_USER_SUCCESS = "GET_ALL_FOLLOWER_USER_SUCCESS"
export const GET_ALL_FOLLOWER_USER_FAILURE = "GET_ALL_FOLLOWER_USER_FAILURE"

// when the user get all the following follower and coming request count then used...
export const GET_ALL_FOLLOW_FOLLOWING_COUNT_REQUEST = "GET_ALL_FOLLOW_FOLLOWING_COUNT_REQUEST"
export const GET_ALL_FOLLOW_FOLLOWING_COUNT_SUCCESS = "GET_ALL_FOLLOW_FOLLOWING_COUNT_SUCCESS"
export const GET_ALL_FOLLOW_FOLLOWING_COUNT_FAILURE = "GET_ALL_FOLLOW_FOLLOWING_COUNT_FAILURE"

// when the get all user status data then used...
export const GET_ALL_FOLLOW_STATUS_WISE_USERS_REQUEST = "GET_ALL_FOLLOW_STATUS_WISE_USERS_REQUEST"
export const GET_ALL_FOLLOW_STATUS_WISE_USERS_SUCCESS = "GET_ALL_FOLLOW_STATUS_WISE_USERS_SUCCESS"
export const GET_ALL_FOLLOW_STATUS_WISE_USERS_FAILURE = "GET_ALL_FOLLOW_STATUS_WISE_USERS_FAILURE"

//get follow status that user is following team or not
export const GET_TEAM_FOLLOW_REQUEST = "GET_TEAM_FOLLOW_REQUEST"
export const GET_TEAM_FOLLOW_SUCCESS = "GET_TEAM_FOLLOW_SUCCESS"
export const GET_TEAM_FOLLOW_FAILURE = "GET_TEAM_FOLLOW_FAILURE"


// get All Team Follwers
export const GET_ALL_TEAM_FOLLOWERS_REQUEST = "GET_ALL_TEAM_FOLLOWERS_REQUEST";
export const GET_ALL_TEAM_FOLLOWERS_SUCCESS = "GET_ALL_TEAM_FOLLOWERS_SUCCESS";
export const GET_ALL_TEAM_FOLLOWERS_FAILURE = "GET_ALL_TEAM_FOLLOWERS_FAILURE";