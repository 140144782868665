import {
  SET_MENU_DISPLAY,
  SET_EVENT_NOTIFICATION_DETAILS,
  SET_COUNTRY_DIALOG,
} from "../constants/other";

export const SetMenuDisplay = (values) => async (dispatch) => {
  dispatch({ type: SET_MENU_DISPLAY, payload: values });
};

export const SetEventNotificationDetails = (values) => async (dispatch) => {
  dispatch({ type: SET_EVENT_NOTIFICATION_DETAILS, payload: values });
};

export const SetCountryDialog = (values) => async (dispatch) => {
  dispatch({ type: SET_COUNTRY_DIALOG, payload: values });
};
