import React from "react";

const OrdersSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.83"
      height="22.829"
      viewBox="0 0 22.83 22.829"
    >
      <g id="orders" transform="translate(0.25 0.25)">
        <path
          id="Path_263"
          data-name="Path 263"
          d="M21.662,3.793a2.884,2.884,0,0,0-2.2-1H4.046l-.04-.327A2.837,2.837,0,0,0,1.166,0H.954A.942.942,0,0,0,0,.93a.942.942,0,0,0,.954.93h.212a.945.945,0,0,1,.947.822L3.426,13.567a4.728,4.728,0,0,0,4.736,4.109h9.96a.93.93,0,1,0,0-1.861H8.161a2.858,2.858,0,0,1-2.69-1.861H16.839a4.738,4.738,0,0,0,4.694-3.827l.744-4.051a2.736,2.736,0,0,0-.62-2.285ZM20.41,5.747,19.66,9.8a2.843,2.843,0,0,1-2.821,2.3H5.168l-.9-7.443H19.466a.964.964,0,0,1,.736.332.914.914,0,0,1,.208.764Z"
          transform="translate(0 0)"
          stroke="#fff"
          strokeWidth="0.5"
        />
        <circle
          id="Ellipse_68"
          data-name="Ellipse 68"
          cx="1.861"
          cy="1.861"
          r="1.861"
          transform="translate(4.793 18.607)"
          stroke="#fff"
          strokeWidth="0.5"
        />
        <circle
          id="Ellipse_69"
          data-name="Ellipse 69"
          cx="1.861"
          cy="1.861"
          r="1.861"
          transform="translate(14.379 18.607)"
          stroke="#fff"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};
export default OrdersSVG;
