import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FollowNewUser,
  // GetAllFollowStatusWiseUsers,
} from "../../../../../store/action/followAction";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

function Follow({ data, key }) {
  const toast = React.useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { getAllFollowStatusWiseUsersData, followNewUserData } = useSelector(
    const { getAllFollowStatusWiseUsersData } = useSelector(
    (state) => state.followResult
  );
  const handleFollow = React.useCallback(
    (e, userData) => {
      e.preventDefault();
      dispatch(
        FollowNewUser({
          iUserId: sessionStorage.getItem("id"),
          iRequesterId: userData?.iUserId,
          userAccountType: sessionStorage.getItem("userProfileType"),
          requestUserAccountType: userData?.eUserAccountType,
        })
      );
    },
    [dispatch]
  );

  // // when the dispatch all user following data then used...
  // React.useEffect(() => {
  //   if (followNewUserData && followNewUserData?.code === "200") {
  //     dispatch(
  //       GetAllFollowStatusWiseUsers({ iUserId: sessionStorage.getItem("id") })
  //     );
  //   }
  // }, [dispatch, followNewUserData]);

  // console.log(getAllFollowStatusWiseUsersData);

  return data &&
    data.iUserId &&
    data.iUserId.toString() !== sessionStorage.getItem("id") ? (
    <div
      className="action-btn"
      key={key}
      onClick={() => {
        if (!sessionStorage.getItem("id")) {
          navigate("/login");
        }
      }}
    >
      <Toast ref={toast} />
      {getAllFollowStatusWiseUsersData &&
      getAllFollowStatusWiseUsersData?.code === "200" &&
      getAllFollowStatusWiseUsersData?.data?.length > 0 &&
      getAllFollowStatusWiseUsersData?.data?.find(
        (item) => item?.iRequesterId === data.iUserId
      ) ? (
        getAllFollowStatusWiseUsersData?.data?.find(
          (item) => item?.iRequesterId === data.iUserId
        )?.eRequesterStatus === "Following" ? (
          <button className="following-btn btn">Following</button>
        ) : (
          <button className="following-btn btn">Requested</button>
        )
      ) : (
        <button
          className="follow-btn btn"
          onClick={(e) => handleFollow(e, data?.user ? data.user : data)}
        >
          Follow
        </button>
      )}
    </div>
  ) : (
    <></>
  );
}

export default React.memo(Follow);
