export const CREATE_WEBSITE_REQUEST = "CREATE_WEBSITE_REQUEST";
export const CREATE_WEBSITE_SUCCESS = "CREATE_WEBSITE_SUCCESS";
export const CREATE_WEBSITE_FAILURE = "CREATE_WEBSITE_FAILURE";

export const UPDATE_WEBSITE_REQUEST = "UPDATE_WEBSITE_REQUEST";
export const UPDATE_WEBSITE_SUCCESS = "UPDATE_WEBSITE_SUCCESS";
export const UPDATE_WEBSITE_FAILURE = "UPDATE_WEBSITE_FAILURE";

export const DELETE_WEBSITE_REQUEST = "DELETE_WEBSITE_REQUEST";
export const DELETE_WEBSITE_SUCCESS = "DELETE_WEBSITE_SUCCESS";
export const DELETE_WEBSITE_FAILURE = "DELETE_WEBSITE_FAILURE";

export const GET_ALL_WEBSITE_REQUEST = "GET_ALL_WEBSITE_REQUEST";
export const GET_ALL_WEBSITE_SUCCESS = "GET_ALL_WEBSITE_SUCCESS";
export const GET_ALL_WEBSITE_FAILURE = "GET_ALL_WEBSITE_FAILURE";

export const GET_WEBSITE_BY_ID_REQUEST = "GET_WEBSITE_BY_ID_REQUEST";
export const GET_WEBSITE_BY_ID_SUCCESS = "GET_WEBSITE_BY_ID_SUCCESS";
export const GET_WEBSITE_BY_ID_FAILURE = "GET_WEBSITE_BY_ID_FAILURE";

export const RESET = "RESET";
