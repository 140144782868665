export const ADD_POST_COMMENT_TEAM_REQUEST = "ADD_POST_COMMENT_TEAM_REQUEST";
export const ADD_POST_COMMENT_TEAM_SUCCESS = "ADD_POST_COMMENT_TEAM_SUCCESS";
export const ADD_POST_COMMENT_TEAM_FAILURE = "ADD_POST_COMMENT_TEAM_FAILURE";

export const VIEW_MORE_COMMENT_TEAM_POST_DATA_REQUEST =
  "VIEW_MORE_COMMENT_TEAM_POST_DATA_REQUEST";
export const VIEW_MORE_COMMENT_TEAM_POST_DATA_SUCCESS =
  "VIEW_MORE_COMMENT_TEAM_POST_DATA_SUCCESS";
export const VIEW_MORE_COMMENT_TEAM_POST_DATA_FAILURE =
  "VIEW_MORE_COMMENT_TEAM_POST_DATA_FAILURE";
export const VIEW_MORE_COMMENT_TEAM_POST_DATA_RESET =
  "VIEW_MORE_COMMENT_TEAM_POST_DATA_RESET";

export const VIEW_MORE_CLICKED_TEAM_POST_ID = "VIEW_MORE_CLICKED_TEAM_POST_ID";

export const CREATE_COMMENT_REPLY_TEAM_REQUEST =
  "CREATE_COMMENT_REPLY_TEAM_REQUEST";
export const CREATE_COMMENT_REPLY_TEAM_SUCCESS =
  "CREATE_COMMENT_REPLY_TEAM_SUCCESS";
export const CREATE_COMMENT_REPLY_TEAM_FAILURE =
  "CREATE_COMMENT_REPLY_TEAM_FAILURE";
