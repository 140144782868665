import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
  USER_ROLE_REQUEST,
  USER_ROLE_SUCCESS,
  USER_ROLE_FAILURE,
  SPORTS_LIST_REQUEST,
  SPORTS_LIST_SUCCESS,
  SPORTS_LIST_FAILURE,
  COUNTRY_LIST_REQUEST,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_LIST_FAILURE,
  TEAM_TYPE_REQUEST,
  TEAM_TYPE_SUCCESS,
  TEAM_TYPE_FAILURE,
  SEASONS_REQUEST,
  SEASONS_SUCCESS,
  SEASONS_FAILURE,
  STATE_LIST_REQUEST,
  STATE_LIST_SUCCESS,
  STATE_LIST_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  EMAIL_CHECK_REQUEST,
  EMAIL_CHECK_SUCCESS,
  EMAIL_CHECK_FAILURE,
  STORE_NAME_RESET,
  FORGOT_PASSWORD_SUCCESS,
  PROFILE_BANNER_UPDATE_REQUEST,
  PROFILE_BANNER_UPDATE_SUCCESS,
  PROFILE_BANNER_UPDATE_FAILURE,
  PROFILE_BY_AUTHCODE_REQUEST,
  PROFILE_BY_AUTHCODE_SUCCESS,
  PROFILE_BY_AUTHCODE_FAILURE,
  PROFILE_IROLEID,
  VERIFY_PERSONAL_USER_SLUG_REQUEST,
  VERIFY_PERSONAL_USER_SLUG_SUCCESS,
  VERIFY_PERSONAL_USER_SLUG_FAILURE,
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILURE,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAILURE,
  SEND_2STEP_CODE_REQUEST,
  SEND_2STEP_CODE_SUCCESS,
  SEND_2STEP_CODE_FAILURE,
  MOBILE_CHECK_REQUEST,
  MOBILE_CHECK_SUCCESS,
  MOBILE_CHECK_FAILURE,
  VERIFY_EMAIL_CODE_REQUEST,
  VERIFY_EMAIL_CODE_SUCCESS,
  VERIFY_EMAIL_CODE_FAILURE,
  BLOCK_USER_REQUEST,
  BLOCK_USER_SUCCESS,
  BLOCK_USER_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  AUTH_PROFILE_SUCCESS,
  AUTH_PROFILE_REQUEST,
  AUTH_PROFILE_FAILURE,
  FOLLOWER_AUTH_PROFILE_REQUEST,
  FOLLOWER_AUTH_PROFILE_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  USER_PASSWORD_CHECK_REQUEST,
  USER_PASSWORD_CHECK_SUCCESS,
  USER_PASSWORD_CHECK_FAILURE,
} from "./../constants/user";
import axios from "axios";

export const Login = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/login`,
      values
    );
    dispatch({ type: LOGIN_SUCCESS, payload: resData.data });
    sessionStorage.setItem("id", resData.data.data.iUserId);
    sessionStorage.setItem(
      "userProfileType",
      resData.data.data.eUserAccountType
    );
    sessionStorage.setItem("userImage", resData.data.data.vImage);
    sessionStorage.setItem("userSlug", resData.data.data.vPersonalSlug);
    sessionStorage.setItem(
      "userName",
      resData.data.data.vFirstName + " " + resData.data.data.vLastName
    );
  } catch (err) {
    dispatch({ type: LOGIN_FAILURE });
  }
};

export const Signup = (values) => async (dispatch) => {
  try {
    dispatch({ type: SIGNUP_REQUEST });
    if (values.vMobileNumber && values.channel === "sms") {
      const resData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/Register`,
        values
      );
      dispatch({ type: SIGNUP_SUCCESS, payload: resData.data });
    } else {
      dispatch({ type: EMAIL_CHECK_REQUEST });
      const emailcheck = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/checkEmailExists`,
        {
          vEmail: values.vEmail,
        }
      );
      dispatch({ type: EMAIL_CHECK_SUCCESS, payload: emailcheck.data });
      if (emailcheck.data.status === true) {
        const resData = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/Register`,
          values
        );
        dispatch({ type: SIGNUP_SUCCESS, payload: resData.data });
      }
    }
  } catch (err) {
    dispatch({ type: SIGNUP_FAILURE });
    dispatch({ type: EMAIL_CHECK_FAILURE });
  }
};

export const UserProfile = (values) => async (dispatch) => {
  try {
    dispatch({ type: PROFILE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/profile/getUserByIdAndAuthcode`,
      values
    );
    dispatch({ type: PROFILE_SUCCESS, payload: resData.data });
    if (values.iUserId === sessionStorage.getItem("id")) {
      sessionStorage.setItem("roleId", resData.data.data.iRoleId);
      dispatch({ type: PROFILE_IROLEID, payload: resData.data.data.iRoleId });
    }
  } catch (err) {
    dispatch({ type: PROFILE_FAILURE });
  }
};

export const getUserForAuthCheckProfile = (values) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_PROFILE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/profile/getUserByIdAndAuthcode`,
      values
    );
    dispatch({ type: AUTH_PROFILE_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: AUTH_PROFILE_FAILURE });
  }
};
export const getUserFollowerData = (values) => async (dispatch) => {
  try {
    dispatch({ type: FOLLOWER_AUTH_PROFILE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/profile/getUserByIdAndAuthcode`,
      values
    );
    dispatch({ type: FOLLOWER_AUTH_PROFILE_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: AUTH_PROFILE_FAILURE });
  }
};
export const UserProfileUpdate = (values) => async (dispatch) => {
  try {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    dispatch({ type: PROFILE_UPDATE_REQUEST });
    if (values.get("vMobileNumber")) {
      dispatch({ type: MOBILE_CHECK_REQUEST });
      const resCheckData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/checkMobileNumberExists`,
        values
      );

      dispatch({
        type: MOBILE_CHECK_SUCCESS,
        payload: resCheckData.data,
      });
      if (resCheckData.data.code === "200") {
        const resData = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/profile/profileUpdate`,
          values,
          config
        );

        if (resData.data.code === "200") {
          const valuesNew = {
            iUserId: sessionStorage.getItem("id"),
          };
          dispatch(UserProfile(valuesNew));
        }

        dispatch({ type: PROFILE_UPDATE_SUCCESS, payload: resData.data });
      }
    } else {
      const resData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/profile/profileUpdate`,
        values,
        config
      );

      if (resData.data.code === "200") {
        const valuesNew = {
          iUserId: sessionStorage.getItem("id"),
        };
        dispatch(UserProfile(valuesNew));
      }

      dispatch({ type: PROFILE_UPDATE_SUCCESS, payload: resData.data });
    }
  } catch (err) {
    dispatch({ type: PROFILE_UPDATE_FAILURE });
  }
};

export const UserProfileBannerUpdate = (values) => async (dispatch) => {
  try {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    dispatch({ type: PROFILE_BANNER_UPDATE_REQUEST });

    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/profile/profileBannerUpdate`,
      values,
      config
    );

    if (resData.data.code === "200") {
      const valuesNew = {
        iUserId: sessionStorage.getItem("id"),
      };
      dispatch(UserProfile(valuesNew));
    }

    dispatch({ type: PROFILE_BANNER_UPDATE_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: PROFILE_BANNER_UPDATE_FAILURE });
  }
};

export const GetAllRoles = () => async (dispatch) => {
  try {
    dispatch({ type: USER_ROLE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/role/getAllRole`
    );

    dispatch({ type: USER_ROLE_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: USER_ROLE_FAILURE });
  }
};

export const getAllSports = () => async (dispatch) => {
  try {
    dispatch({ type: SPORTS_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/sport/getAllSportData`,
      { iUserId: sessionStorage.getItem("id") }
    );

    dispatch({ type: SPORTS_LIST_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: SPORTS_LIST_FAILURE });
  }
};

export const getCountries = () => async (dispatch) => {
  try {
    dispatch({ type: COUNTRY_LIST_REQUEST });
    const resData = await axios.post(
      // `${process.env.REACT_APP_API_URL}/api/country`
      `${process.env.REACT_APP_API_URL}/api/country/getAllCountryData`
    );

    dispatch({ type: COUNTRY_LIST_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: COUNTRY_LIST_FAILURE });
  }
};

export const getTeamTypes = () => async (dispatch) => {
  try {
    dispatch({ type: TEAM_TYPE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamtype/getTeamTypeData`
    );

    dispatch({ type: TEAM_TYPE_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: TEAM_TYPE_FAILURE });
  }
};

export const getSeasons = () => async (dispatch) => {
  try {
    dispatch({ type: SEASONS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/season/getAllSeasonData`
    );

    dispatch({ type: SEASONS_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: SEASONS_FAILURE });
  }
};

export const getStates = (values) => async (dispatch) => {
  try {
    const data = {
      iCountryId: values,
    };
    dispatch({ type: STATE_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/country/stateByCountryId`,
      data
    );
    dispatch({ type: STATE_LIST_SUCCESS, payload: resData.data.data });
  } catch (err) {
    dispatch({ type: STATE_LIST_FAILURE });
  }
};

export const ForgotPassword = (values) => async (dispatch) => {
  try {
    if (values.vMobileNumber && values.channel === "sms") {
      dispatch({ type: FORGOT_PASSWORD_REQUEST });
      const resData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/forget-password`,
        values
      );
      dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: resData.data });
    } else {
      dispatch({ type: EMAIL_CHECK_REQUEST });
      const emailcheck = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/checkEmailExists`,
        values
      );
      dispatch({ type: EMAIL_CHECK_SUCCESS, payload: emailcheck.data });
      if (emailcheck.data.status === false) {
        dispatch({ type: FORGOT_PASSWORD_REQUEST });
        const resData = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/forget-password`,
          values
        );
        dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: resData.data });
      }
    }
  } catch (err) {
    dispatch({ type: FORGOT_PASSWORD_FAILURE });
    dispatch({ type: EMAIL_CHECK_FAILURE });
  }
};

export const ResetPassword = (values) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/reset-password`,
      values
    );
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: RESET_PASSWORD_FAILURE });
  }
};

// export const VerifyEmail = (values) => async (dispatch) => {
//   try {
//     let data = {};
//     if (values.vMobileNumber !== "" && values.vMobileNumber !== undefined) {
//       data = values;
//     } else {
//       data = {
//         // ...values,
//         verifycode: values.vOTP,
//       };
//     }
//     dispatch({ type: VERIFY_EMAIL_REQUEST });
//     const resData = await axios.post(
//       `${process.env.REACT_APP_API_URL}/api/auth/verify-email`,
//       data
//     );
//     dispatch({ type: VERIFY_EMAIL_SUCCESS, payload: resData.data });
//   } catch (err) {
//     dispatch({ type: VERIFY_EMAIL_FAILURE });
//   }
// };

export const VerifyEmail = (values) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_EMAIL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/verify-email`,
      values
    );
    dispatch({ type: VERIFY_EMAIL_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: VERIFY_EMAIL_FAILURE });
  }
};

export const VerifyEmailCode = (values) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_EMAIL_CODE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/checkOtpAndCode`,
      values
    );
    dispatch({ type: VERIFY_EMAIL_CODE_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: VERIFY_EMAIL_CODE_FAILURE });
  }
};

export const ResetState = () => async (dispatch) => {
  dispatch({ type: STORE_NAME_RESET });
};

export const UserResetState = () => async (dispatch) => {
  dispatch({ type: STORE_NAME_RESET });
};

export const UserProfileByAuthcode = (values) => async (dispatch) => {
  try {
    dispatch({ type: PROFILE_BY_AUTHCODE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/profile/getTeamPlayerByAuthcode`,
      values
    );

    dispatch({ type: PROFILE_BY_AUTHCODE_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: PROFILE_BY_AUTHCODE_FAILURE });
  }
};

// Verify user slug exists or not

export const VerifyPersonalUserSlug = (values) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_PERSONAL_USER_SLUG_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/profile/checkUserSlugsExists`,
      values
    );

    dispatch({
      type: VERIFY_PERSONAL_USER_SLUG_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: VERIFY_PERSONAL_USER_SLUG_FAILURE });
  }
};

export const VerifyBusinessUserSlug = (values) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_PERSONAL_USER_SLUG_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/profile/checkUserSlugsExists`,
      values
    );

    dispatch({
      type: VERIFY_PERSONAL_USER_SLUG_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: VERIFY_PERSONAL_USER_SLUG_FAILURE });
  }
};

export const SendOtp = (values) => async (dispatch) => {
  try {
    dispatch({ type: SEND_OTP_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/resendOtp`,
      values
    );

    dispatch({
      type: SEND_OTP_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: SEND_OTP_FAILURE });
  }
};

export const VerifyOTP = (values) => async (dispatch) => {
  try {
    dispatch({ type: OTP_VERIFY_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/profile/verificationOpt`,
      values
    );

    dispatch({
      type: OTP_VERIFY_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: OTP_VERIFY_FAILURE });
  }
};

export const Send2StepCode = (values) => async (dispatch) => {
  try {
    dispatch({ type: SEND_2STEP_CODE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/twoFactorAuthentication`,
      values
    );

    dispatch({
      type: SEND_2STEP_CODE_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: SEND_2STEP_CODE_FAILURE });
  }
};

export const CheckMobileNumerExists = (values) => async (dispatch) => {
  try {
    dispatch({ type: MOBILE_CHECK_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/checkMobileNumberExists`,
      values
    );

    dispatch({
      type: MOBILE_CHECK_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: MOBILE_CHECK_FAILURE });
  }
};

export const BlockUserProfile = (values) => async (dispatch) => {
  try {
    dispatch({ type: BLOCK_USER_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Blockeduser/addBlockedUser`,
      values
    );

    dispatch({
      type: BLOCK_USER_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: BLOCK_USER_FAILURE });
  }
};

export const DeleteAccount = (values) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ACCOUNT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/deletingUserAcount`,
      values
    );

    dispatch({
      type: DELETE_ACCOUNT_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: DELETE_ACCOUNT_FAILURE });
  }
};

export const LogoutUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOGOUT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/logout`,
      {
        deviceType: "Web",
        iUserId: sessionStorage.getItem("id"),
      }
    );

    dispatch({
      type: LOGOUT_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: LOGOUT_FAILURE });
  }
};

export const UserPasswordCheck = (value) => async (dispatch) => {
  try {
    dispatch({ type: USER_PASSWORD_CHECK_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/change-password`,
      value
    );

    dispatch({
      type: USER_PASSWORD_CHECK_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: USER_PASSWORD_CHECK_FAILURE });
  }
};
