import axios from "axios";
import {
  ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_FAILURE,
  ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_REQUEST,
  ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_SUCCESS,
  CREATE_TEAM_POST_FAILURE,
  CREATE_TEAM_POST_REQUEST,
  CREATE_TEAM_POST_SUCCESS,
  GET_ALL_TEAM_MESSAGE_BOARD_POST_FAILURE,
  GET_ALL_TEAM_MESSAGE_BOARD_POST_REQUEST,
  GET_ALL_TEAM_MESSAGE_BOARD_POST_SUCCESS,
  REPORT_POST_TEAM_FAILURE,
  REPORT_POST_TEAM_REQUEST,
  REPORT_POST_TEAM_SUCCESS,
  SINGLE_POST_TEAM_ALL_LIKES_FAILURE,
  SINGLE_POST_TEAM_ALL_LIKES_REQUEST,
  SINGLE_POST_TEAM_ALL_LIKES_SUCCESS,
  TEAM_AND_SPORT_OF_USER_FAILURE,
  TEAM_AND_SPORT_OF_USER_REQUEST,
  TEAM_AND_SPORT_OF_USER_SUCCESS,
  TEMPORARY_STORE_TEAM_POST_LIKE_DATA_FAILURE,
  TEMPORARY_STORE_TEAM_POST_LIKE_DATA_REQUEST,
  TEMPORARY_STORE_TEAM_POST_LIKE_DATA_SUCCESS,
} from "../constants/teamMessageBoard";

export const CreateTeamMessageBoardPost = (values) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TEAM_POST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamBoard/createTeamBoardPost`,
      values
    );
    // dispatch(GetAllPost());
    // dispatch(GetAllPostByUser());

    dispatch({ type: CREATE_TEAM_POST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: CREATE_TEAM_POST_FAILURE });
  }
};

export const GetAllTeamMessageBoardPost = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_TEAM_MESSAGE_BOARD_POST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamBoard/getTeamMessageBoardPostsData`,
      values
    );
    dispatch({
      type: GET_ALL_TEAM_MESSAGE_BOARD_POST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_TEAM_MESSAGE_BOARD_POST_FAILURE });
  }
};

export const PostLoveItTeamMessageBoard = (values) => async (dispatch) => {
  try {
    dispatch({ type: ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamBoard/createTeamBoardPostlike`,
      values
    );
    dispatch({
      type: ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_FAILURE });
  }
};

export const TemporaryStoreTeamPostLikeAction = (values) => (dispatch) => {
  dispatch({ type: TEMPORARY_STORE_TEAM_POST_LIKE_DATA_REQUEST });
  try {
    dispatch({
      type: TEMPORARY_STORE_TEAM_POST_LIKE_DATA_SUCCESS,
      payload: values,
    });
  } catch (error) {
    dispatch({ type: TEMPORARY_STORE_TEAM_POST_LIKE_DATA_FAILURE });
  }
};

// when the user wants to see all users who liked the particular post ...
export const SinglePostAllLikesTeam = (values) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_POST_TEAM_ALL_LIKES_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamBoard/getPostLikers`,
      values
    );
    dispatch({
      type: SINGLE_POST_TEAM_ALL_LIKES_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: SINGLE_POST_TEAM_ALL_LIKES_FAILURE });
  }
};

export const ReportPostTeam = (values) => async (dispatch) => {
  dispatch({ type: REPORT_POST_TEAM_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamBoard/createTeamBoardPostCommentReportTrans`,
      values
    );
    dispatch({ type: REPORT_POST_TEAM_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: REPORT_POST_TEAM_FAILURE });
  }
};

export const GetAllTeamsAndSportsOfUser = (values) => async (dispatch) => {
  dispatch({ type: TEAM_AND_SPORT_OF_USER_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamBoard/filterAssociatedTeamsAndSports`,
      values
    );
    dispatch({ type: TEAM_AND_SPORT_OF_USER_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: TEAM_AND_SPORT_OF_USER_FAILURE });
  }
};
