export const CREATE_EDUCATION_REQUEST = "CREATE_EDUCATION_REQUEST";
export const CREATE_EDUCATION_SUCCESS = "CREATE_EDUCATION_SUCCESS";
export const CREATE_EDUCATION_FAILURE = "CREATE_EDUCATION_FAILURE";
export const CREATE_EDUCATION_RESET = "CREATE_EDUCATION_RESET";

export const UPDATE_EDUCATION_REQUEST = "UPDATE_EDUCATION_REQUEST";
export const UPDATE_EDUCATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS";
export const UPDATE_EDUCATION_FAILURE = "UPDATE_EDUCATION_FAILURE";
export const UPDATE_EDUCATION_RESET_STATE = "UPDATE_EDUCATION_RESET_STATE";

export const DELETE_EDUCATION_REQUEST = "DELETE_EDUCATION_REQUEST";
export const DELETE_EDUCATION_SUCCESS = "DELETE_EDUCATION_SUCCESS";
export const DELETE_EDUCATION_FAILURE = "DELETE_EDUCATION_FAILURE";

export const GET_ALL_EDUCATION_REQUEST = "GET_ALL_EDUCATION_REQUEST";
export const GET_ALL_EDUCATION_SUCCESS = "GET_ALL_EDUCATION_SUCCESS";
export const GET_ALL_EDUCATION_FAILURE = "GET_ALL_EDUCATION_FAILURE";

export const GET_EDUCATION_BY_ID_REQUEST = "GET_EDUCATION_BY_ID_REQUEST";
export const GET_EDUCATION_BY_ID_SUCCESS = "GET_EDUCATION_BY_ID_SUCCESS";
export const GET_EDUCATION_BY_ID_FAILURE = "GET_EDUCATION_BY_ID_FAILURE";

export const GET_EDUCATION_BY_ID_RESET_STATE = "GET_EDUCATION_BY_ID_RESET_STATE"
