import {
  GET_ALL_NOTIFICATIONS_REQUEST,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATION_COUNT_REQUEST,
  GET_NOTIFICATION_COUNT_SUCCESS,
  GET_NOTIFICATION_COUNT_FAILURE,
  SET_NOTIFICATION_SEEN_REQUEST,
  SET_NOTIFICATION_SEEN_SUCCESS,
  SET_NOTIFICATION_SEEN_FAILURE,
  RESET,
  NOTIFICATION_INVITATION_REQUEST,
  NOTIFICATION_INVITATION_SUCCESS,
  NOTIFICATION_INVITATION_FAILURE,
  GET_MESSAGE_BOARD_NOTIFICATION_COUNT_REQUEST,
  GET_MESSAGE_BOARD_NOTIFICATION_COUNT_SUCCESS,
  GET_MESSAGE_BOARD_NOTIFICATION_COUNT_FAILURE,
  GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_FAILURE,
  GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_SUCCESS,
  GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_REQUEST,
  GET_GROUP_COUNT_SUCCESS,
  GET_GROUP_COUNT_REQUEST,
  GET_GROUP_COUNT_FAILURE,
} from "../constants/notifications";

const initialState = {};

export function notificationsReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_NOTIFICATIONS_REQUEST:
    case GET_NOTIFICATION_COUNT_REQUEST:
    case SET_NOTIFICATION_SEEN_REQUEST:
    case GET_MESSAGE_BOARD_NOTIFICATION_COUNT_REQUEST:
    case NOTIFICATION_INVITATION_REQUEST:
    case GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_REQUEST:
    case GET_GROUP_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllNotificationsData: action.payload,
      };
    case NOTIFICATION_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        acceptDeclineRequestData: action.payload,
      };
    case GET_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        getNotificationCountData: action.payload,
      };
    case SET_NOTIFICATION_SEEN_SUCCESS:
      return {
        ...state,
        loading: false,
        setNotificationSeenStatusData: action.payload,
      };
    case GET_MESSAGE_BOARD_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        getMessageBoardNotificationCountData: action.payload,
      };
    case GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        getTeamMsgBoardNotificationCountData: action.payload,
      };
    case GET_GROUP_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        getGroupNotificationCountData: action.payload,
      };
    case SET_NOTIFICATION_SEEN_FAILURE:
    case GET_ALL_NOTIFICATIONS_FAILURE:
    case GET_NOTIFICATION_COUNT_FAILURE:
    case NOTIFICATION_INVITATION_FAILURE:
    case GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_FAILURE:
    case GET_MESSAGE_BOARD_NOTIFICATION_COUNT_FAILURE:
    case GET_GROUP_COUNT_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
