import {
  CREATE_LANGUAGE_REQUEST,
  CREATE_LANGUAGE_SUCCESS,
  CREATE_LANGUAGE_FAILURE,
  UPDATE_LANGUAGE_REQUEST,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE_FAILURE,
  DELETE_LANGUAGE_REQUEST,
  DELETE_LANGUAGE_SUCCESS,
  DELETE_LANGUAGE_FAILURE,
  GET_ALL_LANGUAGE_REQUEST,
  GET_ALL_LANGUAGE_SUCCESS,
  GET_ALL_LANGUAGE_FAILURE,
  GET_LANGUAGE_BY_ID_REQUEST,
  GET_LANGUAGE_BY_ID_SUCCESS,
  GET_LANGUAGE_BY_ID_FAILURE,
  ALL_LANGUAGE_LIST_REQUEST,
  ALL_LANGUAGE_LIST_SUCCESS,
  ALL_LANGUAGE_LIST_FAILURE,
  RESET_LANG,
} from "../constants/language";

const initialState = {};

export function languageReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_LANGUAGE_REQUEST:
    case UPDATE_LANGUAGE_REQUEST:
    case DELETE_LANGUAGE_REQUEST:
    case GET_ALL_LANGUAGE_REQUEST:
    case GET_LANGUAGE_BY_ID_REQUEST:
    case ALL_LANGUAGE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        createLanguageData: action.payload,
      };
    case UPDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateLanguageData: action.payload,
      };
    case DELETE_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteLanguageData: action.payload,
      };
    case GET_ALL_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllLanguageData: action.payload,
      };
    case GET_LANGUAGE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getLanguageByIdData: action.payload,
      };
    case ALL_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allLaguageList: action.payload,
      };
    case CREATE_LANGUAGE_FAILURE:
    case UPDATE_LANGUAGE_FAILURE:
    case DELETE_LANGUAGE_FAILURE:
    case GET_ALL_LANGUAGE_FAILURE:
    case GET_LANGUAGE_BY_ID_FAILURE:
    case ALL_LANGUAGE_LIST_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_LANG:
      return initialState;
    default:
      return state;
  }
}
