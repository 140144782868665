export const CREATE_TEAM_POST_REQUEST = "CREATE_TEAM_POST_REQUEST";
export const CREATE_TEAM_POST_SUCCESS = "CREATE_TEAM_POST_SUCCESS";
export const CREATE_TEAM_POST_FAILURE = "CREATE_TEAM_POST_FAILURE";

export const GET_ALL_TEAM_MESSAGE_BOARD_POST_REQUEST =
  "GET_ALL_TEAM_MESSAGE_BOARD_POST_REQUEST";
export const GET_ALL_TEAM_MESSAGE_BOARD_POST_SUCCESS =
  "GET_ALL_TEAM_MESSAGE_BOARD_POST_SUCCESS";
export const GET_ALL_TEAM_MESSAGE_BOARD_POST_FAILURE =
  "GET_ALL_TEAM_MESSAGE_BOARD_POST_FAILURE";

export const ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_REQUEST =
  "ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_REQUEST";
export const ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_SUCCESS =
  "ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_SUCCESS";
export const ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_FAILURE =
  "ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_FAILURE";

export const ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_CLEAR_DATA =
  "ADD_LOVE_IT_TEAM_MESSAGE_BOARD_POST_CLEAR_DATA";

export const TEMPORARY_STORE_TEAM_POST_LIKE_DATA_REQUEST =
  "TEMPORARY_STORE_TEAM_POST_LIKE_DATA_REQUEST";
export const TEMPORARY_STORE_TEAM_POST_LIKE_DATA_SUCCESS =
  "TEMPORARY_STORE_TEAM_POST_LIKE_DATA_SUCCESS";
export const TEMPORARY_STORE_TEAM_POST_LIKE_DATA_FAILURE =
  "TEMPORARY_STORE_TEAM_POST_LIKE_DATA_FAILURE";

export const TEMPORARY_STORE_TEAM_POST_LIKE_CLEAR_DATA =
  "TEMPORARY_STORE_TEAM_POST_LIKE_CLEAR_DATA";

export const SINGLE_POST_TEAM_ALL_LIKES_REQUEST =
  "SINGLE_POST_TEAM_ALL_LIKES_REQUEST";
export const SINGLE_POST_TEAM_ALL_LIKES_SUCCESS =
  "SINGLE_POST_TEAM_ALL_LIKES_SUCCESS";
export const SINGLE_POST_TEAM_ALL_LIKES_FAILURE =
  "SINGLE_POST_TEAM_ALL_LIKES_FAILURE";

export const REPORT_POST_TEAM_REQUEST = "REPORT_POST_TEAM_REQUEST";
export const REPORT_POST_TEAM_SUCCESS = "REPORT_POST_TEAM_SUCCESS";
export const REPORT_POST_TEAM_FAILURE = "REPORT_POST_TEAM_FAILURE";

export const REPORT_POST_TEAM_VALUES = "REPORT_POST_TEAM_VALUES";
export const REPORT_POST_TEAM_RESET = "REPORT_POST_TEAM_RESET";

export const VIEW_SINGLE_POST_BY_OBJECT = "VIEW_SINGLE_POST_BY_OBJECT";
export const VIEW_SINGLE_POST_BY_OBJECT_RESET =
  "VIEW_SINGLE_POST_BY_OBJECT_RESET";

export const TEAM_AND_SPORT_OF_USER_REQUEST = "TEAM_AND_SPORT_OF_USER_REQUEST";
export const TEAM_AND_SPORT_OF_USER_SUCCESS = "TEAM_AND_SPORT_OF_USER_SUCCESS";
export const TEAM_AND_SPORT_OF_USER_FAILURE = "TEAM_AND_SPORT_OF_USER_FAILURE";

export const SELECTED_TEAM_FOR_CREATE_POST = "SELECTED_TEAM_FOR_CREATE_POST";

export const POST_DATA_ARRAY = "POST_DATA_ARRAY";
