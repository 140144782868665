// import React from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { GetAllPermissionByUser } from "../store/action/permissionAction";

export const CheckPermission = (iModuleMasterlId) => {
  // console.log("iModuleMasterlId",iModuleMasterlId)
  // const dispatch = useDispatch();
  // const ref = React.useRef();
  // const { getAllPermissionByUserData } = useSelector(
  //   (state) => state.permission
  // );
  // const { userProfileData } = useSelector((state) => state.user);

  // React.useEffect(() => {
  //   // if (!getAllPermissionByUserData) {
  //     const values = {
  //       iUserId: sessionStorage.getItem("id"),
  //       iRoleId: sessionStorage.getItem("roleId"),
  //       // iModuleMasterlId: 1,
  //     };
  //     dispatch(GetAllPermissionByUser(values));
  //   // }
  // }, [dispatch]);

  // React.useEffect(() => {
  //   if (!getAllPermissionByUserData) {
  //     const values = {
  //       iUserId: sessionStorage.getItem("id"),
  //       iRoleId: sessionStorage.getItem("roleId"),
  //       // iModuleMasterlId: 1,
  //     };
  //     dispatch(GetAllPermissionByUser(values));
  //   }
  // }, [dispatch,getAllPermissionByUserData]);

  // return !getAllPermissionByUserData ? "wait" :getAllPermissionByUserData &&
  //   getAllPermissionByUserData.code === "200" &&
  //   sessionStorage.getItem("id") &&
  //   sessionStorage.getItem("roleId") &&
  //   getAllPermissionByUserData.data.find(
  //     (item) =>
  //       item.iUserId &&
  //       item.iRoleId &&
  //       item.iUserId.toString() === sessionStorage.getItem("id") &&
  //       item.iRoleId.toString() === sessionStorage.getItem("roleId") &&
  //       item.iModuleMasterlId === iModuleMasterlId
  //   )
  //   ? true
  //   : false;
  return true
};
