import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../components/CustomComponents/PageContainer/PageContainer";
import { GetPrivacyPolicy } from "../store/action/policyAction";
import parse from "html-react-parser";

function BetaTesterAgreement() {
  const dispatch = useDispatch();
  const { privacyPolicyData } = useSelector((state) => state.policy);

  React.useEffect(() => {
    dispatch(
      GetPrivacyPolicy({
        vTitle: "NON-DISCLOSURE AGREEMENT",
      })
    );
  }, [dispatch]);

  return (
    <PageContainer>
      <div className="middle-space cart-section bg-2 pb-space">
        {privacyPolicyData && privacyPolicyData.code === "200" ? (
          <>{parse(privacyPolicyData.data.tDescription)}</>
        ) : (
          <></>
        )}
      </div>
    </PageContainer>
  );
}

export default BetaTesterAgreement;
