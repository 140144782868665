export const CREATE_MODULE_PERMISSION_REQUEST =
  "CREATE_MODULE_PERMISSION_REQUEST";
export const CREATE_MODULE_PERMISSION_SUCCESS =
  "CREATE_MODULE_PERMISSION_SUCCESS";
export const CREATE_MODULE_PERMISSION_FAILURE =
  "CREATE_MODULE_PERMISSION_FAILURE";

export const GET_ALL_PERMISSION_ROLE_REQUEST =
  "GET_ALL_PERMISSION_ROLE_REQUEST";
export const GET_ALL_PERMISSION_ROLE_SUCCESS =
  "GET_ALL_PERMISSION_ROLE_SUCCESS";
export const GET_ALL_PERMISSION_ROLE_FAILURE =
  "GET_ALL_PERMISSION_ROLE_FAILURE";

export const STORE_NAME_RESET = "STORE_NAME_RESET";

export const GET_PERMISSION_BY_IUSERID_REQUEST =
  "GET_PERMISSION_BY_IUSERID_REQUEST";
export const GET_PERMISSION_BY_IUSERID_SUCCESS =
  "GET_PERMISSION_BY_IUSERID_SUCCESS";
export const GET_PERMISSION_BY_IUSERID_FAILURE =
  "GET_PERMISSION_BY_IUSERID_FAILURE";

export const GET_USER_OTHER_ROLE_PERMISSION_REQUEST = "GET_USER_OTHER_ROLE_PERMISSION_REQUEST";
export const GET_USER_OTHER_ROLE_PERMISSION_SUCCESS = "GET_USER_OTHER_ROLE_PERMISSION_SUCCESS";
export const GET_USER_OTHER_ROLE_PERMISSION_FAILURE = "GET_USER_OTHER_ROLE_PERMISSION_FAILURE";
