import React from "react";

function DownloadLinksModal() {
  return (
    <div
      className="modal fade dowload-app-modal"
      id="downloadapp"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Download App
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="image-box">
              <img
                src="assets/img/Google Play.png"
                alt="google-play-store"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=yoplaybook.com"
                  )
                }
              />
            </div>
            <div className="image-box">
              <img
                src="assets/img/App Store.png"
                onClick={() =>
                  window.open("https://apps.apple.com/us/app/yoplaybook-team-management/id6447600121")
                }
                alt="app-store"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadLinksModal;
