// import React from "react";
import { useSelector } from "react-redux";

export function useGetUnder13User() {
  const { userProfileData } = useSelector((state) => state.user);

  return (
    userProfileData &&
    userProfileData.code === "200" &&
    userProfileData.data &&
    Object.keys(userProfileData.data).length > 0 &&
    userProfileData.data.eUnder13 === "Yes"
  );
}
