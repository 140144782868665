import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "primereact/image";
import { UserProfile } from "../../../store/action/userAction";
import { Toast } from "primereact/toast";
import { Mention } from "primereact/mention";
// import { GetAllPost } from "../../../store/action/postAction";
import { GetTeamPlayerList } from "../../../store/action/teamPlayerAction";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { RESET_IMAGE_URL_DATA } from "../../../store/constants/post";
// import DropDown from "../DropDown/DropDown";
import {
  GetAllPostByUser,
  GetImageFromUrl,
} from "../../../store/action/postAction";
// import { useLocation } from "react-router-dom";
import axios from "axios";

import { GetAllGroupMessageBoardPost } from "../../../store/action/groupsAction";
import {
  CREATE_GROUP_POST_SUCCESS,
  SELECTED_GROUP_FOR_CREATE_POST,
  UPLOADED_VIDEO_BLOB,
} from "../../../store/constants/group";

function CreatePostGroup() {
  const dispatch = useDispatch();

  const { userProfileData } = useSelector((state) => state.user);
  const [singleImgColor, setSingleImgColor] = React.useState("");
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const { postId, getAllPostByUserData, getImgUrlData } = useSelector(
    (state) => state.post
  );
  const [checkError, setCheckError] = React.useState();
  const [emojiValue, setEmojiValue] = React.useState("");
  const [images, setImages] = React.useState([]);
  const [isShowUrlTextBox, setIsShowUrlTextBox] = React.useState(false);
  const [youtubeUrlMessage, setYutubeUrlMessage] = React.useState("");
  const [youtubeThumbImg, setYoutubeThumbImg] = React.useState(null);
  const [urlValue, setUrlValue] = React.useState(null);
  const [descVal, setDescVal] = React.useState("");
  const [youtubeEmbedValue, setYoutubeEmbedValue] = React.useState({
    postEmbedShortUrl: "",
    vEmbedLink: "",
  });

  let autoComplete = React.useRef("");
  // let videoCount = React.useRef(0);

  const [files, setFiles] = React.useState([]);
  const [mapImg, setMapImg] = React.useState(null);
  const [imageClass, setImageClass] = React.useState("");
  const [postData, setPostData] = React.useState([]);
  const [videos, setVideos] = React.useState([]);

  const [checkMultipleVideo, setCheckMultipleVideo] = React.useState({
    type: false,
    message: "",
  });
  const [customers, setCustomers] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const autoCompleteRef = React.useRef(null);
  const [multipleSuggestions, setMultipleSuggestions] = React.useState([]);
  const [showEmoji, setShowEmoji] = React.useState(false);
  const toast = React.useRef(null);
  const { teamPlayerListData } = useSelector((state) => state.teamPlayer);
  // const location = useLocation();
  const { selectedGroupForCreatePost } = useSelector((state) => state.groups);
  const emojiSectionRef = useRef(null);

  const handlePlaceSelect = useCallback(async (updateQuery) => {
    const addressObject = autoComplete.current.getPlace();
    if (
      addressObject &&
      addressObject.geometry &&
      addressObject.geometry.location
    ) {
      const lat = addressObject.geometry.location.lat();
      const lng = addressObject.geometry.location.lng();
      await getPlaceDetails(lat, lng);
      updateQuery(addressObject);
      window.$("#locationIdGroup").modal("hide");
      window.$("#createPostModalGroup").modal("show");
    } else {
      console.error("Invalid address object:", addressObject);
    }
  }, []);

  async function getPlaceDetails(lat, lng) {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=15&size=680x248&maptype=roadmap&markers=color:blue%7C${lat},${lng}&key=AIzaSyBMNGjVtdxlIoRqyfPL54wke0Hrk6xPtsA`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      // Uncomment below code for image of the selected address
      // const blob = await response.blob();
      // const url = URL.createObjectURL(blob);
      // setMapImg(url);
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  }

  const loadGoogleMapsScript = (callback) => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBMNGjVtdxlIoRqyfPL54wke0Hrk6xPtsA&libraries=places`;
    script.async = true;
    script.onload = callback;
    document.head.appendChild(script);
  };

  const initializeAutocomplete = (
    autoCompleteRef,
    handlePlaceSelect,
    updateQuery
  ) => {
    autoComplete.current = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );
    autoComplete.current.setFields([
      "address_components",
      "formatted_address",
      "place_id",
      "geometry",
      "name",
    ]);

    autoComplete.current.addListener("place_changed", async () => {
      await handlePlaceSelect(updateQuery);
    });

    autoCompleteRef.current.addEventListener("keydown", async (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        const place = autoComplete.current.getPlace();
        if (place && place.geometry) {
          await handlePlaceSelect(updateQuery);
        }
      }
    });
  };

  React.useEffect(() => {
    if (window.google && window.google.maps) {
      initializeAutocomplete(autoCompleteRef, handlePlaceSelect, setQuery);
    } else {
      loadGoogleMapsScript(() => {
        initializeAutocomplete(autoCompleteRef, handlePlaceSelect, setQuery);
      });
    }
  }, [setQuery, autoCompleteRef, handlePlaceSelect]);

  React.useEffect(() => {
    if (!getAllPostByUserData) {
      dispatch(GetAllPostByUser());
    }
  }, [dispatch, getAllPostByUserData]);

  React.useEffect(() => {
    if (images.length > 0 && mapImg !== null) {
      setMapImg(null);
    }
  }, [setMapImg, images, mapImg]);

  React.useEffect(() => {
    if (
      getAllPostByUserData &&
      getAllPostByUserData?.data !== null &&
      postId !== undefined
    ) {
      setPostData(
        getAllPostByUserData?.data?.find((val) => +val.iPostId === +postId)
      );
    }
  }, [getAllPostByUserData, postId, setPostData]);

  React.useEffect(() => {
    const values = {
      iTeamId: sessionStorage.getItem("teamId"),
      iSportId: sessionStorage.getItem("sportId"),
    };
    if (values.iTeamId > 0 && values.iSportId > 0) {
      dispatch(GetTeamPlayerList(values));
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (
      teamPlayerListData &&
      teamPlayerListData.code === "200" &&
      teamPlayerListData.data &&
      teamPlayerListData.data.length > 0
    ) {
      const data = teamPlayerListData?.data;
      data?.forEach(
        (d) =>
          (d["nickname"] = `${d.UserData?.vFirstName?.replace(/\s+/g, "")}_${
            d.UserData?.vLastName
          }`)
      );
      setCustomers(data);
    }
  }, [teamPlayerListData]);

  React.useEffect(() => {
    if (!userProfileData) {
      const values = {
        iUserId: sessionStorage.getItem("id"),
      };
      dispatch(UserProfile(values));
    }
  }, [dispatch, userProfileData]);

  React.useEffect(() => {
    if (postData && postData.length > 0 && postId !== undefined) {
      document.getElementById("tDescriptionGroup").value =
        postData?.tDescriptionGroup;
      let arr = [];
      postData?.post_transactions?.map((image) => {
        arr.push(image.vFile);
        return false;
      });
      setImages(arr);
      setFiles(arr);
    }
  }, [postData, postId, setImages, setFiles]);

  const handleClear = React.useCallback(() => {
    setImages([]);
    setQuery("");
    autoCompleteRef.current.value = ""; //for emptying location input value
    setMapImg(null);
    dispatch({ type: RESET_IMAGE_URL_DATA });
    // userProfileData.data && userProfileData.data.eUnder13 === "Yes"
    //   ? setIsKid(true)
    //   : setIsKid(false);
    // setCheckMultipleImage({
    //   type: false,
    //   message: "",
    // });
    setFiles([]);
    setSingleImgColor("");
    setVideos([]);
    // setDescriptionErrMsg(false);
    setDescVal("");
    setEmojiValue("");
    // removePDFHandler();
    setShowEmoji(false);
    setYoutubeEmbedValue({
      ...youtubeEmbedValue,
      vEmbedLink: "",
      postEmbedShortUrl: "",
    });
    setIsShowUrlTextBox(false);
    setYutubeUrlMessage("");
    document.getElementById("tDescriptionGroup").value = "";
    dispatch({
      type: SELECTED_GROUP_FOR_CREATE_POST,
      payload: {},
    });
  }, [
    setFiles,
    dispatch,
    setIsShowUrlTextBox,
    youtubeEmbedValue,
    setVideos,
    setYoutubeEmbedValue,
    setImages,
  ]);

  const handleFormSubmit = React.useCallback(
    async (event) => {
      event.preventDefault();
      const formData = new FormData();
      // const tempFormData = new FormData();
      let values = {};
      let errors = {};
      for (let i = 0; i < event.target.length - 1; i++) {
        // if (event.target.name[i] === "tDescriptionGroup") {
        //   if (
        //     event.target[i].name !== "" &&
        //     (event.target[i].value === "" ||
        //       event.target[i].value === "NaN/NaN/NaN")
        //   ) {
        //     errors = {
        //       ...errors,
        //       [event.target[i].name]: true,
        //     };
        //   }
        // }
        if (
          event.target[i].name === "vEmbedLink" &&
          event.target[i].value === ""
        ) {
          errors = {
            ...errors,
            embedErrorMsg: true,
          };
        }
        if (event.target[i].name !== "") {
          values = {
            ...values,
            [event.target[i].name]: event.target[i].value,
          };
        }
      }
      values = {
        ...values,
        iUserId: sessionStorage.getItem("id"),
      };

      formData.append("iUserId", sessionStorage.getItem("id"));
      formData.append("vEmbedLink", youtubeEmbedValue.vEmbedLink);
      formData.append("vEmbedShortLink", youtubeEmbedValue.postEmbedShortUrl);

      if (videos?.length > 1) {
        setCheckMultipleVideo({
          type: true,
          message: "You Can Upload Only One Video",
        });
        return;
      }
      if (files.length > 0) {
        // let videoType = ["video/mp4", "video/webm", "video/mpeg"];
        for (let i = 0; i < files.length; i++) {
          // if (Math.round(files[i]?.size / 1024 / 1024) > 10) {
          //   setCheckMultipleVideo({
          //     type: true,
          //     message: "You Can't Upload More Than 10MB Images/Video",
          //   });
          //   return false;
          // } else {
          //   setCheckMultipleVideo({
          //     type: false,
          //     message: "",
          //   });
          // }
          // if (!videoType.includes(files[i]?.type)) {
          //   // formData.append("vCompressFile", files[i]);
          // }
          formData.append("vFile", files[i]);
        }
      }

      // when the add hashtags backend side then used...
      let hashArray = [];
      let newDescriptionString = values.tDescriptionGroup.split(/\s+/);
      for (let i = 0; i < newDescriptionString.length; i++) {
        if (newDescriptionString[i][0] === "#") {
          hashArray.push(newDescriptionString[i]);
        }
      }
      formData.append("tDescription", values.tDescriptionGroup);
      if (query && query.name && query.name.length > 0) {
        formData.append("vLocation", query.name);
      }
      formData.append("vTitle", JSON.stringify(hashArray));
      setCheckError(errors);

      if (
        images?.length === 1 &&
        files.length > 0 &&
        images[0]?.type === "video"
      ) {
        formData.append(
          "vDefultBackgroundColor",
          getDominantColor(images[0]?.video)
        );
        // dispatch({
        //   type: UPLOADED_VIDEO_BLOB,
        //   payload: images[0]?.video,
        // });
      } else if (
        images?.length === 1 &&
        files.length > 0 &&
        images[0]?.type !== "video"
      ) {
        formData.append("vDefultBackgroundColor", getDominantColor(images[0]));
      }

      // for image url
      const getYoutubeTitle = async (youtubeUrl) => {
        let res = await fetch(
          `https://noembed.com/embed?dataType=json&url=${youtubeUrl}`
        );
        let data = await res.json();
        return data?.title;
      };
      if (
        getImgUrlData &&
        youtubeThumbImg === null &&
        getImgUrlData?.data !== null &&
        files.length <= 0 &&
        images.length === 1
      ) {
        formData.append("vLinkImage", getImgUrlData?.data?.ogImage);
        formData.append("vLink", urlValue);

        formData.append(
          "vLinkDescription",
          getImgUrlData?.data?.ogLinkDescription
        );
      } else if (mapImg && mapImg !== null) {
        formData.append("vLinkImage", mapImg);
      }
      if (youtubeThumbImg !== null && images.length === 1) {
        var regExp =
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        let link = youtubeThumbImg.match(regExp);

        let title = await getYoutubeTitle(youtubeThumbImg);
        if (link !== null) {
          formData.append("vLinkImage", images[0]);
          formData.append("vLink", urlValue);
          formData.append("vLinkDescription", title);
        }
      }

      // tempFormData.append("iUserId", sessionStorage.getItem("id"));
      // formData.append("iTeamId", teamDetails.iTeamId);
      formData.append("iGroupId", selectedGroupForCreatePost.iGroupId);

      // formData.append("iSportId", 1);
      // tempFormData.append("tDescriptionGroup", values.tDescriptionGroup);

      if (Object.keys(errors).length === 0) {
        if (postId) {
          // dispatch(EditUserPost(formData));
        } else {
          try {
            setIsUploading(true);
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/group/createGroupPost`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  const progress = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );
                  if (+progress === 100) {
                    window.$("#createPostModalGroup").modal("hide");
                  }
                  setUploadProgress(progress);
                },
              }
            );

            if (response.data.code === "201") {
              // toast.current.show({
              //   severity: "success",
              //   summary: response.data?.message,
              //   life: 3000,
              // });
              if (
                images?.length === 1 &&
                files.length > 0 &&
                images[0]?.type === "video"
              ) {
                const video = {
                  iGroupPostId: response.data.data.iGroupPostId,
                  vFile: images[0]?.video,
                  vDefultBackgroundColor: getDominantColor(images[0]?.video),
                };
                dispatch({
                  type: UPLOADED_VIDEO_BLOB,
                  payload: video,
                });
              }

              dispatch(
                GetAllGroupMessageBoardPost({
                  iUserId: sessionStorage.getItem("id"),
                  // iTeamId: teamDetails.iTeamId,
                  page: 1,
                })
              );
              dispatch({
                type: CREATE_GROUP_POST_SUCCESS,
                payload: response.data,
              });
            }
          } catch (error) {
            console.error("Error uploading file:", error);
          } finally {
            setIsUploading(false);
            handleClear();
            window.$("#createPostModalGroup").modal("hide");
            // setTimeout(() => dispatch({ type: RESET_CREATE_POST_VALUE }), 3000);
          }
          // dispatch(CreateUserPost(formData));
        }
      }
    },
    [
      youtubeEmbedValue?.vEmbedLink,
      youtubeEmbedValue?.postEmbedShortUrl,
      videos?.length,
      files,
      query,
      images,
      getImgUrlData,
      youtubeThumbImg,
      mapImg,
      selectedGroupForCreatePost?.iGroupId,
      urlValue,
      postId,
      dispatch,
      handleClear,
    ]
  );

  const videoSize = (url) => {
    const video = document.createElement("video");
    let result = {};
    const promise = new Promise((resolve, reject) => {
      video.addEventListener("loadedmetadata", function () {
        result = {
          width: this.videoWidth,
          height: this.videoHeight,
        };
        resolve(result);
      });

      video.onerror = reject;
    });
    video.src = url;
    return promise;
  };

  function imageSize(url) {
    const img = document.createElement("img");
    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        resolve({ width, height });
      };
      img.onerror = reject;
    });
    img.src = url;
    return promise;
  }

  // create dynamic bg color
  function getDominantColor(url) {
    const img = document.createElement("img");
    img.src = url;
    const ctx = document.createElement("canvas").getContext("2d");
    ctx.drawImage(img, 0, 0, 1, 1);
    const i = ctx.getImageData(0, 0, 1, 1).data;
    return (
      "#" +
      ((1 << 24) + (i[0] << 16) + (i[1] << 8) + i[2]).toString(16).slice(1)
    );
  }

  React.useEffect(() => {
    let imageUrl;
    let imageDimension;
    let orientation;
    const getImageUrl = async () => {
      if (images.length === 0) {
        setImageClass("");
        return;
      } else {
        if (images[0]?.type === "video") {
          imageDimension = await videoSize(images[0]?.video);
          let { width, height } = imageDimension;
          orientation =
            height > width
              ? "Portrait"
              : height < width
              ? "Landscape"
              : "Square";
        } else {
          imageUrl = images[0];
          imageDimension = await imageSize(imageUrl);
          let { width, height } = imageDimension;
          orientation =
            width > height * 2
              ? "Paronama"
              : height > width
              ? "Portrait"
              : height < width
              ? "Landscape"
              : "Square";
        }
        let imgType = ["Portrait", "Square"];
        let imgType2 = ["Landscape", "Paronama"];

        if (imgType?.includes(orientation)) {
          if (images?.length === 1) {
            setImageClass("one-p-image");
          } else if (images?.length === 2) {
            setImageClass("two-pl-image");
          } else if (images?.length === 3) {
            setImageClass("three-pll-image");
          } else if (images?.length === 4) {
            setImageClass("four-plll-image");
          } else if (images?.length >= 5) {
            setImageClass("five-pllll-image");
          }
        } else if (imgType2?.includes(orientation)) {
          if (images?.length === 1) {
            setImageClass("one-l-image");
          } else if (images?.length === 2) {
            setImageClass("two-ll-image");
          } else if (images?.length === 3) {
            setImageClass("three-lll-image");
          } else if (images?.length === 4) {
            setImageClass("four-llll-image");
          } else if (images?.length >= 5) {
            setImageClass("five-lllll-image");
          }
        }
      }
    };

    getImageUrl();
  }, [images, setImageClass]);

  React.useEffect(() => {
    if (getImgUrlData && getImgUrlData?.data?.ogImage !== undefined) {
      setImages((pre) => [getImgUrlData?.data?.ogImage]);
    }
  }, [setImages, getImgUrlData]);

  const handleImageChange = React.useCallback(
    (e) => {
      const targetFiles = e.target.files;
      const targetFilesObject = [...targetFiles];
      let videoType = ["video/mp4", "video/webm", "video/mpeg"];
      let videoCount = 0;
      setMapImg(null);
      for (let i = 0; i < files.length; i++) {
        if (videoType.includes(files[i].type)) {
          videoCount += 1;
        }
      }
      targetFilesObject.map((file) => {
        if (Math.round(file?.size / 1024 / 1024) > 100000000) {
          setCheckMultipleVideo({
            type: true,
            message: "You Can't Upload More Than 10MB Images/Video",
          });
        }
        if (videoType.includes(file.type)) {
          setVideos((pre) => [...pre, file]);
          videoCount += 1;

          setImages((images) => [
            ...images.filter((val) => val !== getImgUrlData?.data?.ogImage),
            {
              type: "video",
              video: URL.createObjectURL(file),
            },
          ]);
        } else {
          setImages((images) => [
            ...images.filter((val) => val !== getImgUrlData?.data?.ogImage),
            URL.createObjectURL(file),
          ]);
        }
        setFiles((pre) => [...pre, file]);

        if (+videoCount > 1) {
          setCheckMultipleVideo({
            type: true,
            message: "You Can Upload Only One Video",
          });
        }
        return false;
      });
    },
    [
      setFiles,
      getImgUrlData,
      setVideos,
      setMapImg,
      files,
      setCheckMultipleVideo,
      setImages,
    ]
  );

  const removeImageHandler = React.useCallback(
    (id) => {
      const newImages = images.filter((item, index) => index !== id);
      const newFiles = files.filter((item, index) => index !== id);
      const newVideos = images
        .filter((item, index) => index !== id)
        .filter((item) => item.hasOwnProperty("video"));

      if (newFiles.length === 0) {
        setCheckMultipleVideo({
          type: false,
          message: "",
        });
      }
      if (newImages.length === 0) {
        setImageClass("");
      }

      setImages(newImages);
      setFiles(newFiles);
      setVideos(newVideos);

      for (let file of newFiles) {
        if (newVideos?.length > 1) {
          setCheckMultipleVideo({
            type: true,
            message: "You Can Upload Only One Video",
          });
        } else {
          if (Math.round(file?.size / 1024 / 1024) > 10) {
            setCheckMultipleVideo({
              type: true,
              message: "You Can't Upload More Than 10MB Images/Video",
            });
            return;
          } else {
            setCheckMultipleVideo({
              type: false,
              message: "",
            });
          }
        }
      }
    },
    [
      setImages,
      setCheckMultipleVideo,
      setFiles,
      setVideos,
      // videos,
      setImageClass,
      files,
      // setCheckError,
      images,
    ]
  );

  const onMultipleSearch = React.useCallback(
    (event) => {
      const trigger = event.trigger;

      if (trigger === "@") {
        //in a real application, make a request to a remote url with the query and return suggestions, for demo we filter at client side
        setTimeout(async () => {
          const query = event.query;
          let suggestions;

          if (!query.trim().length) {
            suggestions = [...customers];
          } else {
            await axios
              .post(
                `${process.env.REACT_APP_API_URL}/api/follower/searchUserDetails`,
                { iUserId: sessionStorage.getItem("id"), searchUser: query }
              )
              .then((response) => {
                if (
                  response?.data?.code === "200" &&
                  response?.data?.data?.length > 0
                ) {
                  // return response?.data?.data?.map((data) => ({ id: data?.vPersonalSlug, display: data?.FullName }));
                  return (suggestions = response?.data?.data?.map((data) => ({
                    id: data?.vPersonalSlug,
                    nickname: data?.FullName,
                  })));
                } else return (suggestions = []);
              });
            // .then(callback);
          }
          setMultipleSuggestions(suggestions);
        }, 250);
      } else if (trigger === "#") {
        setTimeout(async () => {
          const query = event.query;
          let suggestions;

          if (!query.trim().length) {
            // suggestions = [...tagSuggestions];
            suggestions = [];
          } else {
            await axios
              .post(
                `${process.env.REACT_APP_API_URL}/api/Hastag/getAllhastagData`,
                {
                  vTitle: query,
                }
              )
              .then((response) => {
                if (
                  response?.data?.code === "200" &&
                  response?.data?.data?.length > 0
                ) {
                  return (suggestions = response?.data?.data?.map(
                    (data) => data?.vTitle
                  ));
                } else return (suggestions = []);
              });
          }

          setMultipleSuggestions(suggestions);
        }, 250);
      }
    },
    [customers]
  );

  const itemTemplate = React.useCallback((suggestion) => {
    return (
      <div className="flex align-items-center">
        <span className="flex flex-column ml-2">
          {suggestion.name}
          <small
            style={{ fontSize: ".75rem", color: "var(--text-secondary-color)" }}
          >
            @{suggestion.nickname}
          </small>
        </span>
      </div>
    );
  }, []);

  const multipleItemTemplate = React.useCallback(
    (suggestion, options) => {
      const trigger = options.trigger;
      if (trigger === "@" && suggestion.nickname) {
        return itemTemplate(suggestion);
      } else if (trigger === "#" && !suggestion.nickname) {
        return <span>{suggestion}</span>;
      }
      return null;
    },
    [itemTemplate]
  );

  const onEmojiSelect = React.useCallback((event, emojiObject) => {
    let textAreaElement = document.getElementById("tDescriptionGroup");
    textAreaElement.value = textAreaElement.value + event.native;
    setEmojiValue(textAreaElement.value + event.native);
  }, []);

  const showEmojiSection = React.useCallback(
    (e) => {
      e.stopPropagation();
      setShowEmoji((showEmoji) => !showEmoji);
    },
    [setShowEmoji]
  );

  // check is url or not
  function isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );
    return res !== null;
  }
  // get url from description
  const urlify = React.useCallback(
    (text) => {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
      if (text.length > 0) {
        for (let urlData of text) {
          if (isValidURL(urlData)) {
            setUrlValue(urlData);
            let youtubeLink = urlData.match(regExp);
            if (youtubeLink !== null) {
              setYoutubeThumbImg(urlData);
              let link = `https://img.youtube.com/vi/${youtubeLink[2]}/0.jpg`;
              setImageClass("one-p-image");
              setImages((pre) => [link]);
              return;
            }

            return urlData.replace(urlRegex, function (url) {
              setImageClass("one-p-image");
              setYoutubeThumbImg(null);
              setTimeout(() => {
                dispatch(GetImageFromUrl({ url }));
              }, 300);
              return;
            });
          }
        }
        return null;
      } else {
        if (isValidURL(text)) {
          setUrlValue(text);
          let youtubeLink = text.match(regExp);
          if (youtubeLink !== null) {
            setYoutubeThumbImg(text);
            let link = `https://img.youtube.com/vi/${youtubeLink[2]}/0.jpg`;
            setImageClass("one-p-image");
            setImages((pre) => [link]);
            return;
          }
          return text.replace(urlRegex, function (url) {
            setImageClass("one-p-image");
            setYoutubeThumbImg(null);
            setTimeout(() => {
              dispatch(GetImageFromUrl({ url }));
            }, 300);

            // return url;
          });
        } else {
          return null;
        }
      }
    },
    [dispatch]
  );

  const handleTextChange = React.useCallback(() => {
    const element = document.getElementById("tDescriptionGroup");
    // let hashArray = [];
    // const markup = value;
    // element.value =
    //   element.value[0].charAt(0).toUpperCase() + element?.value?.slice(1);
    setDescVal(element.value);
    setEmojiValue(element.value);
    element.style.height = "1px";
    element.style.height = 3 + element.scrollHeight + "px";
    let url = element?.value?.split(" ");
    setTimeout(() => {
      urlify(url.length > 0 ? url : element.value);
    }, 200);
  }, [urlify]);

  const validateYouTubeUrl = React.useCallback(
    (e) => {
      const { name, value } = e.target;
      setYoutubeEmbedValue({ ...youtubeEmbedValue, [name]: value });

      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
      let embedYoutubeResult = value.match(regExp);
      if (embedYoutubeResult !== null) {
        setYutubeUrlMessage("");
        setYoutubeEmbedValue({
          ...youtubeEmbedValue,
          [name]: value,
          postEmbedShortUrl: embedYoutubeResult[2],
        });
      } else {
        setYutubeUrlMessage("Please Enter Valid Url");
      }
    },
    [setYutubeUrlMessage, setYoutubeEmbedValue, youtubeEmbedValue]
  );

  // React.useEffect(() => {
  //   const handleClickOutsideBox = (event) => {
  //     const box = document.getElementById("emoji-section-className");
  //     if (box && !box.contains(event.target) && showEmoji) {
  //       setShowEmoji(false);
  //     }
  //   };
  //   document.addEventListener("click", handleClickOutsideBox);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutsideBox);
  //   };
  // }, [setShowEmoji, showEmoji]);

  React.useEffect(() => {
    const handleClickOutsideBox = (event) => {
      if (
        emojiSectionRef.current &&
        !emojiSectionRef.current.contains(event.target) &&
        showEmoji
      ) {
        setShowEmoji(false);
      }
    };

    document.addEventListener("click", handleClickOutsideBox);

    return () => {
      document.removeEventListener("click", handleClickOutsideBox);
    };
  }, [showEmoji]);

  // for find image backgrund color
  // https://stackoverflow.com/questions/2541481/get-average-color-of-image-via-javascript

  return (
    <>
      <div
        className="modal fade create-post-popup"
        id="createPostModalGroup"
        tabIndex="-1"
        aria-labelledby="createPostModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <Toast ref={toast} />

        <div className="modal-dialog modal-dialog-centered innerpopup create-post-box">
          <div className="modal-content">
            <div className="modal-header">
              <div className="crete_post_h">
                <div className="img-box">
                  {/* User Image */}
                  <img
                    alt=""
                    src={
                      userProfileData &&
                      userProfileData.code === "200" &&
                      userProfileData.data.vImage
                        ? userProfileData.data.vImage
                        : `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                    }}
                  />
                </div>
                <div
                  className="user_info"
                  style={{ backgroundColor: `${singleImgColor}` }}
                >
                  {/* User NAme */}
                  {userProfileData &&
                  userProfileData.code === "200" &&
                  userProfileData.data ? (
                    <>
                      <span className="user-name">{`${userProfileData.data.vFirstName} ${userProfileData.data.vLastName}`}</span>
                      {/* User Location */}
                      {query.formatted_address?.length > 0 ? (
                        <div
                          className="location-box"
                          // onClick={() => {
                          //   window.$("#locationIdGroup").modal("show");
                          // }}
                          data-bs-target="#locationIdGroup"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                        >
                          <span className="defualt-text">is in</span>
                          <span className="user-name">{`${query.name}`}</span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleClear()}
              ></button>
            </div>
            <div className="modal-body">
              <form
                className="create-post-data create-post"
                onSubmit={handleFormSubmit}
              >
                {/* Uploading action */}
                <div className="action-btn">
                  <div className="d-grid">
                    {isUploading && <p>Uploading: {uploadProgress}%</p>}
                  </div>
                </div>

                <div className="form-group user-data-upload aa">
                  <div className="form-group">
                    {/* Post Input */}
                    <Mention
                      name="tDescriptionGroup"
                      inputId="tDescriptionGroup"
                      trigger={["@", "#"]}
                      suggestions={multipleSuggestions}
                      onSearch={onMultipleSearch}
                      field={["nickname"]}
                      // field="display"
                      className="form-control textarea-box"
                      placeholder="What's going on?"
                      itemTemplate={multipleItemTemplate}
                      onChange={(e) => handleTextChange(e)}
                    />

                    {/* Post Input map */}

                    {mapImg &&
                    mapImg !== null &&
                    urlValue === null &&
                    videos.length === 0 &&
                    images.length === 0 ? (
                      <>
                        <div className="post-upload-images">
                          <div className="inner-post-upload-images">
                            <div className="five-upload post-images">
                              <div className="images-box">
                                <Image src={mapImg} alt="" />
                              </div>
                              <button className="btn btn-primary" type="button">
                                <i
                                  className="fas fa-times"
                                  onClick={() => setMapImg(null)}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="post-media">
                    {/* Error */}
                    {checkMultipleVideo?.type ? (
                      <div
                        className="alert alert-danger text-center"
                        role="alert"
                        style={{ fontSize: "13px" }}
                      >
                        {checkMultipleVideo?.message}
                      </div>
                    ) : (
                      <></>
                    )}

                    {/* Image list */}
                    <div className="post-upload-images">
                      <div className={`inner-post-upload-images ${imageClass}`}>
                        {images?.slice(0, 5).map((item, key) =>
                          item.type !== "video" ? (
                            <>
                              <div
                                className="five-upload post-images"
                                key={key}
                              >
                                <div className="images-box">
                                  <Image preview src={item} alt="" />
                                </div>
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                >
                                  <i
                                    className="fas fa-times"
                                    onClick={() => removeImageHandler(key)}
                                  ></i>
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="five-upload post-images"
                                key={key}
                              >
                                <div className="images-box">
                                  <video
                                    id="video"
                                    src={item.video}
                                    alt=""
                                    controls
                                  />
                                </div>
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                >
                                  <i
                                    className="fas fa-times"
                                    onClick={() => removeImageHandler(key)}
                                  ></i>
                                </button>
                              </div>
                            </>
                          )
                        )}
                      </div>
                      {/* Image list */}
                      <div
                        className={`five-more-showcase inner-post-upload-images`}
                      >
                        {images?.length > 5 &&
                          images?.slice(5, images.length).map((item, key) =>
                            item?.type !== "video" ? (
                              <div className="five-upload" key={key}>
                                <div className="images-box">
                                  <img src={item} alt="" />
                                </div>
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  onClick={() => removeImageHandler(key + 5)}
                                >
                                  <i className="fas fa-times"></i>
                                </button>
                              </div>
                            ) : (
                              <>
                                <div
                                  className="five-upload post-images"
                                  key={key}
                                >
                                  <div className="images-box">
                                    <video
                                      id="video"
                                      src={item?.video}
                                      alt=""
                                      controls
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                  >
                                    <i
                                      className="fas fa-times"
                                      onClick={() =>
                                        removeImageHandler(key + 5)
                                      }
                                    ></i>
                                  </button>
                                </div>
                              </>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* show dyanmic youtube url field */}
                {isShowUrlTextBox === true ? (
                  <div className="embed-link-input">
                    <input
                      className="form-control"
                      type="text"
                      name="vEmbedLink"
                      value={youtubeEmbedValue.vEmbedLink}
                      onChange={validateYouTubeUrl}
                      placeholder="Add Youtube Url"
                    />
                    <p style={{ color: "red" }}>
                      {checkError?.embedErrorMsg
                        ? "Youtube Link Is Required."
                        : ""}
                    </p>
                    <button
                      type="button"
                      className="btn"
                      onClick={() => {
                        setYoutubeEmbedValue({
                          ...youtubeEmbedValue,
                          vEmbedLink: "",
                          postEmbedShortUrl: "",
                        });
                        setIsShowUrlTextBox(false);
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                    <p className="error">{youtubeUrlMessage}</p>
                  </div>
                ) : (
                  ""
                )}
                {/* end  dyanmic youtube url field */}

                <div className="add_post_con">
                  <p>Add To Post</p>
                  <div className="create-post-btn added-post-btn">
                    <div className="image-box in-image-video">
                      <i className="fal fa-photo-video"></i>
                      <input
                        type="file"
                        multiple
                        accept="image/*,video/*"
                        name="vFile"
                        onChange={(e) => handleImageChange(e)}
                      />
                    </div>
                    {/* Emoji icon */}
                    <div
                      className="image-box in-image-video emoji-btn"
                      id={"newclass-for-test"}
                    >
                      <i
                        className="fal fa-smile"
                        onClick={showEmojiSection}
                      ></i>
                      {showEmoji ? (
                        <div
                          className="emoji-pick-box"
                          ref={emojiSectionRef}
                          id="emoji-section-className"
                        >
                          <Picker
                            data={data}
                            onEmojiSelect={onEmojiSelect}
                            emojiSize="20"
                            emojiButtonSize="30"
                            perLine="12"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div
                      className="image-box in-image-video emoji-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i
                        className="fas fa-map-marker-alt"
                        // onClick={() => {
                        //   window.$("#locationIdGroup").modal("show");
                        // }}
                        data-bs-target="#locationIdGroup"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"

                        // onClick={()=>setShowModel(true)}
                      ></i>
                    </div>
                  </div>

                  <div className="post-btn">
                    <button
                      disabled={
                        emojiValue !== "" ||
                        images.length > 0 ||
                        files.length > 0 ||
                        descVal.length > 0
                          ? false
                          : true
                      }
                      className="btn"
                      type="submit"
                      value="submit"
                    >
                      Post
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* // second modal for except follower */}
      </div>

      <div
        className="modal fade create-post-popup search-location-modal create-post-box"
        id="locationIdGroup"
        tabIndex="-1"
        aria-labelledby="locationIdLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="crete_post_h">
                <i
                  className="fas fa-long-arrow-alt-left set-cursor-pointer"
                  // data-bs-target="#locationIdGroup"
                  // data-bs-dismiss="modal"
                  // data-bs-toggle="modal"
                  // onClick={() => {
                  //   window.$("#locationIdGroup").modal("hide");
                  // }}
                  data-bs-target="#createPostModalGroup"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                ></i>
                <p className="inner-title">Add Location</p>
              </div>
            </div>
            <div className="modal-body inner-create-post">
              <form className="">
                <div className="form-group">
                  <input
                    className="form-control"
                    ref={autoCompleteRef}
                    name={"vLocation"}
                    autoComplete="true"
                    onChange={(event) => {
                      autoCompleteRef.current.value = event.target.value;
                      setQuery(event.target.value);
                      // handleValidate(event);
                    }}
                    placeholder="Where are you?"
                    value={query.formatted_address}
                  />
                  {/* <input
                    onSelect={handleSelectLocation}
                    ref={inputRef}
                    type="text"
                    className="form-control search-connection"
                    placeholder="Search Locations"
                  /> */}
                  <i className="far fa-search"></i>
                </div>
                {/* <div className="tagged-location-section">
                  <div className="location-suggested">
                    <div className="location-suggested-box">
                      <h5>Mumbai</h5>
                      <p>Maharashtra, India</p>
                    </div>
                    <div className="location-suggested-box">
                      <h5>Ahmedabad</h5>
                      <p>Gujarat, India</p>
                    </div>
                    <div className="location-suggested-box">
                      <h5>Ahmedabad</h5>
                      <p>Gujarat, India</p>
                    </div>
                    <div className="location-suggested-box">
                      <h5>Ahmedabad</h5>
                      <p>Gujarat, India</p>
                    </div>
                    <div className="location-suggested-box">
                      <h5>Ahmedabad</h5>
                      <p>Gujarat, India</p>
                    </div>
                    <div className="location-suggested-box">
                      <h5>Ahmedabad</h5>
                      <p>Gujarat, India</p>
                    </div>
                  </div>
                </div> */}
                {/* <div className="create-post-btn sticky-btn"><a href="/" className="submit-form">done</a></div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePostGroup;
