export const CREATE_TEAM_REQUEST = "CREATE_TEAM_REQUEST";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_FAILURE = "CREATE_TEAM_FAILURE";

export const CREATE_PARENT_TEAM_REQUEST = "CREATE_PARENT_TEAM_REQUEST";
export const CREATE_PARENT_TEAM_SUCCESS = "CREATE_PARENT_TEAM_SUCCESS";
export const CREATE_PARENT_TEAM_FAILURE = "CREATE_PARENT_TEAM_FAILURE";

export const GET_TEAM_BY_ID_REQUEST = "GET_TEAM_BY_ID_REQUEST";
export const GET_TEAM_BY_ID_SUCCESS = "GET_TEAM_BY_ID_SUCCESS";
export const GET_TEAM_BY_ID_FAILURE = "GET_TEAM_BY_ID_FAILURE";

export const STORE_NAME_RESET = "STORE_NAME_RESET";

export const TEAM_LIST_REQUEST = "TEAM_LIST_REQUEST";
export const TEAM_LIST_SUCCESS = "TEAM_LIST_SUCCESS";
export const TEAM_LIST_FAILURE = "TEAM_LIST_FAILURE";

export const TEAM_MEMBER_LIST_REQUEST = "TEAM_MEMBER_LIST_REQUEST";
export const TEAM_MEMBER_LIST_SUCCESS = "TEAM_MEMBER_LIST_SUCCESS";
export const TEAM_MEMBER_LIST_FAILURE = "TEAM_MEMBER_LIST_FAILURE";

export const FIND_TEAM_REQUEST = "FIND_TEAM_REQUEST";
export const FIND_TEAM_SUCCESS = "FIND_TEAM_SUCCESS";
export const FIND_TEAM_FAILURE = "FIND_TEAM_FAILURE";

export const GET_ALL_TEAMS_REQUEST = "GET_ALL_TEAMS_REQUEST";
export const GET_ALL_TEAMS_SUCCESS = "GET_ALL_TEAMS_SUCCESS";
export const GET_ALL_TEAMS_FAILURE = "GET_ALL_TEAMS_FAILURE";

export const GET_ALL_ASSOCIATED_TEAMS_REQUEST =
  "GET_ALL_ASSOCIATED_TEAMS_REQUEST";
export const GET_ALL_ASSOCIATED_TEAMS_SUCCESS =
  "GET_ALL_ASSOCIATED_TEAMS_SUCCESS";
export const GET_ALL_ASSOCIATED_TEAMS_FAILURE =
  "GET_ALL_ASSOCIATED_TEAMS_FAILURE";

export const STORE_SELECTED_TEAM_LIST_REQUEST =
  "STORE_SELECTED_TEAM_LIST_REQUEST";
export const STORE_SELECTED_TEAM_LIST_SUCCESS =
  "STORE_SELECTED_TEAM_LIST_SUCCESS";
export const STORE_SELECTED_TEAM_LIST_FAILURE =
  "STORE_SELECTED_TEAM_LIST_FAILURE";

export const RESET_STORE_SELECTED_TEAM = "RESET_STORE_SELECTED_TEAM";

export const GET_ALL_SELECTED_TEAM_REQUEST = "GET_ALL_SELECTED_TEAM_REQUEST";
export const GET_ALL_SELECTED_TEAM_SUCCESS = "GET_ALL_SELECTED_TEAM_SUCCESS";
export const GET_ALL_SELECTED_TEAM_FAILURE = "GET_ALL_SELECTED_TEAM_FAILURE";

export const UPDATE_TEAM_NAME_REQUEST = "UPDATE_TEAM_NAME_REQUEST";
export const UPDATE_TEAM_NAME_SUCCESS = "UPDATE_TEAM_NAME_SUCCESS";
export const UPDATE_TEAM_NAME_FAILURE = "UPDATE_TEAM_NAME_FAILURE";
export const UPDATE_TEAM_NAME_RESET = "UPDATE_TEAM_NAME_RESET";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE";
export const DELETE_TEAM_RESET = "DELETE_TEAM_RESET";

export const DELETE_ALL_TEAM_REQUEST = "DELETE_ALL_TEAM_REQUEST";
export const DELETE_ALL_TEAM_SUCCESS = "DELETE_ALL_TEAM_SUCCESS";
export const DELETE_ALL_TEAM_FAILURE = "DELETE_ALL_TEAM_FAILURE";
export const DELETE_ALL_TEAM_RESET = "DELETE_ALL_TEAM_RESET";

export const EDIT_TEAM_ID = "EDIT_TEAM_ID";

export const UPDATE_TEAM_REQUEST = "UPDATE_TEAM_REQUEST";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_FAILURE = "UPDATE_TEAM_FAILURE";

export const UPDATE_TEAM_TAGLINE_REQUEST = "UPDATE_TEAM_TAGLINE_REQUEST";
export const UPDATE_TEAM_TAGLINE_SUCCESS = "UPDATE_TEAM_TAGLINE_SUCCESS";
export const UPDATE_TEAM_TAGLINE_FAILURE = "UPDATE_TEAM_TAGLINE_FAILURE";
export const RESET_UPDATE_TEAM_TAGLINE = "RESET_UPDATE_TEAM_TAGLINE";

export const GET_ALL_TEAMS_BY_USER_ID_REQUEST =
  "GET_ALL_TEAMS_BY_USER_ID_REQUEST";
export const GET_ALL_TEAMS_BY_USER_ID_SUCCESS =
  "GET_ALL_TEAMS_BY_USER_ID_SUCCESS";
export const GET_ALL_TEAMS_BY_USER_ID_FAILURE =
  "GET_ALL_TEAMS_BY_USER_ID_FAILURE";

export const GET_ALL_PARENT_TEAMS_BY_USER_ID_REQUEST =
  "GET_ALL_PARENT_TEAMS_BY_USER_ID_REQUEST";
export const GET_ALL_PARENT_TEAMS_BY_USER_ID_SUCCESS =
  "GET_ALL_PARENT_TEAMS_BY_USER_ID_SUCCESS";
export const GET_ALL_PARENT_TEAMS_BY_USER_ID_FAILURE =
  "GET_ALL_PARENT_TEAMS_BY_USER_ID_FAILURE";

export const GET_ALL_TEAMS_WITH_PARENTID_REQUEST =
  "GET_ALL_TEAMS_WITH_PARENTID_REQUEST";
export const GET_ALL_TEAMS_WITH_PARENTID_SUCCESS =
  "GET_ALL_TEAMS_WITH_PARENTID_SUCCESS";
export const GET_ALL_TEAMS_WITH_PARENTID_FAILURE =
  "GET_ALL_TEAMS_WITH_PARENTID_FAILURE";

export const GET_TEAM_CREDITS_COUNTS_REQUEST =
  "GET_TEAM_CREDITS_COUNTS_REQUEST";
export const GET_TEAM_CREDITS_COUNTS_SUCCESS =
  "GET_TEAM_CREDITS_COUNTS_SUCCESS";
export const GET_TEAM_CREDITS_COUNTS_FAILURE =
  "GET_TEAM_CREDITS_COUNTS_FAILURE";

export const CURRENT_TEAM = "CURRENT_TEAM";

export const SUBTEAM_BY_PARENTID_REQUEST = "SUBTEAM_BY_PARENTID_REQUEST";
export const SUBTEAM_BY_PARENTID_SUCCESS = "SUBTEAM_BY_PARENTID_SUCCESS";
export const SUBTEAM_BY_PARENTID_FAILURE = "SUBTEAM_BY_PARENTID_FAILURE";

export const VERIFY_TEAM_SLUG_REQUEST = "VERIFY_TEAM_SLUG_REQUEST";
export const VERIFY_TEAM_SLUG_SUCCESS = "VERIFY_TEAM_SLUG_SUCCESS";
export const VERIFY_TEAM_SLUG_FAILURE = "VERIFY_TEAM_SLUG_FAILURE";

export const CURRENT_TEAM_ROLE = "CURRENT_TEAM_ROLE";

export const LOCK_TEAM_DETAILS = "LOCK_TEAM_DETAILS";

export const GET_TEAM_LIST_REQUEST = "GET_TEAM_LIST_REQUEST";
export const GET_TEAM_LIST_SUCCESS = "GET_TEAM_LIST_SUCCESS";
export const GET_TEAM_LIST_FAILURE = "GET_TEAM_LIST_FAILURE";

export const GET_TEAM_LIST_WITH_CHILD_TEAMS_REQUEST =
  "GET_TEAM_LIST_WITH_CHILD_TEAMS_REQUEST";
export const GET_TEAM_LIST_WITH_CHILD_TEAMS_SUCCESS =
  "GET_TEAM_LIST_WITH_CHILD_TEAMS_SUCCESS";
export const GET_TEAM_LIST_WITH_CHILD_TEAMS_FAILURE =
  "GET_TEAM_LIST_WITH_CHILD_TEAMS_FAILURE";
