export const GET_ALL_POST_COMMENT_REQUEST = "GET_ALL_POST_COMMENT_REQUEST"
export const GET_ALL_POST_COMMENT_SUCCESS = "GET_ALL_POST_COMMENT_SUCCESS"
export const GET_ALL_POST_COMMENT_FAILURE = "GET_ALL_POST_COMMENT_FAILURE"

export const ADD_POST_COMMENT_REQUEST = "ADD_POST_COMMENT_REQUEST"
export const ADD_POST_COMMENT_SUCCESS = "ADD_POST_COMMENT_SUCCESS"
export const ADD_POST_COMMENT_FAILURE = "ADD_POST_COMMENT_FAILURE"

export const CLEAR_ADD_POST_COMMENT_DATA = "CLEAR_ADD_POST_COMMENT_DATA"

export const REPLAY_POST_COMMENT_REQUEST = "REPLAY_POST_COMMENT_REQUEST"
export const REPLAY_POST_COMMENT_SUCCESS = "REPLAY_POST_COMMENT_SUCCESS"
export const REPLAY_POST_COMMENT_FAILURE = "REPLAY_POST_COMMENT_FAILURE"

export const CLEAR_REPLAY_POST_COMMENT_DATA = "CLEAR_REPLAY_POST_COMMENT_DATA"

export const REMOVE_POST_COMMENT_REQUEST = "REMOVE_POST_COMMENT_REQUEST"
export const REMOVE_POST_COMMENT_SUCCESS = "REMOVE_POST_COMMENT_SUCCESS"
export const REMOVE_POST_COMMENT_FAILURE = "REMOVE_POST_COMMENT_FAILURE"

export const CLEAR_REMOVE_POST_COMMENT_DATA = "CLEAR_REMOVE_POST_COMMENT_DATA"

export const POST_COMMENT_LIKE_REQUEST = "POST_COMMENT_LIKE_REQUEST"
export const POST_COMMENT_LIKE_SUCCESS = "POST_COMMENT_LIKE_SUCCESS"
export const POST_COMMENT_LIKE_FAILURE = "POST_COMMENT_LIKE_FAILURE"

export const POST_COMMENT_LIKE_CLEAR_DATA = "POST_COMMENT_LIKE_CLEAR_DATA"

export const POST_COMMENT_REMOVE_LIKE_REQUEST = "POST_COMMENT_REMOVE_LIKE_REQUEST"
export const POST_COMMENT_REMOVE_LIKE_SUCCESS = "POST_COMMENT_REMOVE_LIKE_SUCCESS"
export const POST_COMMENT_REMOVE_LIKE_FAILURE = "POST_COMMENT_REMOVE_LIKE_FAILURE"

export const RESET_COMMENT = "RESET_COMMENT"

export const GET_POST_ALL_LIKED_COMMENT_USERS_REQUEST = "GET_POST_ALL_LIKED_COMMENT_USERS_REQUEST"
export const GET_POST_ALL_LIKED_COMMENT_USERS_SUCCESS = "GET_POST_ALL_LIKED_COMMENT_USERS_SUCCESS"
export const GET_POST_ALL_LIKED_COMMENT_USERS_FAILURE = "GET_POST_ALL_LIKED_COMMENT_USERS_FAILURE"

export const REPORT_POST_COMMENT_REQUEST = "REPORT_POST_COMMENT_REQUEST"
export const REPORT_POST_COMMENT_SUCCESS = "REPORT_POST_COMMENT_SUCCESS"
export const REPORT_POST_COMMENT_FAILURE = "REPORT_POST_COMMENT_FAILURE"

export const HIDE_POST_COMMENT_REQUEST = "HIDE_POST_COMMENT_REQUEST"
export const HIDE_POST_COMMENT_SUCCESS = "HIDE_POST_COMMENT_SUCCESS"
export const HIDE_POST_COMMENT_FAILURE = "HIDE_POST_COMMENT_FAILURE"

export const CLEAR_HIDE_POST_COMMENT_DATA = "CLEAR_HIDE_POST_COMMENT_DATA"

export const UNHIDE_POST_COMMENT_REQUEST = "UNHIDE_POST_COMMENT_REQUEST"
export const UNHIDE_POST_COMMENT_SUCCESS = "UNHIDE_POST_COMMENT_SUCCESS"
export const UNHIDE_POST_COMMENT_FAILURE = "UNHIDE_POST_COMMENT_FAILURE"

export const CLEAR_UNHIDE_POST_COMMENT_DATA = "CLEAR_UNHIDE_POST_COMMENT_DATA"

export const REPORT_POST_AND_COMMENT_TIME_DATA = "REPORT_POST_AND_COMMENT_TIME_DATA"



