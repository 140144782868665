import {
  CANVAS_BY_ID_REQUEST,
  CANVAS_BY_ID_SUCCESS,
  CANVAS_BY_ID_FAILURE,
  ADD_CANVAS_REQUEST,
  ADD_CANVAS_SUCCESS,
  ADD_CANVAS_FAILURE,
  RESET_STATE,
  UPDATE_CANVAS_PLAYER_ID,
  SPEED_VALUE,
  SET_IS_DATA,
  GET_CANVAS_FORMATIONS_REQUEST,
  GET_CANVAS_FORMATIONS_SUCCESS,
  GET_CANVAS_FORMATIONS_FAILURE,
  SET_TSHIRT_POSITION_DATA,
  FREE_DRAW_TYPE_VALUE,
  GET_ANDROID_CANVAS_LIST_REQUEST,
  GET_ANDROID_CANVAS_LIST_SUCCESS,
  GET_ANDROID_CANVAS_LIST_FAILURE,
  ADD_CANVAS_FOLDER_REQUEST,
  ADD_CANVAS_FOLDER_SUCCESS,
  ADD_CANVAS_FOLDER_FAILURE,
  GET_CANVAS_FORMATION_BY_ID_REQUEST,
  GET_CANVAS_FORMATION_BY_ID_SUCCESS,
  GET_CANVAS_FORMATION_BY_ID_FAILURE,
  BALL_LINE_TYPE_VALUE,
  FOLDER_ARRAY,
  UPDATE_CANVAS_REQUEST,
  UPDATE_CANVAS_SUCCESS,
  UPDATE_CANVAS_FAILURE,
  RESET_UPDATE_CANVAS_SUCCESS,
} from "../constants/canvas";
import { RESET_ADD_CANVAS_SUCCESS } from "../constants/canvasFolder";

const initialState = {
  addCanvasData: {},
};

export function canvasReducer(state = {}, action) {
  switch (action.type) {
    case ADD_CANVAS_FOLDER_REQUEST:
    case CANVAS_BY_ID_REQUEST:
    case ADD_CANVAS_REQUEST:
    case GET_CANVAS_FORMATIONS_REQUEST:
    case GET_ANDROID_CANVAS_LIST_REQUEST:
    case GET_CANVAS_FORMATION_BY_ID_REQUEST:
    case UPDATE_CANVAS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CANVAS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        canvasByIdData: action.payload,
      };
    case ADD_CANVAS_SUCCESS:
      return {
        ...state,
        loading: false,
        addCanvasData: action.payload,
      };
    case UPDATE_CANVAS_PLAYER_ID: {
      return {
        ...state,
        loading: false,
        updateCanvasPlayerIdData: action.payload,
      };
    }
    case SPEED_VALUE: {
      return {
        ...state,
        loading: false,
        speedValues: action.payload,
      };
    }
    case FREE_DRAW_TYPE_VALUE: {
      return {
        ...state,
        loading: false,
        freeDrawValues: action.payload,
      };
    }
    case BALL_LINE_TYPE_VALUE: {
      return {
        ...state,
        loading: false,
        ballLineTypeValues: action.payload,
      };
    }
    case SET_IS_DATA: {
      return {
        ...state,
        loading: false,
        setIsDataValues: action.payload,
      };
    }
    case GET_CANVAS_FORMATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        getCanvasFormationData: action.payload,
      };
    }
    case SET_TSHIRT_POSITION_DATA: {
      return {
        ...state,
        loading: false,
        setTshirtAndPositionValues: action.payload,
      };
    }
    case GET_ANDROID_CANVAS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        getAndroidCanvasListData: action.payload,
      };
    }
    case ADD_CANVAS_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
        addCategoryData: action.payload,
      };
    case GET_CANVAS_FORMATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getCanvasFormationDataById: action.payload,
      };
    case FOLDER_ARRAY:
      return {
        ...state,
        loading: false,
        folderListData: action.payload,
      };
    case UPDATE_CANVAS_SUCCESS:
      return {
        ...state,
        loading: false,
        updatePlayData: action.payload,
      };

    case RESET_ADD_CANVAS_SUCCESS:
      return {
        ...state,
        loading: false,
        addCanvasData: {},
      };
    case UPDATE_CANVAS_FAILURE:
    case GET_CANVAS_FORMATION_BY_ID_FAILURE:
    case ADD_CANVAS_FOLDER_FAILURE:
    case GET_ANDROID_CANVAS_LIST_FAILURE:
    case GET_CANVAS_FORMATIONS_FAILURE:
    case CANVAS_BY_ID_FAILURE:
    case ADD_CANVAS_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_UPDATE_CANVAS_SUCCESS:
      return {
        ...state,
        loading: false,
        updatePlayData: {},
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
