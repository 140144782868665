import {
  GET_PRIVACY_POLICY_REQUEST,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_PRIVACY_POLICY_FAILURE,
} from "../constants/policy";
import axios from "axios";

export const GetPrivacyPolicy = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRIVACY_POLICY_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/index/getAllPrivacyPolicy`,
      values
    );
    dispatch({
      type: GET_PRIVACY_POLICY_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_PRIVACY_POLICY_FAILURE });
  }
};
