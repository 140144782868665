import {
  EXPORT_EVENTS_REQUEST,
  EXPORT_EVENTS_SUCCESS,
  EXPORT_EVENTS_FAILURE,
} from "../constants/event";

export function calendarExportReducer(state = {}, action) {
  switch (action.type) {
    case EXPORT_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case EXPORT_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        icsFileLinkData: action.payload,
      };
    case EXPORT_EVENTS_FAILURE:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
