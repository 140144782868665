export const CREATE_POST_REQUEST = "CREATE_POST_REQUEST";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAILURE = "CREATE_POST_FAILURE";
export const RESET_CREATE_POST_VALUE = "RESET_CREATE_POST_VALUE";

export const GET_ALL_POST_BY_USERID_REQUEST = "GET_ALL_POST_BY_USERID_REQUEST";
export const GET_ALL_POST_BY_USERID_SUCCESS = "GET_ALL_POST_BY_USERID_SUCCESS";
export const GET_ALL_POST_BY_USERID_FAILURE = "GET_ALL_POST_BY_USERID_FAILURE";

export const GET_ALL_TEAM_POST_WITHOUT_LOGIN_REQUEST =
  "GET_ALL_TEAM_POST_WITHOUT_LOGIN_REQUEST";
export const GET_ALL_TEAM_POST_WITHOUT_LOGIN_SUCCESS =
  "GET_ALL_TEAM_POST_WITHOUT_LOGIN_SUCCESS";
export const GET_ALL_TEAM_POST_WITHOUT_LOGIN_FAILURE =
  "GET_ALL_TEAM_POST_WITHOUT_LOGIN_FAILURE";

export const GET_IMAGE_URL_REQUEST = "GET_IMAGE_URL_REQUEST";
export const GET_IMAGE_URL_SUCCESS = "GET_IMAGE_URL_SUCCESS";
export const GET_IMAGE_URL_FAILURE = "GET_IMAGE_URL_FAILURE";
export const RESET_IMAGE_URL_DATA = "RESET_IMAGE_URL_DATA";

export const GET_ALL_AUTH_POST_BY_USERID_REQUEST =
  "GET_ALL_GET_ALL_AUTH_POST_BY_USERID_REQUESTPOST_BY_USERID_REQUEST";
export const GET_ALL_AUTH_POST_BY_USERID_SUCCESS =
  "GET_ALL_AUTH_POST_BY_USERID_SUCCESS";
export const GET_ALL_AUTH_POST_BY_USERID_FAILURE =
  "GET_ALL_AUTH_POST_BY_USERID_FAILURE";

export const GET_ALL_POST_REQUEST = "GET_ALL_POST_REQUEST";
export const GET_ALL_POST_SUCCESS = "GET_ALL_POST_SUCCESS";
export const GET_ALL_POST_FAILURE = "GET_ALL_POST_FAILURE";

export const GET_ALL_TEAM_POST_REQUEST = "GET_ALL_TEAM_POST_REQUEST";
export const GET_ALL_TEAM_POST_SUCCESS = "GET_ALL_TEAM_POST_SUCCESS";
export const GET_ALL_TEAM_POST_FAILURE = "GET_ALL_TEAM_POST_FAILURE";

export const EDIT_POST_REQUEST = "EDIT_POST_REQUEST";
export const EDIT_POST_SUCCESS = "EDIT_POST_SUCCESS";
export const EDIT_POST_FAILURE = "EDIT_POST_FAILURE";

export const GET_POST_ID = "GET_POST_ID";

export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";
export const DELETE_POST_RESET = "DELETE_POST_RESET";

export const ADD_LOVE_IT_POST_REQUEST = "ADD_LOVE_IT_POST_REQUEST";
export const ADD_LOVE_IT_POST_SUCCESS = "ADD_LOVE_IT_POST_SUCCESS";
export const ADD_LOVE_IT_POST_FAILURE = "ADD_LOVE_IT_POST_FAILURE";

export const ADD_LOVE_IT_POST_CLEAR_DATA = "ADD_LOVE_IT_POST_CLEAR_DATA";

export const SINGLE_POST_ALL_LIKES_REQUEST = "SINGLE_POST_ALL_LIKES_REQUEST";
export const SINGLE_POST_ALL_LIKES_SUCCESS = "SINGLE_POST_ALL_LIKES_SUCCESS";
export const SINGLE_POST_ALL_LIKES_FAILURE = "SINGLE_POST_ALL_LIKES_FAILURE";

export const HIDE_POST_REQUEST = "HIDE_POST_REQUEST";
export const HIDE_POST_SUCCESS = "HIDE_POST_SUCCESS";
export const HIDE_POST_FAILURE = "HIDE_POST_FAILURE";
export const HIDE_POST_RESET = "HIDE_POST_RESET";

export const VIEW_MORE_COMMENT_TIME_POST_DATA_REQUEST =
  "VIEW_MORE_COMMENT_TIME_POST_DATA_REQUEST";
export const VIEW_MORE_COMMENT_TIME_POST_DATA_SUCCESS =
  "VIEW_MORE_COMMENT_TIME_POST_DATA_SUCCESS";
export const VIEW_MORE_COMMENT_TIME_POST_DATA_FAILURE =
  "VIEW_MORE_COMMENT_TIME_POST_DATA_FAILURE";

export const VIEW_MORET_TIME_POST_ID = "VIEW_MORET_TIME_POST_ID";

export const GET_ALL_REPORT_REQUEST = "GET_ALL_REPORT_REQUEST";
export const GET_ALL_REPORT_SUCCESS = "GET_ALL_REPORT_SUCCESS";
export const GET_ALL_REPORT_FAILURE = "GET_ALL_REPORT_FAILURE";
export const VIEW_MORE_COMMENT_TIME_POST_DATA_RESET_DATA =
  "VIEW_MORE_COMMENT_TIME_POST_DATA_RESET_DATA";

export const CREATE_REPORT_POST_REQUEST = "CREATE_REPORT_POST_REQUEST";
export const CREATE_REPORT_POST_SUCCESS = "CREATE_REPORT_POST_SUCCESS";
export const CREATE_REPORT_POST_FAILURE = "CREATE_REPORT_POST_FAILURE";

// when the user show the tagged people list then used...
export const POST_TAGGED_PEOPLE_LIST_REQUEST =
  "POST_TAGGED_PEOPLE_LIST_REQUEST";
export const POST_TAGGED_PEOPLE_LIST_SUCCESS =
  "POST_TAGGED_PEOPLE_LIST_SUCCESS";
export const POST_TAGGED_PEOPLE_LIST_FAILURE =
  "POST_TAGGED_PEOPLE_LIST_FAILURE";

export const POST_TAGGED_PEOPLE_LIST_CLEAR_DATA =
  "POST_TAGGED_PEOPLE_LIST_CLEAR_DATA";

export const TEMPORARY_STORE_POST_DATA_REQUEST =
  "TEMPORARY_STORE_POST_DATA_REQUEST";
export const TEMPORARY_STORE_POST_DATA_SUCCESS =
  "TEMPORARY_STORE_POST_DATA_SUCCESS";
export const TEMPORARY_STORE_POST_DATA_FAILURE =
  "TEMPORARY_STORE_POST_DATA_FAILURE";

export const TEMPORARY_STORE_POST_CLEAR_DATA =
  "TEMPORARY_STORE_POST_CLEAR_DATA";

export const TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_REQUEST =
  "TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_REQUEST";
export const TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_SUCCESS =
  "TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_SUCCESS";
export const TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_FAILURE =
  "TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_FAILURE";

export const TEMPORARY_STORE_DELETED_POST_COMMENT_CLEAR_DATA =
  "TEMPORARY_STORE_DELETED_POST_COMMENT_CLEAR_DATA";

export const TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_COMMENT_DATA_REQUEST =
  "TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_COMMENT_DATA_REQUEST";
export const TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_DATA_SUCCESS =
  "TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_DATA_SUCCESS";
export const TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_DATA_FAILURE =
  "TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_DATA_FAILURE";

export const TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_CLEAR_DATA =
  "TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_CLEAR_DATA";

export const TEMPORARY_STORE_USER_POST_LIKE_DATA_REQUEST =
  "TEMPORARY_STORE_USER_POST_LIKE_DATA_REQUEST";
export const TEMPORARY_STORE_USER_POST_LIKE_DATA_SUCCESS =
  "TEMPORARY_STORE_USER_POST_LIKE_DATA_SUCCESS";
export const TEMPORARY_STORE_USER_POST_LIKE_DATA_FAILURE =
  "TEMPORARY_STORE_USER_POST_LIKE_DATA_FAILURE";

export const TEMPORARY_STORE_USER_POST_LIKE_CLEAR_DATA =
  "TEMPORARY_STORE_USER_POST_LIKE_CLEAR_DATA";

export const TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_REQUEST =
  "TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_REQUEST";
export const TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_SUCCESS =
  "TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_SUCCESS";
export const TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_FAILURE =
  "TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_FAILURE";

export const TEMPORARY_STORE_POST_CLEAR_COMMENT_COUNT_DATA =
  "TEMPORARY_STORE_POST_CLEAR_COMMENT_COUNT_DATA";

export const POST_LIST = "POST_LIST";

export const COMMENT_REPORT_POST_LIST = "COMMENT_REPORT_POST_LIST";
export const COMMENT_REPORT_POST_LIST_RESET = "COMMENT_REPORT_POST_LIST_RESET";

export const HIDE_COMMENT_USERS_LIST = "HIDE_COMMENT_USERS_LIST";
export const HIDE_COMMENT_USERS_LIST_RESET = "HIDE_COMMENT_USERS_LIST_RESET";
