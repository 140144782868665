import React from "react";
import PropsTypes from "prop-types";
import Input from "../CustomComponents/Input/Input";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";

import {
  CreateFacility,
  GetAllFacility,
  GetFacilityByFacilityId,
  UpdateFacility,
  FacilityResetState,
  DeleteFacility,
} from "../../store/action/facilityAction";
import { confirmDialog } from "primereact/confirmdialog";
import { useSelector } from "react-redux";
import DropDown from "../CustomComponents/DropDown/DropDown";

function CreateFacilityTab({ isEvent, setISEvents }) {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const [checkError, setCheckError] = React.useState();
  const [isClear, setIsClear] = React.useState(false);
  const [editEventId, setEditEventId] = React.useState();
  const [deleteEventId, setDeleteEventId] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [nodes, setNodes] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [locationData, setLocationData] = React.useState([]);

  const {
    getAllFacilityData,
    getAllFacilityByfacilityData,
    createFacilityData,
    updateFacilityData,
    deleteFacilityData,
  } = useSelector((state) => state.facility);
  React.useEffect(() => {
    if (isClear) {
      setIsClear(false);
    }
  }, [isClear]);

  React.useEffect(() => {
    if (!getAllFacilityData) {
      dispatch(GetAllFacility({ iUserId: sessionStorage.getItem("id") }));
    }
  }, [dispatch, getAllFacilityData]);

  const handleCancel = React.useCallback(() => {
    dispatch(FacilityResetState());
    setIsClear(true);
    setEditEventId("");
  }, [dispatch]);

  React.useEffect(() => {
    if (deleteFacilityData && deleteFacilityData.code === "200") {
      toast.current.show({
        severity: "success",
        summary: deleteFacilityData?.message,
        life: 1000,
      });
      setTimeout(() => setIsLoading(false), 1000);
      dispatch(FacilityResetState());
      setIsClear(true);
      setTimeout(() => {
        // setISEvents({ flag: false, type: "" });
        // setEditEventId();
      }, 1000);
    }
  }, [deleteFacilityData, setISEvents, dispatch]);

  React.useEffect(() => {
    if (createFacilityData && createFacilityData.code === "200") {
      toast.current.show({
        severity: "success",
        summary: createFacilityData?.message,

        life: 1000,
      });
      setTimeout(() => setIsLoading(false), 1000);
      dispatch(FacilityResetState());
      setIsClear(true);
      setTimeout(() => {
        // setISEvents({ flag: false, type: "" });
        // setEditEventId();
      }, 1000);
    }
  }, [createFacilityData, setISEvents, dispatch]);

  React.useEffect(() => {
    if (editEventId) {
      dispatch(GetFacilityByFacilityId({ iFacilityId: editEventId }));
    }
  }, [editEventId, dispatch]);

  React.useEffect(() => {
    if (updateFacilityData && updateFacilityData.code === "200") {
      toast.current.show({
        severity: "success",
        summary: updateFacilityData?.message,
        life: 1000,
      });
      setTimeout(() => setIsLoading(false), 1000);
      dispatch(FacilityResetState());
      setIsClear(true);

      setTimeout(() => {
        // setISEvents({ flag: false, type: "" });
        setEditEventId();

        // setSelectedTeamPlayer([]);
      }, 1000);
    }
  }, [updateFacilityData, setISEvents, dispatch]);

  const accept = React.useCallback(() => {
    setIsDelete(true);
  }, []);

  const reject = React.useCallback(() => {
    setIsDelete(false);
    setDeleteEventId("");
  }, []);

  React.useEffect(() => {
    if (isDelete && deleteEventId) {
      dispatch(DeleteFacility({ iFacilityId: deleteEventId }));
    }
  }, [isDelete, deleteEventId, dispatch]);

  const handleDelete = React.useCallback(
    (id) => {
      confirmDialog({
        message: "Are You Sure You Want To Proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept,
        reject,
      });
      setDeleteEventId(id);
    },
    [accept, reject]
  );

  React.useEffect(() => {
    let facilityList = [];
    for (let i = 0; i < getAllFacilityData?.data?.length; i++) {
      const facilities = getAllFacilityData?.data[i];
      const editBtn = (
        <button
          className="d-flex align-items-center"
          onClick={() => setEditEventId(facilities.iFacilityId)}
        >
          <i className="far fa-pencil"></i>
          <span>Edit</span>
        </button>
      );
      const deleteBtn = (
        <button
          className="d-flex align-items-center"
          onClick={() => handleDelete(facilities.iFacilityId)}
        >
          <i className="fal fa-trash-alt"></i>
          <span>Delete</span>
        </button>
      );

      let node = {
        key: i,
        data: {
          address: facilities.vAddress,
          location: facilities.vLocation,
          duration: facilities.vDuration,
          locationconflicts: facilities.vLocationConflict,
          edit: editBtn,
          delete: deleteBtn,
        },
      };

      facilityList.push(node);
      // console.log("facilityList", facilityList);
    }

    setNodes(facilityList);
  }, [getAllFacilityData, handleDelete]);

  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      let values = {};
      let errors = {};
      for (let i = 0; i < event.target.length - 1; i++) {
        if (
          (event.target[i].value === "" ||
            event.target[i].value === "NaN/NaN/NaN") &&
          event.target[i].name !== ""
        ) {
          if (event.target[i].name === "vLocationConflict") continue;
          errors = {
            ...errors,
            [event.target[i].name]: true,
          };
        }
        if (event.target[i].name !== "") {
          values = {
            ...values,
            [event.target[i].name]: event.target[i].value,
          };
        }
      }
      values = {
        ...values,
        iUserId: sessionStorage.getItem("id"),
      };

      setCheckError(errors);
      if (Object.keys(errors).length === 0) {
        setIsLoading(true);
        // console.log(values);
        if (editEventId) {
          values = {
            ...values,
            iFacilityId: editEventId,
          };

          dispatch(UpdateFacility(values));
        } else {
          dispatch(CreateFacility(values));
        }
      }
    },
    [dispatch, editEventId, setCheckError]
  );

  var startTime = "2016-08-10 00:00:00";
  var endTime = "2016-08-10 12:00:00";

  //Parse In
  var parseIn = function (date_time) {
    var d = new Date();
    d.setHours(date_time.substring(11, 13));
    d.setMinutes(date_time.substring(14, 16));
    return d;
  };

  //make list
  var getTimeIntervals = function (time1, time2) {
    var arr = [];
    while (time1 < time2) {
      arr.push({
        id: `${time1.toTimeString().substring(0, 5)}`,
        value: `${time1.toTimeString().substring(0, 5)}`,
      });
      time1.setMinutes(time1.getMinutes() + 15);
    }
    return arr;
  };

  startTime = parseIn(startTime);
  endTime = parseIn(endTime);

  var intervals = getTimeIntervals(startTime, endTime);

  React.useEffect(() => {
    const locationsData = [];
    if (getAllFacilityData !== undefined && getAllFacilityData?.data !== null) {
      for (let items of getAllFacilityData?.data) {
        locationsData.push({ id: items?.vLocation, value: items?.vLocation });
      }
    }
    setLocationData(locationsData);
    // console.log("locationsData", locationsData);
  }, [getAllFacilityData]);

  return (
    <div className="test create-form create-facility">
      <Toast ref={toast} />
      <form method="post" onSubmit={handleSubmit}>
        <div className="d-flex align-items-center form-group w-100 py-2">
          <label className="w-25">Location Name:</label>
          <div className="w-100">
            <Input
              placeHolder={
                getAllFacilityByfacilityData &&
                getAllFacilityByfacilityData.code === "200"
                  ? getAllFacilityByfacilityData.data.vLocation
                  : "Location Name"
              }
              name="vLocation"
              type="text"
              title="Location"
              isValidate={true}
              newClass={"form-control"}
              isError={checkError?.vLocation}
              isSetValue={
                getAllFacilityByfacilityData &&
                getAllFacilityByfacilityData.code === "200"
                  ? true
                  : false
              }
              isClear={isClear}
            />
          </div>
        </div>
        <div className="d-flex align-items-center form-group w-100 py-2">
          <label className="w-25">Address:</label>
          <div className="w-100">
            <Input
              placeHolder={
                getAllFacilityByfacilityData &&
                getAllFacilityByfacilityData.code === "200"
                  ? getAllFacilityByfacilityData?.data.vAddress
                  : "Address"
              }
              name="vAddress"
              title={
                getAllFacilityByfacilityData &&
                getAllFacilityByfacilityData.code === "200"
                  ? getAllFacilityByfacilityData?.data.vAddress
                  : "Address"
              }
              isSetValue={
                getAllFacilityByfacilityData &&
                getAllFacilityByfacilityData.code === "200"
                  ? true
                  : false
              }
              isValidate={true}
              newClass={"form-control"}
              isError={checkError?.vAddress}
              isClear={isClear}
            />
          </div>
        </div>
        <div className="d-flex align-items-center form-group w-100 py-2">
          <label className="w-25">Time Gap:</label>
          <div className="w-100">
            {/* <Input
              placeHolder={
                getAllFacilityByfacilityData &&
                getAllFacilityByfacilityData.code === "200"
                  ? getAllFacilityByfacilityData.data.vDuration
                  : ""
              }
              name="vDuration"
              type="text"
              title={
                getAllFacilityByfacilityData &&
                getAllFacilityByfacilityData.code === "200"
                  ? getAllFacilityByfacilityData.data.vDuration
                  : ""
              }
              isSetValue={
                getAllFacilityByfacilityData &&
                getAllFacilityByfacilityData.code === "200"
                  ? true
                  : false
              }
              isValidate={true}
              newClass={"form-control"}
              isError={checkError?.vDuration}
              isClear={isClear}
            /> */}
            <DropDown
              title={
                getAllFacilityByfacilityData !== undefined &&
                getAllFacilityByfacilityData?.code === "200"
                  ? getAllFacilityByfacilityData.data.vDuration
                  : "Time Gap"
              }
              name={"vDuration"}
              field={"value"}
              fieldId={"id"}
              isClear={isClear}
              data={intervals}
              isError={checkError?.vDuration}
              isValidate={true}
              // newClass="form-select"
              placeHolder={
                getAllFacilityByfacilityData &&
                getAllFacilityByfacilityData?.code === "200"
                  ? getAllFacilityByfacilityData?.data.vDuration
                  : "Time Gap"
              }
              isSetValue={
                getAllFacilityByfacilityData &&
                getAllFacilityByfacilityData?.code === "200"
                  ? true
                  : false
              }
            />
          </div>
        </div>

        <div className="d-flex align-items-center form-group w-100 py-2">
          <label className="w-25">Location Conflicts:</label>
          <div className="w-100">
            <DropDown
              title={
                getAllFacilityByfacilityData !== undefined &&
                getAllFacilityByfacilityData?.code === "200"
                  ? getAllFacilityByfacilityData.data.vLocationConflict
                  : "Location Conflicts"
              }
              name={"vLocationConflict"}
              field={"value"}
              fieldId={"id"}
              isClear={isClear}
              data={locationData}
              isError={checkError?.vLocationConflict}
              isValidate={false}
              // newClass="form-select"
              placeHolder={
                getAllFacilityData && getAllFacilityData?.code === "200"
                  ? getAllFacilityByfacilityData?.data.vLocationConflict
                  : "Location Conflicts"
              }
              isSetValue={
                getAllFacilityByfacilityData &&
                getAllFacilityByfacilityData?.code === "200"
                  ? true
                  : false
              }
            />
          </div>
        </div>

        <div className="d-flex align-items-center form-group w-100 py-2 mt-md-3">
          <div className="login-bottom">
            {isLoading ? (
              <img src="/assets/gif/350.gif" className="loading-gif" alt="" />
            ) : (
              <button type="submit" className="invitation-btn ">
                {editEventId ? "Edit" : "Create"}
              </button>
            )}
          </div>
          {editEventId ? (
            <div className="login-bottom ms-2">
              <button
                type="button"
                className="btn change-btn"
                onClick={(e) => handleCancel(e)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </form>
      {getAllFacilityData &&
      getAllFacilityData.code === "200" &&
      getAllFacilityData?.data.length > 10 ? (
        <TreeTable
          value={nodes}
          paginator
          rows={10}
          className="table-responsive mt-4 player-table"
          style={{ minWidth: "100%" }}
        >
          <Column field="address" header="Address"></Column>
          <Column field="location" header="Location"></Column>
          <Column field="duration" header="Time Gap"></Column>
          <Column
            field="locationconflicts"
            header="Location Conflicts"
          ></Column>
          <Column
            field="edit"
            header="Edit"
            className="edit action-btn"
          ></Column>
          <Column
            field="delete"
            header="Delete"
            className="edit action-btn"
          ></Column>
        </TreeTable>
      ) : (
        <TreeTable
          value={nodes}
          rows={10}
          style={{ minWidth: "100%" }}
          className="table-responsive mt-4 player-table"
        >
          <Column
            field="address"
            header="Address"
            className="address-td"
          ></Column>
          <Column field="location" header="Location"></Column>
          <Column field="duration" header="Time Gap"></Column>
          <Column
            field="locationconflicts"
            header="Location Conflicts"
          ></Column>
          <Column
            field="edit"
            header="Edit"
            className="edit action-btn"
          ></Column>
          <Column
            field="delete"
            header="Delete"
            className="edit action-btn"
          ></Column>
        </TreeTable>
      )}

      {/* <div className="table-responsive mt-4 player-table"> */}
      {/* <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Location</th>
              <th>Duration</th>
              <th className="edit">Edit</th>
              <th className="delete">Delete</th>
            </tr>
          </thead>
          <tbody>
            {getAllFacilityData && getAllFacilityData?.code === "200" ? (
              getAllFacilityData?.data?.map((item, key) => (
                <tr key={key}>
                  <td>{item.vAddress}</td>
                  <td>{item.vLocation}</td>
                  <td>{item.vDuration}</td>
                  <td className="edit action-btn">
                    <button
                      className="d-flex align-items-center"
                      onClick={() => setEditEventId(item.iFacilityId)}
                    >
                      <i className="far fa-pencil"></i>
                      <span>Edit</span>
                    </button>
                  </td>
                  <td className="delete action-btn">
                    <button
                      className="d-flex align-items-center"
                      onClick={() => handleDelete(item.iFacilityId)}
                    >
                      <i className="fal fa-trash-alt"></i>
                      <span>Delete</span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No Facilities Found</td>
              </tr>
            )}
          </tbody>
        </table> */}
      {/* </div> */}
    </div>
  );
}

CreateFacilityTab.propTypes = {
  isEvent: PropsTypes.object,
  setISEvents: PropsTypes.func,
};

export default CreateFacilityTab;
