export const CREATE_WORKPLACE_REQUEST = "CREATE_WORKPLACE_REQUEST";
export const CREATE_WORKPLACE_SUCCESS = "CREATE_WORKPLACE_SUCCESS";
export const CREATE_WORKPLACE_FAILURE = "CREATE_WORKPLACE_FAILURE";

export const UPDATE_WORKPLACE_REQUEST = "UPDATE_WORKPLACE_REQUEST";
export const UPDATE_WORKPLACE_SUCCESS = "UPDATE_WORKPLACE_SUCCESS";
export const UPDATE_WORKPLACE_FAILURE = "UPDATE_WORKPLACE_FAILURE";

export const DELETE_WORKPLACE_REQUEST = "DELETE_WORKPLACE_REQUEST";
export const DELETE_WORKPLACE_SUCCESS = "DELETE_WORKPLACE_SUCCESS";
export const DELETE_WORKPLACE_FAILURE = "DELETE_WORKPLACE_FAILURE";

export const GET_ALL_WORKPLACE_REQUEST = "GET_ALL_WORKPLACE_REQUEST";
export const GET_ALL_WORKPLACE_SUCCESS = "GET_ALL_WORKPLACE_SUCCESS";
export const GET_ALL_WORKPLACE_FAILURE = "GET_ALL_WORKPLACE_FAILURE";

export const GET_WORKPLACE_BY_ID_REQUEST = "GET_WORKPLACE_BY_ID_REQUEST";
export const GET_WORKPLACE_BY_ID_SUCCESS = "GET_WORKPLACE_BY_ID_SUCCESS";
export const GET_WORKPLACE_BY_ID_FAILURE = "GET_WORKPLACE_BY_ID_FAILURE";

export const RESET = "RESET";
