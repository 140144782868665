import {
  GET_PRIVACY_POLICY_REQUEST,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_PRIVACY_POLICY_FAILURE,
  GET_BETATEST_POLICY_REQUEST,
  GET_BETATEST_POLICY_SUCCESS,
  GET_BETATEST_POLICY_FAILURE,
} from "../constants/policy";

export function policyReducer(state = {}, action) {
  switch (action.type) {
    case GET_PRIVACY_POLICY_REQUEST:
    case GET_BETATEST_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        privacyPolicyData: action.payload,
      };
    case GET_BETATEST_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        betaTestingData: action.payload,
      };
    case GET_PRIVACY_POLICY_FAILURE:
    case GET_BETATEST_POLICY_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
