import {
  GET_PLAYER_BY_TEAM_REQUEST,
  GET_PLAYER_BY_TEAM_SUCCESS,
  GET_PLAYER_BY_TEAM_FAILURE,
  GET_ALL_SPORTS_TEAMS_REQUEST,
  GET_ALL_SPORTS_TEAMS_SUCCESS,
  GET_ALL_SPORTS_TEAMS_FAILURE,
} from "../constants/teamSports";


export function teamSportsReducer(state = {}, action) {
    switch (action.type) {
      case GET_PLAYER_BY_TEAM_REQUEST:
      case GET_ALL_SPORTS_TEAMS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_PLAYER_BY_TEAM_SUCCESS:
        return {
          ...state,
          loading: false,
          getPlayerByTeamData: action.payload,
        };
        case GET_ALL_SPORTS_TEAMS_SUCCESS:
          return {
            ...state,
            loading:false,
            getAllSportsTeams:action.payload
          }
      case GET_PLAYER_BY_TEAM_FAILURE:
        case GET_ALL_SPORTS_TEAMS_FAILURE:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
  