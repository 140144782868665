import {
  SPORTS_PLAYBOOK_REQUEST,
  SPORTS_PLAYBOOK_SUCCESS,
  SPORTS_PLAYBOOK_FAILURE,
  SELECTED_SPORT,
  STORE_NAME_RESET,
  TEAM_NEWS_REQUEST,
  TEAM_NEWS_SUCCESS,
  TEAM_NEWS_FAILURE,
  ADD_TEAM_NEWS_REQUEST,
  ADD_TEAM_NEWS_SUCCESS,
  ADD_TEAM_NEWS_FAILURE,
  EDIT_TEAM_NEWS_REQUEST,
  EDIT_TEAM_NEWS_SUCCESS,
  EDIT_TEAM_NEWS_FAILURE,
  GET_TEAM_NEWS_BY_ID_REQUEST,
  GET_TEAM_NEWS_BY_ID_SUCCESS,
  GET_TEAM_NEWS_BY_ID_FAILURE,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
  UPDATE_USER_ID,
  UPDATE_USER,
  GET_PLAYER_BY_ID_REQUEST,
  GET_PLAYER_BY_ID_SUCCESS,
  GET_PLAYER_BY_ID_FAILURE,
  SEND_INVITATION_REQUEST,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_FAILURE,
  DELETE_TEAM_NEWS_REQUEST,
  DELETE_TEAM_NEWS_SUCCESS,
  DELETE_TEAM_NEWS_FAILURE,
  GET_SPORT_GROUND_BY_SPORT_ID_REQUEST,
  GET_SPORT_GROUND_BY_SPORT_ID_SUCCESS,
  GET_SPORT_GROUND_BY_SPORT_ID_FAILURE,
  GET_SPORTWISE_POSITIONS_REQUEST,
  GET_SPORTWISE_POSITIONS_SUCCESS,
  GET_SPORTWISE_POSITIONS_FAILURE,
  RESET_USER_ID,
} from "../constants/sports";

const initialState = {};

export function sportsReducer(state = {}, action) {
  switch (action.type) {
    case SPORTS_PLAYBOOK_REQUEST:
    case TEAM_NEWS_REQUEST:
    case ADD_TEAM_NEWS_REQUEST:
    case EDIT_TEAM_NEWS_REQUEST:
    case GET_TEAM_NEWS_BY_ID_REQUEST:
    case DELETE_TEAM_REQUEST:
    case GET_PLAYER_BY_ID_REQUEST:
    case SEND_INVITATION_REQUEST:
    case DELETE_TEAM_NEWS_REQUEST:
    case GET_SPORT_GROUND_BY_SPORT_ID_REQUEST:
    case GET_SPORTWISE_POSITIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SPORTS_PLAYBOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        sportsPlaybookData: action.payload,
      };
    case SELECTED_SPORT:
      return {
        ...state,
        loading: false,
        selectedSport: action.payload,
      };
    case TEAM_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        teamNewsListData: action.payload,
      };
    case ADD_TEAM_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        addTeamNewsResData: action.payload,
      };
    case EDIT_TEAM_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        editTeamNewsData: action.payload,
      };
    case DELETE_TEAM_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteTeamNewsData: action.payload,
      };
    case GET_TEAM_NEWS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        teamNewsByIdData: action.payload,
      };

    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteTeamData: action.payload,
      };
    case UPDATE_USER_ID:
      return {
        ...state,
        editUserId: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        editUserData: action.payload,
      };
    case GET_PLAYER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        playerOrManagerData: action.payload,
      };

    case SEND_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        sendInvitationsData: action.payload,
      };
    case GET_SPORT_GROUND_BY_SPORT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        sportsGroundBySportsIdData: action.payload,
      };
    case GET_SPORTWISE_POSITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        sportwisePositionsData: action.payload,
      };
    case GET_SPORTWISE_POSITIONS_FAILURE:
    case SPORTS_PLAYBOOK_FAILURE:
    case TEAM_NEWS_FAILURE:
    case ADD_TEAM_NEWS_FAILURE:
    case EDIT_TEAM_NEWS_FAILURE:
    case GET_TEAM_NEWS_BY_ID_FAILURE:
    case DELETE_TEAM_FAILURE:
    case GET_PLAYER_BY_ID_FAILURE:
    case SEND_INVITATION_FAILURE:
    case DELETE_TEAM_NEWS_FAILURE:
    case GET_SPORT_GROUND_BY_SPORT_ID_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_USER_ID:
      return {
        ...state,
        editUserId: {},
      };
    case STORE_NAME_RESET:
      return initialState;
    default:
      return state;
  }
}
