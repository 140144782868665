export const CREATE_PERSONAL_EVENT_REQUEST = "CREATE_PERSONAL_EVENT_REQUEST";
export const CREATE_PERSONAL_EVENT_SUCCESS = "CREATE_PERSONAL_EVENT_SUCCESS";
export const CREATE_PERSONAL_EVENT_FAILURE = "CREATE_PERSONAL_EVENT_FAILURE";

export const GET_ALL_PERSONAL_EVENTS_REQUEST =
  "GET_ALL_PERSONAL_EVENTS_REQUEST";
export const GET_ALL_PERSONAL_EVENTS_SUCCESS =
  "GET_ALL_PERSONAL_EVENTS_SUCCESS";
export const GET_ALL_PERSONAL_EVENTS_FAILURE =
  "GET_ALL_PERSONAL_EVENTS_FAILURE";

export const GET_ALL_PERSONAL_EVENTS_BY_EVENTID_REQUEST =
  "GET_ALL_PERSONAL_EVENTS_BY_EVENTID_REQUEST";
export const GET_ALL_PERSONAL_EVENTS_BY_EVENTID_SUCCESS =
  "GET_ALL_PERSONAL_EVENTS_BY_EVENTID_SUCCESS";
export const GET_ALL_PERSONAL_EVENTS_BY_EVENTID_FAILURE =
  "GET_ALL_PERSONAL_EVENTS_BY_EVENTID_FAILURE";

export const EDIT_PERSONAL_EVENT_REQUEST = "EDIT_PERSONAL_EVENT_REQUEST";
export const EDIT_PERSONAL_EVENT_SUCCESS = "EDIT_PERSONAL_EVENT_SUCCESS";
export const EDIT_PERSONAL_EVENT_FAILURE = "EDIT_PERSONAL_EVENT_FAILURE";


export const REMOVE_PARTICULAR_PERSONAL_EVENT_REQUEST = "REMOVE_PARTICULAR_PERSONAL_EVENT_REQUEST";
export const REMOVE_PARTICULAR_PERSONAL_EVENT_SUCCESS = "REMOVE_PARTICULAR_PERSONAL_EVENT_SUCCESS";
export const REMOVE_PARTICULAR_PERSONAL_EVENT_FAILURE = "REMOVE_PARTICULAR_PERSONAL_EVENT_FAILURE";


export const REMOVE_ENTIRE_PERSONAL_EVENT_REQUEST = "REMOVE_ENTIRE_PERSONAL_EVENT_REQUEST";
export const REMOVE_ENTIRE_PERSONAL_EVENT_SUCCESS = "REMOVE_ENTIRE_PERSONAL_EVENT_SUCCESS";
export const REMOVE_ENTIRE_PERSONAL_EVENT_FAILURE = "REMOVE_ENTIRE_PERSONAL_EVENT_FAILURE";

export const RESET_STATE = "RESET_STATE";
