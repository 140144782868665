import {
  GET_ALL_NOTIFICATIONS_REQUEST,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATION_COUNT_REQUEST,
  GET_NOTIFICATION_COUNT_SUCCESS,
  GET_NOTIFICATION_COUNT_FAILURE,
  SET_NOTIFICATION_SEEN_REQUEST,
  SET_NOTIFICATION_SEEN_SUCCESS,
  SET_NOTIFICATION_SEEN_FAILURE,
  RESET,
  NOTIFICATION_INVITATION_REQUEST,
  NOTIFICATION_INVITATION_SUCCESS,
  NOTIFICATION_INVITATION_FAILURE,
  GET_MESSAGE_BOARD_NOTIFICATION_COUNT_REQUEST,
  GET_MESSAGE_BOARD_NOTIFICATION_COUNT_SUCCESS,
  GET_MESSAGE_BOARD_NOTIFICATION_COUNT_FAILURE,
  GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_REQUEST,
  GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_SUCCESS,
  GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_FAILURE,
  GET_GROUP_COUNT_REQUEST,
  GET_GROUP_COUNT_SUCCESS,
  GET_GROUP_COUNT_FAILURE,
} from "../constants/notifications";
import axios from "axios";

export const GetAllNotificationList = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_NOTIFICATIONS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/notification/getNotification`,
      values
    );
    dispatch({
      type: GET_ALL_NOTIFICATIONS_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_NOTIFICATIONS_FAILURE });
  }
};

export const GetNotificationCount = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_NOTIFICATION_COUNT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/notification/notificationCount`,
      values
    );
    dispatch({
      type: GET_NOTIFICATION_COUNT_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_NOTIFICATION_COUNT_FAILURE });
  }
};

export const SetNotificationSeen = (values) => async (dispatch) => {
  try {
    dispatch({ type: SET_NOTIFICATION_SEEN_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/notification/seenNotification`,
      values
    );
    dispatch({
      type: SET_NOTIFICATION_SEEN_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: SET_NOTIFICATION_SEEN_FAILURE });
  }
};

export const AcceptDeclineRequest = (values) => async (dispatch) => {
  try {
    dispatch({ type: NOTIFICATION_INVITATION_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/acceptAndDeclineRequest`,
      values
    );
    dispatch({
      type: NOTIFICATION_INVITATION_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: NOTIFICATION_INVITATION_FAILURE });
  }
};

export const ResetNotifications = () => async (dispatch) => {
  dispatch({ type: RESET });
};

export const GetMessageBoardNotificationCount =
  (values) => async (dispatch) => {
    try {
      dispatch({ type: GET_MESSAGE_BOARD_NOTIFICATION_COUNT_REQUEST });
      const resData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/notification/notificationCountMessageBoard`,
        values
      );
      dispatch({
        type: GET_MESSAGE_BOARD_NOTIFICATION_COUNT_SUCCESS,
        payload: resData.data,
      });
    } catch (err) {
      dispatch({ type: GET_MESSAGE_BOARD_NOTIFICATION_COUNT_FAILURE });
    }
  };

export const GetTeamMsgBoardNotificationCount =
  (values) => async (dispatch) => {
    try {
      dispatch({ type: GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_REQUEST });
      const resData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/notification/notificationCountMessageBoard`,
        values
      );
      dispatch({
        type: GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_SUCCESS,
        payload: resData.data,
      });
    } catch (err) {
      dispatch({ type: GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_FAILURE });
    }
  };

export const GetGroupNotificationCount = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_GROUP_COUNT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/notification/groupNotificationCount`,
      values
    );
    dispatch({
      type: GET_GROUP_COUNT_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_GROUP_COUNT_FAILURE });
  }
};
