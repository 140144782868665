import React from "react";

const StatisticsSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.804"
      height="16.89"
      viewBox="0 0 19.804 16.89"
    >
      <path
        id="statsitcs"
        d="M2880.558,606.219c.242-.2.439-.363.635-.526,1.159-.966,2.313-1.938,3.483-2.89a1.567,1.567,0,0,1,.656-.274.731.731,0,0,1,.673.262q1.562,1.575,3.134,3.139c.031.032.067.059.114.1.263-.229.52-.453.777-.677l6.326-5.546a.938.938,0,0,1,1.319.031.894.894,0,0,1,.109,1.238,1.549,1.549,0,0,1-.247.267q-3.809,3.336-7.622,6.669a1.121,1.121,0,0,1-1.584-.058q-1.5-1.5-2.99-2.992a2.2,2.2,0,0,1-.165-.212l-1.559,1.292c-.961.8-1.921,1.6-2.886,2.392a.423.423,0,0,0-.177.365c.007,1.074,0,2.149,0,3.223v.256h16.521a.979.979,0,1,1-.007,1.954h-17.4a.933.933,0,0,1-1.057-1.052q0-1.92,0-3.841,0-5.277,0-10.553a1.935,1.935,0,0,1,.035-.453.919.919,0,0,1,1.027-.681.9.9,0,0,1,.873.862c.008.106.007.213.007.32q0,3.532,0,7.064Z"
        transform="translate(-2878.463 -597.494)"
        stroke="#fff"
        strokeWidth="0.3"
      />
    </svg>
  );
};
export default StatisticsSVG;
