import React from "react";
// import logo from "../../../assets/img/logo.png"

function Loader() {
  return (
    <div className="loader">
      <img src={"/assets/img/loader.png"} className="img-fluid" alt="loader logo" width="" height="" title="loader logo"/>
    </div>
  );
}

export default Loader;
