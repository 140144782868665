import {
  GET_ALL_ROOM_REQUEST,
  GET_ALL_ROOM_SUCCESS,
  GET_ALL_ROOM_FAILURE,
  // CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAILURE,
  GET_ALL_CHAT_BY_ROOM_REQUEST,
  GET_ALL_CHAT_BY_ROOM_SUCCESS,
  GET_ALL_CHAT_BY_ROOM_FAILURE,
  GET_ALL_MEDIA_BY_ROOM_REQUEST,
  GET_ALL_MEDIA_BY_ROOM_SUCCESS,
  GET_ALL_MEDIA_BY_ROOM_FAILURE,
  GET_ALL_CHAT_GROUP_MEMBER_REQUEST,
  GET_ALL_CHAT_GROUP_MEMBER_SUCCESS,
  GET_ALL_CHAT_GROUP_MEMBER_FAILURE,
  UPDATE_CHAT_SEEN_MESSAGES_REQUEST,
  UPDATE_CHAT_SEEN_MESSAGES_SUCCESS,
  UPDATE_CHAT_SEEN_MESSAGES_FAILURE,
  GET_ALL_CHAT_BY_ROOMID_REQUEST,
  GET_ALL_CHAT_BY_ROOMID_SUCCESS,
  GET_ALL_CHAT_CHAT_BY_ROOMID_FAILURE,
  GET_CHAT_USER_FOLLOWER_REQUEST,
  GET_CHAT_USER_FOLLOWER_SUCCESS,
  GET_CHAT_USER_FOLLOWER_FAILURE,
  GET_ALL_UNSEEN_COUNT_MESSAGE_REQUEST,
  GET_ALL_UNSEEN_COUNT_MESSAGE_SUCCESS,
  GET_ALL_UNSEEN_COUNT_MESSAGE_FAILURE,
} from "../constants/chat";
import axios from "axios";

export const GetAllRoomByUser = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_ROOM_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/chatUserManagement/getAllChatDataByiUserId`,
      {
        iUserId: sessionStorage.getItem("id"),
      }
    );
    dispatch({ type: GET_ALL_ROOM_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_ROOM_FAILURE });
  }
};

export const CreateMessage = (values) => async (dispatch) => {
  try {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    // dispatch({ type: CREATE_MESSAGE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Chat/createChat`,
      values,
      config
    );
    dispatch({ type: CREATE_MESSAGE_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: CREATE_MESSAGE_FAILURE });
  }
};

export const GetAllChatByRoom = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_CHAT_BY_ROOM_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Chat/getAllChatData`,
      values
    );
    dispatch({ type: GET_ALL_CHAT_BY_ROOM_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_CHAT_BY_ROOM_FAILURE });
  }
};

export const GetAllChatGroupMember = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_CHAT_GROUP_MEMBER_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/getAllChatGroupMemberData`,
      values
    );
    dispatch({
      type: GET_ALL_CHAT_GROUP_MEMBER_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_CHAT_GROUP_MEMBER_FAILURE });
  }
};
export const GetAllMediaByRoom = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_MEDIA_BY_ROOM_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Chat/getAllChatImageData`,
      values
    );
    dispatch({ type: GET_ALL_MEDIA_BY_ROOM_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_MEDIA_BY_ROOM_FAILURE });
  }
};
export const updateSeenMessages = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CHAT_SEEN_MESSAGES_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Chat/UnseenUpdateToSeen`,
      values
    );
    dispatch({
      type: UPDATE_CHAT_SEEN_MESSAGES_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: UPDATE_CHAT_SEEN_MESSAGES_FAILURE });
  }
};

export const getAllDataByRoomId = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_CHAT_BY_ROOMID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Chat/getAllOneToOneChatList`,
      values
    );
    dispatch({ type: GET_ALL_CHAT_BY_ROOMID_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_CHAT_CHAT_BY_ROOMID_FAILURE });
  }
};

export const getAllFollowerChatData = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_CHAT_USER_FOLLOWER_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Chat/getAllUnseenChatCount`,
      values
    );
    dispatch({ type: GET_CHAT_USER_FOLLOWER_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_CHAT_USER_FOLLOWER_FAILURE });
  }
};

export const getAllUnseenMessagesCount = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_UNSEEN_COUNT_MESSAGE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Chat/UnseenMeesageTotalCount`,
      values
    );
    dispatch({
      type: GET_ALL_UNSEEN_COUNT_MESSAGE_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_UNSEEN_COUNT_MESSAGE_FAILURE });
  }
};
