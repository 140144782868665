import { PLAYBOOK_ACTIVE_TAB_INDEX } from "../constants/playbookTab";

export function PlaybookTab(state = {playbookTabIndex : 0}, action) {
  switch (action.type) {
    case PLAYBOOK_ACTIVE_TAB_INDEX:
      return {
        ...state,
        loading: false,
        playbookTabIndex: action.payload,
      };
    default:
      return state;
  }
}
