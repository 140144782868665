import React from "react";
const CustomerServiceSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 289.47 316.11">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M154.63,0c6.76,1.21,13.69,1.8,20.23,3.73C219.8,17,247.34,46.61,256.29,92.62c2.12,10.87,1.37,22.3,1.92,33.48.07,1.41,0,2.83,0,4.16,20.16,4.15,31.07,17.61,31.2,38.35.09,15.22,0,30.45,0,45.67,0,16.5-8.47,26.31-24.85,28.67a5.12,5.12,0,0,0-4.1,2.63c-7.35,12-16.7,22.11-29.08,29.17A78.33,78.33,0,0,1,195,285.07c-.62,0-1.24.05-1.85.11a1.86,1.86,0,0,0-.56.22c-5.81,20.41-23,32.9-41.86,30.39-20.06-2.67-35-19.08-34.94-38.4,0-19.54,14.47-35.72,34.56-38.27,17.33-2.21,36.94,8.65,42.08,30.23,14.35,3.16,41.15-10.16,50.21-25.36-2.61,0-4.88,0-7.15,0-5.91,0-8.63-2.79-8.63-8.72q0-46.9,0-93.8a18.88,18.88,0,0,1,.73-5.46c1.12-3.68,3.49-5.27,7.33-5.31,2.35,0,4.7,0,6.85,0,.31-.45.51-.61.52-.77,1.42-25.18-.7-49.65-15.64-71.16-20.83-30-49.58-44.9-86.1-42.71C94.47,18.81,57.91,53.14,51.86,98.76c-1.36,10.34-.9,20.92-1.31,31.93H58c4.81,0,7.63,2.44,8.31,7.24a31,31,0,0,1,.17,4.31v92.88c0,6.26-2.53,8.86-8.75,8.86-9.67,0-19.35.25-29-.18C13.38,243.12.63,230.43.28,215c-.42-18-.33-36,0-54,.3-15.11,13.07-31.14,31.7-30.36.92,0,1.83,0,3.28,0,0-1.51,0-2.8,0-4.09.1-12-.57-24.07,2-36,8.32-39.18,31-66.63,67.67-82.47A100.5,100.5,0,0,1,136.85.49,8.94,8.94,0,0,0,138.58,0ZM50.81,146.27H32.94c-10.43,0-17.12,6.53-17.18,17-.09,16.64-.06,33.29,0,49.93,0,7.2,4.66,13.71,11.75,14.51,7.65.86,15.48.2,23.31.2Zm191.83,82c6.38,0,12.45.3,18.48-.07,8.71-.54,12.66-5.06,12.66-13.75q0-23.28,0-46.55c0-5.74-1.45-11.13-5.07-15.5-2.05-2.47-5.05-5.26-8-5.7-5.88-.88-12-.26-18.12-.26Zm-65.08,49.53c-.1-13.5-10.11-23.67-23.23-23.58A23,23,0,0,0,131.5,277c0,12.56,10.93,23.41,23.52,23.38A22.65,22.65,0,0,0,177.56,277.82Z" />
        </g>
      </g>
    </svg>
  );
};
export default CustomerServiceSVG;
