export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILURE = "PROFILE_FAILURE";

export const AUTH_PROFILE_REQUEST = "AUTH_PROFILE_REQUEST";
export const AUTH_PROFILE_SUCCESS = "AUTH_PROFILE_SUCCESS";
export const AUTH_PROFILE_FAILURE = "AUTH_PROFILE_FAILURE";

export const FOLLOWER_AUTH_PROFILE_REQUEST = "FOLLOWER_AUTH_PROFILE_REQUEST";
export const FOLLOWER_AUTH_PROFILE_SUCCESS = "FOLLOWER_AUTH_PROFILE_SUCCESS";
export const FOLLOWER_AUTH_PROFILE_FAILURE = "FOLLOWER_AUTH_PROFILE_FAILURE";

export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAILURE = "PROFILE_UPDATE_FAILURE";
export const CLEAR_PROFILE_UPDATE_DATA = "CLEAR_PROFILE_UPDATE_DATA";

export const USER_ROLE_REQUEST = "USER_ROLE_REQUEST";
export const USER_ROLE_SUCCESS = "USER_ROLE_SUCCESS";
export const USER_ROLE_FAILURE = "USER_ROLE_FAILURE";

export const SPORTS_LIST_REQUEST = "SPORTS_LIST_REQUEST";
export const SPORTS_LIST_SUCCESS = "SPORTS_LIST_SUCCESS";
export const SPORTS_LIST_FAILURE = "SPORTS_LIST_FAILURE";

export const COUNTRY_LIST_REQUEST = "COUNTRY_LIST_REQUEST";
export const COUNTRY_LIST_SUCCESS = "COUNTRY_LIST_SUCCESS";
export const COUNTRY_LIST_FAILURE = "COUNTRY_LIST_FAILURE";

export const TEAM_TYPE_REQUEST = "TEAM_TYPE_REQUEST";
export const TEAM_TYPE_SUCCESS = "TEAM_TYPE_SUCCESS";
export const TEAM_TYPE_FAILURE = "TEAM_TYPE_FAILURE";

export const SEASONS_REQUEST = "SEASONS_REQUEST";
export const SEASONS_SUCCESS = "SEASONS_SUCCESS";
export const SEASONS_FAILURE = "SEASONS_FAILURE";

export const STATE_LIST_REQUEST = "STATE_LIST_REQUEST";
export const STATE_LIST_SUCCESS = "STATE_LIST_SUCCESS";
export const STATE_LIST_FAILURE = "STATE_LIST_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const VERIFY_EMAIL_CODE_REQUEST = "VERIFY_EMAIL_CODE_REQUEST";
export const VERIFY_EMAIL_CODE_SUCCESS = "VERIFY_EMAIL_CODE_SUCCESS";
export const VERIFY_EMAIL_CODE_FAILURE = "VERIFY_EMAIL_CODE_FAILURE";

export const EMAIL_CHECK_REQUEST = "EMAIL_CHECK_REQUEST";
export const EMAIL_CHECK_SUCCESS = "EMAIL_CHECK_SUCCESS";
export const EMAIL_CHECK_FAILURE = "EMAIL_CHECK_FAILURE";

export const STORE_NAME_RESET = "STORE_NAME_RESET";

export const PROFILE_BANNER_UPDATE_REQUEST = "PROFILE_BANNER_UPDATE_REQUEST";
export const PROFILE_BANNER_UPDATE_SUCCESS = "PROFILE_BANNER_UPDATE_SUCCESS";
export const PROFILE_BANNER_UPDATE_FAILURE = "PROFILE_BANNER_UPDATE_FAILURE";

export const PROFILE_BY_AUTHCODE_REQUEST = "PROFILE_BY_AUTHCODE_REQUEST";
export const PROFILE_BY_AUTHCODE_SUCCESS = "PROFILE_BY_AUTHCODE_SUCCESS";
export const PROFILE_BY_AUTHCODE_FAILURE = "PROFILE_BY_AUTHCODE_FAILURE";

export const VERIFY_PERSONAL_USER_SLUG_REQUEST =
  "VERIFY_PERSONAL_USER_SLUG_REQUEST";
export const VERIFY_PERSONAL_USER_SLUG_SUCCESS =
  "VERIFY_PERSONAL_USER_SLUG_SUCCESS";
export const VERIFY_PERSONAL_USER_SLUG_FAILURE =
  "VERIFY_PERSONAL_USER_SLUG_FAILURE";

export const VERIFY_BUSINESS_USER_SLUG_REQUEST =
  "VERIFY_BUSINESS_USER_SLUG_REQUEST";
export const VERIFY_BUSINESS_USER_SLUG_SUCCESS =
  "VERIFY_BUSINESS_USER_SLUG_SUCCESS";
export const VERIFY_BUSINESS_USER_SLUG_FAILURE =
  "VERIFY_BUSINESS_USER_SLUG_FAILURE";

export const PROFILE_IROLEID = "PROFILE_IROLEID";

export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

export const OTP_VERIFY_REQUEST = "OTP_VERIFY_REQUEST";
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_FAILURE = "OTP_VERIFY_FAILURE";

export const SEND_2STEP_CODE_REQUEST = "SEND_2STEP_CODE_REQUEST";
export const SEND_2STEP_CODE_SUCCESS = "SEND_2STEP_CODE_SUCCESS";
export const SEND_2STEP_CODE_FAILURE = "SEND_2STEP_CODE_FAILURE";

export const MOBILE_CHECK_REQUEST = "MOBILE_CHECK_REQUEST";
export const MOBILE_CHECK_SUCCESS = "MOBILE_CHECK_SUCCESS";
export const MOBILE_CHECK_FAILURE = "MOBILE_CHECK_FAILURE";

export const BLOCK_USER_REQUEST = "BLOCK_USER_REQUEST";
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS";
export const BLOCK_USER_FAILURE = "BLOCK_USER_FAILURE";

export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const USER_PASSWORD_CHECK_REQUEST = "USER_PASSWORD_CHECK_REQUEST";
export const USER_PASSWORD_CHECK_SUCCESS = "USER_PASSWORD_CHECK_SUCCESS";
export const USER_PASSWORD_CHECK_FAILURE = "USER_PASSWORD_CHECK_FAILURE";
