import {
  BANNER_REQUEST,
  BANNER_SUCCESS,
  BANNER_FAILURE,
  TEAM_BANNER_UPDATE_REQUEST,
  TEAM_BANNER_UPDATE_SUCCESS,
  TEAM_BANNER_UPDATE_FAILURE,
} from "./../constants/banner";

export function bannerReducer(state = {}, action) {
  switch (action.type) {
    case BANNER_REQUEST:
    case TEAM_BANNER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        bannerData: action.payload,
      };
    case TEAM_BANNER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateBannerData: action.payload,
      };
    case BANNER_FAILURE:
    case TEAM_BANNER_UPDATE_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
