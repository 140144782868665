import {
  TEAM_NEWS_REQUEST,
  TEAM_NEWS_SUCCESS,
  TEAM_NEWS_FAILURE,
  ADD_TEAM_NEWS_REQUEST,
  ADD_TEAM_NEWS_SUCCESS,
  ADD_TEAM_NEWS_FAILURE,
  EDIT_TEAM_NEWS_REQUEST,
  EDIT_TEAM_NEWS_SUCCESS,
  EDIT_TEAM_NEWS_FAILURE,
  DELETE_TEAM_NEWS_REQUEST,
  DELETE_TEAM_NEWS_SUCCESS,
  DELETE_TEAM_NEWS_FAILURE,
  GET_TEAM_NEWS_BY_ID_REQUEST,
  GET_TEAM_NEWS_BY_ID_SUCCESS,
  GET_TEAM_NEWS_BY_ID_FAILURE,
} from "../constants/teamNews";

export function teamNewsReducer(state = {}, action) {
  switch (action.type) {
    case TEAM_NEWS_REQUEST:
    case ADD_TEAM_NEWS_REQUEST:
    case EDIT_TEAM_NEWS_REQUEST:
    case GET_TEAM_NEWS_BY_ID_REQUEST:
    case DELETE_TEAM_NEWS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TEAM_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        teamNewsListData: action.payload,
      };
    case ADD_TEAM_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        addTeamNewsResData: action.payload,
      };
    case EDIT_TEAM_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateTeamNewsData: action.payload,
      };
    case DELETE_TEAM_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteTeamNewsData: action.payload,
      };
    case GET_TEAM_NEWS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        teamNewsByIdData: action.payload,
      };

    case TEAM_NEWS_FAILURE:
    case ADD_TEAM_NEWS_FAILURE:
    case EDIT_TEAM_NEWS_FAILURE:
    case GET_TEAM_NEWS_BY_ID_FAILURE:
    case DELETE_TEAM_NEWS_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
