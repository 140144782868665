import axios from "axios";
import {
  CREATE_BUSINESS_PROFILE_DATA_FAILURE,
  CREATE_BUSINESS_PROFILE_DATA_REQUEST,
  CREATE_BUSINESS_PROFILE_DATA_SUCCESS,
  GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_FAILURE,
  GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_REQUEST,
  GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_SUCCESS,
  RESET_STATE,
  UPDATE_BUSINESS_PROFILE_DATA_FAILURE,
  UPDATE_BUSINESS_PROFILE_DATA_REQUEST,
  UPDATE_BUSINESS_PROFILE_DATA_SUCCESS,
} from "../constants/business";

export const CreateBusinessProfile = (values) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_BUSINESS_PROFILE_DATA_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/businessProfileTrans/createBusinessProfile`,
      values
    );
    dispatch({
      type: CREATE_BUSINESS_PROFILE_DATA_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: CREATE_BUSINESS_PROFILE_DATA_FAILURE });
  }
};

export const GetBusinessProfileByTransId = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/businessProfileTrans/getDataByBusinessProfileTransId`,
      values
    );
    dispatch({
      type: GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_BUSINESS_PROFILE_BY_TRANS_ID_DATA_FAILURE });
  }
};
export const UpdateBusinessProfile = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BUSINESS_PROFILE_DATA_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/businessProfileTrans/updateBusinessProfile`,
      values
    );
    dispatch({
      type: UPDATE_BUSINESS_PROFILE_DATA_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: UPDATE_BUSINESS_PROFILE_DATA_FAILURE });
  }
};

export const BusinessResetState = () => async (dispatch) => {
  dispatch({ type: RESET_STATE });
};
