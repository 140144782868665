import {
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILURE,
  GET_ALL_POST_REQUEST,
  GET_ALL_POST_SUCCESS,
  GET_ALL_POST_FAILURE,
  GET_ALL_POST_BY_USERID_REQUEST,
  GET_ALL_POST_BY_USERID_SUCCESS,
  GET_ALL_POST_BY_USERID_FAILURE,
  EDIT_POST_REQUEST,
  EDIT_POST_SUCCESS,
  EDIT_POST_FAILURE,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILURE,
  RESET_CREATE_POST_VALUE,
  ADD_LOVE_IT_POST_REQUEST,
  ADD_LOVE_IT_POST_SUCCESS,
  ADD_LOVE_IT_POST_FAILURE,
  ADD_LOVE_IT_POST_CLEAR_DATA,
  GET_POST_ID,
  SINGLE_POST_ALL_LIKES_REQUEST,
  SINGLE_POST_ALL_LIKES_SUCCESS,
  SINGLE_POST_ALL_LIKES_FAILURE,
  HIDE_POST_FAILURE,
  HIDE_POST_REQUEST,
  HIDE_POST_SUCCESS,
  VIEW_MORE_COMMENT_TIME_POST_DATA_REQUEST,
  VIEW_MORE_COMMENT_TIME_POST_DATA_SUCCESS,
  VIEW_MORE_COMMENT_TIME_POST_DATA_FAILURE,
  VIEW_MORET_TIME_POST_ID,
  DELETE_POST_RESET,
  GET_ALL_REPORT_REQUEST,
  GET_ALL_REPORT_FAILURE,
  GET_ALL_REPORT_SUCCESS,
  HIDE_POST_RESET,
  CREATE_REPORT_POST_REQUEST,
  CREATE_REPORT_POST_SUCCESS,
  CREATE_REPORT_POST_FAILURE,
  VIEW_MORE_COMMENT_TIME_POST_DATA_RESET_DATA,
  GET_ALL_AUTH_POST_BY_USERID_REQUEST,
  GET_ALL_AUTH_POST_BY_USERID_SUCCESS,
  GET_ALL_AUTH_POST_BY_USERID_FAILURE,
  GET_IMAGE_URL_REQUEST,
  GET_IMAGE_URL_SUCCESS,
  RESET_IMAGE_URL_DATA,
  POST_TAGGED_PEOPLE_LIST_REQUEST,
  POST_TAGGED_PEOPLE_LIST_SUCCESS,
  POST_TAGGED_PEOPLE_LIST_FAILURE,
  POST_TAGGED_PEOPLE_LIST_CLEAR_DATA,
  GET_ALL_TEAM_POST_SUCCESS,
  GET_ALL_TEAM_POST_REQUEST,
  GET_ALL_TEAM_POST_WITHOUT_LOGIN_REQUEST,
  GET_ALL_TEAM_POST_WITHOUT_LOGIN_SUCCESS,
  GET_ALL_TEAM_POST_WITHOUT_LOGIN_FAILURE,
  TEMPORARY_STORE_POST_DATA_REQUEST,
  TEMPORARY_STORE_POST_DATA_SUCCESS,
  TEMPORARY_STORE_POST_DATA_FAILURE,
  TEMPORARY_STORE_POST_CLEAR_DATA,
  TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_REQUEST,
  TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_SUCCESS,
  TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_FAILURE,
  TEMPORARY_STORE_DELETED_POST_COMMENT_CLEAR_DATA,
  TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_COMMENT_DATA_REQUEST,
  TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_DATA_SUCCESS,
  TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_DATA_FAILURE,
  TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_CLEAR_DATA,
  TEMPORARY_STORE_USER_POST_LIKE_DATA_REQUEST,
  TEMPORARY_STORE_USER_POST_LIKE_DATA_SUCCESS,
  TEMPORARY_STORE_USER_POST_LIKE_DATA_FAILURE,
  TEMPORARY_STORE_USER_POST_LIKE_CLEAR_DATA,
  TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_REQUEST,
  TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_SUCCESS,
  TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_FAILURE,
  TEMPORARY_STORE_POST_CLEAR_COMMENT_COUNT_DATA,
  POST_LIST,
  COMMENT_REPORT_POST_LIST,
  COMMENT_REPORT_POST_LIST_RESET,
  HIDE_COMMENT_USERS_LIST,
  HIDE_COMMENT_USERS_LIST_RESET,
} from "../constants/post";

export function postReducer(
  state = {
    teamporaryStorePostData: [],
    viewMoreTimePostData: {},
    temporaryStorePostDeletedCommentData: [],
    temporaryStorePostHideAndUnHideCommentData: [],
    temporaryStoreUserLikedPostsData: [],
    temporaryStoreUserPostCommentCountData: [],
  },
  action
) {
  switch (action.type) {
    case CREATE_POST_REQUEST:
    case GET_ALL_POST_REQUEST:
    case GET_ALL_REPORT_REQUEST:
    case CREATE_REPORT_POST_REQUEST:
    case GET_ALL_POST_BY_USERID_REQUEST:
    case GET_ALL_AUTH_POST_BY_USERID_REQUEST:
    case HIDE_POST_REQUEST:
    case EDIT_POST_REQUEST:
    case DELETE_POST_REQUEST:
    case ADD_LOVE_IT_POST_REQUEST:
    case SINGLE_POST_ALL_LIKES_REQUEST:
    case VIEW_MORE_COMMENT_TIME_POST_DATA_REQUEST:
    case GET_IMAGE_URL_REQUEST:
    case POST_TAGGED_PEOPLE_LIST_REQUEST:
    case GET_ALL_TEAM_POST_REQUEST:
    case GET_ALL_TEAM_POST_WITHOUT_LOGIN_REQUEST:
    case TEMPORARY_STORE_POST_DATA_REQUEST:
    case TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_REQUEST:
    case TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_COMMENT_DATA_REQUEST:
    case TEMPORARY_STORE_USER_POST_LIKE_DATA_REQUEST:
    case TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_LIST:
      return {
        ...state,
        loading: false,
        postList: action.payload,
      };
    case CREATE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        createPostData: action.payload,
      };
    case EDIT_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        editPostData: action.payload,
      };
    case DELETE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        deletePostData: action.payload,
      };

    case GET_ALL_POST_BY_USERID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllPostByUserData: action.payload,
      };
    case GET_ALL_TEAM_POST_WITHOUT_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        getTeamDataByIdData: action.payload,
      };
    case GET_IMAGE_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        getImgUrlData: action.payload,
      };
    case GET_ALL_AUTH_POST_BY_USERID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllAuthPostByUserData: action.payload,
      };
    case GET_ALL_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllPostDataData: action.payload,
      };
    case GET_ALL_TEAM_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllTeamPostData: action.payload,
      };
    case GET_ALL_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllReportData: action.payload,
      };
    case CREATE_REPORT_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        createReportPostData: action.payload,
      };
    case HIDE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        hidePostData: action.payload,
      };
    case ADD_LOVE_IT_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        postLoveItData: action.payload,
      };
    case SINGLE_POST_ALL_LIKES_SUCCESS:
      return {
        ...state,
        loading: false,
        singlePostAllLikesData: action.payload,
      };

    case VIEW_MORE_COMMENT_TIME_POST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        viewMoreTimePostData: action.payload,
      };

    case GET_POST_ID:
      return {
        postId: action.payload,
      };

    case VIEW_MORET_TIME_POST_ID:
      return {
        ...state,
        viewMoreTimePostId: action.payload,
      };

    case POST_TAGGED_PEOPLE_LIST_SUCCESS:
      return {
        ...state,
        postTimeTaggedPeopleData: action.payload,
      };

    case POST_TAGGED_PEOPLE_LIST_CLEAR_DATA:
      return {
        ...state,
        postTimeTaggedPeopleData: [],
      };

    case TEMPORARY_STORE_POST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        teamporaryStorePostData: [
          action.payload,
          ...state.teamporaryStorePostData.filter(
            (res) => res.postId !== action.payload?.postId
          ),
        ],
      };

    case COMMENT_REPORT_POST_LIST:
      return {
        ...state,
        loading: false,
        commentReportData: action.payload,
      };

    case HIDE_COMMENT_USERS_LIST:
      return {
        ...state,
        loading: false,
        hideCommentUsersData: action.payload,
      };
    case HIDE_COMMENT_USERS_LIST_RESET:
      return {
        ...state,
        loading: false,
        hideCommentUsersData: undefined,
      };

    case COMMENT_REPORT_POST_LIST_RESET:
      return {
        ...state,
        loading: false,
        commentReportData: {},
      };

    case TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        temporaryStorePostDeletedCommentData: [
          action.payload,
          // ...state.temporaryStorePostDeletedCommentData.filter(res => action?.payload?.removeCommentOrReplay === "DirectComment" ? res.iPostCommentedId !== action.payload?.iPostCommentedId : res),
          ...state.temporaryStorePostDeletedCommentData,
        ],
      };

    case TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        temporaryStorePostHideAndUnHideCommentData: [
          action.payload,
          ...state.temporaryStorePostHideAndUnHideCommentData.filter(
            (res) => parseInt(res.postId) !== parseInt(action.payload?.postId)
          ),
        ],
      };

    case TEMPORARY_STORE_USER_POST_LIKE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        temporaryStoreUserLikedPostsData: [
          action.payload,
          ...state.temporaryStoreUserLikedPostsData.filter(
            (res) => parseInt(res.iPostId) !== parseInt(action.payload.iPostId)
          ),
        ],
      };
    case TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        temporaryStoreUserPostCommentCountData: [
          action.payload,
          ...state.temporaryStoreUserPostCommentCountData.filter(
            (res) => parseInt(res.iPostId) !== parseInt(action.payload.iPostId)
          ),
        ],
      };

    case VIEW_MORE_COMMENT_TIME_POST_DATA_RESET_DATA:
      return {
        ...state,
        loading: false,
        viewMoreTimePostData: {},
      };

    case GET_ALL_POST_BY_USERID_FAILURE:
    case CREATE_POST_FAILURE:
    case GET_ALL_POST_FAILURE:
    case GET_ALL_REPORT_FAILURE:
    case CREATE_REPORT_POST_FAILURE:
    case EDIT_POST_FAILURE:
    case DELETE_POST_FAILURE:
    case ADD_LOVE_IT_POST_FAILURE:
    case SINGLE_POST_ALL_LIKES_FAILURE:
    case GET_ALL_AUTH_POST_BY_USERID_FAILURE:
    case HIDE_POST_FAILURE:
    case POST_TAGGED_PEOPLE_LIST_FAILURE:
    case GET_ALL_TEAM_POST_WITHOUT_LOGIN_FAILURE:
    case TEMPORARY_STORE_POST_DATA_FAILURE:
    case TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_FAILURE:
    case TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_DATA_FAILURE:
    case TEMPORARY_STORE_USER_POST_LIKE_DATA_FAILURE:
    case TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_CREATE_POST_VALUE:
      return {
        ...state,
        loading: false,
        createPostData: {},
      };
    case DELETE_POST_RESET:
      return {
        ...state,
        loading: false,
        deletePostData: {},
      };

    case HIDE_POST_RESET:
      return {
        ...state,
        loading: false,
        hidePostData: {},
      };
    case ADD_LOVE_IT_POST_CLEAR_DATA:
      return {
        ...state,
        loading: false,
        postLoveItData: "",
      };
    case TEMPORARY_STORE_POST_CLEAR_DATA:
      return {
        ...state,
        loading: false,
        teamporaryStorePostData: [],
      };
    case TEMPORARY_STORE_DELETED_POST_COMMENT_CLEAR_DATA:
      return {
        ...state,
        loading: false,
        temporaryStorePostDeletedCommentData: [],
      };

    case TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_CLEAR_DATA:
      return {
        ...state,
        loading: false,
        temporaryStorePostHideAndUnHideCommentData: [],
      };

    case TEMPORARY_STORE_USER_POST_LIKE_CLEAR_DATA:
      return {
        ...state,
        loading: false,
        temporaryStoreUserLikedPostsData: [],
      };

    case TEMPORARY_STORE_POST_CLEAR_COMMENT_COUNT_DATA:
      return {
        ...state,
        loading: false,
        temporaryStoreUserPostCommentCountData: [],
      };

    case VIEW_MORE_COMMENT_TIME_POST_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        viewMoreTimePostData: {},
      };

    case RESET_IMAGE_URL_DATA:
      return {
        ...state,
        loading: false,
        getImgUrlData: {},
      };

    default:
      return state;
  }
}
