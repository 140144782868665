import {
  CREATE_WORKPLACE_REQUEST,
  CREATE_WORKPLACE_SUCCESS,
  CREATE_WORKPLACE_FAILURE,
  UPDATE_WORKPLACE_REQUEST,
  UPDATE_WORKPLACE_SUCCESS,
  UPDATE_WORKPLACE_FAILURE,
  DELETE_WORKPLACE_REQUEST,
  DELETE_WORKPLACE_SUCCESS,
  DELETE_WORKPLACE_FAILURE,
  GET_ALL_WORKPLACE_REQUEST,
  GET_ALL_WORKPLACE_SUCCESS,
  GET_ALL_WORKPLACE_FAILURE,
  GET_WORKPLACE_BY_ID_REQUEST,
  GET_WORKPLACE_BY_ID_SUCCESS,
  GET_WORKPLACE_BY_ID_FAILURE,
  RESET,
} from "../constants/workplace";

const initialState = {};

export function workplaceReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_WORKPLACE_REQUEST:
    case UPDATE_WORKPLACE_REQUEST:
    case DELETE_WORKPLACE_REQUEST:
    case GET_ALL_WORKPLACE_REQUEST:
    case GET_WORKPLACE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_WORKPLACE_SUCCESS:
      return {
        ...state,
        loading: false,
        createWorkplaceData: action.payload,
      };
    case UPDATE_WORKPLACE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateWorkplaceData: action.payload,
      };
    case DELETE_WORKPLACE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteWorkplaceData: action.payload,
      };
    case GET_ALL_WORKPLACE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllWorkplaceData: action.payload,
      };
    case GET_WORKPLACE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getWorkplaceByIdData: action.payload,
      };
    // case GET_RESET_WORKPLACE_BY_ID_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     getWorkplaceByIdData: {},
    //   };
    case CREATE_WORKPLACE_FAILURE:
    case UPDATE_WORKPLACE_FAILURE:
    case DELETE_WORKPLACE_FAILURE:
    case GET_ALL_WORKPLACE_FAILURE:
    case GET_WORKPLACE_BY_ID_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
