import axios from "axios";
import {
  GET_PROFILE_LIST_DATA_FAILURE,
  GET_PROFILE_LIST_DATA_REQUEST,
  GET_PROFILE_LIST_DATA_SUCCESS,
} from "../constants/profileList";

export const GetProfileListData = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_PROFILE_LIST_DATA_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamSport/getAllUserBusinessAndAssociatedTeams`,
      values
    );
    dispatch({
      type: GET_PROFILE_LIST_DATA_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_PROFILE_LIST_DATA_FAILURE });
  }
};
