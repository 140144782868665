export const GET_ALL_ROOM_REQUEST = "GET_ALL_ROOM_REQUEST";
export const GET_ALL_ROOM_SUCCESS = "GET_ALL_ROOM_SUCCESS";
export const GET_ALL_ROOM_FAILURE = "GET_ALL_ROOM_FAILURE";

export const CREATE_MESSAGE_REQUEST = "CREATE_MESSAGE_REQUEST";
export const CREATE_MESSAGE_SUCCESS = "CREATE_MESSAGE_SUCCESS";
export const CREATE_MESSAGE_FAILURE = "CREATE_MESSAGE_FAILURE";

export const GET_ALL_CHAT_BY_ROOM_REQUEST = "GET_ALL_CHAT_BY_ROOM_REQUEST";
export const GET_ALL_CHAT_BY_ROOM_SUCCESS = "GET_ALL_CHAT_BY_ROOM_SUCCESS";
export const GET_ALL_CHAT_BY_ROOM_FAILURE = "GET_ALL_CHAT_BY_ROOM_FAILURE";

export const GET_ALL_CHAT_GROUP_MEMBER_REQUEST =
  "GET_ALL_CHAT_GROUP_MEMBER_REQUEST";
export const GET_ALL_CHAT_GROUP_MEMBER_SUCCESS =
  "GET_ALL_CHAT_GROUP_MEMBER_SUCCESS";
export const GET_ALL_CHAT_GROUP_MEMBER_FAILURE =
  "GET_ALL_CHAT_GROUP_MEMBER_FAILURE";

export const GET_ALL_MEDIA_BY_ROOM_REQUEST = "GET_ALL_MEDIA_BY_ROOM_REQUEST";
export const GET_ALL_MEDIA_BY_ROOM_SUCCESS = "GET_ALL_MEDIA_BY_ROOM_SUCCESS";
export const GET_ALL_MEDIA_BY_ROOM_FAILURE = "GET_ALL_MEDIA_BY_ROOM_FAILURE";

export const UPDATE_CHAT_SEEN_MESSAGES_REQUEST =
  "UPDATE_CHAT_SEEN_MESSAGES_REQUEST";
export const UPDATE_CHAT_SEEN_MESSAGES_SUCCESS =
  "UPDATE_CHAT_SEEN_MESSAGES_SUCCESS";
export const UPDATE_CHAT_SEEN_MESSAGES_FAILURE =
  "UPDATE_CHAT_SEEN_MESSAGES_FAILURE";

export const GET_ALL_CHAT_BY_ROOMID_REQUEST = "GET_ALL_CHAT_BY_ROOMID_REQUEST";
export const GET_ALL_CHAT_BY_ROOMID_SUCCESS = "GET_ALL_CHAT_BY_ROOMID_SUCCESS";
export const GET_ALL_CHAT_CHAT_BY_ROOMID_FAILURE =
  "GET_ALL_CHAT_CHAT_BY_ROOMID_FAILURE";

export const GET_ALL_CHAT_BY_ROOMID_RESET = "GET_ALL_CHAT_BY_ROOMID_RESET";

export const GET_CHAT_USER_FOLLOWER_REQUEST = "GET_CHAT_USER_FOLLOWER_REQUEST";
export const GET_CHAT_USER_FOLLOWER_SUCCESS = "GET_CHAT_USER_FOLLOWER_SUCCESS";
export const GET_CHAT_USER_FOLLOWER_FAILURE = "GET_CHAT_USER_FOLLOWER_FAILURE";

export const GET_ALL_UNSEEN_COUNT_MESSAGE_REQUEST =
  "GET_ALL_UNSEEN_COUNT_MESSAGE_REQUEST";
export const GET_ALL_UNSEEN_COUNT_MESSAGE_SUCCESS =
  "GET_ALL_UNSEEN_COUNT_MESSAGE_SUCCESS";
export const GET_ALL_UNSEEN_COUNT_MESSAGE_FAILURE =
  "GET_ALL_UNSEEN_COUNT_MESSAGE_FAILURE";
