import React from "react";

const RoleManageMentSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.434"
      height="32.966"
      viewBox="0 0 33.434 32.966"
      className="icon-feather-roles"
    >
      <g
        id="Group_15922"
        data-name="Group 15922"
        transform="translate(4.773 -27.286)"
      >
        <path
          id="Path_482"
          data-name="Path 482"
          d="M63.372,86.439a3.673,3.673,0,0,1-3.714-3.695,3.657,3.657,0,1,1,7.315.012A3.673,3.673,0,0,1,63.372,86.439Zm2.243-3.684a2.323,2.323,0,0,0-2.3-2.32,2.306,2.306,0,0,0-.02,4.613A2.293,2.293,0,0,0,65.615,82.754Z"
          transform="translate(-51.384 -41.273)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_483"
          data-name="Path 483"
          d="M50.473,121.012c0,.144-.009.288,0,.43.015.193-.081.26-.254.257l-.86-.014c-.142,0-.2-.078-.2-.213,0-.415-.018-.83.008-1.244a1.3,1.3,0,0,1,.546-.916,7.134,7.134,0,0,1,2.211-1.143c.522-.187,1.051-.36,1.585-.506a4.039,4.039,0,0,1,2.12-.156,16.694,16.694,0,0,1,2.228.751,13.335,13.335,0,0,1,1.9,1.028,1.786,1.786,0,0,1,.9,1.745c-.055.7.109.674-.672.672-.647,0-.67,0-.638-.636a.8.8,0,0,0-.42-.777,10.876,10.876,0,0,0-2.289-1.1,7.559,7.559,0,0,0-1.346-.393,2.878,2.878,0,0,0-1.036.058,12.845,12.845,0,0,0-3.371,1.215.65.65,0,0,0-.423.755A.984.984,0,0,1,50.473,121.012Z"
          transform="translate(-42.978 -71.962)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_484"
          data-name="Path 484"
          d="M133.92,106.911c-.749,0-1.5,0-2.247,0a.669.669,0,1,1-.034-1.336c.47,0,.94,0,1.41,0,1.012,0,2.024.005,3.035,0a.773.773,0,0,1,.753.483c.159.327-.249.87-.647.87h-2.27Z"
          transform="translate(-108.469 -62.473)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_485"
          data-name="Path 485"
          d="M75.266,30.492c0,.733,0,1.465,0,2.2a.672.672,0,1,1-1.338-.009q0-2.2,0-4.4a.674.674,0,1,1,1.339.011Q75.268,29.394,75.266,30.492Z"
          transform="translate(-62.806)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_486"
          data-name="Path 486"
          d="M76.064,163.764c0-.734,0-1.467,0-2.2a.69.69,0,0,1,.658-.733.714.714,0,0,1,.679.725q.007,2.249,0,4.5a.681.681,0,0,1-.68.664.666.666,0,0,1-.656-.68C76.06,165.279,76.064,164.521,76.064,163.764Z"
          transform="translate(-64.517 -106.715)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_487"
          data-name="Path 487"
          d="M-1.558,107.725c.741,0,1.482,0,2.223,0a.692.692,0,0,1,.718.65.724.724,0,0,1-.722.686c-.868.007-1.737,0-2.605,0q-.955,0-1.91-.006a.721.721,0,0,1-.668-.66.688.688,0,0,1,.648-.67C-3.1,107.721-2.331,107.726-1.558,107.725Z"
          transform="translate(0 -64.198)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_488"
          data-name="Path 488"
          d="M118.568,148.469a2.063,2.063,0,0,1-.456-.265c-.908-.9-1.8-1.818-2.7-2.728-.151-.152-.322-.285-.47-.44a.643.643,0,0,1,.322-1.134.687.687,0,0,1,.536.162c1.105,1.076,2.194,2.169,3.279,3.265a.569.569,0,0,1,.131.679A.76.76,0,0,1,118.568,148.469Z"
          transform="translate(-95.457 -93.156)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_489"
          data-name="Path 489"
          d="M22.8,55.591a1.825,1.825,0,0,1-.441-.241q-1.625-1.6-3.229-3.221a.616.616,0,0,1,0-.916.629.629,0,0,1,.917-.005q1.629,1.613,3.242,3.243a.574.574,0,0,1,.142.678A.717.717,0,0,1,22.8,55.591Z"
          transform="translate(-18.773 -18.795)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_490"
          data-name="Path 490"
          d="M43.161,162.213c.081-.2.151-.391.234-.577q.842-1.893,1.689-3.785a.7.7,0,0,1,.721-.441.63.63,0,0,1,.55.577,1.709,1.709,0,0,1-.181.653c-.556,1.256-1.123,2.507-1.687,3.759-.253.563-.821.673-1.163.217A1.731,1.731,0,0,1,43.161,162.213Z"
          transform="translate(-38.176 -103.973)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_491"
          data-name="Path 491"
          d="M43.252,33.6a.7.7,0,0,1,.556-.676.618.618,0,0,1,.656.329c.236.476.452.962.67,1.447.41.912.814,1.827,1.224,2.74a.632.632,0,0,1-.308.9A.64.64,0,0,1,45.176,38c-.346-.759-.68-1.523-1.021-2.284-.263-.586-.532-1.17-.792-1.757A3.076,3.076,0,0,1,43.252,33.6Z"
          transform="translate(-38.249 -4.3)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_492"
          data-name="Path 492"
          d="M2.651,74.088c.2.081.386.147.565.227q1.9.848,3.806,1.7a.7.7,0,0,1,.44.722.632.632,0,0,1-.579.548A1.728,1.728,0,0,1,6.23,77.1c-1.249-.552-2.492-1.118-3.738-1.675-.571-.255-.7-.8-.253-1.159A1.788,1.788,0,0,1,2.651,74.088Z"
          transform="translate(-5.199 -37.269)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_493"
          data-name="Path 493"
          d="M126.469,76.728a.665.665,0,0,1,.415-.557q2.04-.923,4.084-1.834a.684.684,0,0,1,.987.479.622.622,0,0,1-.3.63c-.476.236-.961.454-1.446.672-.933.421-1.869.837-2.8,1.256A.669.669,0,0,1,126.469,76.728Z"
          transform="translate(-104.873 -37.41)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_494"
          data-name="Path 494"
          d="M2.716,130.888a.683.683,0,0,1-.741-.534.62.62,0,0,1,.331-.656c.469-.233.948-.444,1.426-.659.919-.413,1.842-.821,2.761-1.234a.638.638,0,0,1,.909.33.657.657,0,0,1-.37.865q-2.031.912-4.064,1.821A1.245,1.245,0,0,1,2.716,130.888Z"
          transform="translate(-5.192 -80.214)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_495"
          data-name="Path 495"
          d="M131.254,130.967c-.3-.127-.605-.252-.907-.387q-1.719-.766-3.435-1.536a.7.7,0,0,1-.445-.718.63.63,0,0,1,.573-.553,1.693,1.693,0,0,1,.653.179c1.256.555,2.505,1.126,3.76,1.683a.74.74,0,0,1,.5.614.591.591,0,0,1-.3.564A2.321,2.321,0,0,1,131.254,130.967Z"
          transform="translate(-104.866 -80.25)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_496"
          data-name="Path 496"
          d="M99.943,162.107a.708.708,0,0,1-.533.8.62.62,0,0,1-.656-.33c-.236-.476-.452-.962-.67-1.447-.41-.912-.814-1.827-1.224-2.739a.638.638,0,0,1,.329-.909.645.645,0,0,1,.853.349q.811,1.8,1.61,3.611C99.768,161.7,99.878,161.958,99.943,162.107Z"
          transform="translate(-81.107 -103.988)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_497"
          data-name="Path 497"
          d="M99.963,33.724c-.083.193-.209.5-.344.8-.508,1.138-1.021,2.274-1.528,3.413a.667.667,0,0,1-.863.423.645.645,0,0,1-.333-.943q.825-1.849,1.645-3.7a4.908,4.908,0,0,1,.234-.467.641.641,0,0,1,.659-.326A.708.708,0,0,1,99.963,33.724Z"
          transform="translate(-81.127 -4.301)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_498"
          data-name="Path 498"
          d="M118.378,49.669a2.108,2.108,0,0,1-.271.473c-1.036,1.051-2.084,2.091-3.129,3.134a.717.717,0,0,1-.893.119.672.672,0,0,1-.042-1.038q.872-.877,1.75-1.749c.465-.465.929-.933,1.4-1.4a.658.658,0,0,1,1.018.026A1.991,1.991,0,0,1,118.378,49.669Z"
          transform="translate(-94.745 -17.17)"
          stroke="#000"
          strokeWidth="0.5"
        />
        <path
          id="Path_499"
          data-name="Path 499"
          d="M20.167,150.725a.483.483,0,0,1-.569-.337.706.706,0,0,1,.155-.775q.646-.65,1.3-1.3l1.835-1.833a.686.686,0,0,1,1.047,0,.666.666,0,0,1-.09.9Q22.529,148.7,21.215,150a7.844,7.844,0,0,1-.58.564,2.108,2.108,0,0,1-.446.216Z"
          transform="translate(-19.269 -95.035)"
          stroke="#000"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};
export default RoleManageMentSVG;
