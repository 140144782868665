import { CURRENT_PATH } from "../constants/header";

export function headerReducer(state = {}, action) {
  switch (action.type) {
    // case GET_ALL_SUB_CATEGORY_DATA_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    case CURRENT_PATH:
      return {
        ...state,
        loading: false,
        currentPath: action.payload,
      };

    // case GET_ALL_SUB_CATEGORY_DATA_FAILURE:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    default:
      return state;
  }
}
