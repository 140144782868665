import {
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILURE,
  GET_ALL_POST_REQUEST,
  GET_ALL_POST_SUCCESS,
  GET_ALL_POST_FAILURE,
  GET_ALL_POST_BY_USERID_REQUEST,
  GET_ALL_POST_BY_USERID_SUCCESS,
  GET_ALL_POST_BY_USERID_FAILURE,
  EDIT_POST_REQUEST,
  EDIT_POST_SUCCESS,
  EDIT_POST_FAILURE,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILURE,
  ADD_LOVE_IT_POST_REQUEST,
  ADD_LOVE_IT_POST_SUCCESS,
  ADD_LOVE_IT_POST_FAILURE,
  SINGLE_POST_ALL_LIKES_REQUEST,
  SINGLE_POST_ALL_LIKES_SUCCESS,
  SINGLE_POST_ALL_LIKES_FAILURE,
  HIDE_POST_FAILURE,
  HIDE_POST_REQUEST,
  HIDE_POST_SUCCESS,
  VIEW_MORE_COMMENT_TIME_POST_DATA_REQUEST,
  VIEW_MORE_COMMENT_TIME_POST_DATA_SUCCESS,
  VIEW_MORE_COMMENT_TIME_POST_DATA_FAILURE,
  GET_ALL_REPORT_FAILURE,
  GET_ALL_REPORT_REQUEST,
  GET_ALL_REPORT_SUCCESS,
  CREATE_REPORT_POST_REQUEST,
  CREATE_REPORT_POST_SUCCESS,
  CREATE_REPORT_POST_FAILURE,
  GET_ALL_AUTH_POST_BY_USERID_REQUEST,
  GET_ALL_AUTH_POST_BY_USERID_SUCCESS,
  GET_ALL_AUTH_POST_BY_USERID_FAILURE,
  GET_IMAGE_URL_REQUEST,
  GET_IMAGE_URL_SUCCESS,
  GET_IMAGE_URL_FAILURE,
  POST_TAGGED_PEOPLE_LIST_REQUEST,
  POST_TAGGED_PEOPLE_LIST_SUCCESS,
  POST_TAGGED_PEOPLE_LIST_FAILURE,
  GET_ALL_TEAM_POST_REQUEST,
  GET_ALL_TEAM_POST_SUCCESS,
  GET_ALL_TEAM_POST_FAILURE,
  GET_ALL_TEAM_POST_WITHOUT_LOGIN_REQUEST,
  GET_ALL_TEAM_POST_WITHOUT_LOGIN_SUCCESS,
  GET_ALL_TEAM_POST_WITHOUT_LOGIN_FAILURE,
  TEMPORARY_STORE_POST_DATA_REQUEST,
  TEMPORARY_STORE_POST_DATA_SUCCESS,
  TEMPORARY_STORE_POST_DATA_FAILURE,
  TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_REQUEST,
  TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_SUCCESS,
  TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_FAILURE,
  TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_COMMENT_DATA_REQUEST,
  TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_DATA_SUCCESS,
  TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_DATA_FAILURE,
  TEMPORARY_STORE_USER_POST_LIKE_DATA_REQUEST,
  TEMPORARY_STORE_USER_POST_LIKE_DATA_SUCCESS,
  TEMPORARY_STORE_USER_POST_LIKE_DATA_FAILURE,
  TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_REQUEST,
  TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_SUCCESS,
  TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_FAILURE,
  POST_LIST,
  COMMENT_REPORT_POST_LIST,
} from "../constants/post";

import axios from "axios";

export const CreateUserPost = (values) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_POST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/createPost`,
      values
    );
    // dispatch(GetAllPost());
    // dispatch(GetAllPostByUser());

    dispatch({ type: CREATE_POST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: CREATE_POST_FAILURE });
  }
};
// get image from url
export const GetImageFromUrl = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_IMAGE_URL_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/getImageFromUrl
      `,
      values
    );
    dispatch({ type: GET_IMAGE_URL_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_IMAGE_URL_FAILURE });
  }
};

export const GetAllPostByUser = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_POST_BY_USERID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/getAllDataByiUserId`,
      values && Object.keys(values).length > 0
        ? values
        : {
            iUserId: sessionStorage.getItem("id"),
            iFriendUserId: sessionStorage.getItem("id"),
          }
    );

    dispatch({ type: GET_ALL_POST_BY_USERID_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_POST_BY_USERID_FAILURE });
  }
};

export const GetAllTeamPostWithoutLogin = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_TEAM_POST_WITHOUT_LOGIN_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/getPostDataWithOutLogin`,
      values
    );

    dispatch({
      type: GET_ALL_TEAM_POST_WITHOUT_LOGIN_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_TEAM_POST_WITHOUT_LOGIN_FAILURE });
  }
};
export const GetAllAuthPostByUser = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_AUTH_POST_BY_USERID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/getAllDataByiUserId`,
      values && Object.keys(values).length > 0
        ? values
        : {
            iUserId: sessionStorage.getItem("id"),
          }
    );
    dispatch({
      type: GET_ALL_AUTH_POST_BY_USERID_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_AUTH_POST_BY_USERID_FAILURE });
  }
};

export const HidePostByUser = (values) => async (dispatch) => {
  try {
    dispatch({ type: HIDE_POST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/postHide`,
      values
    );
    dispatch({ type: HIDE_POST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: HIDE_POST_FAILURE });
  }
};

export const GetAllPost = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_POST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/getAllPostData`,
      values
    );
    dispatch({ type: GET_ALL_POST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_POST_FAILURE });
  }
};
export const GetAllTeamPost = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_TEAM_POST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/getAllPostData`,
      values
    );
    dispatch({ type: GET_ALL_TEAM_POST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_TEAM_POST_FAILURE });
  }
};
export const getAllReport = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_REPORT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/report/getAllReport`,
      values
    );

    // console.log("ReportData:",resData.data);

    dispatch({ type: GET_ALL_REPORT_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_REPORT_FAILURE });
  }
};

export const createReportPost = (values) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_REPORT_POST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/report/createReportData`,
      values
    );
    dispatch(getAllReport());
    dispatch({ type: CREATE_REPORT_POST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: CREATE_REPORT_POST_FAILURE });
  }
};

export const EditUserPost = (values) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_POST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/updatePostData`,
      values
    );
    dispatch({ type: EDIT_POST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: EDIT_POST_FAILURE });
  }
};

export const DeleteUserPost = (values) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_POST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/deletePostDataById`,
      values
    );
    dispatch({ type: DELETE_POST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: DELETE_POST_FAILURE });
  }
};

// when the user like and dislike then used
export const PostLoveIt = (values) => async (dispatch) => {
  try {
    dispatch({ type: ADD_LOVE_IT_POST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/postLovesit/createPostLovesit`,
      values
    );
    dispatch({ type: ADD_LOVE_IT_POST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: ADD_LOVE_IT_POST_FAILURE });
  }
};

// when the user show the all post like then used..
export const SinglePostAllLikes = (values) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_POST_ALL_LIKES_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/postLovesit/getUserDetails`,
      values
    );
    dispatch({ type: SINGLE_POST_ALL_LIKES_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: SINGLE_POST_ALL_LIKES_FAILURE });
  }
};

// view more comment time post data then used...
export const ViewMoreCommentPostData = (values) => async (dispatch) => {
  dispatch({ type: VIEW_MORE_COMMENT_TIME_POST_DATA_REQUEST });
  try {
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/getPostByiPostId`,
      values
    );
    dispatch({
      type: VIEW_MORE_COMMENT_TIME_POST_DATA_SUCCESS,
      payload: resData?.data,
    });
  } catch (error) {
    dispatch({ type: VIEW_MORE_COMMENT_TIME_POST_DATA_FAILURE });
  }
};

// when the user show tag people list then used...
export const PostTimeTaggedPeopleList = (values) => (dispatch) => {
  dispatch({ type: POST_TAGGED_PEOPLE_LIST_REQUEST });
  try {
    dispatch({ type: POST_TAGGED_PEOPLE_LIST_SUCCESS, payload: values });
  } catch (error) {
    dispatch({ type: POST_TAGGED_PEOPLE_LIST_FAILURE });
  }
};

// when the user like,comment and replay then store temporary data then used...
export const TemoporaryStorePostDataAction = (values) => (dispatch) => {
  dispatch({ type: TEMPORARY_STORE_POST_DATA_REQUEST });
  try {
    dispatch({ type: TEMPORARY_STORE_POST_DATA_SUCCESS, payload: values });
  } catch (error) {
    dispatch({ type: TEMPORARY_STORE_POST_DATA_FAILURE });
  }
};

// when the user delete the post comment then used...
export const TemporaryStoreDeltedPostCommentAction = (values) => (dispatch) => {
  dispatch({ type: TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_REQUEST });
  try {
    dispatch({
      type: TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_SUCCESS,
      payload: values,
    });
  } catch (error) {
    dispatch({ type: TEMPORARY_STORE_DELETED_POST_COMMENT_DATA_FAILURE });
  }
};

// when the user delete the post comment then used...
export const TemporaryStoreHideAndUnHidePostCommentAction =
  (values) => (dispatch) => {
    dispatch({
      type: TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_COMMENT_DATA_REQUEST,
    });
    try {
      dispatch({
        type: TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_DATA_SUCCESS,
        payload: values,
      });
    } catch (error) {
      dispatch({
        type: TEMPORARY_STORE_HIDE_AND_UNHIDE_POST_POST_COMMENT_DATA_FAILURE,
      });
    }
  };

// when the user like the post then store user liked post data
export const TemporaryStoreUserPostLikeAction = (values) => (dispatch) => {
  dispatch({ type: TEMPORARY_STORE_USER_POST_LIKE_DATA_REQUEST });
  try {
    dispatch({
      type: TEMPORARY_STORE_USER_POST_LIKE_DATA_SUCCESS,
      payload: values,
    });
  } catch (error) {
    dispatch({ type: TEMPORARY_STORE_USER_POST_LIKE_DATA_FAILURE });
  }
};

// when the get user comment count then used..
export const TemporaryStoreUserPostCommentCountAction =
  (values) => (dispatch) => {
    dispatch({ type: TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_REQUEST });
    try {
      dispatch({
        type: TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_SUCCESS,
        payload: values,
      });
    } catch (error) {
      dispatch({ type: TEMPORARY_STORE_POST_COMMENT_COUNT_DATA_FAILURE });
    }
  };

// post list set up
export const SetPostList = (values) => (dispatch) => {
  dispatch({ type: POST_LIST, payload: values });
};

export const SetCommentReportPostList = (values) => (dispatch) => {
  dispatch({ type: COMMENT_REPORT_POST_LIST, payload: values });
};
