import React from "react";

const TeamManagementSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Group_160"
      data-name="Group 160"
      width="30.838"
      height="21.768"
      viewBox="0 0 30.838 21.768"
    >
      <path
        id="fi-rr-users"
        d="M6.8,11.791A4.082,4.082,0,1,1,10.884,7.71,4.082,4.082,0,0,1,6.8,11.791Zm0-6.349A2.268,2.268,0,1,0,9.07,7.71,2.268,2.268,0,0,0,6.8,5.442Zm6.8,15.419v-.454A6.8,6.8,0,1,0,0,20.408v.454a.907.907,0,1,0,1.814,0v-.454a4.989,4.989,0,1,1,9.977,0v.454a.907.907,0,1,0,1.814,0Zm8.163-4.535a6.349,6.349,0,0,0-10.582-4.732.907.907,0,1,0,1.21,1.351,4.535,4.535,0,0,1,7.558,3.38.907.907,0,0,0,1.814,0Zm-5.9-8.163a4.082,4.082,0,1,1,4.082-4.082,4.082,4.082,0,0,1-4.082,4.082Zm0-6.349A2.268,2.268,0,1,0,18.14,4.082,2.268,2.268,0,0,0,15.873,1.814Z"
        transform="translate(9.07)"
      />
      <path
        id="fi-rr-users-2"
        data-name="fi-rr-users"
        d="M12,16.326a6.349,6.349,0,0,1,10.582-4.732.907.907,0,1,1-1.21,1.351,4.535,4.535,0,0,0-7.558,3.38.907.907,0,0,1-1.814,0Zm5.9-8.163a4.082,4.082,0,1,0-4.082-4.082A4.082,4.082,0,0,0,17.9,8.163Zm0-6.349a2.268,2.268,0,1,1-2.268,2.268A2.268,2.268,0,0,1,17.9,1.814Z"
        transform="translate(-12)"
      />
    </svg>
  );
};

export default TeamManagementSVG;
