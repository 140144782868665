import {
  CREATE_PERSONAL_EVENT_REQUEST,
  CREATE_PERSONAL_EVENT_SUCCESS,
  CREATE_PERSONAL_EVENT_FAILURE,
  GET_ALL_PERSONAL_EVENTS_REQUEST,
  GET_ALL_PERSONAL_EVENTS_SUCCESS,
  GET_ALL_PERSONAL_EVENTS_FAILURE,
  GET_ALL_PERSONAL_EVENTS_BY_EVENTID_REQUEST,
  GET_ALL_PERSONAL_EVENTS_BY_EVENTID_SUCCESS,
  GET_ALL_PERSONAL_EVENTS_BY_EVENTID_FAILURE,
  EDIT_PERSONAL_EVENT_REQUEST,
  EDIT_PERSONAL_EVENT_SUCCESS,
  EDIT_PERSONAL_EVENT_FAILURE,
  RESET_STATE,
  REMOVE_ENTIRE_PERSONAL_EVENT_REQUEST,
  REMOVE_ENTIRE_PERSONAL_EVENT_SUCCESS,
  REMOVE_ENTIRE_PERSONAL_EVENT_FAILURE,
  REMOVE_PARTICULAR_PERSONAL_EVENT_REQUEST,
  REMOVE_PARTICULAR_PERSONAL_EVENT_SUCCESS,
  REMOVE_PARTICULAR_PERSONAL_EVENT_FAILURE
} from "../constants/personalCalendar";

const initialState = {};

export function personalEventReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_PERSONAL_EVENT_REQUEST:
    case GET_ALL_PERSONAL_EVENTS_REQUEST:
    case GET_ALL_PERSONAL_EVENTS_BY_EVENTID_REQUEST:
    case EDIT_PERSONAL_EVENT_REQUEST:
    case REMOVE_ENTIRE_PERSONAL_EVENT_REQUEST:
    case REMOVE_PARTICULAR_PERSONAL_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_PERSONAL_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        editPersonalEventData: action.payload,
      };
    case CREATE_PERSONAL_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        createPersonalEventData: action.payload,
      };
    case GET_ALL_PERSONAL_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allPersonalEventData: action.payload,
      };
    case GET_ALL_PERSONAL_EVENTS_BY_EVENTID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllPersonalEventsByIdData: action.payload,
      };
    case REMOVE_PARTICULAR_PERSONAL_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        removeParticularPersonalEventData: action.payload,
      };
    case REMOVE_ENTIRE_PERSONAL_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        removeEntirePersonalEventData: action.payload,
      };
    case CREATE_PERSONAL_EVENT_FAILURE:
    case GET_ALL_PERSONAL_EVENTS_FAILURE:
    case GET_ALL_PERSONAL_EVENTS_BY_EVENTID_FAILURE:
    case EDIT_PERSONAL_EVENT_FAILURE:
    case REMOVE_ENTIRE_PERSONAL_EVENT_FAILURE:
    case REMOVE_PARTICULAR_PERSONAL_EVENT_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
