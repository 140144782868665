export const GET_ALL_EMBEDED_VIDEO_REQUEST = "GET_ALL_EMBEDED_VIDEO_REQUEST"
export const GET_ALL_EMBEDED_VIDEO_SUCCESS = "GET_ALL_EMBEDED_VIDEO_SUCCESS"
export const GET_ALL_EMBEDED_VIDEO_FAILURE = "GET_ALL_EMBEDED_VIDEO_FAILURE"

export const CREATE_EMBEDED_VIDEO_REQUEST = "CREATE_EMBEDED_VIDEO_REQUEST"
export const CREATE_EMBEDED_VIDEO_SUCCESS = "CREATE_EMBEDED_VIDEO_SUCCESS"
export const CREATE_EMBEDED_VIDEO_FAILURE = "CREATE_EMBEDED_VIDEO_FAILURE"

export const UPDATE_EMBEDED_VIDEO_REQUEST = "UPDATE_EMBEDED_VIDEO_REQUEST"
export const UPDATE_EMBEDED_VIDEO_SUCCESS = "UPDATE_EMBEDED_VIDEO_SUCCESS"
export const UPDATE_EMBEDED_VIDEO_FAILURE = "UPDATE_EMBEDED_VIDEO_FAILURE"

export const REMOVE_EMBEDED_VIDEO_REQUEST = "REMOVE_EMBEDED_VIDEO_REQUEST"
export const REMOVE_EMBEDED_VIDEO_SUCCESS = "REMOVE_EMBEDED_VIDEO_SUCCESS"
export const REMOVE_EMBEDED_VIDEO_FAILURE = "REMOVE_EMBEDED_VIDEO_FAILURE"


export const GET_ALL_EMBEDED_VIDEO_BY_ID_REQUEST = "GET_ALL_EMBEDED_VIDEO_BY_ID_REQUEST"
export const GET_ALL_EMBEDED_VIDEO_BY_ID_SUCCESS = "GET_ALL_EMBEDED_VIDEO_BY_ID_SUCCESS"
export const GET_ALL_EMBEDED_VIDEO_BY_ID_FAILURE = "GET_ALL_EMBEDED_VIDEO_BY_ID_FAILURE"