import {
  ADD_MEMBER_BY_EMAIL_FAILURE,
  ADD_MEMBER_BY_EMAIL_REQUEST,
  ADD_MEMBER_BY_EMAIL_SUCCESS,
  CREATE_GROUP_POST_SUCCESS,
  GET_ALL_GROUP_DATA_FAILURE,
  GET_ALL_GROUP_DATA_REQUEST,
  GET_ALL_GROUP_DATA_SUCCESS,
  GET_ALL_GROUP_MATES_FAILURE,
  GET_ALL_GROUP_MATES_REQUEST,
  GET_ALL_GROUP_MATES_SUCCESS,
  GET_ALL_GROUP_MESSAGE_BOARD_POST_FAILURE,
  GET_ALL_GROUP_MESSAGE_BOARD_POST_REQUEST,
  GET_ALL_GROUP_MESSAGE_BOARD_POST_SUCCESS,
  JOIN_GROUP_BY_ACCESS_CODE_FAILURE,
  JOIN_GROUP_BY_ACCESS_CODE_REQUEST,
  JOIN_GROUP_BY_ACCESS_CODE_SUCCESS,
  POST_DATA_ARRAY_GROUP,
  REPORT_POST_GROUP_VALUES,
  SELECTED_GROUP_FOR_CREATE_POST,
  SELECTED_GROUP_FOR_FEED,
  SELECTED_GROUP_OBJ,
  SINGLE_POST_GROUP_ALL_LIKES_FAILURE,
  SINGLE_POST_GROUP_ALL_LIKES_REQUEST,
  SINGLE_POST_GROUP_ALL_LIKES_SUCCESS,
  UPDATE_GROUP_PROFILE_FAILURE,
  UPDATE_GROUP_PROFILE_REQUEST,
  UPDATE_GROUP_PROFILE_SUCCESS,
  GET_ALL_BANNED_GROUP_MATES_REQUEST,
  GET_ALL_BANNED_GROUP_MATES_SUCCESS,
  GET_ALL_BANNED_GROUP_MATES_FAILURE,
  UPDATE_GROUP_SETTINGS_SUCCESS,
  UPDATE_GROUP_SETTINGS_FAILURE,
  UPDATE_GROUP_SETTINGS_REQUEST,
  UPLOADED_VIDEO_BLOB,
} from "../constants/group";

export function groupsReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_GROUP_DATA_REQUEST:
    case UPDATE_GROUP_PROFILE_REQUEST:
    case ADD_MEMBER_BY_EMAIL_REQUEST:
    case JOIN_GROUP_BY_ACCESS_CODE_REQUEST:
    case GET_ALL_GROUP_MATES_REQUEST:
    case GET_ALL_GROUP_MESSAGE_BOARD_POST_REQUEST:
    case GET_ALL_BANNED_GROUP_MATES_REQUEST:
    case SINGLE_POST_GROUP_ALL_LIKES_REQUEST:
    case UPDATE_GROUP_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_GROUP_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllGroupsData: action.payload,
      };
    case UPDATE_GROUP_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateGroupProfile: action.payload,
      };
    case ADD_MEMBER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        addMemberByEmailId: action.payload,
      };
    case JOIN_GROUP_BY_ACCESS_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        joinGroupByAccessCode: action.payload,
      };
    case GET_ALL_GROUP_MATES_SUCCESS:
      return {
        ...state,
        loading: false,
        getGroupMates: action.payload,
      };
    case GET_ALL_GROUP_MESSAGE_BOARD_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllGroupMessagePosts: action.payload,
      };
    case CREATE_GROUP_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        createGroupPostSuccess: action.payload,
      };
    case SINGLE_POST_GROUP_ALL_LIKES_SUCCESS:
      return {
        ...state,
        loading: false,
        singlePostAllLikesDataGroup: action.payload,
      };
    case UPDATE_GROUP_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateGroupSettingsRes: action.payload,
      };
    case SELECTED_GROUP_OBJ:
      return {
        ...state,
        loading: false,
        selectedGroupObject: action.payload,
      };
    case SELECTED_GROUP_FOR_FEED:
      return {
        ...state,
        loading: false,
        selectedGroupObjectFeed: action.payload,
      };
    case SELECTED_GROUP_FOR_CREATE_POST:
      return {
        ...state,
        loading: false,
        selectedGroupForCreatePost: action.payload,
      };
    case POST_DATA_ARRAY_GROUP:
      return {
        ...state,
        loading: false,
        postDataArrayGroup: action.payload,
      };
    case GET_ALL_BANNED_GROUP_MATES_SUCCESS:
      return {
        ...state,
        loading: false,
        getBannedGroupMates: action.payload,
      };
    case REPORT_POST_GROUP_VALUES:
      return {
        ...state,
        loading: false,
        report: action.payload,
      };
    case UPLOADED_VIDEO_BLOB:
      return {
        ...state,
        loading: false,
        uploadVideoBlob: action.payload,
      };
    case GET_ALL_BANNED_GROUP_MATES_FAILURE:
    case GET_ALL_GROUP_DATA_FAILURE:
    case UPDATE_GROUP_PROFILE_FAILURE:
    case ADD_MEMBER_BY_EMAIL_FAILURE:
    case JOIN_GROUP_BY_ACCESS_CODE_FAILURE:
    case GET_ALL_GROUP_MATES_FAILURE:
    case GET_ALL_GROUP_MESSAGE_BOARD_POST_FAILURE:
    case SINGLE_POST_GROUP_ALL_LIKES_FAILURE:
    case UPDATE_GROUP_SETTINGS_FAILURE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
