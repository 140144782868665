import React from "react";

const IdentitySVG = () =>{
    return(
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 352.78 352.78"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path d="M282.78,60.8H216V39.6a39.6,39.6,0,0,0-79.2,0V60.8H70a70.19,70.19,0,0,0-70,70v152a70.21,70.21,0,0,0,70,70H282.79a70.2,70.2,0,0,0,70-70v-152A70.18,70.18,0,0,0,282.78,60.8ZM155.19,39.6a21.2,21.2,0,0,1,42.4,0V70a21.2,21.2,0,0,1-42.4,0ZM334.38,282.78a51.65,51.65,0,0,1-51.59,51.6H70a51.65,51.65,0,0,1-51.59-51.6v-152A51.65,51.65,0,0,1,70,79.19h67.86l1.4,4a39.33,39.33,0,0,0,74.28,0l1.4-4h67.86a51.65,51.65,0,0,1,51.59,51.6Z" />
            <path d="M261.59,282.78a9.21,9.21,0,0,1-9.2,9.2h-45.6a9.2,9.2,0,1,1,0-18.4h45.6A9.21,9.21,0,0,1,261.59,282.78Z" />
            <path d="M292,222a9.21,9.21,0,0,1-9.19,9.2h-76a9.2,9.2,0,0,1,0-18.4h76A9.2,9.2,0,0,1,292,222Z" />
            <path d="M292,161.19a9.21,9.21,0,0,1-9.19,9.2h-76a9.2,9.2,0,0,1,0-18.4h76A9.21,9.21,0,0,1,292,161.19Z" />
            <path d="M146,152H70a9.21,9.21,0,0,0-9.19,9.2V282.78A9.2,9.2,0,0,0,70,292h76a9.21,9.21,0,0,0,9.2-9.2V161.19A9.21,9.21,0,0,0,146,152Zm-9.2,121.59H79.19V170.39h57.6Z" />
          </g>
        </g>
      </svg>
    );
}
export default IdentitySVG;