import React from "react";
const OrderReceiptSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.71"
      height="23.876"
      viewBox="0 0 18.71 23.876"
    >
      <g id="Receipt" transform="translate(-2.75 0.25)">
        <path
          id="Path_30290"
          data-name="Path 30290"
          d="M16.152,0H8.058A4.971,4.971,0,0,0,3,4.865V22.38a.969.969,0,0,0,.54.86,1.046,1.046,0,0,0,1.043-.057l2.131-1.4,2.131,1.4a1.045,1.045,0,0,0,1.143,0l2.125-1.4,2.125,1.4a1.045,1.045,0,0,0,1.144,0l2.125-1.4,2.125,1.4a1.046,1.046,0,0,0,1.041.057.969.969,0,0,0,.54-.858V4.865A4.971,4.971,0,0,0,16.152,0Zm3.035,20.531L18.074,19.8a1.045,1.045,0,0,0-1.145,0L14.8,21.2,12.68,19.8a1.045,1.045,0,0,0-1.144,0L9.411,21.2,7.287,19.8a1.045,1.045,0,0,0-1.142,0l-1.121.732V4.865A2.979,2.979,0,0,1,8.058,1.946h8.094a2.979,2.979,0,0,1,3.035,2.919Z"
          stroke="#fff"
          strokeWidth="0.5"
        />
        <rect
          id="Rectangle_25"
          data-name="Rectangle 25"
          width="11"
          height="2"
          rx="1"
          transform="translate(7 7.705)"
          stroke="#fff"
          strokeWidth="0.5"
        />
        <rect
          id="Rectangle_26"
          data-name="Rectangle 26"
          width="7"
          height="2"
          rx="1"
          transform="translate(8 11.705)"
          stroke="#fff"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};
export default OrderReceiptSVG;
