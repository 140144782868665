import {
    GET_ALL_EMBEDED_VIDEO_REQUEST,
    GET_ALL_EMBEDED_VIDEO_SUCCESS,
    GET_ALL_EMBEDED_VIDEO_FAILURE,
    CREATE_EMBEDED_VIDEO_REQUEST,
    CREATE_EMBEDED_VIDEO_SUCCESS,
    CREATE_EMBEDED_VIDEO_FAILURE,
    UPDATE_EMBEDED_VIDEO_REQUEST,
    UPDATE_EMBEDED_VIDEO_SUCCESS,
    UPDATE_EMBEDED_VIDEO_FAILURE,
    REMOVE_EMBEDED_VIDEO_REQUEST,
    REMOVE_EMBEDED_VIDEO_SUCCESS,
    REMOVE_EMBEDED_VIDEO_FAILURE,
    GET_ALL_EMBEDED_VIDEO_BY_ID_REQUEST,
    GET_ALL_EMBEDED_VIDEO_BY_ID_SUCCESS,
    GET_ALL_EMBEDED_VIDEO_BY_ID_FAILURE,
} from '../constants/embedVideo';

// const initialState = {};



export function embedVideoReducer(state = {}, action) {
    switch (action.type) {
        case GET_ALL_EMBEDED_VIDEO_REQUEST:
        case CREATE_EMBEDED_VIDEO_REQUEST:
        case UPDATE_EMBEDED_VIDEO_REQUEST:
        case REMOVE_EMBEDED_VIDEO_REQUEST:
        case GET_ALL_EMBEDED_VIDEO_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_EMBEDED_VIDEO_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllEmbedVideosData: action.payload,
            };
        case CREATE_EMBEDED_VIDEO_SUCCESS:
            return {
                ...state,
                loading: false,
                createEmbedVideoData: action.payload,
            };
        case UPDATE_EMBEDED_VIDEO_SUCCESS:
            return {
                ...state,
                loading: false,
                updateEmbedVideoData: action.payload,
            };
        case REMOVE_EMBEDED_VIDEO_SUCCESS:
            return {
                ...state,
                loading: false,
                removeEmbedVideoData: action.payload,
            };
        case GET_ALL_EMBEDED_VIDEO_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                allEmbedVidoesByIdData: action.payload,
            };

        case GET_ALL_EMBEDED_VIDEO_FAILURE:
        case CREATE_EMBEDED_VIDEO_FAILURE:
        case UPDATE_EMBEDED_VIDEO_FAILURE:
        case REMOVE_EMBEDED_VIDEO_FAILURE:
        case GET_ALL_EMBEDED_VIDEO_BY_ID_FAILURE:
            return {
                ...state,
                loading: true,
            };
        // case RESET_STATE:
        //     return initialState;
        default:
            return state;
    }
}
